import { Subheading } from '@/components/heading.tsx'
import { Text } from '@/components/text.tsx'
import { Trans } from '@lingui/macro'
import type { ReactNode } from 'react'

export function TypeSection(props: { propertyTypeField: ReactNode; listingTypeField: ReactNode }) {
  return (
    <section className='grid gap-x-8 gap-y-6 sm:grid-cols-2'>
      <div className='space-y-1'>
        <Subheading>
          <Trans context='EditListingsDetailsPage' comment='Subheading text for the type section'>
            Type
          </Trans>
        </Subheading>
        <Text>
          <Trans context='EditListingDetailsPage' comment='Detail text for the type section'>
            Specify the type of property and listing.
          </Trans>
        </Text>
      </div>
      <div className='space-y-4'>
        {props.propertyTypeField}
        {props.listingTypeField}
      </div>
    </section>
  )
}
