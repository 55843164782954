import {
  GetListingResponse,
  Listing,
  ListingType,
  MeasurementUnit,
  PropertyType,
  Style,
} from '@/pb/service/listing/v1/listing_service_pb'
import { Image, VariantType } from '@/pb/service/media/v1/media_service_pb'

export const demoGetListingResponse = new GetListingResponse({
  listing: new Listing({
    id: 'listing-1',
    amenities: ['home gym', 'heated pool'],
    area: 4919,
    bathrooms: 5,
    bedrooms: 4,
    beds: 0,
    cityOrNeighborhood: 'Dallas',
    country: 'United States',
    features: ['3 car garage', 'gated community', "chef's kitchen", 'outdoor grills'],
    listingType: ListingType.FOR_SALE,
    lotSize: 8058,
    measurementUnit: MeasurementUnit.IMPERIAL,
    name: '414 Oak Drive',
    nearbyAttractions: ['dog park', 'shopping malls'],
    propertyType: PropertyType.SINGLE_FAMILY_HOME,
    stateOrRegion: 'Texas',
    style: Style.FARMHOUSE,
    yearBuilt: 2023,
    images: [
      new Image({
        id: 'image-1',
        variants: [
          {
            id: 'image-1-variant',
            url: '/demo/images/home-front.webp',
            width: 1536,
            height: 1024,
            type: VariantType.ORIGINAL,
            contentType: 'image/webp',
          },
        ],
      }),
      new Image({
        id: 'image-2',
        variants: [
          {
            id: 'image-2-variant',
            url: '/demo/images/home-living-room.webp',
            width: 1536,
            height: 1024,
            type: VariantType.ORIGINAL,
            contentType: 'image/webp',
          },
        ],
      }),
      new Image({
        id: 'image-3',
        variants: [
          {
            id: 'image-3-variant',
            url: '/demo/images/home-kitchen.webp',
            width: 1536,
            height: 1024,
            type: VariantType.ORIGINAL,
            contentType: 'image/webp',
          },
        ],
      }),
      new Image({
        id: 'image-4',
        variants: [
          {
            id: 'image-4-variant',
            url: '/demo/images/home-bathroom.webp',
            width: 1536,
            height: 1024,
            type: VariantType.ORIGINAL,
            contentType: 'image/webp',
          },
        ],
      }),
      new Image({
        id: 'image-5',
        variants: [
          {
            id: 'image-5-variant',
            url: '/demo/images/home-backyard.webp',
            width: 1536,
            height: 1024,
            type: VariantType.ORIGINAL,
            contentType: 'image/webp',
          },
        ],
      }),
    ],
  }),
})
