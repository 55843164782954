import { createFileRoute } from '@tanstack/react-router'

import { Alert, AlertActions, AlertDescription, AlertTitle } from '@/components/alert.tsx'
import { Badge } from '@/components/badge.tsx'
import { Button } from '@/components/button.tsx'
import { Heading } from '@/components/heading.tsx'
import { Link } from '@/components/link.tsx'
import { Strong, Text } from '@/components/text.tsx'
import { getListing } from '@/pb/service/listing/v1/listing_service-ListingsService_connectquery'
import { ListingType, PropertyType, Style } from '@/pb/service/listing/v1/listing_service_pb'
import type { Image } from '@/pb/service/media/v1/media_service_pb'
import { ErrorPage } from '@/routes/-components/error-page.tsx'
import ResponsiveImage from '@/routes/-components/responsive-image.tsx'
import { SpinnerButton } from '@/routes/-components/spinner-button.tsx'
import { useDeleteListingMutation } from '@/routes/_protected/_dashboard/-listings/mutations/useDeleteListingMutation.ts'
import { formatValue } from '@/utils/format-util.ts'
import { toastOptions } from '@/utils/toast-util.ts'
import { createQueryOptions, useSuspenseQuery } from '@connectrpc/connect-query'
import { ChevronLeftIcon, TrashIcon } from '@heroicons/react/20/solid'
import * as Sentry from '@sentry/react'
import { clsx } from 'clsx'
import { type ReactNode, useState } from 'react'

import { getLocalizedError } from '@/i18n/error-localization.ts'
import {
  getLocalizedListingType,
  getLocalizedPropertyType,
  getLocalizedShortAreaMeasurementUnit,
  getLocalizedStyle,
} from '@/i18n/listing-localization.ts'
import { Plural, Trans, msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { toast } from 'sonner'

export const Route = createFileRoute('/_protected/_dashboard/listings/$id')({
  component: ListingDetail,
  loader: async ({ context: { queryClient, connectTransport }, params }) => {
    await queryClient.prefetchQuery({
      ...createQueryOptions(getListing, { id: params.id }, { transport: connectTransport }),
    })
  },
})

function ListingDetail(): ReactNode {
  const params = Route.useParams()
  const navigate = Route.useNavigate()
  const { queryClient } = Route.useRouteContext()

  const { data } = useSuspenseQuery(getListing, { id: params.id })
  const deleteListingMutation = useDeleteListingMutation(queryClient)

  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)

  const { _, i18n } = useLingui()

  if (data.listing === undefined) {
    return <ErrorPage />
  }

  const listing = data.listing
  const images = listing.images

  async function handleDeleteListing() {
    try {
      await deleteListingMutation.mutateAsync({ id: params.id })
      await navigate({ to: '/listings' })
      toast.success(
        _(
          msg({
            context: 'ToastAlert',
            comment: `Toast alert text that's shown when a listing is successfully delete`,
            message: 'Listing deleted',
          }),
        ),
        toastOptions,
      )
    } catch (e) {
      const { isExpectedError, message } = getLocalizedError(e)
      if (!isExpectedError) {
        Sentry.captureException(e)
      }
      toast.error(_(message), toastOptions)
    }
  }

  return (
    <>
      <div className='max-lg:hidden'>
        <Link href='/listings' className='inline-flex items-center gap-2 text-base/6 text-zinc-500 dark:text-zinc-400'>
          <ChevronLeftIcon className='size-4 fill-zinc-400 dark:fill-zinc-500' />
          <Trans context='ListingDetailPage' comment='Link text for navigating back to listings list page'>
            Listings
          </Trans>
        </Link>
      </div>
      <ImagesPreview className='mt-4 h-56 w-full sm:h-[30rem] lg:mt-8' images={images} />
      <div className='mt-8 flex flex-col sm:flex-row sm:items-start sm:justify-between'>
        <div>
          <div className='flex flex-wrap items-center gap-x-4 gap-y-2'>
            <Heading>{listing?.name}</Heading>
            {listing.propertyType !== PropertyType.PROPERTY_TYPE_UNSPECIFIED &&
              listing.listingType !== ListingType.LISTING_TYPE_UNSPECIFIED && (
                <Badge color={'zinc'}>
                  <Trans context='ListingDetailPage' comment='Badge text that joins the property and listing type'>
                    {_(getLocalizedPropertyType(listing.propertyType))} -{' '}
                    {_(getLocalizedListingType(listing.listingType))}
                  </Trans>
                </Badge>
              )}
          </div>
        </div>
        <div className='mt-6 flex flex-col-reverse content-between gap-4 sm:mt-0 sm:flex-none sm:flex-row'>
          <Button className='hidden sm:block' outline onClick={() => setIsDeleteAlertOpen(true)}>
            <TrashIcon className='fill-gray-800 dark:fill-gray-200' />
          </Button>
          <Button
            className='text-sm/6 sm:hidden sm:flex-none sm:text-base/6'
            plain
            onClick={() => setIsDeleteAlertOpen(true)}
          >
            <Trans context='ListingDetailPage' comment='Button text for deleting the listing'>
              Delete
            </Trans>
          </Button>
          <Button
            className='text-sm/6 sm:text-base/6'
            outline
            onClick={async () => await navigate({ to: '/listings/$id/edit/details', params: { id: listing.id } })}
          >
            <Trans context='ListingDetailPage' comment='Button text for editing the listing details'>
              Edit details
            </Trans>
          </Button>
          <Button
            className='w-full text-sm/6 sm:w-fit sm:text-base/6'
            href='/listings/$id/generate'
            params={{ id: params.id }}
          >
            <Trans context='ListingDetailPage' comment='Button text for navigating to the generate description page'>
              Generate Description
            </Trans>
          </Button>
          <Alert open={isDeleteAlertOpen} onClose={() => setIsDeleteAlertOpen(false)}>
            <AlertTitle>
              <Trans context='ListingDetailPage' comment='Alert title text for delete listing confirmation'>
                Are you sure you want to delete this listing?
              </Trans>
            </AlertTitle>
            <AlertDescription>
              <Trans context='ListingDetailPage' comment='Alert description text for delete listing confirmation'>
                You will delete everything associated with this listing, including generated descriptions.
              </Trans>
            </AlertDescription>
            <AlertActions>
              <Button plain onClick={() => setIsDeleteAlertOpen(false)} disabled={deleteListingMutation.isPending}>
                <Trans context='ListingDetailPage' comment='Alert action button text for cancelling listing deletion'>
                  Cancel
                </Trans>
              </Button>
              <SpinnerButton
                onClick={handleDeleteListing}
                type='submit'
                disabled={deleteListingMutation.isPending}
                showSpinner={deleteListingMutation.isPending}
              >
                <Trans context='ListingDetailPage' comment='Alert action button text for submitting listing deletion'>
                  Delete
                </Trans>
              </SpinnerButton>
            </AlertActions>
          </Alert>
        </div>
      </div>
      <div className='mt-4 sm:mt-10'>
        <dl className='grid grid-cols-1 sm:grid-cols-2'>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='ListingDetailPage' comment='Label text for listing name'>
                    Name
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{formatValue(listing.name)}</Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='ListingDetailPage' comment='Label text for city or neighborhood'>
                    City or Neighborhood
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{formatValue(listing.cityOrNeighborhood)}</Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='ListingDetailPage' comment='Label text for state or region'>
                    State or Region
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{formatValue(listing.stateOrRegion)}</Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='ListingDetailPage' comment='Label text for country'>
                    Country
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{formatValue(listing.country)}</Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='ListingDetailPage' comment='Label text for property type'>
                    Property Type
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>
                {listing.propertyType === PropertyType.PROPERTY_TYPE_UNSPECIFIED
                  ? '-'
                  : _(getLocalizedPropertyType(listing.propertyType))}
              </Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='ListingDetailPage' comment='Label text for listing type'>
                    Listing Type
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>
                {listing.listingType === ListingType.LISTING_TYPE_UNSPECIFIED
                  ? '-'
                  : _(getLocalizedListingType(listing.listingType))}
              </Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='ListingDetailPage' comment='Label text for style'>
                    Style
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{listing.style === Style.STYLE_UNSPECIFIED ? '-' : _(getLocalizedStyle(listing.style))}</Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='ListingDetailPage' comment='Label text for bedroom count'>
                    Bedrooms
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>
                <Plural
                  context='ListingDetailPage'
                  comment='Text that shows the number of bedrooms'
                  value={listing.bedrooms}
                  _0={<Trans>-</Trans>}
                  one={<Trans>{i18n.number(listing.bedrooms)} bedroom</Trans>}
                  two={<Trans>{i18n.number(listing.bedrooms)} bedrooms</Trans>}
                  few={<Trans>{i18n.number(listing.bedrooms)} bedrooms</Trans>}
                  many={<Trans>{i18n.number(listing.bedrooms)} bedrooms</Trans>}
                  other={<Trans>{i18n.number(listing.bedrooms)} bedrooms</Trans>}
                />
              </Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='ListingDetailPage' comment='Label text for bathroom count'>
                    Bathrooms
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>
                <Plural
                  context='ListingDetailPage'
                  comment='Text that displays the bathroom count'
                  value={listing.bathrooms}
                  _0={<Trans>-</Trans>}
                  one={<Trans>{i18n.number(listing.bathrooms)} bathroom</Trans>}
                  two={<Trans>{i18n.number(listing.bathrooms)} bathrooms</Trans>}
                  few={<Trans>{i18n.number(listing.bathrooms)} bathrooms</Trans>}
                  many={<Trans>{i18n.number(listing.bathrooms)} bathrooms</Trans>}
                  other={<Trans>{i18n.number(listing.bathrooms)} bathrooms</Trans>}
                />
              </Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='ListingDetailPage' comment='Label text for area'>
                    Area
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>
                <Plural
                  context='ListingDetailPage'
                  comment='Text that displays the area along with its abbreviated measurement unit'
                  value={listing.area}
                  _0={<Trans>-</Trans>}
                  one={
                    <Trans>
                      {i18n.number(listing.area)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                  two={
                    <Trans>
                      {i18n.number(listing.area)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                  few={
                    <Trans>
                      {i18n.number(listing.area)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                  many={
                    <Trans>
                      {i18n.number(listing.area)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                  other={
                    <Trans>
                      {i18n.number(listing.area)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                />
              </Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='ListingDetailPage' comment='Label text for lot size'>
                    Lot Size
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>
                <Plural
                  context='ListingDetailPage'
                  comment='Text that displays the lot size along with its abbreviated measurement unit'
                  value={listing.lotSize}
                  _0={<Trans>-</Trans>}
                  one={
                    <Trans>
                      {i18n.number(listing.lotSize)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                  two={
                    <Trans>
                      {i18n.number(listing.lotSize)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                  few={
                    <Trans>
                      {i18n.number(listing.lotSize)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                  many={
                    <Trans>
                      {i18n.number(listing.lotSize)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                  other={
                    <Trans>
                      {i18n.number(listing.lotSize)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                />
              </Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='ListingDetailPage' comment='Label text for year built'>
                    Year Built
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>
                {listing.yearBuilt === 0
                  ? '-'
                  : i18n.date(new Date(Date.UTC(listing.yearBuilt, 1)), { year: 'numeric' })}
              </Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-2 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='ListingDetailPage' comment='Label text for amenities'>
                    Amenities
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{formatValue(listing.amenities)}</Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-2 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='ListingDetailPage' comment='Label text for nearby attractions'>
                    Nearby Attractions
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{formatValue(listing.nearbyAttractions)}</Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-2 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='ListingDetailPage' comment='Label text for features or selling points'>
                    Features or Selling Points
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{formatValue(listing.features)}</Text>
            </dd>
          </div>
        </dl>
      </div>
    </>
  )
}

function ImagesPreview(props: { images: Image[]; className?: string }): ReactNode {
  const params = Route.useParams()
  const imageCount = props.images.length || 0

  const renderImages = () => {
    switch (imageCount) {
      case 0:
        return (
          <div className='flex h-full w-full items-center justify-center rounded-md bg-gray-100 dark:bg-zinc-800' />
        )
      case 1:
        return (
          <ResponsiveImage
            sizes='100vw'
            alt='Listing image'
            variants={props.images[0].variants || []}
            className='h-full w-full rounded-md bg-gray-300 object-cover object-center'
          />
        )
      case 2:
        return (
          <div className='flex h-full w-full gap-1 sm:gap-2'>
            {props.images.map((image, index) => (
              <ResponsiveImage
                key={image.id}
                sizes='50vw'
                alt={`Listing image ${index + 1}`}
                variants={image.variants || []}
                className={clsx(
                  index === 0 ? 'rounded-l-md' : 'rounded-r-md',
                  'h-full w-1/2 bg-gray-300 object-cover object-center',
                )}
              />
            ))}
          </div>
        )
      case 3:
      case 4:
        return (
          <div className='flex h-full w-full gap-1 sm:gap-2'>
            <ResponsiveImage
              sizes='50vw'
              alt='Listing image 1'
              variants={props.images[0].variants || []}
              className='h-full min-h-0 w-7/12 rounded-l-md bg-gray-300 object-cover object-center'
            />
            <div className='flex w-5/12 flex-col gap-1 sm:gap-2'>
              {props.images.slice(1, 3).map((image, index) => (
                <ResponsiveImage
                  key={image.id}
                  sizes='25vw'
                  alt={`Listing image ${index + 2}`}
                  variants={image.variants || []}
                  className={`h-1/2 min-h-0 w-full bg-gray-300 object-cover object-center ${
                    index === 0 ? 'rounded-tr-md' : 'rounded-br-md'
                  }`}
                />
              ))}
            </div>
          </div>
        )
      default: // 5 or 6 images
        return (
          <>
            <div className='@xl:w-1/2 w-3/5'>
              <ResponsiveImage
                sizes='(min-w: 640px) 50vw, (min-w: 768px) 45vw, (min-w: 1024px) 45vw, (min-w: 1280px) 35vw, (min-w: 1536px) 40vw, 30vw'
                alt='Listing image 1'
                variants={props.images[0].variants || []}
                className='h-full w-full rounded-l-md bg-gray-300 object-cover object-center'
              />
            </div>
            <div className='flex @xl:w-1/4 w-2/5 flex-col items-stretch gap-2'>
              {props.images.slice(1, 3).map((image, index) => (
                <div key={image.id} className='min-h-0 flex-1'>
                  <ResponsiveImage
                    sizes='(min-w: 640px) 35vw, (min-w: 768px) 20vw, (min-w: 1024px) 25vw, (min-w: 1280px) 20vw, (min-w: 1536px) 20vw, 15vw'
                    alt={`Listing image ${index + 2}`}
                    variants={image.variants || []}
                    className='h-full w-full @xl:rounded-r-none rounded-r-md bg-gray-300 object-cover object-center'
                  />
                </div>
              ))}
            </div>
            <div className='@xl:flex hidden @xl:w-1/4 @xl:flex-col @xl:items-stretch @xl:gap-y-2'>
              {props.images.slice(3, 5).map((image, index) => (
                <div key={image.id} className='min-h-0 flex-1'>
                  <ResponsiveImage
                    sizes='(min-w: 640px) 35vw, (min-w: 768px) 20vw, (min-w: 1024px) 25vw, (min-w: 1280px) 20vw, (min-w: 1536px) 20vw, 15vw'
                    alt={`Listing image ${index + 4}`}
                    variants={image.variants || []}
                    className='h-full w-full @xl:rounded-r-md rounded-r-none bg-gray-300 object-cover object-center'
                  />
                </div>
              ))}
            </div>
          </>
        )
    }
  }

  return (
    <div className={clsx('@container relative', props.className)}>
      <div className='flex h-full w-full items-stretch gap-1 sm:gap-2'>{renderImages()}</div>
      <div className='absolute right-4 bottom-4 flex items-center justify-start gap-2'>
        <Button
          className='text-sm/6 shadow-md sm:text-base/6'
          color='light'
          href='/listings/$id/edit/photos'
          params={{ id: params.id }}
        >
          <Trans context='ListingDetailPage' comment='Button text for navigating to the edit listing photos page'>
            Edit photos
          </Trans>
        </Button>
        {imageCount > 0 && (
          <Button
            className='text-sm/6 shadow-md sm:text-base/6'
            color='light'
            href='/listings/$id/photos'
            params={{ id: params.id }}
          >
            <Trans
              context='ListingDetailPage'
              comment='Button text for navigation to the view all photos for a listing page'
            >
              View all
            </Trans>
          </Button>
        )}
        <div />
      </div>
    </div>
  )
}
