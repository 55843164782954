import localforage from 'localforage'

export async function setTokens(authToken: string, refreshToken: string) {
  await localforage.setItem('auth_token', authToken)
  await localforage.setItem('refresh_token', refreshToken)
}

export async function getTokens() {
  const authToken = await localforage.getItem<string>('auth_token')
  const refreshToken = await localforage.getItem<string>('refresh_token')
  if (!authToken || !refreshToken) {
    throw new Error('tokens not found')
  }
  return { authToken, refreshToken }
}

export async function clearTokens() {
  await localforage.removeItem('auth_token')
  await localforage.removeItem('refresh_token')
}

export async function hasTokens() {
  try {
    await getTokens()
    return true
  } catch (e) {
    return false
  }
}
