import { Button } from '@/components/button'
import { getListing } from '@/pb/service/listing/v1/listing_service-ListingsService_connectquery'
import { ErrorPage } from '@/routes/-components/error-page.tsx'
import ResponsiveImage from '@/routes/-components/responsive-image.tsx'
import { createQueryOptions, useSuspenseQuery } from '@connectrpc/connect-query'
import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { createFileRoute } from '@tanstack/react-router'
import type { ReactNode } from 'react'

export const Route = createFileRoute('/_protected/_dashboard/listings/$id/photos/$photoId')({
  component: ListingPhotoDetail,
  loader: async ({ context: { queryClient, connectTransport }, params }) => {
    await queryClient.prefetchQuery({
      ...createQueryOptions(getListing, { id: params.id }, { transport: connectTransport }),
    })
  },
})

function ListingPhotoDetail(): ReactNode {
  const params = Route.useParams()

  const { data } = useSuspenseQuery(getListing, { id: params.id })
  if (data.listing === undefined) {
    return <ErrorPage />
  }

  const images = data.listing.images

  const currentImageIndex = images.findIndex((img) => img.id === params.photoId)
  const currentImage = images[currentImageIndex]

  const prevImageId = currentImageIndex > 0 ? images[currentImageIndex - 1].id : null
  const nextImageId = currentImageIndex < images.length - 1 ? images[currentImageIndex + 1].id : null

  if (!currentImage) {
    return <ErrorPage />
  }

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-black'>
      <div className='absolute top-4 left-4 z-10'>
        <Button color='light' href='/listings/$id' params={{ id: params.id }}>
          <XMarkIcon className='size-8 fill-zinc-950 dark:fill-white' />
        </Button>
      </div>

      <div className='absolute inset-y-0 left-4 flex items-center'>
        {prevImageId && (
          <Button color='light' href='/listings/$id/photos/$photoId' params={{ id: params.id, photoId: prevImageId }}>
            <ChevronLeftIcon className='size-8 fill-zinc-950 dark:fill-white' />
          </Button>
        )}
      </div>

      <div className='absolute inset-y-0 right-4 flex items-center'>
        {nextImageId && (
          <Button color='light' href='/listings/$id/photos/$photoId' params={{ id: params.id, photoId: nextImageId }}>
            <ChevronRightIcon className='size-8 fill-zinc-950 dark:fill-white' />
          </Button>
        )}
      </div>

      <div className='flex h-full w-full items-center justify-center'>
        <ResponsiveImage
          sizes='100vw'
          alt={`Listing image ${currentImageIndex + 1}`}
          variants={currentImage.variants || []}
          className='max-h-full max-w-full object-contain'
        />
      </div>
    </div>
  )
}
