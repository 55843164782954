import { Alert, AlertActions, AlertDescription, AlertTitle } from '@/components/alert.tsx'
import { Button } from '@/components/button.tsx'
import { Divider } from '@/components/divider.tsx'
import { ErrorMessage, Field, Fieldset, Label } from '@/components/fieldset.tsx'
import { Heading, Subheading } from '@/components/heading.tsx'
import { Input } from '@/components/input.tsx'
import { Text } from '@/components/text.tsx'
import { deleteAccount } from '@/pb/service/account/v1/account_service-AccountService_connectquery'
import { changePassword, sendVerifyAndChangeEmail } from '@/pb/service/auth/v1/auth_service-AuthService_connectquery'
import { MessageBox } from '@/routes/-components/message-box.tsx'
import { SpinnerButton } from '@/routes/-components/spinner-button.tsx'
import { clearTokens, setTokens } from '@/utils/token-util.ts'
import { callUnaryMethod, useMutation } from '@connectrpc/connect-query'
import { useForm } from '@tanstack/react-form'
import { createFileRoute } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { type ReactNode, useState } from 'react'

import { getLocalizedError } from '@/i18n/error-localization.ts'
import { toastOptions } from '@/utils/toast-util.ts'
import type { MessageDescriptor } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import * as Sentry from '@sentry/react'
import { toast } from 'sonner'
import { useImmer } from 'use-immer'
import { z } from 'zod'

export const Route = createFileRoute('/_protected/_dashboard/settings')({
  component: Settings,
})

const changeEmailSchema = z.object({
  newEmail: z.string().email(),
})

const changePasswordSchema = z.object({
  currentPassword: z.string().min(1),
  newPassword: z.string().min(6),
  confirmNewPassword: z.string().min(6),
})

type ChangeEmailForm = z.infer<typeof changeEmailSchema>
type ChangePasswordForm = z.infer<typeof changePasswordSchema>

type FormStatus = 'initial' | 'success' | 'error'

interface FormStateInfo {
  status: FormStatus
  error: MessageDescriptor | null
}

interface PageFormsState {
  changePassword: FormStateInfo
  changeEmail: FormStateInfo
}

function Settings(): ReactNode {
  const { connectTransport, queryClient } = Route.useRouteContext()
  const navigate = Route.useNavigate()

  const { _ } = useLingui()

  const deleteAccountMutation = useMutation(deleteAccount, {
    onSuccess: async () => {
      queryClient.clear()
      await clearTokens()
      Sentry.getCurrentScope().clear()
      await navigate({ to: '/login', replace: true })
    },
    onError: (e) => {
      const { isExpectedError, message } = getLocalizedError(e)
      if (!isExpectedError) {
        Sentry.captureException(e)
      }
      toast.error(_(message), toastOptions)
    },
  })

  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
  const [formsState, setFormsState] = useImmer<PageFormsState>({
    changePassword: { status: 'initial', error: null },
    changeEmail: { status: 'initial', error: null },
  })

  const changePasswordForm = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    onSubmit: handleChangePasswordSubmit,
    validatorAdapter: zodValidator(),
  })

  const changeEmailForm = useForm({
    defaultValues: {
      newEmail: '',
    },
    onSubmit: handleChangeEmailSubmit,
    validatorAdapter: zodValidator(),
  })

  async function handleChangeEmailSubmit({ value }: { value: ChangeEmailForm }) {
    setFormsState((draft) => {
      draft.changeEmail.error = null
      draft.changeEmail.status = 'initial'
    })

    try {
      await callUnaryMethod(
        sendVerifyAndChangeEmail,
        {
          newEmail: value.newEmail,
        },
        { transport: connectTransport },
      )
      changeEmailForm.reset()
      setFormsState((draft) => {
        draft.changeEmail.error = null
        draft.changeEmail.status = 'success'
      })
    } catch (e) {
      const { isExpectedError, message } = getLocalizedError(e)
      if (!isExpectedError) {
        Sentry.captureException(e)
      }
      setFormsState((draft) => {
        draft.changeEmail.error = message
        draft.changeEmail.status = 'error'
      })
    }
  }

  async function handleChangePasswordSubmit({ value }: { value: ChangePasswordForm }) {
    setFormsState((draft) => {
      draft.changePassword.error = null
      draft.changePassword.status = 'initial'
    })

    try {
      const refreshTokens = await callUnaryMethod(
        changePassword,
        {
          currentPassword: value.currentPassword,
          newPassword: value.confirmNewPassword,
        },
        { transport: connectTransport },
      )
      const { idToken, refreshToken } = refreshTokens
      await setTokens(idToken, refreshToken)
      changePasswordForm.reset()
      setFormsState((draft) => {
        draft.changePassword.error = null
        draft.changePassword.status = 'success'
      })
    } catch (e) {
      const { isExpectedError, message } = getLocalizedError(e)
      if (!isExpectedError) {
        Sentry.captureException(e)
      }
      setFormsState((draft) => {
        draft.changePassword.error = message
        draft.changePassword.status = 'error'
      })
    }
  }

  const newEmailField = (
    <changeEmailForm.Field
      name='newEmail'
      validators={{
        onSubmit: changeEmailSchema.shape.newEmail,
      }}
    >
      {(field) => (
        <Field>
          <Label>
            <Trans context='SettingsPage' comment='Label text for new email input field'>
              Email
            </Trans>
          </Label>
          <Input
            id={field.name}
            name={field.name}
            type='email'
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            invalid={field.state.meta.errors.length > 0}
          />
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='SettingsPage'
                comment='Input error message for when an email is not provided or is invalid'
              >
                Please enter a valid email
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </changeEmailForm.Field>
  )

  const currentPasswordField = (
    <changePasswordForm.Field
      name='currentPassword'
      validators={{
        onSubmit: changePasswordSchema.shape.currentPassword,
      }}
    >
      {(field) => (
        <Field>
          <Label>
            <Trans context='SettingsPage' comment='Label text for current password input field'>
              Current password
            </Trans>
          </Label>
          <Input
            id={field.name}
            name={field.name}
            type='password'
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            invalid={field.state.meta.errors.length > 0}
          />
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans context='SettingsPage' comment='Input error message for when the current password is not provided'>
                Please enter your current password
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </changePasswordForm.Field>
  )

  const newPasswordField = (
    <changePasswordForm.Field
      name='newPassword'
      validators={{
        onSubmit: changePasswordSchema.shape.newPassword,
      }}
    >
      {(field) => (
        <Field>
          <Label>
            <Trans context='SettingsPage' comment='Label text for new password input field'>
              New password
            </Trans>
          </Label>
          <Input
            id={field.name}
            name={field.name}
            type='password'
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            invalid={field.state.meta.errors.length > 0}
          />
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans context='SettingsPage' comment='Input error message for when the current password is not provided'>
                New password must be longer than 6 characters long
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </changePasswordForm.Field>
  )

  const confirmNewPasswordField = (
    <changePasswordForm.Field
      name='confirmNewPassword'
      validators={{
        onChangeListenTo: ['newPassword'],
        onSubmit: ({ value, fieldApi }) => {
          if (value !== fieldApi.form.getFieldValue('newPassword')) {
            return 'passwords must match'
          }
          return undefined
        },
      }}
    >
      {(field) => (
        <Field>
          <Label>
            <Trans context='SettingsPage' comment='Label text for confirm password input field'>
              Confirm password
            </Trans>
          </Label>
          <Input
            id={field.name}
            name={field.name}
            type='password'
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            invalid={field.state.meta.errors.length > 0}
          />
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans context='SettingsPage' comment='Input error message for when the passwords do not match'>
                Passwords no not match
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </changePasswordForm.Field>
  )

  return (
    <>
      <Heading>
        <Trans context='SettingsPage' comment='Header text for settings page'>
          Settings
        </Trans>
      </Heading>
      <Divider className='my-10 mt-6' />
      <section className='grid gap-x-8 gap-y-6 sm:grid-cols-2'>
        <div className='space-y-1'>
          <Subheading>
            <Trans context='SettingsPage' comment='Subheading text for change email section'>
              Change email
            </Trans>
          </Subheading>
          <Text>
            <Trans context='SettingsPage' comment='Detail text for change email section'>
              Update the email associated with your account
            </Trans>
          </Text>
        </div>
        <form
          onSubmit={async (e) => {
            e.preventDefault()
            e.stopPropagation()
            await changeEmailForm.handleSubmit()
          }}
        >
          <changeEmailForm.Subscribe selector={(state) => [state.isSubmitting, state.canSubmit]}>
            {([isSubmitting]) => (
              <Fieldset disabled={isSubmitting} className='space-y-6'>
                {newEmailField}
                <Button type='submit'>
                  <Trans context='SettingsPage' comment='Button text that submits the email change for the user'>
                    Update
                  </Trans>
                </Button>
                {formsState.changeEmail.status === 'error' && (
                  // biome-ignore lint/style/noNonNullAssertion: <explanation>
                  <MessageBox error>{_(formsState.changeEmail.error!)}</MessageBox>
                )}
                {formsState.changeEmail.status === 'success' && (
                  <MessageBox success>
                    <Trans
                      context='SettingsPage'
                      comment='Text that shows after an email change request has been submitted'
                    >
                      Check your email for a link to verify your new email
                    </Trans>
                  </MessageBox>
                )}
              </Fieldset>
            )}
          </changeEmailForm.Subscribe>
        </form>
      </section>

      <Divider className='my-10' soft />

      <section className='grid gap-x-8 gap-y-6 sm:grid-cols-2'>
        <div className='space-y-1'>
          <Subheading>
            <Trans context='SettingsPage' comment='Subheading text for change password section'>
              Change password
            </Trans>
          </Subheading>
          <Text>
            <Trans context='SettingsPage' comment='Detail text for change password section'>
              Update the password associated with your account
            </Trans>
          </Text>
        </div>
        <form
          onSubmit={async (e) => {
            e.preventDefault()
            e.stopPropagation()
            await changePasswordForm.handleSubmit()
          }}
        >
          <changePasswordForm.Subscribe selector={(state) => [state.isSubmitting, state.canSubmit]}>
            {([isSubmitting]) => (
              <Fieldset disabled={isSubmitting} className='space-y-6'>
                {currentPasswordField}
                {newPasswordField}
                {confirmNewPasswordField}
                <Button type='submit'>
                  <Trans context='SettingsPage' comment='Button text that submits the password change for the user'>
                    Update
                  </Trans>
                </Button>
                {formsState.changePassword.status === 'error' && (
                  // biome-ignore lint/style/noNonNullAssertion: <explanation>
                  <MessageBox error>{_(formsState.changePassword.error!)}</MessageBox>
                )}
                {formsState.changePassword.status === 'success' && (
                  <MessageBox success>
                    <Trans context='SettingsPage' comment="Text that's displayed on successful password update">
                      Password updated successfully
                    </Trans>
                  </MessageBox>
                )}
              </Fieldset>
            )}
          </changePasswordForm.Subscribe>
        </form>
      </section>

      <Divider className='my-10' soft />

      <section className='grid gap-x-8 gap-y-6 sm:grid-cols-2'>
        <div className='space-y-1'>
          <Subheading>
            <Trans context='SettingsPage' comment='Subheading text for delete account section'>
              Delete account
            </Trans>
          </Subheading>
          <Text>
            <Trans context='SettingsPage' comment='Detail text for delete account section'>
              You can delete your account here. This action is not reversible.
            </Trans>
          </Text>
        </div>
        <div>
          <Button color='rose' onClick={() => setIsDeleteAlertOpen(true)}>
            <Trans context='SettingsPage' comment='Button text for deleting account'>
              Delete my account
            </Trans>
          </Button>
        </div>
      </section>

      <Alert open={isDeleteAlertOpen} onClose={() => setIsDeleteAlertOpen(false)}>
        <AlertTitle>
          <Trans context='SettingsPage' comment='Alert title text for delete account confirmation'>
            Are you sure you want to delete your account?
          </Trans>
        </AlertTitle>
        <AlertDescription>
          <Trans context='SettingsPage' comment='Alert description text for delete account confirmation'>
            You will delete everything associated with this account.
          </Trans>
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setIsDeleteAlertOpen(false)} disabled={deleteAccountMutation.isPending}>
            <Trans
              context='SettingsPage'
              comment='Alert action button text for dismissing alert and cancelling account deletion'
            >
              Cancel
            </Trans>
          </Button>
          <SpinnerButton
            color='rose'
            type='submit'
            onClick={deleteAccountMutation.mutate}
            disabled={deleteAccountMutation.isPending}
            showSpinner={deleteAccountMutation.isPending}
          >
            <Trans context='SettingsPage' comment='Alert action button text for submitting account deletion request'>
              Delete
            </Trans>
          </SpinnerButton>
        </AlertActions>
      </Alert>
    </>
  )
}
