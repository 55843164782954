import { Link } from '@/components/link.tsx'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { Trans } from '@lingui/macro'
import type { ReactNode } from 'react'

export function BackNavigation(props: { listingId: string; new: boolean }): ReactNode {
  return (
    <div className='hidden lg:block'>
      {props.new ? (
        <Link href='/listings' className='inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400'>
          <ChevronLeftIcon className='size-4 fill-zinc-400 dark:fill-zinc-500' />
          <Trans context='EditListingDetailsPage' comment='Link text that navigates back to the listings list page'>
            Listings
          </Trans>
        </Link>
      ) : (
        <Link
          href='/listings/$id'
          params={{ id: props.listingId }}
          className='inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400'
        >
          <ChevronLeftIcon className='size-4 fill-zinc-400 dark:fill-zinc-500' />
          <Trans context='EditListingDetailsPage' comment='Link text that navigates back to the listing details page'>
            Listing
          </Trans>
        </Link>
      )}
    </div>
  )
}
