import { Divider } from '@/components/divider.tsx'
import { Heading, Subheading } from '@/components/heading'
import { Text } from '@/components/text.tsx'
import { Spinner } from '@/routes/-components/spinner.tsx'
import { getRelativeTimeString } from '@/utils/date-util.ts'
import { isDarkMode } from '@/utils/theme-util.ts'
import { callUnaryMethod, createConnectInfiniteQueryKey } from '@connectrpc/connect-query'
import * as Sentry from '@sentry/react'
import { useSuspenseInfiniteQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { Fragment, type ReactNode, useEffect } from 'react'

import { listDescriptionsForAccount } from '@/pb/service/description/v1/description_service-DescriptionService_connectquery'
import { Trans } from '@lingui/macro'
import { useInView } from 'react-intersection-observer'

const itemsPerPage = 5

export const Route = createFileRoute('/_protected/_dashboard/generated/')({
  component: ListGeneratedForAccount,
  loader: async ({ context: { queryClient, connectTransport } }) => {
    await queryClient.prefetchInfiniteQuery({
      queryKey: createConnectInfiniteQueryKey(listDescriptionsForAccount),
      initialPageParam: 0,
      queryFn: ({ pageParam = 0 }) =>
        callUnaryMethod(
          listDescriptionsForAccount,
          { limit: itemsPerPage, offset: pageParam },
          { transport: connectTransport },
        ),
    })
  },
})

function ListGeneratedForAccount() {
  const { connectTransport } = Route.useRouteContext()

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useSuspenseInfiniteQuery({
    queryKey: createConnectInfiniteQueryKey(listDescriptionsForAccount),
    initialPageParam: 0,
    queryFn: ({ pageParam = 0 }) =>
      callUnaryMethod(
        listDescriptionsForAccount,
        { limit: itemsPerPage, offset: pageParam },
        { transport: connectTransport },
      ),
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (allPages.reduce((total, response) => total + response.descriptions.length, 0) === lastPage.total) {
        return undefined
      }

      if (lastPageParam === undefined) {
        return undefined
      }

      return lastPageParam + lastPage.descriptions.length
    },
  })

  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      fetchNextPage()
        .then()
        .catch((e) => {
          Sentry.captureException(e)
        })
    }
  }, [fetchNextPage, inView])

  return (
    <>
      {data.pages.reduce((total, response) => total + response.descriptions.length, 0) === 0 ? (
        <Empty />
      ) : (
        <div className='mx-auto flex h-full max-w-prose flex-col'>
          <Heading>
            <Trans context='ListGeneratedPage' comment='Header text for list generated descriptions page'>
              Generated descriptions
            </Trans>
          </Heading>
          <ul className='mt-4 flex-grow'>
            {data.pages.map((group, i) => {
              return (
                <Fragment key={`group-${i}-${group.descriptions.length}`}>
                  {group.descriptions.map((description) => {
                    return (
                      <li key={description.id} className='flex flex-col gap-4 pb-4'>
                        <Divider />
                        <div className='space-y-4'>
                          <Subheading className='font-semibold font-serif'>{description.listingName}</Subheading>
                          <Text className='whitespace-pre-wrap font-serif text-zinc-900 dark:text-white'>
                            {description.content}
                          </Text>
                          <Text className='font-medium font-serif'>
                            {getRelativeTimeString(description.createdAt?.toDate())}
                          </Text>
                        </div>
                      </li>
                    )
                  })}
                </Fragment>
              )
            })}
          </ul>
          <div ref={ref} className='flex items-center justify-center'>
            {isFetchingNextPage ? (
              <Spinner {...(!isDarkMode() && { dark: true })} className='my-4' />
            ) : hasNextPage ? (
              <div className='h-4' />
            ) : null}
          </div>
        </div>
      )}
    </>
  )
}

function Empty(): ReactNode {
  return (
    <div className='flex h-full w-full items-center justify-center'>
      <div className='mb-48 flex flex-col items-center'>
        <Subheading className='mt-2 text-center'>
          <Trans context='ListGeneratedPage' comment='Subheading text for empty state'>
            No generated descriptions
          </Trans>
        </Subheading>
        <Text className='mt-2 text-center'>
          <Trans context='ListGeneratedPage' comment='Detail text for empty state'>
            Any descriptions you generate will appear here
          </Trans>
        </Text>
      </div>
    </div>
  )
}
