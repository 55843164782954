import { Alert, AlertActions, AlertDescription, AlertTitle } from '@/components/alert.tsx'
import { Button } from '@/components/button.tsx'
import { Divider } from '@/components/divider.tsx'
import { Heading, Subheading } from '@/components/heading.tsx'
import { Link } from '@/components/link.tsx'
import { Text } from '@/components/text.tsx'
import { getLocalizedError } from '@/i18n/error-localization.ts'
import { getAccountCredits } from '@/pb/service/account/v1/account_service-AccountService_connectquery'
import {
  createCheckoutSession,
  listProducts,
} from '@/pb/service/checkout/v1/checkout_service-CheckoutService_connectquery'
import { localizedPrice, mapToDinero } from '@/utils/currency-util.ts'
import { toastOptions } from '@/utils/toast-util.ts'
import { callUnaryMethod, createConnectQueryKey, createQueryOptions, useSuspenseQuery } from '@connectrpc/connect-query'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import * as Sentry from '@sentry/react'
import { type SearchSchemaInput, createFileRoute } from '@tanstack/react-router'
import { allocate, dinero } from 'dinero.js'
import { type ReactNode, useEffect, useState } from 'react'
import { toast } from 'sonner'
import { z } from 'zod'

const creditsSearchSchema = z.object({
  from: z.string().catch(''),
  success: z.boolean().catch(false),
})

export const Route = createFileRoute('/_protected/_dashboard/credits/purchase')({
  validateSearch: (
    input: {
      from?: string
      success?: boolean
    } & SearchSchemaInput,
  ) => creditsSearchSchema.parse(input),
  loader: async ({ context: { queryClient, connectTransport } }) => {
    await Promise.all([
      queryClient.prefetchQuery({ ...createQueryOptions(listProducts, undefined, { transport: connectTransport }) }),
      queryClient.prefetchQuery({
        ...createQueryOptions(getAccountCredits, undefined, { transport: connectTransport }),
      }),
    ])
  },
  component: PurchaseCredits,
})

function PurchaseCredits(): ReactNode {
  const navigate = Route.useNavigate()
  const { from, success } = Route.useSearch()
  const { connectTransport, queryClient } = Route.useRouteContext()

  const {
    data: { products },
  } = useSuspenseQuery(listProducts)
  const {
    data: { credits },
  } = useSuspenseQuery(getAccountCredits)

  useEffect(() => {
    if (success) {
      queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(getAccountCredits, undefined),
        exact: true,
      })
    }
  }, [success, queryClient])

  const [isOpen, setIsOpen] = useState(success)

  const { _, i18n } = useLingui()

  async function handlePurchaseClicked(priceId: string) {
    try {
      const response = await callUnaryMethod(
        createCheckoutSession,
        {
          priceId: priceId,
          fromListingId: from,
        },
        { transport: connectTransport },
      )

      window.location.href = response.checkoutSessionUrl
    } catch (e) {
      const { isExpectedError, message } = getLocalizedError(e)
      if (!isExpectedError) {
        Sentry.captureException(e)
      }
      toast.error(_(message), toastOptions)
    }
  }

  return (
    <>
      {from && (
        <div className='hidden lg:block'>
          <Link
            from={Route.fullPath}
            href='/listings/$id/generate'
            params={{ id: from }}
            className='inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400'
          >
            <ChevronLeftIcon className='size-4 fill-zinc-400 dark:fill-zinc-500' />
            <Trans
              context='PurchaseCreditsPage'
              comment='Link text that navigates back to the generate description page'
            >
              Generate
            </Trans>
          </Link>
        </div>
      )}
      <Heading className={from && 'lg:mt-10'}>
        <Trans context='PurchaseCreditsPage' comment='Heading text for the purchase credits page'>
          Purchase credits
        </Trans>
      </Heading>
      <Divider className='mt-6' soft />
      <div className='card mt-8 w-fit space-y-2 p-4'>
        <Subheading>
          <Trans context='PurchaseCreditsPage' comment='Subheading text for remaining credits amount'>
            Remaining credits
          </Trans>
        </Subheading>
        <div className='font-semibold text-3xl/8 sm:text-2xl/8'>{i18n.number(credits)}</div>
      </div>
      <div className='mt-8 flex flex-row items-center gap-4'>
        <Subheading>
          <Trans
            context='PurchaseCreditsPage'
            comment='Subheading text for the available credit packs there are to purchase'
          >
            Available packs
          </Trans>
        </Subheading>
        <Alert open={isOpen} onClose={setIsOpen}>
          <AlertTitle>
            <Trans context='PurchaseCreditsPage' comment='Alert title text for succesful purchase of credits'>
              Purchase complete!
            </Trans>
          </AlertTitle>
          <AlertDescription>
            <Trans context='PurchaseCreditsPage' comment='Alert description text for succesful purchase of credits'>
              Your purchase was successful. The credits from your purchase have been applied to your account
            </Trans>
          </AlertDescription>
          <AlertActions>
            <Button
              onClick={async () => {
                setIsOpen(false)
                await navigate({
                  search: (prev) => ({ success: false, from: prev.from }),
                })
              }}
            >
              <Trans context='PurchaseCreditsPage' comment='Alert action button text for dismissing the alert'>
                Close
              </Trans>
            </Button>
          </AlertActions>
        </Alert>
      </div>
      <div className='mt-8 grid gap-6 sm:grid-cols-2 lg:grid-cols-3'>
        {products.map((product, i) => {
          const packCurrency = mapToDinero(product.currency)
          const packAmount = dinero({ amount: product.unitAmount, currency: packCurrency })
          const allocatedPrices = allocate(packAmount, Array(product.creditAmount).fill(1))
          const perCreditAmount = allocatedPrices[0]

          return (
            <div key={product.id} className='card relative flex flex-col items-stretch gap-6 p-6'>
              {i === 1 && (
                <div className='absolute top-0 right-0 rounded-tr-md rounded-bl-md bg-zinc-100 dark:bg-zinc-800'>
                  <p className='px-3 py-1 font-bold text-xs dark:text-white'>
                    <Trans
                      context='PurchaseCreditsPage'
                      comment='Label text for highlightingng the most popular pack of credits that is purchased'
                    >
                      Most popular
                    </Trans>
                  </p>
                </div>
              )}
              <div className='font-bold text-lg'>
                <Trans
                  context='PurchaseCreditsPage'
                  comment="Text that displays how many credits come in this pack that's available to purchase"
                >
                  {i18n.number(product.creditAmount)} Credits
                </Trans>
              </div>
              <div className='font-bold text-3xl'>{localizedPrice(packAmount, i18n.locale)}</div>
              <Text>
                <Trans
                  context='PurchaseCreditsPage'
                  comment="Text that displays the price per credit in this pack that's available to purchase"
                >
                  Price per credit: {localizedPrice(perCreditAmount, i18n.locale, true)}
                </Trans>
              </Text>
              <Button onClick={() => handlePurchaseClicked(product.priceId)}>
                <Trans context='PurchaseCreditsPage' comment='Button text for purchasing a pack of credits'>
                  Purchase
                </Trans>
              </Button>
            </div>
          )
        })}
      </div>
    </>
  )
}
