// @generated by protoc-gen-connect-query v1.4.1
// @generated from file service/checkout/v1/checkout_service.proto (package service.checkout.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { CreateCheckoutSessionRequest, CreateCheckoutSessionResponse, ListProductsRequest, ListProductsResponse } from "./checkout_service_pb.js";

/**
 * @generated from rpc service.checkout.v1.CheckoutService.CreateCheckoutSession
 */
export const createCheckoutSession = {
  localName: "createCheckoutSession",
  name: "CreateCheckoutSession",
  kind: MethodKind.Unary,
  I: CreateCheckoutSessionRequest,
  O: CreateCheckoutSessionResponse,
  service: {
    typeName: "service.checkout.v1.CheckoutService"
  }
};

/**
 * @generated from rpc service.checkout.v1.CheckoutService.ListProducts
 */
export const listProducts = {
  localName: "listProducts",
  name: "ListProducts",
  kind: MethodKind.Unary,
  I: ListProductsRequest,
  O: ListProductsResponse,
  service: {
    typeName: "service.checkout.v1.CheckoutService"
  }
};
