import {
  getListing,
  listListings,
  updateListing,
} from '@/pb/service/listing/v1/listing_service-ListingsService_connectquery'
import { createConnectInfiniteQueryKey, createConnectQueryKey, useMutation } from '@connectrpc/connect-query'
import type { QueryClient } from '@tanstack/react-query'

export function useUpdateListingMutation(queryClient: QueryClient) {
  return useMutation(updateListing, {
    onSuccess: async (_data, variables) => {
      const { id } = variables
      queryClient.removeQueries({
        queryKey: createConnectQueryKey(getListing, { id }),
        exact: true,
      })
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: createConnectInfiniteQueryKey(listListings),
          exact: true,
        }),
      ])
    },
  })
}
