import { createListing, listListings } from '@/pb/service/listing/v1/listing_service-ListingsService_connectquery'
import { createConnectInfiniteQueryKey, useMutation } from '@connectrpc/connect-query'
import type { QueryClient } from '@tanstack/react-query'

export function useCreateListingMutation(queryClient: QueryClient) {
  return useMutation(createListing, {
    onSuccess: async (_data, _variables) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: createConnectInfiniteQueryKey(listListings),
          exact: true,
          refetchType: 'inactive',
        }),
      ])
    },
  })
}
