import { Button } from '@/components/button.tsx'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '@/components/dialog.tsx'
import { Divider } from '@/components/divider.tsx'
import { Description, ErrorMessage, Field, FieldGroup, Fieldset, Label } from '@/components/fieldset'
import { Heading, Subheading } from '@/components/heading'
import { Link } from '@/components/link.tsx'
import { Listbox, ListboxDescription, ListboxLabel, ListboxOption } from '@/components/listbox'
import { Text } from '@/components/text'
import { getLocalizedError } from '@/i18n/error-localization.ts'
import {
  getLocalizedLanguageDescription,
  getLocalizedLanguageName,
  getLocalizedLengthDescription,
  getLocalizedLengthName,
  getLocalizedTargetDescription,
  getLocalizedTargetName,
  getLocalizedToneDescription,
  getLocalizedToneName,
} from '@/i18n/generate-localization.ts'
import { getLocalizedListingType, getLocalizedPropertyType } from '@/i18n/listing-localization.ts'
import { getAccountCredits } from '@/pb/service/account/v1/account_service-AccountService_connectquery'
import {
  listDescriptionsForAccount,
  listDescriptionsForListing,
} from '@/pb/service/description/v1/description_service-DescriptionService_connectquery'
import { DescriptionService } from '@/pb/service/description/v1/description_service_connect'
import {
  DescriptionLanguage,
  DescriptionLength,
  DescriptionTarget,
  DescriptionTone,
} from '@/pb/service/description/v1/description_service_pb'
import { getListing } from '@/pb/service/listing/v1/listing_service-ListingsService_connectquery'
import { ListingType, PropertyType } from '@/pb/service/listing/v1/listing_service_pb'
import { ErrorPage } from '@/routes/-components/error-page.tsx'
import { SpinnerButton } from '@/routes/-components/spinner-button.tsx'
import { MarkdownComponent } from '@/routes/_protected/_dashboard/-listings/components/markdown-component.tsx'
import { getGenerateLanguageFromLocale } from '@/utils/gen-util.ts'
import { toastOptions } from '@/utils/toast-util.ts'
import { createPromiseClient } from '@connectrpc/connect'
import {
  createConnectInfiniteQueryKey,
  createConnectQueryKey,
  createQueryOptions,
  useSuspenseQuery,
} from '@connectrpc/connect-query'
import { ArrowRightIcon } from '@heroicons/react/16/solid'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { Trans, msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { markdownLookBack } from '@llm-ui/markdown'
import { throttleBasic, useLLMOutput } from '@llm-ui/react'
import * as Sentry from '@sentry/react'
import { useForm } from '@tanstack/react-form'
import { createFileRoute } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { useCallback, useState } from 'react'
import { toast } from 'sonner'
import { z } from 'zod'

const DescriptionLanguageEnum = z.nativeEnum(DescriptionLanguage)
type DescriptionLanguageEnum = z.infer<typeof DescriptionLanguageEnum>

const ToneEnum = z.nativeEnum(DescriptionTone)
type ToneEnum = z.infer<typeof ToneEnum>

const TargetEnum = z.nativeEnum(DescriptionTarget)
type TargetEnum = z.infer<typeof TargetEnum>

const LengthEnum = z.nativeEnum(DescriptionLength)
type LengthEnum = z.infer<typeof LengthEnum>

const generateDescriptionSchema = z.object({
  language: DescriptionLanguageEnum,
  tone: ToneEnum,
  target: TargetEnum,
  length: LengthEnum,
})

type GenerateDescriptionForm = z.infer<typeof generateDescriptionSchema>

export const Route = createFileRoute('/_protected/_dashboard/listings/$id/generate')({
  component: GenerateDescription,
  loader: async ({ context: { queryClient, connectTransport }, params }) => {
    await Promise.all([
      queryClient.prefetchQuery({
        ...createQueryOptions(getListing, { id: params.id }, { transport: connectTransport }),
      }),
      queryClient.prefetchQuery({
        ...createQueryOptions(getAccountCredits, undefined, { transport: connectTransport }),
      }),
    ])
  },
})

function GenerateDescription() {
  const { connectTransport, queryClient } = Route.useRouteContext()
  const params = Route.useParams()

  const descriptionServiceClient = createPromiseClient(DescriptionService, connectTransport)
  const { data: listingData } = useSuspenseQuery(getListing, { id: params.id })
  const {
    data: { credits },
  } = useSuspenseQuery(getAccountCredits)

  const [generatedDescription, setGeneratedDescription] = useState<string>('')
  const [isGenerating, setIsGenerating] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const { _, i18n } = useLingui()

  if (listingData.listing === undefined) {
    return <ErrorPage />
  }

  const listing = listingData.listing

  const form = useForm({
    defaultValues: {
      language: getGenerateLanguageFromLocale(),
      tone: DescriptionTone.TONE_INFORMATIVE,
      target: DescriptionTarget.TARGET_FAMILIES,
      length: DescriptionLength.LENGTH_MEDIUM,
    },
    onSubmit: handleSubmit,
    validatorAdapter: zodValidator(),
  })

  async function handleSubmit({ value }: { value: GenerateDescriptionForm }) {
    setGeneratedDescription('')
    setIsGenerating(true)
    setIsOpen(true)

    const listingId = params.id

    try {
      const stream = descriptionServiceClient.generateDescription(
        {
          listingId: listingId,
          language: value.language,
          tone: value.tone,
          target: value.target,
          length: value.length,
        },
        { timeoutMs: 60_000 },
      )

      let fullDescription = ''
      for await (const response of stream) {
        fullDescription += response.content
        setGeneratedDescription(fullDescription)
      }

      await Promise.all([
        await queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getAccountCredits),
          exact: true,
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectInfiniteQueryKey(listDescriptionsForListing, { listingId: listingId }),
          exact: true,
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectInfiniteQueryKey(listDescriptionsForAccount),
          exact: true,
        }),
      ])
    } catch (e) {
      const { isExpectedError, message } = getLocalizedError(e)
      if (!isExpectedError) {
        Sentry.captureException(e)
      }
      toast.error(_(message), toastOptions)
    } finally {
      setIsGenerating(false)
    }
  }

  const handleCopyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(generatedDescription)
      toast.success(
        _(
          msg({
            context: 'ToastAlert',
            comment: 'Toast alert text when some text is successfully copied to the users clipboard',
            message: 'Copied to clipboard',
          }),
        ),
        toastOptions,
      )
    } catch (e) {
      toast.error(
        _(
          msg({
            context: 'TextAlert',
            comment: `Toast alert text when an error occurs during copying some text to the user's clipboard`,
            message: 'Failed to copy to clipboard',
          }),
        ),
        toastOptions,
      )
    }
  }, [generatedDescription, _])

  const { blockMatches } = useLLMOutput({
    llmOutput: generatedDescription,
    blocks: [],
    fallbackBlock: {
      component: MarkdownComponent,
      lookBack: markdownLookBack(),
    },
    isStreamFinished: !isGenerating,
    throttle: throttleBasic(),
  })

  const languageField = (
    <form.Field
      name='language'
      validators={{
        onSubmit: generateDescriptionSchema.shape.language,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='GenerateDescriptionPage' comment='Label text for language list box'>
              Language
            </Trans>
          </Label>
          <Description>
            <Trans context='GenerateDescriptionPage' comment='Description text for language list box'>
              Select the language for your description
            </Trans>
          </Description>
          <Listbox
            name={field.name}
            onChange={(e) => field.handleChange(e)}
            value={field.state.value}
            invalid={field.state.meta.errors.length > 0}
            className='max-w-md'
          >
            {Object.values(DescriptionLanguage)
              .filter((value): value is DescriptionLanguage => typeof value === 'number')
              .filter((language) => language !== DescriptionLanguage.LANGUAGE_UNSPECIFIED)
              .map((value) => {
                return (
                  <ListboxOption key={value} value={value}>
                    <ListboxLabel>{_(getLocalizedLanguageName(value))}</ListboxLabel>
                    <ListboxDescription>{_(getLocalizedLanguageDescription(value))}</ListboxDescription>
                  </ListboxOption>
                )
              })}
          </Listbox>
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='GenerateDescriptionPage'
                comment='List box error message that is displayed when the user does not select a language'
              >
                Please select a language for your description
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const toneField = (
    <form.Field
      name='tone'
      validators={{
        onSubmit: generateDescriptionSchema.shape.tone,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='GenerateDescriptionPage' comment='Label text for tone list box'>
              Tone
            </Trans>
          </Label>
          <Description>
            <Trans context='GeneratedDescriptionPage' comment='Description text for tone list box'>
              Select the tone for your description
            </Trans>
          </Description>
          <Listbox
            name={field.name}
            onChange={(e) => field.handleChange(e)}
            value={field.state.value}
            invalid={field.state.meta.errors.length > 0}
            className='max-w-[434px]'
          >
            {Object.values(DescriptionTone)
              .filter((value): value is DescriptionTone => typeof value === 'number')
              .filter((tone) => tone !== DescriptionTone.TONE_UNSPECIFIED)
              .map((value) => {
                return (
                  <ListboxOption key={value} value={value}>
                    <ListboxLabel>{_(getLocalizedToneName(value))}</ListboxLabel>
                    <ListboxDescription className='hidden xl:inline-flex'>
                      {_(getLocalizedToneDescription(value))}
                    </ListboxDescription>
                  </ListboxOption>
                )
              })}
          </Listbox>
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='GenerateDescriptionPage'
                comment='List box error message that is displayed when the user does not select a tone'
              >
                Please select a tone for your description
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const targetField = (
    <form.Field
      name='target'
      validators={{
        onSubmit: generateDescriptionSchema.shape.target,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='GeneratedDescriptionPage' comment='Label text for target list box'>
              Target
            </Trans>
          </Label>
          <Description>
            <Trans context='GenerateDescriptionPage' comment='Description text for target list box'>
              Select the ideal audience for this listing
            </Trans>
          </Description>
          <Listbox
            name={field.name}
            onChange={(e) => field.handleChange(e)}
            value={field.state.value}
            invalid={field.state.meta.errors.length > 0}
            className='max-w-[434px]'
          >
            {Object.values(DescriptionTarget)
              .filter((value): value is DescriptionTarget => typeof value === 'number')
              .filter((target) => target !== DescriptionTarget.TARGET_UNSPECIFIED)
              .map((value) => {
                return (
                  <ListboxOption key={value} value={value}>
                    <ListboxLabel>{_(getLocalizedTargetName(value))}</ListboxLabel>
                    <ListboxDescription className='hidden xl:inline-flex'>
                      {_(getLocalizedTargetDescription(value))}
                    </ListboxDescription>
                  </ListboxOption>
                )
              })}
          </Listbox>
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='GenerateDescriptionPage'
                comment='List box error message that is displayed when the user does not select a target'
              >
                Please select a target for your description
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const lengthField = (
    <form.Field
      name='length'
      validators={{
        onSubmit: generateDescriptionSchema.shape.length,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='GenerateDescriptionPage' comment='Label text for length list box'>
              Length
            </Trans>
          </Label>
          <Description>
            <Trans context='GenerateDescriptionPage' comment='Description text for length list box'>
              Select the desired length of the description
            </Trans>
          </Description>
          <Listbox
            name={field.name}
            onChange={(e) => field.handleChange(e)}
            value={field.state.value}
            invalid={field.state.meta.errors.length > 0}
            className='max-w-[434px]'
          >
            {Object.values(DescriptionLength)
              .filter((value): value is DescriptionLength => typeof value === 'number')
              .filter((length) => length !== DescriptionLength.LENGTH_UNSPECIFIED)
              .map((value) => {
                return (
                  <ListboxOption key={value} value={value}>
                    <ListboxLabel>{_(getLocalizedLengthName(value))}</ListboxLabel>
                    <ListboxDescription className='hidden xl:inline-flex'>
                      {_(getLocalizedLengthDescription(value))}
                    </ListboxDescription>
                  </ListboxOption>
                )
              })}
          </Listbox>
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='GenerateDescriptionPage'
                comment='List box error message that is displayed when the user does not select a length'
              >
                Please select a length for your description
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  return (
    <div className='mx-auto flex h-full max-w-4xl flex-col'>
      <div className='hidden lg:block'>
        <Link
          from={Route.fullPath}
          href='/listings/$id'
          params={{ id: params.id }}
          className='inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400'
        >
          <ChevronLeftIcon className='size-4 fill-zinc-400 dark:fill-zinc-500' />
          <Trans context='GenerateDescriptionPage' comment='Link text for navigating back to listing details page'>
            Listing
          </Trans>
        </Link>
      </div>
      <div className='flex items-center justify-between lg:mt-10'>
        <div className='flex w-full flex-col-reverse items-start justify-start gap-y-4 sm:flex-row sm:items-center sm:justify-between sm:gap-y-0'>
          <Heading>
            <Trans context='GenerateDescriptionPage' comment='Header text for generate description page'>
              Generate Description
            </Trans>
          </Heading>
          <Button
            className='-ml-4 self-start sm:self-auto'
            plain
            href='/listings/$id/generated'
            params={{ id: params.id }}
          >
            <Trans
              context='GenerateDescriptionPage'
              comment='Button text that navigates you to the prevoiusly generated descriptions page'
            >
              Previously generated
            </Trans>
            <ArrowRightIcon className='size-4' />
          </Button>
        </div>
      </div>
      <Divider className='my-8' soft />
      <form
        className='grow'
        onSubmit={async (e) => {
          e.preventDefault()
          e.stopPropagation()
          await form.handleSubmit()
        }}
      >
        <form.Subscribe selector={(state) => [state.isSubmitting, state.canSubmit]}>
          {([isSubmitting, canSubmit]) => (
            <>
              <section className='grid gap-x-8 gap-y-6 sm:grid-cols-2'>
                <div className='space-y-1'>
                  <Subheading>
                    <Trans context='GenerateDescriptionPage' comment='Subheading text for generate options section'>
                      Customize your description
                    </Trans>
                  </Subheading>
                  <Text>
                    <Trans context='GenerateDescriptionPage' comment='Detail text for generate options section'>
                      Set the language, tone, target audience and length for your description
                    </Trans>
                  </Text>
                </div>
                <div>
                  <Fieldset disabled={isSubmitting}>
                    <FieldGroup>
                      {languageField}
                      {toneField}
                      {targetField}
                      {lengthField}
                    </FieldGroup>
                  </Fieldset>
                </div>
              </section>
              <div className=' mt-8 flex w-full flex-col items-stretch justify-start gap-4 sm:flex-row sm:justify-end'>
                <Text className='order-last self-center sm:order-none'>
                  <Trans
                    context='GenerateDescriptionPage'
                    comment="Text that shows the remaining credits in the user's account"
                  >
                    Remaining credits: {i18n.number(credits)}
                  </Trans>
                </Text>
                <Button
                  outline
                  disabled={!canSubmit || isSubmitting || isGenerating}
                  className='min-h-10'
                  href='/credits/purchase'
                  search={{ from: params.id }}
                >
                  <Trans
                    context='GenerateDescriptionPage'
                    comment='Button text for navigating to the purchase credits page'
                  >
                    Purchase Credits
                  </Trans>
                </Button>
                <SpinnerButton
                  className='order-first sm:order-none'
                  type='submit'
                  disabled={!canSubmit || isSubmitting || isGenerating || credits === 0}
                  showSpinner={isSubmitting || isGenerating}
                >
                  <Trans
                    context='GenerateDescriptionPage'
                    comment='Button text for generating a description for the listing'
                  >
                    Generate
                  </Trans>
                </SpinnerButton>
              </div>
            </>
          )}
        </form.Subscribe>
      </form>
      <Dialog open={generatedDescription !== '' && isOpen} onClose={setIsOpen} size='3xl'>
        <DialogTitle className='font-serif'>{listing.name}</DialogTitle>
        {listing.propertyType !== PropertyType.PROPERTY_TYPE_UNSPECIFIED &&
          listing.listingType !== ListingType.LISTING_TYPE_UNSPECIFIED && (
            <DialogDescription className='font-serif'>
              <Trans
                context='GenerateDescriptionPage'
                comment='Dialog description text that shows the property and listing type'
              >
                {_(getLocalizedPropertyType(listing.propertyType))} - {_(getLocalizedListingType(listing.listingType))}
              </Trans>
            </DialogDescription>
          )}
        <DialogBody className='text-sm text-zinc-900 dark:text-white'>
          {generatedDescription && (
            <div>
              {blockMatches.map((blockMatch, index) => {
                const Component = blockMatch.block.component
                return <Component key={`${index}-${blockMatch.output}`} blockMatch={blockMatch} />
              })}
            </div>
          )}
        </DialogBody>
        {!isGenerating && (
          <DialogActions>
            <Button plain onClick={() => setIsOpen(false)}>
              <Trans
                context='GenerateDescriptionPage'
                comment='Dialog action button text that dismisses the generated description dialog'
              >
                Close
              </Trans>
            </Button>
            <Button onClick={handleCopyToClipboard}>
              <Trans
                context='GenerateDescriptionPage'
                comment="Dialog action button text that copies the generated desription to the user's clipboard"
              >
                Copy to clipboard
              </Trans>
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}
