import { Button } from '@/components/button.tsx'
import { Divider } from '@/components/divider.tsx'
import { ErrorMessage, Field, Label } from '@/components/fieldset.tsx'
import { Input } from '@/components/input.tsx'
import { Listbox, ListboxLabel, ListboxOption } from '@/components/listbox.tsx'
import { getListing } from '@/pb/service/listing/v1/listing_service-ListingsService_connectquery'
import { ListingType, MeasurementUnit, PropertyType, Style } from '@/pb/service/listing/v1/listing_service_pb'
import { SpinnerButton } from '@/routes/-components/spinner-button.tsx'
import { AdditionalDetailsSection } from '@/routes/_protected/_dashboard/-listings/components/additional-details-section.tsx'
import { BackNavigation } from '@/routes/_protected/_dashboard/-listings/components/back-navigation.tsx'
import { BasicDetailsSection } from '@/routes/_protected/_dashboard/-listings/components/basic-details-section.tsx'
import { Header } from '@/routes/_protected/_dashboard/-listings/components/header.tsx'
import { LocationSection } from '@/routes/_protected/_dashboard/-listings/components/location-section.tsx'
import { NameSection } from '@/routes/_protected/_dashboard/-listings/components/name-section.tsx'
import { SizeSection } from '@/routes/_protected/_dashboard/-listings/components/size-section.tsx'
import { TypeSection } from '@/routes/_protected/_dashboard/-listings/components/type-section.tsx'
import { useUpdateListingMutation } from '@/routes/_protected/_dashboard/-listings/mutations/useUpdateListingMutation.ts'
import { getCountries } from '@/utils/country-util.ts'
import { toastOptions } from '@/utils/toast-util.ts'
import { createQueryOptions, useSuspenseQuery } from '@connectrpc/connect-query'
import { PlusIcon } from '@heroicons/react/16/solid'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { NumberParser } from '@internationalized/number'
import { useForm } from '@tanstack/react-form'
import { type SearchSchemaInput, createFileRoute } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { type ReactNode, useState } from 'react'

import { getLocalizedError } from '@/i18n/error-localization.ts'
import {
  getLocalizedAreaMeasurementUnit,
  getLocalizedListingType,
  getLocalizedPropertyType,
  getLocalizedStyle,
} from '@/i18n/listing-localization.ts'
import { NotFoundPage } from '@/routes/-components/not-found-page.tsx'
import { Trans, msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import * as Sentry from '@sentry/react'
import { toast } from 'sonner'
import { z } from 'zod'

const editListingSearchSchema = z.object({
  isNew: z.boolean().catch(false),
})

const PropertyTypeEnum = z.nativeEnum(PropertyType)
type PropertyTypeEnum = z.infer<typeof PropertyTypeEnum>

const ListingTypeEnum = z.nativeEnum(ListingType)
type ListingTypeEnum = z.infer<typeof ListingTypeEnum>

const StyleEnum = z.nativeEnum(Style)
type StyleEnum = z.infer<typeof StyleEnum>

const MeasurementUnitsEnum = z.nativeEnum(MeasurementUnit)
type MeasurementUnitsEnum = z.infer<typeof MeasurementUnitsEnum>

const updateListingFormSchema = z.object({
  name: z.string().min(1).max(128),
  cityOrNeighborhood: z.string().optional(),
  stateOrRegion: z.string().optional(),
  country: z.string().optional(),
  propertyType: PropertyTypeEnum,
  listingType: ListingTypeEnum,
  style: StyleEnum,
  bedrooms: z.number().int().nonnegative().optional(),
  bathrooms: z.number().nonnegative().optional(),
  beds: z.number().int().nonnegative().optional(),
  area: z.number().int().nonnegative().optional(),
  lotSize: z.number().int().nonnegative().optional(),
  yearBuilt: z.number().int().nonnegative().max(9999).optional(),
  amenities: z.array(
    z.object({
      text: z.string(),
    }),
  ),
  nearbyAttractions: z.array(
    z.object({
      text: z.string(),
    }),
  ),
  features: z.array(
    z.object({
      text: z.string(),
    }),
  ),
  measurementUnit: MeasurementUnitsEnum,
})

type UpdateListingForm = z.infer<typeof updateListingFormSchema>

export const Route = createFileRoute('/_protected/_dashboard/listings/$id/edit/details')({
  validateSearch: (input: { isNew?: boolean } & SearchSchemaInput) => editListingSearchSchema.parse(input),
  component: EditListing,
  loader: async ({ context: { queryClient, connectTransport }, params }) => {
    await queryClient.prefetchQuery({
      ...createQueryOptions(getListing, { id: params.id }, { transport: connectTransport }),
    })
  },
})

const bedroomValues = Array.from({ length: 21 }, (_, index) => index)
const bedsValues = Array.from({ length: 21 }, (_, index) => index)
const bathroomValues = Array.from({ length: 31 }, (_, index) => index * 0.5)

function EditListing(): ReactNode {
  const { isNew } = Route.useSearch()
  const { queryClient } = Route.useRouteContext()
  const params = Route.useParams()
  const navigate = Route.useNavigate()

  const { data } = useSuspenseQuery(getListing, { id: params.id })
  const updateMutation = useUpdateListingMutation(queryClient)

  const { _, i18n } = useLingui()

  const numberInputParser = new NumberParser(i18n.locale, { style: 'decimal' })
  const countries = getCountries()

  if (data.listing === undefined) {
    return <NotFoundPage />
  }

  const listing = data.listing

  const [country, setCountry] = useState(countries.get(listing.country))

  async function handleSaveChanges({ value }: { value: UpdateListingForm }) {
    try {
      await updateMutation.mutateAsync({
        id: listing.id,
        listing: {
          name: value.name,
          cityOrNeighborhood: value.cityOrNeighborhood,
          stateOrRegion: value.stateOrRegion,
          country: value.country,
          propertyType: value.propertyType,
          listingType: value.listingType,
          style: value.style,
          bedrooms: value.bedrooms,
          bathrooms: value.bathrooms,
          beds: value.beds,
          area: value.area,
          lotSize: value.lotSize,
          yearBuilt: value.yearBuilt,
          amenities: value.amenities.map(({ text }) => text),
          nearbyAttractions: value.nearbyAttractions.map(({ text }) => text),
          features: value.features.map(({ text }) => text),
          measurementUnit: value.measurementUnit,
        },
      })
      toast.success(
        _(
          msg({
            context: 'ToastAlert',
            comment: `Toast alert text that's shown when a listing is successfully update`,
            message: 'Listing updated',
          }),
        ),
        toastOptions,
      )
      await navigate({ to: '/listings/$id', params: { id: params.id } })
    } catch (e) {
      const { isExpectedError, message } = getLocalizedError(e)
      if (!isExpectedError) {
        Sentry.captureException(e)
      }
      toast.error(_(message), toastOptions)
    }
  }

  const form = useForm({
    defaultValues: {
      name: listing.name,
      cityOrNeighborhood: listing.cityOrNeighborhood,
      stateOrRegion: listing.stateOrRegion,
      country: listing.country,
      propertyType: listing.propertyType,
      listingType: listing.listingType,
      style: listing.style,
      bedrooms: listing.bedrooms,
      bathrooms: listing.bathrooms,
      beds: listing.beds,
      area: listing.area,
      lotSize: listing.lotSize,
      yearBuilt: listing.yearBuilt,
      amenities: [...listing.amenities.map((value) => ({ text: value }))] as Array<{ text: string }>,
      nearbyAttractions: [...listing.nearbyAttractions.map((value) => ({ text: value }))] as Array<{
        text: string
      }>,
      features: [...listing.features.map((value) => ({ text: value }))] as Array<{ text: string }>,
      measurementUnit: listing.measurementUnit,
    },
    onSubmit: handleSaveChanges,
    validatorAdapter: zodValidator(),
  })

  const nameField = (
    <form.Field
      name='name'
      validators={{
        onSubmit: updateListingFormSchema.shape.name,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Input
            id={field.name}
            name={field.name}
            type='text'
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            invalid={field.state.meta.errors.length > 0}
          />
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='EditListingDetailsPage'
                comment='Input error message that is displayed when the user does not specify a name for the listing'
              >
                Please provide a name for the listing
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const propertyTypeField = (
    <form.Field
      name='propertyType'
      validators={{
        onSubmit: updateListingFormSchema.shape.propertyType,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='EditListingDetailsPage' comment='Label text for property type list box'>
              Property Type
            </Trans>
          </Label>
          <Listbox
            name={field.name}
            onChange={(e) => field.handleChange(e)}
            value={field.state.value}
            invalid={field.state.meta.errors.length > 0}
          >
            {Object.values(PropertyType)
              .filter((value): value is PropertyType => typeof value === 'number')
              .map((value) => (
                <ListboxOption key={value} value={value}>
                  <ListboxLabel>
                    {value === PropertyType.PROPERTY_TYPE_UNSPECIFIED ? '-' : _(getLocalizedPropertyType(value))}
                  </ListboxLabel>
                </ListboxOption>
              ))}
          </Listbox>
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='EditListingDetailsPage'
                comment='List box error message that is displayed when the user does not select a property type'
              >
                Please select a property type
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const listingTypeField = (
    <form.Field
      name='listingType'
      validators={{
        onSubmit: updateListingFormSchema.shape.listingType,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='EditListingDetailsPage' comment='Label text for listing type list box'>
              Listing Type
            </Trans>
          </Label>
          <Listbox
            name={field.name}
            onChange={(e) => field.handleChange(e)}
            value={field.state.value}
            invalid={field.state.meta.errors.length > 0}
          >
            {Object.values(ListingType)
              .filter((value): value is ListingType => typeof value === 'number')
              .map((value) => (
                <ListboxOption key={value} value={value}>
                  <ListboxLabel>
                    {value === ListingType.LISTING_TYPE_UNSPECIFIED ? '-' : _(getLocalizedListingType(value))}
                  </ListboxLabel>
                </ListboxOption>
              ))}
          </Listbox>
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='EditListingDetailsPage'
                comment='List box error message that is displayed when the user does not select a listing type'
              >
                Please select a listing type
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const cityField = (
    <form.Field
      name='cityOrNeighborhood'
      validators={{
        onSubmit: updateListingFormSchema.shape.cityOrNeighborhood,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='EditListingDetailsPage' comment='Label text for city or neighborhood input'>
              City or Neighborhood
            </Trans>
          </Label>
          <Input
            id={field.name}
            name={field.name}
            className='col-span-2'
            type='text'
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            invalid={field.state.meta.errors.length > 0}
          />
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='EditListingDetailsPage'
                comment='Input error message that is displayed when the user does not specify a city or neighborhood'
              >
                Please specify a city or neighborhood
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const stateOrRegionField = (
    <form.Field
      name='stateOrRegion'
      validators={{
        onSubmit: updateListingFormSchema.shape.stateOrRegion,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='EditListingDetailsPage' comment='Label text for state or region list box'>
              State or Region
            </Trans>
          </Label>
          <Listbox
            name={field.name}
            value={field.state.value}
            onChange={(state) => {
              field.handleChange(state)
            }}
          >
            {country?.regions?.map((region) => (
              <ListboxOption key={region?.code} value={region?.name}>
                <ListboxLabel>{region?.name || '-'}</ListboxLabel>
              </ListboxOption>
            ))}
          </Listbox>
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='EditListingDetailsPage'
                comment='List box error message that is displayed when the user does not select a state or region'
              >
                Please select a state or region
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const countryField = (
    <form.Field
      name='country'
      validators={{
        onSubmit: updateListingFormSchema.shape.country,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='EditListingDetailsPage' comment='Label text for country list box'>
              Country
            </Trans>
          </Label>
          <Listbox
            name={field.name}
            aria-label='Country'
            placeholder='Country'
            value={field.state.value}
            onChange={(country) => {
              field.handleChange(country)
              setCountry(countries.get(country))
            }}
            className='col-span-2'
          >
            {[...countries.entries()].map(([, country]) => (
              <ListboxOption key={country.code} value={country.name}>
                <span>{country.emoji}</span>
                <ListboxLabel>{country.name || '-'}</ListboxLabel>
              </ListboxOption>
            ))}
          </Listbox>
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='EditListingDetailsPage'
                comment='List box error message that is displayed when the user does not select a country'
              >
                Please select a country
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const styleField = (
    <form.Field
      name='style'
      validators={{
        onSubmit: updateListingFormSchema.shape.style,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='EditListingDetailsPage' comment='Label text for style list box'>
              Style
            </Trans>
          </Label>
          <Listbox
            name={field.name}
            onChange={(e) => field.handleChange(e)}
            value={field.state.value}
            invalid={field.state.meta.errors.length > 0}
          >
            {Object.values(Style)
              .filter((value): value is Style => typeof value === 'number')
              .map((value) => (
                <ListboxOption key={value} value={value}>
                  <ListboxLabel>{value === Style.STYLE_UNSPECIFIED ? '-' : _(getLocalizedStyle(value))}</ListboxLabel>
                </ListboxOption>
              ))}
          </Listbox>
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='EditListingDetailsPage'
                comment='List box error message that is displayed when the user does not select a style'
              >
                Please select a style
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const bedroomsField = (
    <form.Field
      name='bedrooms'
      validators={{
        onSubmit: updateListingFormSchema.shape.bedrooms,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='EditListingDetailsPage' comment='Label text for bedrooom count list box'>
              Bedrooms
            </Trans>
          </Label>
          <Listbox
            name={field.name}
            onChange={(e) => field.handleChange(e)}
            value={field.state.value}
            invalid={field.state.meta.errors.length > 0}
          >
            {bedroomValues.map((value) => {
              return (
                <ListboxOption key={value} value={value}>
                  <ListboxLabel>{value > 0 ? i18n.number(value) : '-'}</ListboxLabel>
                </ListboxOption>
              )
            })}
          </Listbox>
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='EditListingDetailsPage'
                comment='List box error message that is displayed when the user does not select the bedroom count'
              >
                Please select the bedroom count
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const bathroomsField = (
    <form.Field
      name='bathrooms'
      validators={{
        onSubmit: updateListingFormSchema.shape.bathrooms,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='EditListingDetailsPage' comment='Label text for bathroom count list box'>
              Bathrooms
            </Trans>
          </Label>
          <Listbox
            name={field.name}
            onChange={(e) => field.handleChange(e)}
            value={field.state.value}
            invalid={field.state.meta.errors.length > 0}
          >
            {bathroomValues.map((value) => {
              return (
                <ListboxOption key={value} value={value}>
                  <ListboxLabel>{value > 0 ? i18n.number(value) : '-'}</ListboxLabel>
                </ListboxOption>
              )
            })}
          </Listbox>
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='EditListingDetailsPage'
                comment='List box error message that is displayed when the user does not select the bathroom count'
              >
                Please select the bathroom count
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const bedsField = (
    <form.Field
      name='beds'
      validators={{
        onSubmit: updateListingFormSchema.shape.beds,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='EditListingDetailsPage' comment='Label text for bed count list box'>
              Beds
            </Trans>
          </Label>
          <Listbox
            name={field.name}
            onChange={(e) => field.handleChange(e)}
            value={field.state.value}
            invalid={field.state.meta.errors.length > 0}
          >
            {bedsValues.map((value) => {
              return (
                <ListboxOption key={value} value={value}>
                  <ListboxLabel>{value > 0 ? i18n.number(value) : '-'}</ListboxLabel>
                </ListboxOption>
              )
            })}
          </Listbox>
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='EditListingDetailsPage'
                comment='List box error message that is displayed when the user does not select the bathroom count'
              >
                Please select the bathroom count
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const areaField = (
    <form.Field
      name='area'
      validators={{
        onSubmit: updateListingFormSchema.shape.area,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='EditListingDetailsPage' comment='Label text for area'>
              Area
            </Trans>
          </Label>
          <Input
            id={field.name}
            name={field.name}
            type='text'
            inputMode='numeric'
            value={field.state.value === 0 ? '' : field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => {
              const number = numberInputParser.parse(e.target.value)
              if (Number.isNaN(number)) {
                field.handleChange(0)
              } else {
                field.handleChange(number)
              }
            }}
            invalid={field.state.meta.errors.length > 0}
          />
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='EditListingDetailsPage'
                comment='Input error message that is displayed when the user does not specify an area'
              >
                Please specify the area
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const lotSizeField = (
    <form.Field
      name='lotSize'
      validators={{
        onSubmit: updateListingFormSchema.shape.lotSize,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='EditListingDetailsPage' comment='Label text for lot size'>
              Lot Size
            </Trans>
          </Label>
          <Input
            id={field.name}
            name={field.name}
            type='text'
            inputMode='numeric'
            value={field.state.value === 0 ? '' : field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => {
              const number = numberInputParser.parse(e.target.value)
              if (Number.isNaN(number)) {
                field.handleChange(0)
              } else {
                field.handleChange(number)
              }
            }}
            invalid={field.state.meta.errors.length > 0}
          />
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='EditListingDetailsPage'
                comment='Input error message that is displayed when the user does not specify a lot size'
              >
                Please specify the lot size
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const measurementUnitField = (
    <form.Field
      name='measurementUnit'
      validators={{
        onSubmit: updateListingFormSchema.shape.measurementUnit,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='EditListingDetailsPage' comment='Label text for measurement unit list box'>
              Measurement Unit
            </Trans>
          </Label>
          <Listbox
            name={field.name}
            onChange={(e) => field.handleChange(e)}
            value={field.state.value}
            invalid={field.state.meta.errors.length > 0}
          >
            {Object.values(MeasurementUnit)
              .filter((value): value is MeasurementUnit => typeof value === 'number')
              .map((value) => (
                <ListboxOption key={value} value={value}>
                  <ListboxLabel>{_(getLocalizedAreaMeasurementUnit(value))}</ListboxLabel>
                </ListboxOption>
              ))}
          </Listbox>
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='EditListingDetailsPage'
                comment='List box error message that is displayed when the user does not select a measurement unit'
              >
                Please select a measurement unit
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const yearBuiltField = (
    <form.Field
      name='yearBuilt'
      validators={{
        onSubmit: updateListingFormSchema.shape.yearBuilt,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='EditListingDetailsPage' comment='Label text for year built'>
              Year Built
            </Trans>
          </Label>
          <Input
            id={field.name}
            name={field.name}
            type='text'
            inputMode='numeric'
            value={field.state.value === 0 ? '' : field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => {
              const number = numberInputParser.parse(e.target.value)
              if (Number.isNaN(number)) {
                field.handleChange(0)
              } else {
                field.handleChange(number)
              }
            }}
            invalid={field.state.meta.errors.length > 0}
          />
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='EditListingDetailsPage'
                comment='Input error message that is displayed when the user does not specify the year built'
              >
                Please specify the year built
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const amenitiesField = (
    <form.Field name='amenities' mode='array'>
      {(field) => {
        return (
          <div className='flex w-full flex-col items-stretch gap-4'>
            {field.state.value.map((value, i) => {
              return (
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                <div key={i} className='flex min-w-0 max-w-full items-center justify-start gap-2'>
                  <Input
                    className='max-w-md'
                    value={value.text}
                    onChange={(e) => field.replaceValue(i, { text: e.target.value })}
                  />
                  <Button
                    plain
                    onClick={async () => {
                      await field.removeValue(i)
                    }}
                  >
                    <XMarkIcon className='fill-gray-500' />
                  </Button>
                </div>
              )
            })}
            <Button
              className='self-start'
              outline
              onClick={() => {
                field.pushValue({ text: '' })
              }}
              type='button'
            >
              <PlusIcon />
              <Trans context='EditListingDetailsPage' comment='Button text for adding a new ameneity to the list'>
                Add
              </Trans>
            </Button>
          </div>
        )
      }}
    </form.Field>
  )

  const nearbyAttractionField = (
    <form.Field name='nearbyAttractions' mode='array'>
      {(field) => {
        return (
          <div className='flex w-full flex-col items-stretch gap-4'>
            {field.state.value.map((value, i) => {
              return (
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                <div key={i} className='flex min-w-0 max-w-full items-center justify-start gap-2'>
                  <Input
                    className='max-w-md'
                    value={value.text}
                    onChange={(e) => field.replaceValue(i, { text: e.target.value })}
                  />
                  <Button
                    plain
                    onClick={async () => {
                      await field.removeValue(i)
                    }}
                  >
                    <XMarkIcon className='fill-gray-500' />
                  </Button>
                </div>
              )
            })}
            <Button
              className='self-start'
              outline
              onClick={() => {
                field.pushValue({ text: '' })
              }}
              type='button'
            >
              <PlusIcon />
              <Trans
                context='EditListingDetailsPage'
                comment='Button text for adding a new nearby attraction to the list'
              >
                Add
              </Trans>
            </Button>
          </div>
        )
      }}
    </form.Field>
  )

  const featuresField = (
    <form.Field name='features' mode='array'>
      {(field) => {
        return (
          <div className='flex w-full flex-col items-stretch gap-4'>
            {field.state.value.map((value, i) => {
              return (
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                <div key={i} className='flex min-w-0 max-w-full items-center justify-start gap-2'>
                  <Input
                    className='max-w-md'
                    value={value.text}
                    onChange={(e) => field.replaceValue(i, { text: e.target.value })}
                  />
                  <Button
                    plain
                    onClick={async () => {
                      await field.removeValue(i)
                    }}
                  >
                    <XMarkIcon className='fill-gray-500' />
                  </Button>
                </div>
              )
            })}
            <Button
              className='self-start'
              outline
              onClick={() => {
                field.pushValue({ text: '' })
              }}
              type='button'
            >
              <PlusIcon />
              <Trans
                context='EditListingDetailsPage'
                comment='Button text for adding a new feature or selling point to the list'
              >
                Add
              </Trans>
            </Button>
          </div>
        )
      }}
    </form.Field>
  )

  return (
    <>
      <form
        onSubmit={async (e) => {
          e.preventDefault()
          e.stopPropagation()
          await form.handleSubmit()
        }}
      >
        <form.Subscribe
          selector={(state) => ({
            isSubmitting: state.isSubmitting,
            canSubmit: state.canSubmit,
            listingType: state.values.listingType,
          })}
        >
          {({ isSubmitting, canSubmit, listingType }) => (
            <>
              <BackNavigation new={isNew} listingId={listing.id} />
              <Header new={isNew} />
              <NameSection nameField={nameField} />
              <Divider className='my-10' soft />
              <TypeSection propertyTypeField={propertyTypeField} listingTypeField={listingTypeField} />
              <Divider className='my-10' soft />
              <LocationSection
                cityField={cityField}
                stateOrRegionField={stateOrRegionField}
                countryField={countryField}
              />
              <Divider className='my-10' soft />
              <BasicDetailsSection
                isAirbnb={isAirbnb(listingType)}
                bedroomsField={bedroomsField}
                bathroomsField={bathroomsField}
                bedsField={bedsField}
                styleField={styleField}
                yearBuiltField={yearBuiltField}
              />
              <Divider className='my-10' soft />
              <SizeSection
                isAirbnb={isAirbnb(listingType)}
                areaField={areaField}
                lotSizeField={lotSizeField}
                measurementUnitField={measurementUnitField}
              />
              <Divider className='my-10' soft />
              <AdditionalDetailsSection
                amenitiesField={amenitiesField}
                nearbyAttractionsField={nearbyAttractionField}
                featuresField={featuresField}
              />

              <div className='mt-8 flex justify-end gap-4'>
                <Button
                  type='reset'
                  plain
                  disabled={!canSubmit || isSubmitting}
                  from={Route.fullPath}
                  href='/listings/$id'
                  params={{ id: params.id }}
                >
                  <Trans context='EditListingDetailsPage' comment='Button text for cancelling listing details changes'>
                    Cancel
                  </Trans>
                </Button>
                <SpinnerButton type='submit' showSpinner={isSubmitting} disabled={!canSubmit || isSubmitting}>
                  <Trans context='EditListingDetailsPage' comment='Button text for saving changes to listing details'>
                    Save changes
                  </Trans>
                </SpinnerButton>
              </div>
            </>
          )}
        </form.Subscribe>
      </form>
    </>
  )
}

function isAirbnb(listingType: ListingType): boolean {
  return (
    listingType === ListingType.AIRBNB_SHARED_ROOM ||
    listingType === ListingType.AIRBNB_PRIVATE_ROOM ||
    listingType === ListingType.AIRBNB_ENTIRE_PLACE
  )
}
