import { Subheading } from '@/components/heading.tsx'
import { Text } from '@/components/text.tsx'
import { Trans } from '@lingui/macro'
import type { ReactNode } from 'react'

export function SizeSection(props: {
  isAirbnb: boolean
  areaField: ReactNode
  lotSizeField: ReactNode
  measurementUnitField: ReactNode
}) {
  return (
    <section className='grid gap-x-8 gap-y-6 sm:grid-cols-2'>
      <div className='space-y-1'>
        <Subheading>
          <Trans context='EditListingDetailsPage' comment='Subheading text for the size section'>
            Size
          </Trans>
        </Subheading>
        <Text>
          <Trans context='EditListingDetailsPage' comment='Detail text for the size section'>
            Provide the size details of your listing, including area, lot size, and measurement units.
          </Trans>
        </Text>
      </div>
      <div className='grid grid-cols-2 gap-6'>
        {props.areaField}
        {props.isAirbnb || props.lotSizeField}
        {props.measurementUnitField}
      </div>
    </section>
  )
}
