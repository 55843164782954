import type { LLMOutputComponent } from '@llm-ui/react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

export const MarkdownComponent: LLMOutputComponent = ({ blockMatch }) => {
  const markdown = blockMatch.output
  return (
    <ReactMarkdown
      className='prose-md prose prose-zinc dark:prose-invert prose-p:font-serif'
      remarkPlugins={[remarkGfm]}
    >
      {markdown}
    </ReactMarkdown>
  )
}
