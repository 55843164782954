// @generated by protoc-gen-es v1.10.0
// @generated from file service/description/v1/description_service.proto (package service.description.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum service.description.v1.DescriptionTone
 */
export const DescriptionTone = /*@__PURE__*/ proto3.makeEnum(
  "service.description.v1.DescriptionTone",
  [
    {no: 0, name: "TONE_UNSPECIFIED"},
    {no: 1, name: "TONE_PROFESSIONAL"},
    {no: 2, name: "TONE_FRIENDLY"},
    {no: 3, name: "TONE_LUXURIOUS"},
    {no: 4, name: "TONE_MODERN"},
    {no: 5, name: "TONE_RUSTIC"},
    {no: 6, name: "TONE_ENERGETIC"},
    {no: 7, name: "TONE_SERENE"},
    {no: 8, name: "TONE_INFORMATIVE"},
    {no: 9, name: "TONE_PERSUASIVE"},
    {no: 10, name: "TONE_NOSTALGIC"},
    {no: 11, name: "TONE_ROMANTIC"},
    {no: 12, name: "TONE_ADVENTUROUS"},
    {no: 13, name: "TONE_COZY"},
    {no: 14, name: "TONE_ECO_FRIENDLY"},
    {no: 15, name: "TONE_ARTSY"},
    {no: 16, name: "TONE_MINIMALIST"},
  ],
);

/**
 * @generated from enum service.description.v1.DescriptionTarget
 */
export const DescriptionTarget = /*@__PURE__*/ proto3.makeEnum(
  "service.description.v1.DescriptionTarget",
  [
    {no: 0, name: "TARGET_UNSPECIFIED"},
    {no: 1, name: "TARGET_FIRST_TIME_BUYERS"},
    {no: 2, name: "TARGET_LUXURY_SEEKERS"},
    {no: 3, name: "TARGET_INVESTORS"},
    {no: 4, name: "TARGET_RETIREES"},
    {no: 5, name: "TARGET_YOUNG_PROFESSIONALS"},
    {no: 6, name: "TARGET_FAMILIES"},
    {no: 7, name: "TARGET_VACATION_RENTERS"},
    {no: 8, name: "TARGET_CORPORATE_RENTERS"},
    {no: 9, name: "TARGET_STUDENTS"},
    {no: 10, name: "TARGET_ECO_CONSCIOUS_TRAVELERS"},
    {no: 11, name: "TARGET_COUPLES"},
    {no: 12, name: "TARGET_SOLO_TRAVELERS"},
    {no: 13, name: "TARGET_DIGITAL_NOMADS"},
    {no: 14, name: "TARGET_ADVENTURE_SEEKERS"},
    {no: 15, name: "TARGET_PET_OWNERS"},
    {no: 16, name: "TARGET_LUXURY_TRAVELERS"},
    {no: 17, name: "TARGET_GROUP_TRAVELERS"},
    {no: 18, name: "TARGET_WELLNESS_ENTHUSIASTS"},
    {no: 19, name: "TARGET_CULTURE_ENTHUSIASTS"},
    {no: 20, name: "TARGET_BUDGET_TRAVELERS"},
  ],
);

/**
 * @generated from enum service.description.v1.DescriptionLanguage
 */
export const DescriptionLanguage = /*@__PURE__*/ proto3.makeEnum(
  "service.description.v1.DescriptionLanguage",
  [
    {no: 0, name: "LANGUAGE_UNSPECIFIED"},
    {no: 1, name: "LANGUAGE_BG_BG"},
    {no: 2, name: "LANGUAGE_CS_CZ"},
    {no: 3, name: "LANGUAGE_DA_DK"},
    {no: 4, name: "LANGUAGE_DE_AT"},
    {no: 5, name: "LANGUAGE_DE_CH"},
    {no: 6, name: "LANGUAGE_DE_DE"},
    {no: 7, name: "LANGUAGE_DE_LU"},
    {no: 8, name: "LANGUAGE_EL_GR"},
    {no: 9, name: "LANGUAGE_EN_AU"},
    {no: 10, name: "LANGUAGE_EN_CA"},
    {no: 11, name: "LANGUAGE_EN_GB"},
    {no: 12, name: "LANGUAGE_EN_IE"},
    {no: 13, name: "LANGUAGE_EN_NZ"},
    {no: 14, name: "LANGUAGE_EN_US"},
    {no: 15, name: "LANGUAGE_ES_AR"},
    {no: 16, name: "LANGUAGE_ES_CL"},
    {no: 17, name: "LANGUAGE_ES_CO"},
    {no: 18, name: "LANGUAGE_ES_CR"},
    {no: 19, name: "LANGUAGE_ES_ES"},
    {no: 20, name: "LANGUAGE_ES_MX"},
    {no: 21, name: "LANGUAGE_ET_EE"},
    {no: 22, name: "LANGUAGE_FI_FI"},
    {no: 23, name: "LANGUAGE_FR_BE"},
    {no: 24, name: "LANGUAGE_FR_CA"},
    {no: 25, name: "LANGUAGE_FR_CH"},
    {no: 26, name: "LANGUAGE_FR_FR"},
    {no: 27, name: "LANGUAGE_FR_LU"},
    {no: 28, name: "LANGUAGE_HE_IL"},
    {no: 29, name: "LANGUAGE_HR_HR"},
    {no: 30, name: "LANGUAGE_HU_HU"},
    {no: 31, name: "LANGUAGE_IS_IS"},
    {no: 32, name: "LANGUAGE_IT_CH"},
    {no: 33, name: "LANGUAGE_IT_IT"},
    {no: 34, name: "LANGUAGE_JA_JP"},
    {no: 35, name: "LANGUAGE_KO_KR"},
    {no: 36, name: "LANGUAGE_LB_LU"},
    {no: 37, name: "LANGUAGE_LT_LT"},
    {no: 38, name: "LANGUAGE_LV_LV"},
    {no: 39, name: "LANGUAGE_NB_NO"},
    {no: 40, name: "LANGUAGE_NL_BE"},
    {no: 41, name: "LANGUAGE_NL_NL"},
    {no: 42, name: "LANGUAGE_NN_NO"},
    {no: 43, name: "LANGUAGE_PL_PL"},
    {no: 44, name: "LANGUAGE_PT_BR"},
    {no: 45, name: "LANGUAGE_PT_PT"},
    {no: 46, name: "LANGUAGE_RM_CH"},
    {no: 47, name: "LANGUAGE_RO_RO"},
    {no: 48, name: "LANGUAGE_RU_RU"},
    {no: 49, name: "LANGUAGE_SK_SK"},
    {no: 50, name: "LANGUAGE_SL_SI"},
    {no: 51, name: "LANGUAGE_SV_SE"},
    {no: 52, name: "LANGUAGE_TR_TR"},
  ],
);

/**
 * @generated from enum service.description.v1.DescriptionLength
 */
export const DescriptionLength = /*@__PURE__*/ proto3.makeEnum(
  "service.description.v1.DescriptionLength",
  [
    {no: 0, name: "LENGTH_UNSPECIFIED"},
    {no: 1, name: "LENGTH_SHORT"},
    {no: 2, name: "LENGTH_MEDIUM"},
    {no: 3, name: "LENGTH_LONG"},
    {no: 4, name: "LENGTH_CAPTION"},
  ],
);

/**
 * @generated from message service.description.v1.GenerateDescriptionRequest
 */
export const GenerateDescriptionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.description.v1.GenerateDescriptionRequest",
  () => [
    { no: 1, name: "listing_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "language", kind: "enum", T: proto3.getEnumType(DescriptionLanguage) },
    { no: 3, name: "tone", kind: "enum", T: proto3.getEnumType(DescriptionTone) },
    { no: 4, name: "target", kind: "enum", T: proto3.getEnumType(DescriptionTarget) },
    { no: 5, name: "length", kind: "enum", T: proto3.getEnumType(DescriptionLength) },
  ],
);

/**
 * @generated from message service.description.v1.GenerateDescriptionResponse
 */
export const GenerateDescriptionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.description.v1.GenerateDescriptionResponse",
  () => [
    { no: 1, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.description.v1.ListDescriptionsForAccountRequest
 */
export const ListDescriptionsForAccountRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.description.v1.ListDescriptionsForAccountRequest",
  () => [
    { no: 1, name: "listing_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message service.description.v1.ListDescriptionsForAccountResponse
 */
export const ListDescriptionsForAccountResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.description.v1.ListDescriptionsForAccountResponse",
  () => [
    { no: 1, name: "descriptions", kind: "message", T: Description, repeated: true },
    { no: 2, name: "total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message service.description.v1.ListDescriptionsForListingRequest
 */
export const ListDescriptionsForListingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.description.v1.ListDescriptionsForListingRequest",
  () => [
    { no: 1, name: "listing_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message service.description.v1.ListDescriptionsForListingResponse
 */
export const ListDescriptionsForListingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.description.v1.ListDescriptionsForListingResponse",
  () => [
    { no: 1, name: "descriptions", kind: "message", T: Description, repeated: true },
    { no: 2, name: "total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message service.description.v1.Description
 */
export const Description = /*@__PURE__*/ proto3.makeMessageType(
  "service.description.v1.Description",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "listing_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "listing_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "created_at", kind: "message", T: Timestamp },
  ],
);

