import '@/index.css'
import { TransportProvider, callUnaryMethod } from '@connectrpc/connect-query'
import { createConnectTransport } from '@connectrpc/connect-web'
import '@fontsource-variable/inter'
import '@fontsource-variable/lora'
import {
  confirmEmailRecoveryCode,
  confirmEmailVerificationCode,
  confirmPasswordResetCode,
  login,
  refreshIdToken,
  sendPasswordResetEmail,
  signUp,
} from '@/pb/service/auth/v1/auth_service-AuthService_connectquery'
import { ErrorPage } from '@/routes/-components/error-page.tsx'
import { NotFoundPage } from '@/routes/-components/not-found-page.tsx'
import { PendingPage } from '@/routes/-components/pending-page.tsx'
import { clearTokens, getTokens, setTokens } from '@/utils/token-util.ts'
import { Code, ConnectError, type Interceptor } from '@connectrpc/connect'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider, createRouter } from '@tanstack/react-router'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { routeTree } from './routeTree.gen'

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

const publicProcedures = new Map<string, boolean>([
  [`${login.service.typeName}/${login.name}`, true],
  [`${signUp.service.typeName}/${signUp.name}`, true],
  [`${refreshIdToken.service.typeName}/${refreshIdToken.name}`, true],
  [`${sendPasswordResetEmail.service.typeName}/${sendPasswordResetEmail.name}`, true],
  [`${confirmPasswordResetCode.service.typeName}/${confirmPasswordResetCode.name}`, true],
  [`${confirmEmailVerificationCode.service.typeName}/${confirmEmailVerificationCode.name}`, true],
  [`${confirmEmailRecoveryCode.service.typeName}/${confirmEmailRecoveryCode.name}`, true],
])

const addHeadersInterceptor: Interceptor = (next) => async (req) => {
  req.header.set('X-Client-Language', navigator.language)
  req.header.set('X-Client-Source', 'web-app')
  req.header.set('X-Client-Version', import.meta.env.VERSION)
  return await next(req)
}

const addTokenInterceptor: Interceptor = (next) => async (req) => {
  const procedure = `${req.service.typeName}/${req.method.name}`
  if (publicProcedures.has(procedure)) {
    return await next(req)
  }
  const { authToken } = await getTokens()
  if (authToken) {
    req.header.set('Authorization', `Bearer ${authToken}`)
  }
  return await next(req)
}

const retryInterceptor: Interceptor = (next) => async (req) => {
  const procedure = `${req.service.typeName}/${req.method.name}`
  if (publicProcedures.has(procedure)) {
    return await next(req)
  }
  try {
    return await next(req)
  } catch (err) {
    if (err instanceof ConnectError && err.code === Code.Unauthenticated) {
      try {
        const { refreshToken } = await getTokens()
        const refreshedTokens = await callUnaryMethod(refreshIdToken, { refreshToken }, { transport: connectTransport })

        const { idToken: newIdToken, refreshToken: newRefreshToken } = refreshedTokens

        await setTokens(newIdToken, newRefreshToken)

        req.header.set('Authorization', `Bearer ${newIdToken}`)
        return await next(req)
      } catch (e) {
        Sentry.captureException(e)
        await clearTokens()
        queryClient.clear()
        Sentry.getCurrentScope().clear()
        await router.navigate({ to: '/login', replace: true })
        throw e
      }
    }
    throw err
  }
}

const connectTransport = createConnectTransport({
  baseUrl: import.meta.env.VITE_API_URL,
  interceptors: [addHeadersInterceptor, addTokenInterceptor, retryInterceptor],
  useBinaryFormat: import.meta.env.PROD,
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60_000 * 5, // 5 minutes
      retry: false,
    },
  },
})

const router = createRouter({
  routeTree,
  context: {
    queryClient: queryClient,
    connectTransport: connectTransport,
  },
  defaultPreload: 'intent',
  defaultPreloadStaleTime: 0,
  defaultOnCatch: (e) => {
    Sentry.captureException(e)
  },
  defaultErrorComponent: () => {
    return <ErrorPage />
  },
  defaultPendingComponent: () => {
    return <PendingPage />
  },
  defaultNotFoundComponent: () => {
    return <NotFoundPage />
  },
})

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://96054997259efd5d937f21d848838220@o4508056552931328.ingest.us.sentry.io/4508056558698496',
    integrations: [Sentry.replayIntegration(), Sentry.tanstackRouterBrowserTracingIntegration(router)],
    tracesSampleRate: 0.05,
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
  })
}

// biome-ignore lint/style/noNonNullAssertion: <explanation>
const rootElement = document.getElementById('app')!
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <StrictMode>
      <TransportProvider transport={connectTransport}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </TransportProvider>
    </StrictMode>,
  )
}
