import LogoIcon from '@/assets/LogoIcon.tsx'
import { Button } from '@/components/button.tsx'
import { Heading } from '@/components/heading.tsx'
import { Text } from '@/components/text.tsx'
import { getLocalizedError } from '@/i18n/error-localization.ts'
import { createAccount } from '@/pb/service/account/v1/account_service-AccountService_connectquery'
import { sendVerificationEmail } from '@/pb/service/auth/v1/auth_service-AuthService_connectquery'
import { SpinnerButton } from '@/routes/-components/spinner-button.tsx'
import { toastOptions } from '@/utils/toast-util.ts'
import { getTokens, setTokens } from '@/utils/token-util.ts'
import { callUnaryMethod } from '@connectrpc/connect-query'
import { Plural, Trans, msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import * as Sentry from '@sentry/react'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'

export const Route = createFileRoute('/_protected/verify')({
  component: Verify,
})

function Verify() {
  const navigate = useNavigate()

  const [verifyingAccount, setVerifyingAccount] = useState(false)
  const [remainingTime, setRemainingTime] = useState(30)
  const { connectTransport } = Route.useRouteContext()

  const { _ } = useLingui()

  useEffect(() => {
    if (remainingTime > 0) {
      const timer = setTimeout(() => {
        setRemainingTime((prevTime) => prevTime - 1)
      }, 1000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [remainingTime])

  async function handleResendEmailClicked() {
    try {
      await callUnaryMethod(sendVerificationEmail, {}, { transport: connectTransport })
      toast.success(
        _(
          msg({
            context: 'ToastAlert',
            comment: `Toast alert text that's shown when the verification email is successfully resent`,
            message: 'Email successfully resent',
          }),
        ),
        toastOptions,
      )
    } catch (e) {
      const { isExpectedError, message } = getLocalizedError(e)
      if (!isExpectedError) {
        Sentry.captureException(e)
      }
      toast.error(_(message), toastOptions)
    }
    setRemainingTime(30)
  }

  async function handleAlreadyVerifiedClicked() {
    setVerifyingAccount(true)
    try {
      const { refreshToken } = await getTokens()
      const response = await callUnaryMethod(createAccount, { refreshToken }, { transport: connectTransport })
      await setTokens(response.idToken, response.refreshToken)
      await navigate({ to: '/listings' })
      Sentry.setUser({ email: response.account?.email ?? '' })
    } catch (e) {
      const { isExpectedError, message } = getLocalizedError(e)
      if (!isExpectedError) {
        Sentry.captureException(e)
      }
      toast.error(_(message), toastOptions)
    } finally {
      setVerifyingAccount(false)
    }
  }

  return (
    <div className='relative flex h-full w-full items-center justify-center sm:bg-gray-100 sm:dark:bg-zinc-950'>
      <LogoIcon className='absolute top-6 left-6 h-4 w-18' />
      <div className='sm:card w-full rounded-none px-6 py-12 sm:max-w-[440px] sm:rounded-xl sm:px-12'>
        <div className='space-y-6'>
          <Heading>
            <Trans context='VerifyEmailPage' comment="Heading text that's shown on the verify email page">
              First, let's verify your email
            </Trans>
          </Heading>
          <Text>
            <Trans context='VerifyEmailPage' comment="Detail text that's shown on the verify email page">
              Check your email to verify your account and get started.
            </Trans>
          </Text>
          <Text>
            <Plural
              context='VerifyEmailPage'
              comment='Text that displays a countdown for when the user is able to resend the verification email, with a reminder to check spam folder'
              value={remainingTime}
              _0={
                <Trans>
                  If you haven't received the email, please check your spam folder. If it's not there, click the button
                  below to resend the verification email.
                </Trans>
              }
              one={
                <Trans>
                  If you haven't received the email, please check your spam folder. If it's not there, you can resend it
                  in {remainingTime} second.
                </Trans>
              }
              two={
                <Trans>
                  If you haven't received the email, please check your spam folder. If it's not there, you can resend it
                  in {remainingTime} seconds.
                </Trans>
              }
              few={
                <Trans>
                  If you haven't received the email, please check your spam folder. If it's not there, you can resend it
                  in {remainingTime} seconds.
                </Trans>
              }
              many={
                <Trans>
                  If you haven't received the email, please check your spam folder. If it's not there, you can resend it
                  in {remainingTime} seconds.
                </Trans>
              }
              other={
                <Trans>
                  If you haven't received the email, please check your spam folder. If it's not there, you can resend it
                  in {remainingTime} seconds.
                </Trans>
              }
            />
          </Text>
          <Button outline className='min-h-10 w-full' onClick={handleResendEmailClicked} disabled={remainingTime > 0}>
            <Trans context='VerifyEmailPage' comment='Button text for resending verification email'>
              Resend email
            </Trans>
          </Button>
          <SpinnerButton
            className='w-full'
            disabled={verifyingAccount}
            showSpinner={verifyingAccount}
            onClick={handleAlreadyVerifiedClicked}
          >
            <Trans context='VerifyEmailPage' comment='Button text for confirming email has been verified'>
              Already verified
            </Trans>
          </SpinnerButton>
        </div>
      </div>
    </div>
  )
}
