export function getTimeDifference(date: Date): { value: number; unit: Intl.RelativeTimeFormatUnit } {
  const now = new Date()
  const diffInMilliseconds = now.getTime() - date.getTime()
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000)

  const units: { name: Intl.RelativeTimeFormatUnit; seconds: number }[] = [
    { name: 'year', seconds: 31536000 },
    { name: 'month', seconds: 2592000 },
    { name: 'week', seconds: 604800 },
    { name: 'day', seconds: 86400 },
    { name: 'hour', seconds: 3600 },
    { name: 'minute', seconds: 60 },
    { name: 'second', seconds: 1 },
  ]

  for (const unit of units) {
    if (diffInSeconds >= unit.seconds) {
      const value = Math.floor(diffInSeconds / unit.seconds)
      return { value: -value, unit: unit.name }
    }
  }

  return { value: 0, unit: 'second' as Intl.RelativeTimeFormatUnit }
}

export function getRelativeTimeString(date?: Date, lang = navigator.language): string {
  if (!date) {
    return ''
  }

  const { value, unit } = getTimeDifference(date)

  const rtf = new Intl.RelativeTimeFormat(lang, { numeric: 'auto' })
  return rtf.format(value, unit)
}
