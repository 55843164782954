import CoinDiagonalIcon from '@/assets/CoinDiagonalIcon.tsx'
import FeedbackIcon from '@/assets/FeedbackIcon.tsx'
import HomeIcon from '@/assets/HomeIcon.tsx'
import LogoIcon from '@/assets/LogoIcon.tsx'
import LogoutCircleIcon from '@/assets/LogoutIcon.tsx'
import PenIcon from '@/assets/PenIcon.tsx'
import SettingsIcon from '@/assets/SettingsIcon.tsx'
import { Navbar } from '@/components/navbar'
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from '@/components/sidebar'
import { SidebarLayout } from '@/components/sidebar-layout'
import { clearTokens } from '@/utils/token-util.ts'
import { Trans } from '@lingui/macro'
import * as Sentry from '@sentry/react'
import { Outlet, createFileRoute, useMatchRoute } from '@tanstack/react-router'
import type { ReactNode } from 'react'

export const Route = createFileRoute('/_protected/_dashboard')({
  component: Dashboard,
})

function Dashboard(): ReactNode {
  const navigate = Route.useNavigate()
  const matchRoute = useMatchRoute()

  const { queryClient } = Route.useRouteContext()

  async function handleLogOutClicked() {
    try {
      queryClient.clear()
      Sentry.getCurrentScope().clear()
      await clearTokens()
      await navigate({ to: '/login', replace: true })
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  return (
    <SidebarLayout
      navbar={<Navbar />}
      sidebar={
        <Sidebar>
          <SidebarHeader>
            <div className='h-fit w-full'>
              <LogoIcon className='mx-2 my-4 h-4 w-18 text-zinc-800 dark:text-zinc-50' />
            </div>
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              <SidebarItem
                href='/listings'
                current={matchRoute({ to: '/listings', fuzzy: true, pending: false }) ? true : undefined}
                preload='intent'
              >
                <HomeIcon className='size-4 text-zinc-800 dark:text-zinc-50' />
                <SidebarLabel>
                  <Trans
                    context='Dashboard'
                    comment='Sidebar item text that navigates the user to the list listings page'
                  >
                    Listings
                  </Trans>
                </SidebarLabel>
              </SidebarItem>
              <SidebarItem
                href='/generated'
                current={matchRoute({ to: '/generated', fuzzy: true, pending: false }) ? true : undefined}
                preload='intent'
              >
                <PenIcon className='size-4 text-zinc-800 dark:text-zinc-50' />
                <SidebarLabel>
                  <Trans
                    context='Dashboard'
                    comment='Sidebar item text that navigates the user to the generated descriptons page'
                  >
                    Generated
                  </Trans>
                </SidebarLabel>
              </SidebarItem>
            </SidebarSection>

            <SidebarSpacer />
            <SidebarSection>
              <SidebarItem href='/credits/purchase'>
                <CoinDiagonalIcon className='size-4 text-zinc-800 dark:text-zinc-50' />
                <SidebarLabel>
                  <Trans
                    context='Dashboard'
                    comment='Sidebar item text that navigates the user to the purchase credits page'
                  >
                    Purchase credits
                  </Trans>
                </SidebarLabel>
              </SidebarItem>
              <SidebarItem href='/feedback'>
                <FeedbackIcon className='size-4 text-zinc-800 dark:text-zinc-50' />
                <SidebarLabel>
                  <Trans
                    context='Dashboard'
                    comment='Sidebar item text that navigate the user to the share feedback page'
                  >
                    Share feedback
                  </Trans>
                </SidebarLabel>
              </SidebarItem>
              <SidebarItem href='/settings'>
                <SettingsIcon className='size-4 text-zinc-800 dark:text-zinc-50' />
                <SidebarLabel>
                  <Trans context='Dashboard' comment='Sidebar item text that navigates the user to the settings page'>
                    Settings
                  </Trans>
                </SidebarLabel>
              </SidebarItem>
            </SidebarSection>
          </SidebarBody>
          <SidebarFooter>
            <SidebarItem onClick={handleLogOutClicked}>
              <LogoutCircleIcon className='size-4 text-zinc-800 dark:text-zinc-50' />
              <SidebarLabel>
                <Trans context='Dashboard' comment='Sidebar item text that logs the user out'>
                  Log out
                </Trans>
              </SidebarLabel>
            </SidebarItem>
          </SidebarFooter>
        </Sidebar>
      }
    >
      <Outlet />
    </SidebarLayout>
  )
}
