import { CheckCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { clsx } from 'clsx'
import type { ReactNode } from 'react'

export interface MessageBoxProps {
  className?: string
  children: React.ReactNode
  info?: boolean
  error?: boolean
  success?: boolean
}

const variantStyles = {
  info: {
    bg: 'bg-blue-50 dark:bg-blue-900',
    icon: InformationCircleIcon,
    iconColor: 'fill-blue-400',
    textColor: 'text-blue-800 dark:text-white',
  },
  error: {
    bg: 'bg-red-50 dark:bg-red-900',
    icon: XCircleIcon,
    iconColor: 'fill-red-400',
    textColor: 'text-red-800 dark:text-white',
  },
  success: {
    bg: 'bg-green-50 dark:bg-green-900',
    icon: CheckCircleIcon,
    iconColor: 'fill-green-400',
    textColor: 'text-green-800 dark:text-white',
  },
}

export function MessageBox({ className = '', children, info, error, success }: MessageBoxProps): ReactNode {
  let variant: 'info' | 'error' | 'success'

  if (error) variant = 'error'
  else if (success) variant = 'success'
  else if (info) variant = 'info'
  else variant = 'info'

  const { bg, icon: Icon, iconColor, textColor } = variantStyles[variant]

  return (
    <div className={clsx(className, 'rounded-md p-4', bg)}>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <Icon className={clsx('h-5 w-5', iconColor)} aria-hidden='true' />
        </div>
        <div className='ml-3'>
          <h3 className={clsx('text-sm', textColor)}>{children}</h3>
        </div>
      </div>
    </div>
  )
}
