import { ErrorMessage, Field, Fieldset } from '@/components/fieldset.tsx'
import { Heading } from '@/components/heading.tsx'
import { Textarea } from '@/components/textarea.tsx'
import { getLocalizedError } from '@/i18n/error-localization.ts'
import { createFeedback } from '@/pb/service/feedback/v1/feedback_service-FeedbackService_connectquery'
import { SpinnerButton } from '@/routes/-components/spinner-button.tsx'
import { toastOptions } from '@/utils/toast-util.ts'
import { callUnaryMethod } from '@connectrpc/connect-query'
import { Trans, msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import * as Sentry from '@sentry/react'
import { useForm } from '@tanstack/react-form'
import { createFileRoute } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-form-adapter'
import type { ReactNode } from 'react'
import { toast } from 'sonner'
import { z } from 'zod'

export const Route = createFileRoute('/_protected/_dashboard/feedback')({
  component: Feedback,
})

const feedbackFormSchema = z.object({
  feedback: z.string().min(1),
})

type FeedbackForm = z.infer<typeof feedbackFormSchema>

function Feedback(): ReactNode {
  const { connectTransport } = Route.useRouteContext()

  const { _ } = useLingui()

  const form = useForm({
    defaultValues: {
      feedback: '',
    },
    onSubmit: handleSubmit,
    validatorAdapter: zodValidator(),
  })

  async function handleSubmit({ value }: { value: FeedbackForm }) {
    try {
      await callUnaryMethod(
        createFeedback,
        {
          feedback: value.feedback,
        },
        { transport: connectTransport },
      )
      form.reset()
      toast.success(
        _(
          msg({
            context: 'ToastAlert',
            comment: `Toast alert text that's shown when feedback is successfully submitted`,
            message: 'Your feedback has been received',
          }),
        ),
        toastOptions,
      )
    } catch (e) {
      const { isExpectedError, message } = getLocalizedError(e)
      if (!isExpectedError) {
        Sentry.captureException(e)
      }
      toast.error(_(message), toastOptions)
    }
  }

  const feedbackField = (
    <form.Field
      name='feedback'
      validators={{
        onSubmit: feedbackFormSchema.shape.feedback,
      }}
    >
      {(field) => (
        <Field>
          <Textarea
            id={field.name}
            name={field.name}
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            invalid={field.state.meta.errors.length > 0}
            rows={8}
          />
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='SubmitFeedbackPage'
                comment='Text area error message that is displayed when the user does not fill out the text area'
              >
                Please provide your feedback before submitting
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  return (
    <div className='mx-auto max-w-2xl'>
      <Heading>
        <Trans context='SubmitFeedbackPage' comment='Heading text for the submit feedback page'>
          Help us improve
        </Trans>
      </Heading>
      <form
        className='mt-8'
        onSubmit={async (e) => {
          e.preventDefault()
          e.stopPropagation()
          await form.handleSubmit()
        }}
      >
        <form.Subscribe selector={(state) => [state.isSubmitting, state.canSubmit]}>
          {([isSubmitting, canSubmit]) => (
            <Fieldset disabled={isSubmitting} className='flex w-full flex-col space-y-5'>
              {feedbackField}
              <SpinnerButton
                className='ml-auto'
                type='submit'
                disabled={!canSubmit || isSubmitting}
                showSpinner={isSubmitting}
              >
                <Trans context='SubmitFeedbackPage' comment='Button text for submitting feedback'>
                  Send feedback
                </Trans>
              </SpinnerButton>
            </Fieldset>
          )}
        </form.Subscribe>
      </form>
    </div>
  )
}
