import { Heading } from '@/components/heading.tsx'
import { Text } from '@/components/text.tsx'
import { confirmEmailVerificationCode } from '@/pb/service/auth/v1/auth_service-AuthService_connectquery'
import { callUnaryMethod } from '@connectrpc/connect-query'
import { createFileRoute } from '@tanstack/react-router'

import LogoIcon from '@/assets/LogoIcon.tsx'
import { getLocalizedError } from '@/i18n/error-localization.ts'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import * as Sentry from '@sentry/react'
import { z } from 'zod'

const confirmVerifySearchSchema = z.object({
  code: z.string().catch(''),
})

export const Route = createFileRoute('/verify/confirm')({
  loaderDeps: ({ search: { code } }) => ({ code }),
  loader: async ({ context: { connectTransport }, deps: { code } }) => {
    await callUnaryMethod(
      confirmEmailVerificationCode,
      { oobCode: code },
      { transport: connectTransport },
    )
  },
  validateSearch: confirmVerifySearchSchema,
  errorComponent: ({ error }) => VerifyEmailError(error),
  component: VerifyEmailSuccess,
})

function VerifyEmailSuccess() {
  return (
    <div className="relative flex h-full w-full items-center justify-center sm:bg-gray-100 sm:dark:bg-zinc-950">
      <LogoIcon className="absolute top-6 left-6 h-4 w-18" />
      <div className="sm:card w-full gap-4 rounded-none px-6 py-12 sm:max-w-[440px] sm:rounded-xl sm:px-12">
        <div className="flex flex-col gap-y-4">
          <Heading>
            <Trans
              context="ConfirmEmailVerificationPage"
              comment="Heading text that is displayed on succesful email verification"
            >
              Email successfully verified
            </Trans>
          </Heading>
          <Text>
            <Trans
              context="ConfirmEmailVerificationPage"
              comment="Detail text that is displayed on succesful email verification"
            >
              Your email is now verified. Please return to the app and click{' '}
              <strong>Already verified</strong> to finish creating your account.
            </Trans>
          </Text>
        </div>
      </div>
    </div>
  )
}

function VerifyEmailError(e: unknown) {
  const { _ } = useLingui()

  const { isExpectedError, message } = getLocalizedError(e)
  if (!isExpectedError) {
    Sentry.captureException(e)
  }

  return (
    <div className="relative flex h-full w-full items-center justify-center sm:bg-gray-100 sm:dark:bg-zinc-950">
      <LogoIcon className="absolute top-6 left-6 h-4 w-18" />
      <div className="sm:card w-full rounded-none px-6 py-12 sm:max-w-[440px] sm:rounded-xl sm:px-12">
        <div className="flex flex-col gap-y-6">
          <Heading>
            <Trans
              context="ConfirmEmailVerificationPage"
              comment="Heading text that's shown when the email is unable to be verified due to some error"
            >
              Failed to verify email
            </Trans>
          </Heading>
          <Text>{_(message)}</Text>
        </div>
      </div>
    </div>
  )
}
