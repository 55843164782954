import { Badge } from '@/components/badge.tsx'
import { Button } from '@/components/button.tsx'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '@/components/dialog.tsx'
import { Heading } from '@/components/heading.tsx'
import { Strong, Text } from '@/components/text.tsx'
import {
  getLocalizedListingType,
  getLocalizedPropertyType,
  getLocalizedShortAreaMeasurementUnit,
  getLocalizedStyle,
} from '@/i18n/listing-localization.ts'
import type { Image } from '@/pb/service/media/v1/media_service_pb'
import ResponsiveImage from '@/routes/-components/responsive-image.tsx'
import { SpinnerButton } from '@/routes/-components/spinner-button.tsx'
import { MarkdownComponent } from '@/routes/_protected/_dashboard/-listings/components/markdown-component.tsx'
import { demoGetListingResponse } from '@/utils/demo-util.ts'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { Plural, Trans, msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { markdownLookBack } from '@llm-ui/markdown'
import { throttleBasic, useLLMOutput } from '@llm-ui/react'
import * as Sentry from '@sentry/react'
import { createFileRoute } from '@tanstack/react-router'
import { clsx } from 'clsx'
import { type ReactNode, useRef, useState } from 'react'
import { locales } from '../../lingui.config.ts'

export const Route = createFileRoute('/demo')({
  component: ListingDetail,
})

const amenities = msg({
  context: 'DemoPage',
  comment: 'Text for list of amenities for the demo home',
  message: 'home gym, heated pool',
})

const features = msg({
  context: 'DemoPage',
  comment: 'Text for list of features or selling points for the demo home',
  message: `3 car garage, gated community, chef's kitchen, outdoor grills`,
})

const nearbyAttractions = msg({
  context: 'DemoPage',
  comment: 'Text for list of nearby attractions for the demo home',
  message: 'dog park, shopping malls',
})

const generateErrorMessage = msg({
  context: 'DemoPage',
  comment: 'Error text for when there is an error generating the demo description',
  message: 'Error generating description. Please try again later.',
})

function ListingDetail(): ReactNode {
  const data = demoGetListingResponse

  const [generatedDescription, setGeneratedDescription] = useState<string>('')
  const [isGenerating, setIsGenerating] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const cancelGenerationRef = useRef(false)

  const { _, i18n } = useLingui()

  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  const listing = data.listing!
  const images = listing.images

  function handleOnDialogClosed() {
    setIsOpen(false)
    setIsGenerating(false)
    cancelGenerationRef.current = true
  }

  function handleGoBackClicked() {
    window.history.back()
  }

  async function handleGenerateClicked() {
    setGeneratedDescription('')
    setIsGenerating(true)
    setIsOpen(true)
    cancelGenerationRef.current = false

    const userLocale = navigator.language

    let fullDescription = ''
    try {
      const [locale] = userLocale.split('-')
      const descriptionLocale = locales.includes(userLocale) ? userLocale : 'en'
      const response = await fetch(`/demo/description/${descriptionLocale}.md`)

      if (!response.ok) {
        Sentry.captureException(new Error(`error fetching demo markdown file for ${locale}`))
      }

      const text = await response.text()
      const characters = text.split('')

      for (const char of characters) {
        if (cancelGenerationRef.current) {
          break
        }
        fullDescription += char
        setGeneratedDescription(fullDescription)
        await new Promise((resolve) => setTimeout(resolve, getRandomTypingDelay()))
      }
    } catch (e) {
      Sentry.captureException(e)
      setGeneratedDescription(_(generateErrorMessage))
    } finally {
      setIsGenerating(false)
    }
  }

  function getRandomTypingDelay() {
    return Math.random() * 50 - 25
  }

  const { blockMatches } = useLLMOutput({
    llmOutput: generatedDescription,
    blocks: [],
    fallbackBlock: {
      component: MarkdownComponent,
      lookBack: markdownLookBack(),
    },
    isStreamFinished: !isGenerating,
    throttle: throttleBasic(),
  })

  return (
    <div className='mx-auto max-w-5xl p-6 lg:px-0'>
      <div>
        <Button plain onClick={handleGoBackClicked}>
          <ChevronLeftIcon className='size-4 fill-zinc-500 dark:fill-zinc-500' />
          <Trans context='DemoPage' comment='Link text for leaving demo and navigating back to landing paage'>
            Exit demo
          </Trans>
        </Button>
      </div>
      <ImagesPreview className='mt-8 h-56 w-full sm:h-[30rem]' images={images} />
      <div className='mt-8 flex flex-wrap items-center sm:flex-nowrap sm:justify-between'>
        <div>
          <div className='flex flex-wrap items-center gap-x-4 gap-y-2'>
            <Heading>{listing?.name}</Heading>
            <Badge color={'zinc'}>
              <Trans context='DemoPage' comment='Badge text that joins the property and listing type'>
                {_(getLocalizedPropertyType(listing.propertyType))} - {_(getLocalizedListingType(listing.listingType))}
              </Trans>
            </Badge>
          </div>
        </div>
        <div className='mt-8 flex gap-4 sm:mt-0'>
          <SpinnerButton disabled={isGenerating} showSpinner={isGenerating} onClick={handleGenerateClicked}>
            <Trans context='DemoPage' comment='Button text for generating a description for the listing'>
              Generate Description
            </Trans>
          </SpinnerButton>
        </div>
      </div>
      <div className='mt-10'>
        <dl className='grid grid-cols-1 sm:grid-cols-2'>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='DemoPage' comment='Label text for listing name'>
                    Name
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{listing.name}</Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='DemoPage' comment='Label text for city'>
                    City
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{listing.cityOrNeighborhood}</Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='DemoPage' comment='Label text for state'>
                    State
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{listing.stateOrRegion}</Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='DemoPage' comment='Label text for country'>
                    Country
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{listing.country}</Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='DemoPage' comment='Label text for property type'>
                    Property Type
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{_(getLocalizedPropertyType(listing.propertyType))}</Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='DemoPage' comment='Label text for listing type'>
                    Listing Type
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{_(getLocalizedListingType(listing.listingType))}</Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='DemoPage' comment='Label text for style'>
                    Style
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{_(getLocalizedStyle(listing.style))}</Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='DemoPage' comment='Label text for bedroom count'>
                    Bedrooms
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>
                <Plural
                  context='DemoPage'
                  comment='Text that shows the number of bedrooms'
                  value={listing.bedrooms}
                  _0={<Trans>-</Trans>}
                  one={<Trans>{i18n.number(listing.bedrooms)} bedroom</Trans>}
                  two={<Trans>{i18n.number(listing.bedrooms)} bedrooms</Trans>}
                  few={<Trans>{i18n.number(listing.bedrooms)} bedrooms</Trans>}
                  many={<Trans>{i18n.number(listing.bedrooms)} bedrooms</Trans>}
                  other={<Trans>{i18n.number(listing.bedrooms)} bedrooms</Trans>}
                />
              </Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='DemoPage' comment='Label text for bathroom count'>
                    Bathrooms
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>
                <Plural
                  context='DemoPage'
                  comment='Text that displays the bathroom count'
                  value={listing.bathrooms}
                  _0={<Trans>-</Trans>}
                  one={<Trans>{i18n.number(listing.bathrooms)} bathroom</Trans>}
                  two={<Trans>{i18n.number(listing.bathrooms)} bathrooms</Trans>}
                  few={<Trans>{i18n.number(listing.bathrooms)} bathrooms</Trans>}
                  many={<Trans>{i18n.number(listing.bathrooms)} bathrooms</Trans>}
                  other={<Trans>{i18n.number(listing.bathrooms)} bathrooms</Trans>}
                />
              </Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='DemoPage' comment='Label text for area'>
                    Area
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>
                <Plural
                  context='DemoPage'
                  comment='Text that displays the area along with its abbreviated measurement unit'
                  value={listing.area}
                  _0={<Trans>-</Trans>}
                  one={
                    <Trans>
                      {i18n.number(listing.area)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                  two={
                    <Trans>
                      {i18n.number(listing.area)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                  few={
                    <Trans>
                      {i18n.number(listing.area)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                  many={
                    <Trans>
                      {i18n.number(listing.area)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                  other={
                    <Trans>
                      {i18n.number(listing.area)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                />
              </Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='DemoPage' comment='Label text for lot size'>
                    Lot Size
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>
                <Plural
                  context='DemoPage'
                  comment='Text that displays the lot size along with its abbreviated measurement unit'
                  value={listing.lotSize}
                  _0={<Trans>-</Trans>}
                  one={
                    <Trans>
                      {i18n.number(listing.lotSize)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                  two={
                    <Trans>
                      {i18n.number(listing.lotSize)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                  few={
                    <Trans>
                      {i18n.number(listing.lotSize)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                  many={
                    <Trans>
                      {i18n.number(listing.lotSize)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                  other={
                    <Trans>
                      {i18n.number(listing.lotSize)} {_(getLocalizedShortAreaMeasurementUnit(listing.measurementUnit))}
                    </Trans>
                  }
                />
              </Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-1 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='DemoPage' comment='Label text for year built'>
                    Year Built
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>
                {i18n.date(new Date(Date.UTC(listing.yearBuilt, 1)), {
                  year: 'numeric',
                })}
              </Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-2 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='DemoPage' comment='Label text for amenities'>
                    Amenities
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{_(amenities)}</Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-2 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='DemoPage' comment='Label text for nearby attractions'>
                    Nearby Attractions
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{_(nearbyAttractions)}</Text>
            </dd>
          </div>
          <div className='border-zinc-950/5 border-t py-6 sm:col-span-2 sm:px-0 dark:border-white/5'>
            <dt>
              <Text>
                <Strong>
                  <Trans context='DemoPage' comment='Label text for features or selling points'>
                    Features or Selling Points
                  </Trans>
                </Strong>
              </Text>
            </dt>
            <dd className='mt-1 sm:mt-2'>
              <Text>{_(features)}</Text>
            </dd>
          </div>
        </dl>
      </div>
      <Dialog open={generatedDescription !== '' && isOpen} onClose={handleOnDialogClosed} size='3xl'>
        <DialogTitle className='font-serif'>{listing.name}</DialogTitle>
        <DialogDescription className='font-serif'>
          <Trans context='DemoPage' comment='Dialog description text that shows the property and listing type'>
            {_(getLocalizedPropertyType(listing.propertyType))} - {_(getLocalizedListingType(listing.listingType))}
          </Trans>
        </DialogDescription>
        <DialogBody className='text-sm text-zinc-900 dark:text-white'>
          {generatedDescription && (
            <div>
              {blockMatches.map((blockMatch, index) => {
                const Component = blockMatch.block.component
                return <Component key={`${index}-${blockMatch.output}`} blockMatch={blockMatch} />
              })}
            </div>
          )}
        </DialogBody>
        {!isGenerating && (
          <DialogActions>
            <Button plain onClick={() => setIsOpen(false)}>
              <Trans
                context='DemoPage'
                comment='Dialog action button text that dismisses the generated description dialog'
              >
                Close
              </Trans>
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}

function ImagesPreview(props: {
  images: Image[]
  className?: string
}): ReactNode {
  const imageCount = props.images.length || 0

  const renderImages = () => {
    switch (imageCount) {
      case 0:
        return (
          <div className='flex h-full w-full items-center justify-center rounded-md bg-gray-100 dark:bg-zinc-800' />
        )
      case 1:
        return (
          <ResponsiveImage
            sizes='100vw'
            alt='Listing image'
            variants={props.images[0].variants || []}
            className='h-full w-full rounded-md bg-gray-300 object-cover object-center'
          />
        )
      case 2:
        return (
          <div className='flex h-full w-full gap-1 sm:gap-2'>
            {props.images.map((image, index) => (
              <ResponsiveImage
                key={image.id}
                sizes='50vw'
                alt={`Listing image ${index + 1}`}
                variants={image.variants || []}
                className={clsx(
                  index === 0 ? 'rounded-l-md' : 'rounded-r-md',
                  'h-full w-1/2 bg-gray-300 object-cover object-center',
                )}
              />
            ))}
          </div>
        )
      case 3:
      case 4:
        return (
          <div className='flex h-full w-full gap-1 sm:gap-2'>
            <ResponsiveImage
              sizes='50vw'
              alt='Listing image 1'
              variants={props.images[0].variants || []}
              className='h-full min-h-0 w-7/12 rounded-l-md bg-gray-300 object-cover object-center'
            />
            <div className='flex w-5/12 flex-col gap-1 sm:gap-2'>
              {props.images.slice(1, 3).map((image, index) => (
                <ResponsiveImage
                  key={image.id}
                  sizes='25vw'
                  alt={`Listing image ${index + 2}`}
                  variants={image.variants || []}
                  className={`h-1/2 min-h-0 w-full bg-gray-300 object-cover object-center ${index === 0 ? 'rounded-tr-md' : 'rounded-br-md'
                    }`}
                />
              ))}
            </div>
          </div>
        )
      default: // 5 or 6 images
        return (
          <>
            <div className='@xl:w-1/2 w-3/5'>
              <ResponsiveImage
                sizes='(min-width: 640px) 50vw, (min-width: 768px) 45vw, (min-width: 1024px) 45vw, (min-width: 1280px) 35vw, (min-width: 1536px) 40vw, 30vw'
                alt='Listing image 1'
                variants={props.images[0].variants || []}
                className='h-full w-full rounded-l-md bg-gray-300 object-cover object-center'
              />
            </div>
            <div className='flex @xl:w-1/4 w-2/5 flex-col items-stretch gap-1 sm:gap-2'>
              {props.images.slice(1, 3).map((image, index) => (
                <div key={image.id} className='min-h-0 flex-1'>
                  <ResponsiveImage
                    sizes='(min-width: 640px) 35vw, (min-width: 768px) 20vw, (min-width: 1024px) 25vw, (min-width: 1280px) 20vw, (min-width: 1536px) 20vw, 15vw'
                    alt={`Listing image ${index + 2}`}
                    variants={image.variants || []}
                    className='h-full w-full @xl:rounded-r-none rounded-r-md bg-gray-300 object-cover object-center'
                  />
                </div>
              ))}
            </div>
            <div className='@xl:flex hidden @xl:w-1/4 @xl:flex-col @xl:items-stretch @xl:gap-y-2'>
              {props.images.slice(3, 5).map((image, index) => (
                <div key={image.id} className='min-h-0 flex-1'>
                  <ResponsiveImage
                    sizes='(min-width: 640px) 35vw, (min-width: 768px) 20vw, (min-width: 1024px) 25vw, (min-width: 1280px) 20vw, (min-width: 1536px) 20vw, 15vw'
                    alt={`Listing image ${index + 4}`}
                    variants={image.variants || []}
                    className='h-full w-full @xl:rounded-r-md rounded-r-none bg-gray-300 object-cover object-center'
                  />
                </div>
              ))}
            </div>
          </>
        )
    }
  }

  return (
    <div className={clsx('@container', props.className)}>
      <div className='flex h-full w-full items-stretch gap-1 sm:gap-2'>{renderImages()}</div>
    </div>
  )
}
