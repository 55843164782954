export function getCountries() {
  return new Map([
    [
      '',
      {
        name: '',
        code: '',
        emoji: '',
        regions: [{ name: '', code: '' }],
      },
    ],
    [
      'Afghanistan',
      {
        name: 'Afghanistan',
        code: 'AF',
        emoji: '🇦🇫',
        regions: [
          { name: 'Badakhshan', code: 'BDS' },
          { name: 'Badghis', code: 'BDG' },
          { name: 'Baghlan', code: 'BGL' },
          { name: 'Balkh', code: 'BAL' },
          { name: 'Bamyan', code: 'BAM' },
          { name: 'Daykundi', code: 'DAY' },
          { name: 'Farah', code: 'FRA' },
          { name: 'Faryab', code: 'FYB' },
          { name: 'Ghazni', code: 'GHA' },
          { name: 'Ghōr', code: 'GHO' },
          { name: 'Helmand', code: 'HEL' },
          { name: 'Herat', code: 'HER' },
          { name: 'Jowzjan', code: 'JOW' },
          { name: 'Kabul', code: 'KAB' },
          { name: 'Kandahar', code: 'KAN' },
          { name: 'Kapisa', code: 'KAP' },
          { name: 'Khost', code: 'KHO' },
          { name: 'Kunar', code: 'KNR' },
          { name: 'Kunduz Province', code: 'KDZ' },
          { name: 'Laghman', code: 'LAG' },
          { name: 'Logar', code: 'LOG' },
          { name: 'Nangarhar', code: 'NAN' },
          { name: 'Nimruz', code: 'NIM' },
          { name: 'Nuristan', code: 'NUR' },
          { name: 'Paktia', code: 'PIA' },
          { name: 'Paktika', code: 'PKA' },
          { name: 'Panjshir', code: 'PAN' },
          { name: 'Parwan', code: 'PAR' },
          { name: 'Samangan', code: 'SAM' },
          { name: 'Sar-e Pol', code: 'SAR' },
          { name: 'Takhar', code: 'TAK' },
          { name: 'Urozgan', code: 'URU' },
          { name: 'Zabul', code: 'ZAB' },
        ],
      },
    ],
    [
      'Aland Islands',
      {
        name: 'Aland Islands',
        code: 'AX',
        emoji: '🇦🇽',
        regions: [],
      },
    ],
    [
      'Albania',
      {
        name: 'Albania',
        code: 'AL',
        emoji: '🇦🇱',
        regions: [
          { name: 'Berat County', code: '01' },
          { name: 'Berat District', code: 'BR' },
          { name: 'Bulqizë District', code: 'BU' },
          { name: 'Delvinë District', code: 'DL' },
          { name: 'Devoll District', code: 'DV' },
          { name: 'Dibër County', code: '09' },
          { name: 'Dibër District', code: 'DI' },
          { name: 'Durrës County', code: '02' },
          { name: 'Durrës District', code: 'DR' },
          { name: 'Elbasan County', code: '03' },
          { name: 'Fier County', code: '04' },
          { name: 'Fier District', code: 'FR' },
          { name: 'Gjirokastër County', code: '05' },
          { name: 'Gjirokastër District', code: 'GJ' },
          { name: 'Gramsh District', code: 'GR' },
          { name: 'Has District', code: 'HA' },
          { name: 'Kavajë District', code: 'KA' },
          { name: 'Kolonjë District', code: 'ER' },
          { name: 'Korçë County', code: '06' },
          { name: 'Korçë District', code: 'KO' },
          { name: 'Krujë District', code: 'KR' },
          { name: 'Kuçovë District', code: 'KC' },
          { name: 'Kukës County', code: '07' },
          { name: 'Kukës District', code: 'KU' },
          { name: 'Kurbin District', code: 'KB' },
          { name: 'Lezhë County', code: '08' },
          { name: 'Lezhë District', code: 'LE' },
          { name: 'Librazhd District', code: 'LB' },
          { name: 'Lushnjë District', code: 'LU' },
          { name: 'Malësi e Madhe District', code: 'MM' },
          { name: 'Mallakastër District', code: 'MK' },
          { name: 'Mat District', code: 'MT' },
          { name: 'Mirditë District', code: 'MR' },
          { name: 'Peqin District', code: 'PQ' },
          { name: 'Përmet District', code: 'PR' },
          { name: 'Pogradec District', code: 'PG' },
          { name: 'Pukë District', code: 'PU' },
          { name: 'Sarandë District', code: 'SR' },
          { name: 'Shkodër County', code: '10' },
          { name: 'Shkodër District', code: 'SH' },
          { name: 'Skrapar District', code: 'SK' },
          { name: 'Tepelenë District', code: 'TE' },
          { name: 'Tirana County', code: '11' },
          { name: 'Tirana District', code: 'TR' },
          { name: 'Tropojë District', code: 'TP' },
          { name: 'Vlorë County', code: '12' },
          { name: 'Vlorë District', code: 'VL' },
        ],
      },
    ],
    [
      'Algeria',
      {
        name: 'Algeria',
        code: 'DZ',
        emoji: '🇩🇿',
        regions: [
          { name: 'Adrar', code: '01' },
          { name: 'Aïn Defla', code: '44' },
          { name: 'Aïn Témouchent', code: '46' },
          { name: 'Algiers', code: '16' },
          { name: 'Annaba', code: '23' },
          { name: 'Batna', code: '05' },
          { name: 'Béchar', code: '08' },
          { name: 'Béjaïa', code: '06' },
          { name: 'Béni Abbès', code: '53' },
          { name: 'Biskra', code: '07' },
          { name: 'Blida', code: '09' },
          { name: 'Bordj Baji Mokhtar', code: '52' },
          { name: 'Bordj Bou Arréridj', code: '34' },
          { name: 'Bouïra', code: '10' },
          { name: 'Boumerdès', code: '35' },
          { name: 'Chlef', code: '02' },
          { name: 'Constantine', code: '25' },
          { name: 'Djanet', code: '56' },
          { name: 'Djelfa', code: '17' },
          { name: 'El Bayadh', code: '32' },
          { name: `El M'ghair`, code: '49' },
          { name: 'El Menia', code: '50' },
          { name: 'El Oued', code: '39' },
          { name: 'El Tarf', code: '36' },
          { name: 'Ghardaïa', code: '47' },
          { name: 'Guelma', code: '24' },
          { name: 'Illizi', code: '33' },
          { name: 'In Guezzam', code: '58' },
          { name: 'In Salah', code: '57' },
          { name: 'Jijel', code: '18' },
          { name: 'Khenchela', code: '40' },
          { name: 'Laghouat', code: '03' },
          { name: `M'Sila`, code: '28' },
          { name: 'Mascara', code: '29' },
          { name: 'Médéa', code: '26' },
          { name: 'Mila', code: '43' },
          { name: 'Mostaganem', code: '27' },
          { name: 'Naama', code: '45' },
          { name: 'Oran', code: '31' },
          { name: 'Ouargla', code: '30' },
          { name: 'Ouled Djellal', code: '51' },
          { name: 'Oum El Bouaghi', code: '04' },
          { name: 'Relizane', code: '48' },
          { name: 'Saïda', code: '20' },
          { name: 'Sétif', code: '19' },
          { name: 'Sidi Bel Abbès', code: '22' },
          { name: 'Skikda', code: '21' },
          { name: 'Souk Ahras', code: '41' },
          { name: 'Tamanghasset', code: '11' },
          { name: 'Tébessa', code: '12' },
          { name: 'Tiaret', code: '14' },
          { name: 'Timimoun', code: '54' },
          { name: 'Tindouf', code: '37' },
          { name: 'Tipasa', code: '42' },
          { name: 'Tissemsilt', code: '38' },
          { name: 'Tizi Ouzou', code: '15' },
          { name: 'Tlemcen', code: '13' },
          { name: 'Touggourt', code: '55' },
        ],
      },
    ],
    [
      'American Samoa',
      {
        name: 'American Samoa',
        code: 'AS',
        emoji: '🇦🇸',
        regions: [],
      },
    ],
    [
      'Andorra',
      {
        name: 'Andorra',
        code: 'AD',
        emoji: '🇦🇩',
        regions: [
          { name: 'Andorra la Vella', code: '07' },
          { name: 'Canillo', code: '02' },
          { name: 'Encamp', code: '03' },
          { name: 'Escaldes-Engordany', code: '08' },
          { name: 'La Massana', code: '04' },
          { name: 'Ordino', code: '05' },
          { name: 'Sant Julià de Lòria', code: '06' },
        ],
      },
    ],
    [
      'Angola',
      {
        name: 'Angola',
        code: 'AO',
        emoji: '🇦🇴',
        regions: [
          { name: 'Bengo Province', code: 'BGO' },
          { name: 'Benguela Province', code: 'BGU' },
          { name: 'Bié Province', code: 'BIE' },
          { name: 'Cabinda Province', code: 'CAB' },
          { name: 'Cuando Cubango Province', code: 'CCU' },
          { name: 'Cuanza Norte Province', code: 'CNO' },
          { name: 'Cuanza Sul', code: 'CUS' },
          { name: 'Cunene Province', code: 'CNN' },
          { name: 'Huambo Province', code: 'HUA' },
          { name: 'Huíla Province', code: 'HUI' },
          { name: 'Luanda Province', code: 'LUA' },
          { name: 'Lunda Norte Province', code: 'LNO' },
          { name: 'Lunda Sul Province', code: 'LSU' },
          { name: 'Malanje Province', code: 'MAL' },
          { name: 'Moxico Province', code: 'MOX' },
          { name: 'Uíge Province', code: 'UIG' },
          { name: 'Zaire Province', code: 'ZAI' },
        ],
      },
    ],
    [
      'Anguilla',
      {
        name: 'Anguilla',
        code: 'AI',
        emoji: '🇦🇮',
        regions: [],
      },
    ],
    [
      'Antarctica',
      {
        name: 'Antarctica',
        code: 'AQ',
        emoji: '🇦🇶',
        regions: [],
      },
    ],
    [
      'Antigua and Barbuda',
      {
        name: 'Antigua and Barbuda',
        code: 'AG',
        emoji: '🇦🇬',
        regions: [
          { name: 'Barbuda', code: '10' },
          { name: 'Redonda', code: '11' },
          { name: 'Saint George Parish', code: '03' },
          { name: 'Saint John Parish', code: '04' },
          { name: 'Saint Mary Parish', code: '05' },
          { name: 'Saint Paul Parish', code: '06' },
          { name: 'Saint Peter Parish', code: '07' },
          { name: 'Saint Philip Parish', code: '08' },
        ],
      },
    ],
    [
      'Argentina',
      {
        name: 'Argentina',
        code: 'AR',
        emoji: '🇦🇷',
        regions: [
          { name: 'Buenos Aires', code: 'B' },
          { name: 'Catamarca', code: 'K' },
          { name: 'Chaco', code: 'H' },
          { name: 'Chubut', code: 'U' },
          { name: 'Ciudad Autónoma de Buenos Aires', code: 'C' },
          { name: 'Córdoba', code: 'X' },
          { name: 'Corrientes', code: 'W' },
          { name: 'Entre Ríos', code: 'E' },
          { name: 'Formosa', code: 'P' },
          { name: 'Jujuy', code: 'Y' },
          { name: 'La Pampa', code: 'L' },
          { name: 'La Rioja', code: 'F' },
          { name: 'Mendoza', code: 'M' },
          { name: 'Misiones', code: 'N' },
          { name: 'Neuquén', code: 'Q' },
          { name: 'Río Negro', code: 'R' },
          { name: 'Salta', code: 'A' },
          { name: 'San Juan', code: 'J' },
          { name: 'Santa Cruz', code: 'Z' },
          { name: 'Santa Fe', code: 'S' },
          { name: 'Santiago del Estero', code: 'G' },
          { name: 'Tierra del Fuego', code: 'V' },
          { name: 'Tucumán', code: 'T' },
        ],
      },
    ],
    [
      'Armenia',
      {
        name: 'Armenia',
        code: 'AM',
        emoji: '🇦🇲',
        regions: [
          { name: 'Aragatsotn Region', code: 'AG' },
          { name: 'Ararat Province', code: 'AR' },
          { name: 'Armavir Region', code: 'AV' },
          { name: 'Gegharkunik Province', code: 'GR' },
          { name: 'Kotayk Region', code: 'KT' },
          { name: 'Lori Region', code: 'LO' },
          { name: 'Shirak Region', code: 'SH' },
          { name: 'Syunik Province', code: 'SU' },
          { name: 'Tavush Region', code: 'TV' },
          { name: 'Vayots Dzor Region', code: 'VD' },
          { name: 'Yerevan', code: 'ER' },
        ],
      },
    ],
    [
      'Aruba',
      {
        name: 'Aruba',
        code: 'AW',
        emoji: '🇦🇼',
        regions: [],
      },
    ],
    [
      'Australia',
      {
        name: 'Australia',
        code: 'AU',
        emoji: '🇦🇺',
        regions: [
          { name: 'Australian Capital Territory', code: 'ACT' },
          { name: 'New South Wales', code: 'NSW' },
          { name: 'Northern Territory', code: 'NT' },
          { name: 'Queensland', code: 'QLD' },
          { name: 'South Australia', code: 'SA' },
          { name: 'Tasmania', code: 'TAS' },
          { name: 'Victoria', code: 'VIC' },
          { name: 'Western Australia', code: 'WA' },
        ],
      },
    ],
    [
      'Austria',
      {
        name: 'Austria',
        code: 'AT',
        emoji: '🇦🇹',
        regions: [
          { name: 'Burgenland', code: '1' },
          { name: 'Carinthia', code: '2' },
          { name: 'Lower Austria', code: '3' },
          { name: 'Salzburg', code: '5' },
          { name: 'Styria', code: '6' },
          { name: 'Tyrol', code: '7' },
          { name: 'Upper Austria', code: '4' },
          { name: 'Vienna', code: '9' },
          { name: 'Vorarlberg', code: '8' },
        ],
      },
    ],
    [
      'Azerbaijan',
      {
        name: 'Azerbaijan',
        code: 'AZ',
        emoji: '🇦🇿',
        regions: [
          { name: 'Absheron District', code: 'ABS' },
          { name: 'Agdam District', code: 'AGM' },
          { name: 'Agdash District', code: 'AGS' },
          { name: 'Aghjabadi District', code: 'AGC' },
          { name: 'Agstafa District', code: 'AGA' },
          { name: 'Agsu District', code: 'AGU' },
          { name: 'Astara District', code: 'AST' },
          { name: 'Babek District', code: 'BAB' },
          { name: 'Baku', code: 'BA' },
          { name: 'Balakan District', code: 'BAL' },
          { name: 'Barda District', code: 'BAR' },
          { name: 'Beylagan District', code: 'BEY' },
          { name: 'Bilasuvar District', code: 'BIL' },
          { name: 'Dashkasan District', code: 'DAS' },
          { name: 'Fizuli District', code: 'FUZ' },
          { name: 'Ganja', code: 'GA' },
          { name: 'Gədəbəy', code: 'GAD' },
          { name: 'Gobustan District', code: 'QOB' },
          { name: 'Goranboy District', code: 'GOR' },
          { name: 'Goychay', code: 'GOY' },
          { name: 'Goygol District', code: 'GYG' },
          { name: 'Hajigabul District', code: 'HAC' },
          { name: 'Imishli District', code: 'IMI' },
          { name: 'Ismailli District', code: 'ISM' },
          { name: 'Jabrayil District', code: 'CAB' },
          { name: 'Jalilabad District', code: 'CAL' },
          { name: 'Julfa District', code: 'CUL' },
          { name: 'Kalbajar District', code: 'KAL' },
          { name: 'Kangarli District', code: 'KAN' },
          { name: 'Khachmaz District', code: 'XAC' },
          { name: 'Khizi District', code: 'XIZ' },
          { name: 'Khojali District', code: 'XCI' },
          { name: 'Kurdamir District', code: 'KUR' },
          { name: 'Lachin District', code: 'LAC' },
          { name: 'Lankaran', code: 'LAN' },
          { name: 'Lankaran District', code: 'LA' },
          { name: 'Lerik District', code: 'LER' },
          { name: 'Martuni', code: 'XVD' },
          { name: 'Masally District', code: 'MAS' },
          { name: 'Mingachevir', code: 'MI' },
          { name: 'Nakhchivan Autonomous Republic', code: 'NX' },
          { name: 'Neftchala District', code: 'NEF' },
          { name: 'Oghuz District', code: 'OGU' },
          { name: 'Ordubad District', code: 'ORD' },
          { name: 'Qabala District', code: 'QAB' },
          { name: 'Qakh District', code: 'QAX' },
          { name: 'Qazakh District', code: 'QAZ' },
          { name: 'Quba District', code: 'QBA' },
          { name: 'Qubadli District', code: 'QBI' },
          { name: 'Qusar District', code: 'QUS' },
          { name: 'Saatly District', code: 'SAT' },
          { name: 'Sabirabad District', code: 'SAB' },
          { name: 'Sadarak District', code: 'SAD' },
          { name: 'Salyan District', code: 'SAL' },
          { name: 'Samukh District', code: 'SMX' },
          { name: 'Shabran District', code: 'SBN' },
          { name: 'Shahbuz District', code: 'SAH' },
          { name: 'Shaki', code: 'SA' },
          { name: 'Shaki District', code: 'SAK' },
          { name: 'Shamakhi District', code: 'SMI' },
          { name: 'Shamkir District', code: 'SKR' },
          { name: 'Sharur District', code: 'SAR' },
          { name: 'Shirvan', code: 'SR' },
          { name: 'Shusha District', code: 'SUS' },
          { name: 'Siazan District', code: 'SIY' },
          { name: 'Sumqayit', code: 'SM' },
          { name: 'Tartar District', code: 'TAR' },
          { name: 'Tovuz District', code: 'TOV' },
          { name: 'Ujar District', code: 'UCA' },
          { name: 'Yardymli District', code: 'YAR' },
          { name: 'Yevlakh', code: 'YE' },
          { name: 'Yevlakh District', code: 'YEV' },
          { name: 'Zangilan District', code: 'ZAN' },
          { name: 'Zaqatala District', code: 'ZAQ' },
          { name: 'Zardab District', code: 'ZAR' },
        ],
      },
    ],
    [
      'Bahrain',
      {
        name: 'Bahrain',
        code: 'BH',
        emoji: '🇧🇭',
        regions: [
          { name: 'Capital', code: '13' },
          { name: 'Central', code: '16' },
          { name: 'Muharraq', code: '15' },
          { name: 'Northern', code: '17' },
          { name: 'Southern', code: '14' },
        ],
      },
    ],
    [
      'Bangladesh',
      {
        name: 'Bangladesh',
        code: 'BD',
        emoji: '🇧🇩',
        regions: [
          { name: 'Bagerhat District', code: '05' },
          { name: 'Bahadia', code: '33' },
          { name: 'Bandarban District', code: '01' },
          { name: 'Barguna District', code: '02' },
          { name: 'Barisal District', code: '06' },
          { name: 'Barisal Division', code: 'A' },
          { name: 'Bhola District', code: '07' },
          { name: 'Bogra District', code: '03' },
          { name: 'Brahmanbaria District', code: '04' },
          { name: 'Chandpur District', code: '09' },
          { name: 'Chapai Nawabganj District', code: '45' },
          { name: 'Chittagong District', code: '10' },
          { name: 'Chittagong Division', code: 'B' },
          { name: 'Chuadanga District', code: '12' },
          { name: 'Comilla District', code: '08' },
          { name: `Cox's Bazar District`, code: '11' },
          { name: 'Dhaka District', code: '13' },
          { name: 'Dhaka Division', code: 'C' },
          { name: 'Dinajpur District', code: '14' },
          { name: 'Faridpur District', code: '15' },
          { name: 'Feni District', code: '16' },
          { name: 'Gaibandha District', code: '19' },
          { name: 'Gazipur District', code: '18' },
          { name: 'Gopalganj District', code: '17' },
          { name: 'Habiganj District', code: '20' },
          { name: 'Jamalpur District', code: '21' },
          { name: 'Jessore District', code: '22' },
          { name: 'Jhalokati District', code: '25' },
          { name: 'Jhenaidah District', code: '23' },
          { name: 'Joypurhat District', code: '24' },
          { name: 'Khagrachari District', code: '29' },
          { name: 'Khulna District', code: '27' },
          { name: 'Khulna Division', code: 'D' },
          { name: 'Kishoreganj District', code: '26' },
          { name: 'Kurigram District', code: '28' },
          { name: 'Kushtia District', code: '30' },
          { name: 'Lakshmipur District', code: '31' },
          { name: 'Lalmonirhat District', code: '32' },
          { name: 'Madaripur District', code: '36' },
          { name: 'Meherpur District', code: '39' },
          { name: 'Moulvibazar District', code: '38' },
          { name: 'Munshiganj District', code: '35' },
          { name: 'Mymensingh District', code: '34' },
          { name: 'Mymensingh Division', code: 'H' },
          { name: 'Naogaon District', code: '48' },
          { name: 'Narail District', code: '43' },
          { name: 'Narayanganj District', code: '40' },
          { name: 'Natore District', code: '44' },
          { name: 'Netrokona District', code: '41' },
          { name: 'Nilphamari District', code: '46' },
          { name: 'Noakhali District', code: '47' },
          { name: 'Pabna District', code: '49' },
          { name: 'Panchagarh District', code: '52' },
          { name: 'Patuakhali District', code: '51' },
          { name: 'Pirojpur District', code: '50' },
          { name: 'Rajbari District', code: '53' },
          { name: 'Rajshahi District', code: '54' },
          { name: 'Rajshahi Division', code: 'E' },
          { name: 'Rangamati Hill District', code: '56' },
          { name: 'Rangpur District', code: '55' },
          { name: 'Rangpur Division', code: 'F' },
          { name: 'Satkhira District', code: '58' },
          { name: 'Shariatpur District', code: '62' },
          { name: 'Sherpur District', code: '57' },
          { name: 'Sirajganj District', code: '59' },
          { name: 'Sunamganj District', code: '61' },
          { name: 'Sylhet District', code: '60' },
          { name: 'Sylhet Division', code: 'G' },
          { name: 'Tangail District', code: '63' },
          { name: 'Thakurgaon District', code: '64' },
        ],
      },
    ],
    [
      'Barbados',
      {
        name: 'Barbados',
        code: 'BB',
        emoji: '🇧🇧',
        regions: [
          { name: 'Christ Church', code: '01' },
          { name: 'Saint Andrew', code: '02' },
          { name: 'Saint George', code: '03' },
          { name: 'Saint James', code: '04' },
          { name: 'Saint John', code: '05' },
          { name: 'Saint Joseph', code: '06' },
          { name: 'Saint Lucy', code: '07' },
          { name: 'Saint Michael', code: '08' },
          { name: 'Saint Peter', code: '09' },
          { name: 'Saint Philip', code: '10' },
          { name: 'Saint Thomas', code: '11' },
        ],
      },
    ],
    [
      'Belarus',
      {
        name: 'Belarus',
        code: 'BY',
        emoji: '🇧🇾',
        regions: [
          { name: 'Brest Region', code: 'BR' },
          { name: 'Gomel Region', code: 'HO' },
          { name: 'Grodno Region', code: 'HR' },
          { name: 'Minsk', code: 'HM' },
          { name: 'Minsk Region', code: 'MI' },
          { name: 'Mogilev Region', code: 'MA' },
          { name: 'Vitebsk Region', code: 'VI' },
        ],
      },
    ],
    [
      'Belgium',
      {
        name: 'Belgium',
        code: 'BE',
        emoji: '🇧🇪',
        regions: [
          { name: 'Antwerp', code: 'VAN' },
          { name: 'Brussels-Capital Region', code: 'BRU' },
          { name: 'East Flanders', code: 'VOV' },
          { name: 'Flanders', code: 'VLG' },
          { name: 'Flemish Brabant', code: 'VBR' },
          { name: 'Hainaut', code: 'WHT' },
          { name: 'Liège', code: 'WLG' },
          { name: 'Limburg', code: 'VLI' },
          { name: 'Luxembourg', code: 'WLX' },
          { name: 'Namur', code: 'WNA' },
          { name: 'Wallonia', code: 'WAL' },
          { name: 'Walloon Brabant', code: 'WBR' },
          { name: 'West Flanders', code: 'VWV' },
        ],
      },
    ],
    [
      'Belize',
      {
        name: 'Belize',
        code: 'BZ',
        emoji: '🇧🇿',
        regions: [
          { name: 'Belize District', code: 'BZ' },
          { name: 'Cayo District', code: 'CY' },
          { name: 'Corozal District', code: 'CZL' },
          { name: 'Orange Walk District', code: 'OW' },
          { name: 'Stann Creek District', code: 'SC' },
          { name: 'Toledo District', code: 'TOL' },
        ],
      },
    ],
    [
      'Benin',
      {
        name: 'Benin',
        code: 'BJ',
        emoji: '🇧🇯',
        regions: [
          { name: 'Alibori Department', code: 'AL' },
          { name: 'Atakora Department', code: 'AK' },
          { name: 'Atlantique Department', code: 'AQ' },
          { name: 'Borgou Department', code: 'BO' },
          { name: 'Collines Department', code: 'CO' },
          { name: 'Donga Department', code: 'DO' },
          { name: 'Kouffo Department', code: 'KO' },
          { name: 'Littoral Department', code: 'LI' },
          { name: 'Mono Department', code: 'MO' },
          { name: 'Ouémé Department', code: 'OU' },
          { name: 'Plateau Department', code: 'PL' },
          { name: 'Zou Department', code: 'ZO' },
        ],
      },
    ],
    [
      'Bermuda',
      {
        name: 'Bermuda',
        code: 'BM',
        emoji: '🇧🇲',
        regions: [
          { name: 'Devonshire', code: 'DEV' },
          { name: 'Hamilton', code: 'HA' },
          { name: 'Paget', code: 'PAG' },
          { name: 'Pembroke', code: 'PEM' },
          { name: `Saint George's`, code: 'SGE' },
          { name: 'Sandys', code: 'SAN' },
          { name: `Smith's`, code: 'SMI' },
          { name: 'Southampton', code: 'SOU' },
          { name: 'Warwick', code: 'WAR' },
        ],
      },
    ],
    [
      'Bhutan',
      {
        name: 'Bhutan',
        code: 'BT',
        emoji: '🇧🇹',
        regions: [
          { name: 'Bumthang District', code: '33' },
          { name: 'Chukha District', code: '12' },
          { name: 'Dagana District', code: '22' },
          { name: 'Gasa District', code: 'GA' },
          { name: 'Haa District', code: '13' },
          { name: 'Lhuntse District', code: '44' },
          { name: 'Mongar District', code: '42' },
          { name: 'Paro District', code: '11' },
          { name: 'Pemagatshel District', code: '43' },
          { name: 'Punakha District', code: '23' },
          { name: 'Samdrup Jongkhar District', code: '45' },
          { name: 'Samtse District', code: '14' },
          { name: 'Sarpang District', code: '31' },
          { name: 'Thimphu District', code: '15' },
          { name: 'Trashigang District', code: '41' },
          { name: 'Trongsa District', code: '32' },
          { name: 'Tsirang District', code: '21' },
          { name: 'Wangdue Phodrang District', code: '24' },
          { name: 'Zhemgang District', code: '34' },
        ],
      },
    ],
    [
      'Bolivia',
      {
        name: 'Bolivia',
        code: 'BO',
        emoji: '🇧🇴',
        regions: [
          { name: 'Beni Department', code: 'B' },
          { name: 'Chuquisaca Department', code: 'H' },
          { name: 'Cochabamba Department', code: 'C' },
          { name: 'La Paz Department', code: 'L' },
          { name: 'Oruro Department', code: 'O' },
          { name: 'Pando Department', code: 'N' },
          { name: 'Potosí Department', code: 'P' },
          { name: 'Santa Cruz Department', code: 'S' },
          { name: 'Tarija Department', code: 'T' },
        ],
      },
    ],
    [
      'Bonaire, Sint Eustatius and Saba',
      {
        name: 'Bonaire, Sint Eustatius and Saba',
        code: 'BQ',
        emoji: '🇧🇶',
        regions: [
          { name: 'Bonaire', code: 'BQ1' },
          { name: 'Saba', code: 'BQ2' },
          { name: 'Sint Eustatius', code: 'BQ3' },
        ],
      },
    ],
    [
      'Bosnia and Herzegovina',
      {
        name: 'Bosnia and Herzegovina',
        code: 'BA',
        emoji: '🇧🇦',
        regions: [
          { name: 'Bosnian Podrinje Canton', code: '05' },
          { name: 'Brčko District', code: 'BRC' },
          { name: 'Canton 10', code: '10' },
          { name: 'Central Bosnia Canton', code: '06' },
          { name: 'Federation of Bosnia and Herzegovina', code: 'BIH' },
          { name: 'Herzegovina-Neretva Canton', code: '07' },
          { name: 'Posavina Canton', code: '02' },
          { name: 'Republika Srpska', code: 'SRP' },
          { name: 'Sarajevo Canton', code: '09' },
          { name: 'Tuzla Canton', code: '03' },
          { name: 'Una-Sana Canton', code: '01' },
          { name: 'West Herzegovina Canton', code: '08' },
          { name: 'Zenica-Doboj Canton', code: '04' },
        ],
      },
    ],
    [
      'Botswana',
      {
        name: 'Botswana',
        code: 'BW',
        emoji: '🇧🇼',
        regions: [
          { name: 'Central District', code: 'CE' },
          { name: 'Ghanzi District', code: 'GH' },
          { name: 'Kgalagadi District', code: 'KG' },
          { name: 'Kgatleng District', code: 'KL' },
          { name: 'Kweneng District', code: 'KW' },
          { name: 'Ngamiland', code: 'NG' },
          { name: 'North-East District', code: 'NE' },
          { name: 'North-West District', code: 'NW' },
          { name: 'South-East District', code: 'SE' },
          { name: 'Southern District', code: 'SO' },
        ],
      },
    ],
    [
      'Bouvet Island',
      {
        name: 'Bouvet Island',
        code: 'BV',
        emoji: '🇧🇻',
        regions: [],
      },
    ],
    [
      'Brazil',
      {
        name: 'Brazil',
        code: 'BR',
        emoji: '🇧🇷',
        regions: [
          { name: 'Acre', code: 'AC' },
          { name: 'Alagoas', code: 'AL' },
          { name: 'Amapá', code: 'AP' },
          { name: 'Amazonas', code: 'AM' },
          { name: 'Bahia', code: 'BA' },
          { name: 'Ceará', code: 'CE' },
          { name: 'Distrito Federal', code: 'DF' },
          { name: 'Espírito Santo', code: 'ES' },
          { name: 'Goiás', code: 'GO' },
          { name: 'Maranhão', code: 'MA' },
          { name: 'Mato Grosso', code: 'MT' },
          { name: 'Mato Grosso do Sul', code: 'MS' },
          { name: 'Minas Gerais', code: 'MG' },
          { name: 'Pará', code: 'PA' },
          { name: 'Paraíba', code: 'PB' },
          { name: 'Paraná', code: 'PR' },
          { name: 'Pernambuco', code: 'PE' },
          { name: 'Piauí', code: 'PI' },
          { name: 'Rio de Janeiro', code: 'RJ' },
          { name: 'Rio Grande do Norte', code: 'RN' },
          { name: 'Rio Grande do Sul', code: 'RS' },
          { name: 'Rondônia', code: 'RO' },
          { name: 'Roraima', code: 'RR' },
          { name: 'Santa Catarina', code: 'SC' },
          { name: 'São Paulo', code: 'SP' },
          { name: 'Sergipe', code: 'SE' },
          { name: 'Tocantins', code: 'TO' },
        ],
      },
    ],
    [
      'British Indian Ocean Territory',
      {
        name: 'British Indian Ocean Territory',
        code: 'IO',
        emoji: '🇮🇴',
        regions: [],
      },
    ],
    [
      'Brunei',
      {
        name: 'Brunei',
        code: 'BN',
        emoji: '🇧🇳',
        regions: [
          { name: 'Belait District', code: 'BE' },
          { name: 'Brunei-Muara District', code: 'BM' },
          { name: 'Temburong District', code: 'TE' },
          { name: 'Tutong District', code: 'TU' },
        ],
      },
    ],
    [
      'Bulgaria',
      {
        name: 'Bulgaria',
        code: 'BG',
        emoji: '🇧🇬',
        regions: [
          { name: 'Blagoevgrad Province', code: '01' },
          { name: 'Burgas Province', code: '02' },
          { name: 'Dobrich Province', code: '08' },
          { name: 'Gabrovo Province', code: '07' },
          { name: 'Haskovo Province', code: '26' },
          { name: 'Kardzhali Province', code: '09' },
          { name: 'Kyustendil Province', code: '10' },
          { name: 'Lovech Province', code: '11' },
          { name: 'Montana Province', code: '12' },
          { name: 'Pazardzhik Province', code: '13' },
          { name: 'Pernik Province', code: '14' },
          { name: 'Pleven Province', code: '15' },
          { name: 'Plovdiv Province', code: '16' },
          { name: 'Razgrad Province', code: '17' },
          { name: 'Ruse Province', code: '18' },
          { name: 'Shumen', code: '27' },
          { name: 'Silistra Province', code: '19' },
          { name: 'Sliven Province', code: '20' },
          { name: 'Smolyan Province', code: '21' },
          { name: 'Sofia City Province', code: '22' },
          { name: 'Sofia Province', code: '23' },
          { name: 'Stara Zagora Province', code: '24' },
          { name: 'Targovishte Province', code: '25' },
          { name: 'Varna Province', code: '03' },
          { name: 'Veliko Tarnovo Province', code: '04' },
          { name: 'Vidin Province', code: '05' },
          { name: 'Vratsa Province', code: '06' },
          { name: 'Yambol Province', code: '28' },
        ],
      },
    ],
    [
      'Burkina Faso',
      {
        name: 'Burkina Faso',
        code: 'BF',
        emoji: '🇧🇫',
        regions: [
          { name: 'Balé Province', code: 'BAL' },
          { name: 'Bam Province', code: 'BAM' },
          { name: 'Banwa Province', code: 'BAN' },
          { name: 'Bazèga Province', code: 'BAZ' },
          { name: 'Boucle du Mouhoun Region', code: '01' },
          { name: 'Bougouriba Province', code: 'BGR' },
          { name: 'Boulgou', code: 'BLG' },
          { name: 'Cascades Region', code: '02' },
          { name: 'Centre', code: '03' },
          { name: 'Centre-Est Region', code: '04' },
          { name: 'Centre-Nord Region', code: '05' },
          { name: 'Centre-Ouest Region', code: '06' },
          { name: 'Centre-Sud Region', code: '07' },
          { name: 'Comoé Province', code: 'COM' },
          { name: 'Est Region', code: '08' },
          { name: 'Ganzourgou Province', code: 'GAN' },
          { name: 'Gnagna Province', code: 'GNA' },
          { name: 'Gourma Province', code: 'GOU' },
          { name: 'Hauts-Bassins Region', code: '09' },
          { name: 'Houet Province', code: 'HOU' },
          { name: 'Ioba Province', code: 'IOB' },
          { name: 'Kadiogo Province', code: 'KAD' },
          { name: 'Kénédougou Province', code: 'KEN' },
          { name: 'Komondjari Province', code: 'KMD' },
          { name: 'Kompienga Province', code: 'KMP' },
          { name: 'Kossi Province', code: 'KOS' },
          { name: 'Koulpélogo Province', code: 'KOP' },
          { name: 'Kouritenga Province', code: 'KOT' },
          { name: 'Kourwéogo Province', code: 'KOW' },
          { name: 'Léraba Province', code: 'LER' },
          { name: 'Loroum Province', code: 'LOR' },
          { name: 'Mouhoun', code: 'MOU' },
          { name: 'Nahouri Province', code: 'NAO' },
          { name: 'Namentenga Province', code: 'NAM' },
          { name: 'Nayala Province', code: 'NAY' },
          { name: 'Nord Region, Burkina Faso', code: '10' },
          { name: 'Noumbiel Province', code: 'NOU' },
          { name: 'Oubritenga Province', code: 'OUB' },
          { name: 'Oudalan Province', code: 'OUD' },
          { name: 'Passoré Province', code: 'PAS' },
          { name: 'Plateau-Central Region', code: '11' },
          { name: 'Poni Province', code: 'PON' },
          { name: 'Sahel Region', code: '12' },
          { name: 'Sanguié Province', code: 'SNG' },
          { name: 'Sanmatenga Province', code: 'SMT' },
          { name: 'Séno Province', code: 'SEN' },
          { name: 'Sissili Province', code: 'SIS' },
          { name: 'Soum Province', code: 'SOM' },
          { name: 'Sourou Province', code: 'SOR' },
          { name: 'Sud-Ouest Region', code: '13' },
          { name: 'Tapoa Province', code: 'TAP' },
          { name: 'Tuy Province', code: 'TUI' },
          { name: 'Yagha Province', code: 'YAG' },
          { name: 'Yatenga Province', code: 'YAT' },
          { name: 'Ziro Province', code: 'ZIR' },
          { name: 'Zondoma Province', code: 'ZON' },
          { name: 'Zoundwéogo Province', code: 'ZOU' },
        ],
      },
    ],
    [
      'Burundi',
      {
        name: 'Burundi',
        code: 'BI',
        emoji: '🇧🇮',
        regions: [
          { name: 'Bubanza Province', code: 'BB' },
          { name: 'Bujumbura Mairie Province', code: 'BM' },
          { name: 'Bujumbura Rural Province', code: 'BL' },
          { name: 'Bururi Province', code: 'BR' },
          { name: 'Cankuzo Province', code: 'CA' },
          { name: 'Cibitoke Province', code: 'CI' },
          { name: 'Gitega Province', code: 'GI' },
          { name: 'Karuzi Province', code: 'KR' },
          { name: 'Kayanza Province', code: 'KY' },
          { name: 'Kirundo Province', code: 'KI' },
          { name: 'Makamba Province', code: 'MA' },
          { name: 'Muramvya Province', code: 'MU' },
          { name: 'Muyinga Province', code: 'MY' },
          { name: 'Mwaro Province', code: 'MW' },
          { name: 'Ngozi Province', code: 'NG' },
          { name: 'Rumonge Province', code: 'RM' },
          { name: 'Rutana Province', code: 'RT' },
          { name: 'Ruyigi Province', code: 'RY' },
        ],
      },
    ],
    [
      'Cambodia',
      {
        name: 'Cambodia',
        code: 'KH',
        emoji: '🇰🇭',
        regions: [
          { name: 'Banteay Meanchey', code: '1' },
          { name: 'Battambang', code: '2' },
          { name: 'Kampong Cham', code: '3' },
          { name: 'Kampong Chhnang', code: '4' },
          { name: 'Kampong Speu', code: '5' },
          { name: 'Kampong Thom', code: '6' },
          { name: 'Kampot', code: '7' },
          { name: 'Kandal', code: '8' },
          { name: 'Kep', code: '23' },
          { name: 'Koh Kong', code: '9' },
          { name: 'Kratie', code: '10' },
          { name: 'Mondulkiri', code: '11' },
          { name: 'Oddar Meanchey', code: '22' },
          { name: 'Pailin', code: '24' },
          { name: 'Phnom Penh', code: '12' },
          { name: 'Preah Vihear', code: '13' },
          { name: 'Prey Veng', code: '14' },
          { name: 'Pursat', code: '15' },
          { name: 'Ratanakiri', code: '16' },
          { name: 'Siem Reap', code: '17' },
          { name: 'Sihanoukville', code: '18' },
          { name: 'Stung Treng', code: '19' },
          { name: 'Svay Rieng', code: '20' },
          { name: 'Takeo', code: '21' },
        ],
      },
    ],
    [
      'Cameroon',
      {
        name: 'Cameroon',
        code: 'CM',
        emoji: '🇨🇲',
        regions: [
          { name: 'Adamawa', code: 'AD' },
          { name: 'Centre', code: 'CE' },
          { name: 'East', code: 'ES' },
          { name: 'Far North', code: 'EN' },
          { name: 'Littoral', code: 'LT' },
          { name: 'North', code: 'NO' },
          { name: 'Northwest', code: 'NW' },
          { name: 'South', code: 'SU' },
          { name: 'Southwest', code: 'SW' },
          { name: 'West', code: 'OU' },
        ],
      },
    ],
    [
      'Canada',
      {
        name: 'Canada',
        code: 'CA',
        emoji: '🇨🇦',
        regions: [
          { name: 'Alberta', code: 'AB' },
          { name: 'British Columbia', code: 'BC' },
          { name: 'Manitoba', code: 'MB' },
          { name: 'New Brunswick', code: 'NB' },
          { name: 'Newfoundland and Labrador', code: 'NL' },
          { name: 'Northwest Territories', code: 'NT' },
          { name: 'Nova Scotia', code: 'NS' },
          { name: 'Nunavut', code: 'NU' },
          { name: 'Ontario', code: 'ON' },
          { name: 'Prince Edward Island', code: 'PE' },
          { name: 'Quebec', code: 'QC' },
          { name: 'Saskatchewan', code: 'SK' },
          { name: 'Yukon', code: 'YT' },
        ],
      },
    ],
    [
      'Cape Verde',
      {
        name: 'Cape Verde',
        code: 'CV',
        emoji: '🇨🇻',
        regions: [
          { name: 'Barlavento Islands', code: 'B' },
          { name: 'Boa Vista', code: 'BV' },
          { name: 'Brava', code: 'BR' },
          { name: 'Maio Municipality', code: 'MA' },
          { name: 'Mosteiros', code: 'MO' },
          { name: 'Paul', code: 'PA' },
          { name: 'Porto Novo', code: 'PN' },
          { name: 'Praia', code: 'PR' },
          { name: 'Ribeira Brava Municipality', code: 'RB' },
          { name: 'Ribeira Grande', code: 'RG' },
          { name: 'Ribeira Grande de Santiago', code: 'RS' },
          { name: 'Sal', code: 'SL' },
          { name: 'Santa Catarina', code: 'CA' },
          { name: 'Santa Catarina do Fogo', code: 'CF' },
          { name: 'Santa Cruz', code: 'CR' },
          { name: 'São Domingos', code: 'SD' },
          { name: 'São Filipe', code: 'SF' },
          { name: 'São Lourenço dos Órgãos', code: 'SO' },
          { name: 'São Miguel', code: 'SM' },
          { name: 'São Vicente', code: 'SV' },
          { name: 'Sotavento Islands', code: 'S' },
          { name: 'Tarrafal', code: 'TA' },
          { name: 'Tarrafal de São Nicolau', code: 'TS' },
        ],
      },
    ],
    [
      'Cayman Islands',
      {
        name: 'Cayman Islands',
        code: 'KY',
        emoji: '🇰🇾',
        regions: [],
      },
    ],
    [
      'Central African Republic',
      {
        name: 'Central African Republic',
        code: 'CF',
        emoji: '🇨🇫',
        regions: [
          { name: 'Bamingui-Bangoran Prefecture', code: 'BB' },
          { name: 'Bangui', code: 'BGF' },
          { name: 'Basse-Kotto Prefecture', code: 'BK' },
          { name: 'Haut-Mbomou Prefecture', code: 'HM' },
          { name: 'Haute-Kotto Prefecture', code: 'HK' },
          { name: 'Kémo Prefecture', code: 'KG' },
          { name: 'Lobaye Prefecture', code: 'LB' },
          { name: 'Mambéré-Kadéï', code: 'HS' },
          { name: 'Mbomou Prefecture', code: 'MB' },
          { name: 'Nana-Grébizi Economic Prefecture', code: 'KB' },
          { name: 'Nana-Mambéré Prefecture', code: 'NM' },
          { name: `Ombella-M'Poko Prefecture`, code: 'MP' },
          { name: 'Ouaka Prefecture', code: 'UK' },
          { name: 'Ouham Prefecture', code: 'AC' },
          { name: 'Ouham-Pendé Prefecture', code: 'OP' },
          { name: 'Sangha-Mbaéré', code: 'SE' },
          { name: 'Vakaga Prefecture', code: 'VK' },
        ],
      },
    ],
    [
      'Chad',
      {
        name: 'Chad',
        code: 'TD',
        emoji: '🇹🇩',
        regions: [
          { name: 'Bahr el Gazel', code: 'BG' },
          { name: 'Batha', code: 'BA' },
          { name: 'Borkou', code: 'BO' },
          { name: 'Chari-Baguirmi', code: 'CB' },
          { name: 'Ennedi-Est', code: 'EE' },
          { name: 'Ennedi-Ouest', code: 'EO' },
          { name: 'Guéra', code: 'GR' },
          { name: 'Hadjer-Lamis', code: 'HL' },
          { name: 'Kanem', code: 'KA' },
          { name: 'Lac', code: 'LC' },
          { name: 'Logone Occidental', code: 'LO' },
          { name: 'Logone Oriental', code: 'LR' },
          { name: 'Mandoul', code: 'MA' },
          { name: 'Mayo-Kebbi Est', code: 'ME' },
          { name: 'Mayo-Kebbi Ouest', code: 'MO' },
          { name: 'Moyen-Chari', code: 'MC' },
          { name: `N'Djamena`, code: 'ND' },
          { name: 'Ouaddaï', code: 'OD' },
          { name: 'Salamat', code: 'SA' },
          { name: 'Sila', code: 'SI' },
          { name: 'Tandjilé', code: 'TA' },
          { name: 'Tibesti', code: 'TI' },
          { name: 'Wadi Fira', code: 'WF' },
        ],
      },
    ],
    [
      'Chile',
      {
        name: 'Chile',
        code: 'CL',
        emoji: '🇨🇱',
        regions: [
          { name: 'Aisén del General Carlos Ibañez del Campo', code: 'AI' },
          { name: 'Antofagasta', code: 'AN' },
          { name: 'Arica y Parinacota', code: 'AP' },
          { name: 'Atacama', code: 'AT' },
          { name: 'Biobío', code: 'BI' },
          { name: 'Coquimbo', code: 'CO' },
          { name: 'La Araucanía', code: 'AR' },
          { name: `Libertador General Bernardo O'Higgins`, code: 'LI' },
          { name: 'Los Lagos', code: 'LL' },
          { name: 'Los Ríos', code: 'LR' },
          { name: 'Magallanes y de la Antártica Chilena', code: 'MA' },
          { name: 'Maule', code: 'ML' },
          { name: 'Ñuble', code: 'NB' },
          { name: 'Región Metropolitana de Santiago', code: 'RM' },
          { name: 'Tarapacá', code: 'TA' },
          { name: 'Valparaíso', code: 'VS' },
        ],
      },
    ],
    [
      'China',
      {
        name: 'China',
        code: 'CN',
        emoji: '🇨🇳',
        regions: [
          { name: 'Anhui', code: 'AH' },
          { name: 'Beijing', code: 'BJ' },
          { name: 'Chongqing', code: 'CQ' },
          { name: 'Fujian', code: 'FJ' },
          { name: 'Gansu', code: 'GS' },
          { name: 'Guangdong', code: 'GD' },
          { name: 'Guangxi Zhuang', code: 'GX' },
          { name: 'Guizhou', code: 'GZ' },
          { name: 'Hainan', code: 'HI' },
          { name: 'Hebei', code: 'HE' },
          { name: 'Heilongjiang', code: 'HL' },
          { name: 'Henan', code: 'HA' },
          { name: 'Hong Kong SAR', code: 'HK' },
          { name: 'Hubei', code: 'HB' },
          { name: 'Hunan', code: 'HN' },
          { name: 'Inner Mongolia', code: 'NM' },
          { name: 'Jiangsu', code: 'JS' },
          { name: 'Jiangxi', code: 'JX' },
          { name: 'Jilin', code: 'JL' },
          { name: 'Liaoning', code: 'LN' },
          { name: 'Macau SAR', code: 'MO' },
          { name: 'Ningxia Huizu', code: 'NX' },
          { name: 'Qinghai', code: 'QH' },
          { name: 'Shaanxi', code: 'SN' },
          { name: 'Shandong', code: 'SD' },
          { name: 'Shanghai', code: 'SH' },
          { name: 'Shanxi', code: 'SX' },
          { name: 'Sichuan', code: 'SC' },
          { name: 'Taiwan', code: 'TW' },
          { name: 'Tianjin', code: 'TJ' },
          { name: 'Xinjiang', code: 'XJ' },
          { name: 'Xizang', code: 'XZ' },
          { name: 'Yunnan', code: 'YN' },
          { name: 'Zhejiang', code: 'ZJ' },
        ],
      },
    ],
    [
      'Christmas Island',
      {
        name: 'Christmas Island',
        code: 'CX',
        emoji: '🇨🇽',
        regions: [],
      },
    ],
    [
      'Cocos (Keeling) Islands',
      {
        name: 'Cocos (Keeling) Islands',
        code: 'CC',
        emoji: '🇨🇨',
        regions: [],
      },
    ],
    [
      'Colombia',
      {
        name: 'Colombia',
        code: 'CO',
        emoji: '🇨🇴',
        regions: [
          { name: 'Amazonas', code: 'AMA' },
          { name: 'Antioquia', code: 'ANT' },
          { name: 'Arauca', code: 'ARA' },
          { name: 'Archipiélago de San Andrés, Providencia y Santa Catalina', code: 'SAP' },
          { name: 'Atlántico', code: 'ATL' },
          { name: 'Bogotá D.C.', code: 'DC' },
          { name: 'Bolívar', code: 'BOL' },
          { name: 'Boyacá', code: 'BOY' },
          { name: 'Caldas', code: 'CAL' },
          { name: 'Caquetá', code: 'CAQ' },
          { name: 'Casanare', code: 'CAS' },
          { name: 'Cauca', code: 'CAU' },
          { name: 'Cesar', code: 'CES' },
          { name: 'Chocó', code: 'CHO' },
          { name: 'Córdoba', code: 'COR' },
          { name: 'Cundinamarca', code: 'CUN' },
          { name: 'Guainía', code: 'GUA' },
          { name: 'Guaviare', code: 'GUV' },
          { name: 'Huila', code: 'HUI' },
          { name: 'La Guajira', code: 'LAG' },
          { name: 'Magdalena', code: 'MAG' },
          { name: 'Meta', code: 'MET' },
          { name: 'Nariño', code: 'NAR' },
          { name: 'Norte de Santander', code: 'NSA' },
          { name: 'Putumayo', code: 'PUT' },
          { name: 'Quindío', code: 'QUI' },
          { name: 'Risaralda', code: 'RIS' },
          { name: 'Santander', code: 'SAN' },
          { name: 'Sucre', code: 'SUC' },
          { name: 'Tolima', code: 'TOL' },
          { name: 'Valle del Cauca', code: 'VAC' },
          { name: 'Vaupés', code: 'VAU' },
          { name: 'Vichada', code: 'VID' },
        ],
      },
    ],
    [
      'Comoros',
      {
        name: 'Comoros',
        code: 'KM',
        emoji: '🇰🇲',
        regions: [
          { name: 'Anjouan', code: 'A' },
          { name: 'Grande Comore', code: 'G' },
          { name: 'Mohéli', code: 'M' },
        ],
      },
    ],
    [
      'Congo',
      {
        name: 'Congo',
        code: 'CG',
        emoji: '🇨🇬',
        regions: [
          { name: 'Bouenza Department', code: '11' },
          { name: 'Brazzaville', code: 'BZV' },
          { name: 'Cuvette Department', code: '8' },
          { name: 'Cuvette-Ouest Department', code: '15' },
          { name: 'Kouilou Department', code: '5' },
          { name: 'Lékoumou Department', code: '2' },
          { name: 'Likouala Department', code: '7' },
          { name: 'Niari Department', code: '9' },
          { name: 'Plateaux Department', code: '14' },
          { name: 'Pointe-Noire', code: '16' },
          { name: 'Pool Department', code: '12' },
          { name: 'Sangha Department', code: '13' },
        ],
      },
    ],
    [
      'Cook Islands',
      {
        name: 'Cook Islands',
        code: 'CK',
        emoji: '🇨🇰',
        regions: [],
      },
    ],
    [
      'Costa Rica',
      {
        name: 'Costa Rica',
        code: 'CR',
        emoji: '🇨🇷',
        regions: [
          { name: 'Alajuela Province', code: 'A' },
          { name: 'Guanacaste Province', code: 'G' },
          { name: 'Heredia Province', code: 'H' },
          { name: 'Limón Province', code: 'L' },
          { name: 'Provincia de Cartago', code: 'C' },
          { name: 'Puntarenas Province', code: 'P' },
          { name: 'San José Province', code: 'SJ' },
        ],
      },
    ],
    [
      `Cote D'Ivoire (Ivory Coast)`,
      {
        name: `Cote D'Ivoire (Ivory Coast)`,
        code: 'CI',
        emoji: '🇨🇮',
        regions: [
          { name: 'Abidjan', code: 'AB' },
          { name: 'Agnéby', code: '16' },
          { name: 'Bafing Region', code: '17' },
          { name: 'Bas-Sassandra District', code: 'BS' },
          { name: 'Bas-Sassandra Region', code: '09' },
          { name: 'Comoé District', code: 'CM' },
          { name: 'Denguélé District', code: 'DN' },
          { name: 'Denguélé Region', code: '10' },
          { name: 'Dix-Huit Montagnes', code: '06' },
          { name: 'Fromager', code: '18' },
          { name: 'Gôh-Djiboua District', code: 'GD' },
          { name: 'Haut-Sassandra', code: '02' },
          { name: 'Lacs District', code: 'LC' },
          { name: 'Lacs Region', code: '07' },
          { name: 'Lagunes District', code: 'LG' },
          { name: 'Lagunes region', code: '01' },
          { name: 'Marahoué Region', code: '12' },
          { name: 'Montagnes District', code: 'MG' },
          { name: 'Moyen-Cavally', code: '19' },
          { name: 'Moyen-Comoé', code: '05' },
          { name: `N'zi-Comoé`, code: '11' },
          { name: 'Sassandra-Marahoué District', code: 'SM' },
          { name: 'Savanes Region', code: '03' },
          { name: 'Sud-Bandama', code: '15' },
          { name: 'Sud-Comoé', code: '13' },
          { name: 'Vallée du Bandama District', code: 'VB' },
          { name: 'Vallée du Bandama Region', code: '04' },
          { name: 'Woroba District', code: 'WR' },
          { name: 'Worodougou', code: '14' },
          { name: 'Yamoussoukro', code: 'YM' },
          { name: 'Zanzan Region', code: 'ZZ' },
        ],
      },
    ],
    [
      'Croatia',
      {
        name: 'Croatia',
        code: 'HR',
        emoji: '🇭🇷',
        regions: [
          { name: 'Bjelovar-Bilogora', code: '07' },
          { name: 'Brod-Posavina', code: '12' },
          { name: 'Dubrovnik-Neretva', code: '19' },
          { name: 'Istria', code: '18' },
          { name: 'Karlovac', code: '04' },
          { name: 'Koprivnica-Križevci', code: '06' },
          { name: 'Krapina-Zagorje', code: '02' },
          { name: 'Lika-Senj', code: '09' },
          { name: 'Međimurje', code: '20' },
          { name: 'Osijek-Baranja', code: '14' },
          { name: 'Požega-Slavonia', code: '11' },
          { name: 'Primorje-Gorski Kotar', code: '08' },
          { name: 'Šibenik-Knin', code: '15' },
          { name: 'Sisak-Moslavina', code: '03' },
          { name: 'Split-Dalmatia', code: '17' },
          { name: 'Varaždin', code: '05' },
          { name: 'Virovitica-Podravina', code: '10' },
          { name: 'Vukovar-Syrmia', code: '16' },
          { name: 'Zadar', code: '13' },
          { name: 'Zagreb', code: '01' },
          { name: 'Zagreb', code: '21' },
        ],
      },
    ],
    [
      'Cuba',
      {
        name: 'Cuba',
        code: 'CU',
        emoji: '🇨🇺',
        regions: [
          { name: 'Artemisa Province', code: '15' },
          { name: 'Camagüey Province', code: '09' },
          { name: 'Ciego de Ávila Province', code: '08' },
          { name: 'Cienfuegos Province', code: '06' },
          { name: 'Granma Province', code: '12' },
          { name: 'Guantánamo Province', code: '14' },
          { name: 'Havana Province', code: '03' },
          { name: 'Holguín Province', code: '11' },
          { name: 'Isla de la Juventud', code: '99' },
          { name: 'Las Tunas Province', code: '10' },
          { name: 'Matanzas Province', code: '04' },
          { name: 'Mayabeque Province', code: '16' },
          { name: 'Pinar del Río Province', code: '01' },
          { name: 'Sancti Spíritus Province', code: '07' },
          { name: 'Santiago de Cuba Province', code: '13' },
          { name: 'Villa Clara Province', code: '05' },
        ],
      },
    ],
    [
      'Curaçao',
      {
        name: 'Curaçao',
        code: 'CW',
        emoji: '🇨🇼',
        regions: [],
      },
    ],
    [
      'Cyprus',
      {
        name: 'Cyprus',
        code: 'CY',
        emoji: '🇨🇾',
        regions: [
          { name: 'Famagusta District (Mağusa)', code: '04' },
          { name: 'Kyrenia District (Keryneia)', code: '06' },
          { name: 'Larnaca District (Larnaka)', code: '03' },
          { name: 'Limassol District (Leymasun)', code: '02' },
          { name: 'Nicosia District (Lefkoşa)', code: '01' },
          { name: 'Paphos District (Pafos)', code: '05' },
        ],
      },
    ],
    [
      'Czech Republic',
      {
        name: 'Czech Republic',
        code: 'CZ',
        emoji: '🇨🇿',
        regions: [
          { name: 'Benešov', code: '201' },
          { name: 'Beroun', code: '202' },
          { name: 'Blansko', code: '641' },
          { name: 'Břeclav', code: '644' },
          { name: 'Brno-město', code: '642' },
          { name: 'Brno-venkov', code: '643' },
          { name: 'Bruntál', code: '801' },
          { name: 'Česká Lípa', code: '511' },
          { name: 'České Budějovice', code: '311' },
          { name: 'Český Krumlov', code: '312' },
          { name: 'Cheb', code: '411' },
          { name: 'Chomutov', code: '422' },
          { name: 'Chrudim', code: '531' },
          { name: 'Děčín', code: '421' },
          { name: 'Domažlice', code: '321' },
          { name: 'Frýdek-Místek', code: '802' },
          { name: 'Havlíčkův Brod', code: '631' },
          { name: 'Hodonín', code: '645' },
          { name: 'Hradec Králové', code: '521' },
          { name: 'Jablonec nad Nisou', code: '512' },
          { name: 'Jeseník', code: '711' },
          { name: 'Jičín', code: '522' },
          { name: 'Jihlava', code: '632' },
          { name: 'Jihočeský kraj', code: '31' },
          { name: 'Jihomoravský kraj', code: '64' },
          { name: 'Jindřichův Hradec', code: '313' },
          { name: 'Karlovarský kraj', code: '41' },
          { name: 'Karlovy Vary', code: '412' },
          { name: 'Karviná', code: '803' },
          { name: 'Kladno', code: '203' },
          { name: 'Klatovy', code: '322' },
          { name: 'Kolín', code: '204' },
          { name: 'Kraj Vysočina', code: '63' },
          { name: 'Královéhradecký kraj', code: '52' },
          { name: 'Kroměříž', code: '721' },
          { name: 'Kutná Hora', code: '205' },
          { name: 'Liberec', code: '513' },
          { name: 'Liberecký kraj', code: '51' },
          { name: 'Litoměřice', code: '423' },
          { name: 'Louny', code: '424' },
          { name: 'Mělník', code: '206' },
          { name: 'Mladá Boleslav', code: '207' },
          { name: 'Moravskoslezský kraj', code: '80' },
          { name: 'Most', code: '425' },
          { name: 'Náchod', code: '523' },
          { name: 'Nový Jičín', code: '804' },
          { name: 'Nymburk', code: '208' },
          { name: 'Olomouc', code: '712' },
          { name: 'Olomoucký kraj', code: '71' },
          { name: 'Opava', code: '805' },
          { name: 'Ostrava-město', code: '806' },
          { name: 'Pardubice', code: '532' },
          { name: 'Pardubický kraj', code: '53' },
          { name: 'Pelhřimov', code: '633' },
          { name: 'Písek', code: '314' },
          { name: 'Plzeň-jih', code: '324' },
          { name: 'Plzeň-město', code: '323' },
          { name: 'Plzeň-sever', code: '325' },
          { name: 'Plzeňský kraj', code: '32' },
          { name: 'Prachatice', code: '315' },
          { name: 'Praha-východ', code: '209' },
          { name: 'Praha-západ', code: '20A' },
          { name: 'Praha, Hlavní město', code: '10' },
          { name: 'Přerov', code: '714' },
          { name: 'Příbram', code: '20B' },
          { name: 'Prostějov', code: '713' },
          { name: 'Rakovník', code: '20C' },
          { name: 'Rokycany', code: '326' },
          { name: 'Rychnov nad Kněžnou', code: '524' },
          { name: 'Semily', code: '514' },
          { name: 'Sokolov', code: '413' },
          { name: 'Strakonice', code: '316' },
          { name: 'Středočeský kraj', code: '20' },
          { name: 'Šumperk', code: '715' },
          { name: 'Svitavy', code: '533' },
          { name: 'Tábor', code: '317' },
          { name: 'Tachov', code: '327' },
          { name: 'Teplice', code: '426' },
          { name: 'Třebíč', code: '634' },
          { name: 'Trutnov', code: '525' },
          { name: 'Uherské Hradiště', code: '722' },
          { name: 'Ústecký kraj', code: '42' },
          { name: 'Ústí nad Labem', code: '427' },
          { name: 'Ústí nad Orlicí', code: '534' },
          { name: 'Vsetín', code: '723' },
          { name: 'Vyškov', code: '646' },
          { name: 'Žďár nad Sázavou', code: '635' },
          { name: 'Zlín', code: '724' },
          { name: 'Zlínský kraj', code: '72' },
          { name: 'Znojmo', code: '647' },
        ],
      },
    ],
    [
      'Democratic Republic of the Congo',
      {
        name: 'Democratic Republic of the Congo',
        code: 'CD',
        emoji: '🇨🇩',
        regions: [
          { name: 'Bas-Uélé', code: 'BU' },
          { name: 'Équateur', code: 'EQ' },
          { name: 'Haut-Katanga', code: 'HK' },
          { name: 'Haut-Lomami', code: 'HL' },
          { name: 'Haut-Uélé', code: 'HU' },
          { name: 'Ituri', code: 'IT' },
          { name: 'Kasaï', code: 'KS' },
          { name: 'Kasaï Central', code: 'KC' },
          { name: 'Kasaï Oriental', code: 'KE' },
          { name: 'Kinshasa', code: 'KN' },
          { name: 'Kongo Central', code: 'BC' },
          { name: 'Kwango', code: 'KG' },
          { name: 'Kwilu', code: 'KL' },
          { name: 'Lomami', code: 'LO' },
          { name: 'Lualaba', code: 'LU' },
          { name: 'Mai-Ndombe', code: 'MN' },
          { name: 'Maniema', code: 'MA' },
          { name: 'Mongala', code: 'MO' },
          { name: 'Nord-Kivu', code: 'NK' },
          { name: 'Nord-Ubangi', code: 'NU' },
          { name: 'Sankuru', code: 'SA' },
          { name: 'Sud-Kivu', code: 'SK' },
          { name: 'Sud-Ubangi', code: 'SU' },
          { name: 'Tanganyika', code: 'TA' },
          { name: 'Tshopo', code: 'TO' },
          { name: 'Tshuapa', code: 'TU' },
        ],
      },
    ],
    [
      'Denmark',
      {
        name: 'Denmark',
        code: 'DK',
        emoji: '🇩🇰',
        regions: [
          { name: 'Capital Region of Denmark', code: '84' },
          { name: 'Central Denmark Region', code: '82' },
          { name: 'North Denmark Region', code: '81' },
          { name: 'Region of Southern Denmark', code: '83' },
          { name: 'Region Zealand', code: '85' },
        ],
      },
    ],
    [
      'Djibouti',
      {
        name: 'Djibouti',
        code: 'DJ',
        emoji: '🇩🇯',
        regions: [
          { name: 'Ali Sabieh Region', code: 'AS' },
          { name: 'Arta Region', code: 'AR' },
          { name: 'Dikhil Region', code: 'DI' },
          { name: 'Djibouti', code: 'DJ' },
          { name: 'Obock Region', code: 'OB' },
          { name: 'Tadjourah Region', code: 'TA' },
        ],
      },
    ],
    [
      'Dominica',
      {
        name: 'Dominica',
        code: 'DM',
        emoji: '🇩🇲',
        regions: [
          { name: 'Saint Andrew Parish', code: '02' },
          { name: 'Saint David Parish', code: '03' },
          { name: 'Saint George Parish', code: '04' },
          { name: 'Saint John Parish', code: '05' },
          { name: 'Saint Joseph Parish', code: '06' },
          { name: 'Saint Luke Parish', code: '07' },
          { name: 'Saint Mark Parish', code: '08' },
          { name: 'Saint Patrick Parish', code: '09' },
          { name: 'Saint Paul Parish', code: '10' },
          { name: 'Saint Peter Parish', code: '11' },
        ],
      },
    ],
    [
      'Dominican Republic',
      {
        name: 'Dominican Republic',
        code: 'DO',
        emoji: '🇩🇴',
        regions: [
          { name: 'Azua Province', code: '02' },
          { name: 'Baoruco Province', code: '03' },
          { name: 'Barahona Province', code: '04' },
          { name: 'Dajabón Province', code: '05' },
          { name: 'Distrito Nacional', code: '01' },
          { name: 'Duarte Province', code: '06' },
          { name: 'El Seibo Province', code: '08' },
          { name: 'Espaillat Province', code: '09' },
          { name: 'Hato Mayor Province', code: '30' },
          { name: 'Hermanas Mirabal Province', code: '19' },
          { name: 'Independencia', code: '10' },
          { name: 'La Altagracia Province', code: '11' },
          { name: 'La Romana Province', code: '12' },
          { name: 'La Vega Province', code: '13' },
          { name: 'María Trinidad Sánchez Province', code: '14' },
          { name: 'Monseñor Nouel Province', code: '28' },
          { name: 'Monte Cristi Province', code: '15' },
          { name: 'Monte Plata Province', code: '29' },
          { name: 'Pedernales Province', code: '16' },
          { name: 'Peravia Province', code: '17' },
          { name: 'Puerto Plata Province', code: '18' },
          { name: 'Samaná Province', code: '20' },
          { name: 'San Cristóbal Province', code: '21' },
          { name: 'San José de Ocoa Province', code: '31' },
          { name: 'San Juan Province', code: '22' },
          { name: 'San Pedro de Macorís', code: '23' },
          { name: 'Sánchez Ramírez Province', code: '24' },
          { name: 'Santiago Province', code: '25' },
          { name: 'Santiago Rodríguez Province', code: '26' },
          { name: 'Santo Domingo Province', code: '32' },
          { name: 'Valverde Province', code: '27' },
        ],
      },
    ],
    [
      'Ecuador',
      {
        name: 'Ecuador',
        code: 'EC',
        emoji: '🇪🇨',
        regions: [
          { name: 'Azuay', code: 'A' },
          { name: 'Bolívar', code: 'B' },
          { name: 'Cañar', code: 'F' },
          { name: 'Carchi', code: 'C' },
          { name: 'Chimborazo', code: 'H' },
          { name: 'Cotopaxi', code: 'X' },
          { name: 'El Oro', code: 'O' },
          { name: 'Esmeraldas', code: 'E' },
          { name: 'Galápagos', code: 'W' },
          { name: 'Guayas', code: 'G' },
          { name: 'Imbabura', code: 'I' },
          { name: 'Loja', code: 'L' },
          { name: 'Los Ríos', code: 'R' },
          { name: 'Manabí', code: 'M' },
          { name: 'Morona-Santiago', code: 'S' },
          { name: 'Napo', code: 'N' },
          { name: 'Orellana', code: 'D' },
          { name: 'Pastaza', code: 'Y' },
          { name: 'Pichincha', code: 'P' },
          { name: 'Santa Elena', code: 'SE' },
          { name: 'Santo Domingo de los Tsáchilas', code: 'SD' },
          { name: 'Sucumbíos', code: 'U' },
          { name: 'Tungurahua', code: 'T' },
          { name: 'Zamora Chinchipe', code: 'Z' },
        ],
      },
    ],
    [
      'Egypt',
      {
        name: 'Egypt',
        code: 'EG',
        emoji: '🇪🇬',
        regions: [
          { name: 'Alexandria', code: 'ALX' },
          { name: 'Aswan', code: 'ASN' },
          { name: 'Asyut', code: 'AST' },
          { name: 'Beheira', code: 'BH' },
          { name: 'Beni Suef', code: 'BNS' },
          { name: 'Cairo', code: 'C' },
          { name: 'Dakahlia', code: 'DK' },
          { name: 'Damietta', code: 'DT' },
          { name: 'Faiyum', code: 'FYM' },
          { name: 'Gharbia', code: 'GH' },
          { name: 'Giza', code: 'GZ' },
          { name: 'Ismailia', code: 'IS' },
          { name: 'Kafr el-Sheikh', code: 'KFS' },
          { name: 'Luxor', code: 'LX' },
          { name: 'Matrouh', code: 'MT' },
          { name: 'Minya', code: 'MN' },
          { name: 'Monufia', code: 'MNF' },
          { name: 'New Valley', code: 'WAD' },
          { name: 'North Sinai', code: 'SIN' },
          { name: 'Port Said', code: 'PTS' },
          { name: 'Qalyubia', code: 'KB' },
          { name: 'Qena', code: 'KN' },
          { name: 'Red Sea', code: 'BA' },
          { name: 'Sharqia', code: 'SHR' },
          { name: 'Sohag', code: 'SHG' },
          { name: 'South Sinai', code: 'JS' },
          { name: 'Suez', code: 'SUZ' },
        ],
      },
    ],
    [
      'El Salvador',
      {
        name: 'El Salvador',
        code: 'SV',
        emoji: '🇸🇻',
        regions: [
          { name: 'Ahuachapán Department', code: 'AH' },
          { name: 'Cabañas Department', code: 'CA' },
          { name: 'Chalatenango Department', code: 'CH' },
          { name: 'Cuscatlán Department', code: 'CU' },
          { name: 'La Libertad Department', code: 'LI' },
          { name: 'La Paz Department', code: 'PA' },
          { name: 'La Unión Department', code: 'UN' },
          { name: 'Morazán Department', code: 'MO' },
          { name: 'San Miguel Department', code: 'SM' },
          { name: 'San Salvador Department', code: 'SS' },
          { name: 'San Vicente Department', code: 'SV' },
          { name: 'Santa Ana Department', code: 'SA' },
          { name: 'Sonsonate Department', code: 'SO' },
          { name: 'Usulután Department', code: 'US' },
        ],
      },
    ],
    [
      'Equatorial Guinea',
      {
        name: 'Equatorial Guinea',
        code: 'GQ',
        emoji: '🇬🇶',
        regions: [
          { name: 'Annobón Province', code: 'AN' },
          { name: 'Bioko Norte Province', code: 'BN' },
          { name: 'Bioko Sur Province', code: 'BS' },
          { name: 'Centro Sur Province', code: 'CS' },
          { name: 'Insular Region', code: 'I' },
          { name: 'Kié-Ntem Province', code: 'KN' },
          { name: 'Litoral Province', code: 'LI' },
          { name: 'Río Muni', code: 'C' },
          { name: 'Wele-Nzas Province', code: 'WN' },
        ],
      },
    ],
    [
      'Eritrea',
      {
        name: 'Eritrea',
        code: 'ER',
        emoji: '🇪🇷',
        regions: [
          { name: 'Anseba Region', code: 'AN' },
          { name: 'Debub Region', code: 'DU' },
          { name: 'Gash-Barka Region', code: 'GB' },
          { name: 'Maekel Region', code: 'MA' },
          { name: 'Northern Red Sea Region', code: 'SK' },
          { name: 'Southern Red Sea Region', code: 'DK' },
        ],
      },
    ],
    [
      'Estonia',
      {
        name: 'Estonia',
        code: 'EE',
        emoji: '🇪🇪',
        regions: [
          { name: 'Harju County', code: '37' },
          { name: 'Hiiu County', code: '39' },
          { name: 'Ida-Viru County', code: '44' },
          { name: 'Järva County', code: '51' },
          { name: 'Jõgeva County', code: '49' },
          { name: 'Lääne County', code: '57' },
          { name: 'Lääne-Viru County', code: '59' },
          { name: 'Pärnu County', code: '67' },
          { name: 'Põlva County', code: '65' },
          { name: 'Rapla County', code: '70' },
          { name: 'Saare County', code: '74' },
          { name: 'Tartu County', code: '78' },
          { name: 'Valga County', code: '82' },
          { name: 'Viljandi County', code: '84' },
          { name: 'Võru County', code: '86' },
        ],
      },
    ],
    [
      'Eswatini',
      {
        name: 'Eswatini',
        code: 'SZ',
        emoji: '🇸🇿',
        regions: [
          { name: 'Hhohho District', code: 'HH' },
          { name: 'Lubombo District', code: 'LU' },
          { name: 'Manzini District', code: 'MA' },
          { name: 'Shiselweni District', code: 'SH' },
        ],
      },
    ],
    [
      'Ethiopia',
      {
        name: 'Ethiopia',
        code: 'ET',
        emoji: '🇪🇹',
        regions: [
          { name: 'Addis Ababa', code: 'AA' },
          { name: 'Afar Region', code: 'AF' },
          { name: 'Amhara Region', code: 'AM' },
          { name: 'Benishangul-Gumuz Region', code: 'BE' },
          { name: 'Dire Dawa', code: 'DD' },
          { name: 'Gambela Region', code: 'GA' },
          { name: 'Harari Region', code: 'HA' },
          { name: 'Oromia Region', code: 'OR' },
          { name: 'Somali Region', code: 'SO' },
          { name: `Southern Nations, Nationalities, and Peoples' Region`, code: 'SN' },
          { name: 'Tigray Region', code: 'TI' },
        ],
      },
    ],
    [
      'Falkland Islands',
      {
        name: 'Falkland Islands',
        code: 'FK',
        emoji: '🇫🇰',
        regions: [],
      },
    ],
    [
      'Faroe Islands',
      {
        name: 'Faroe Islands',
        code: 'FO',
        emoji: '🇫🇴',
        regions: [],
      },
    ],
    [
      'Fiji Islands',
      {
        name: 'Fiji Islands',
        code: 'FJ',
        emoji: '🇫🇯',
        regions: [
          { name: 'Ba', code: '01' },
          { name: 'Bua', code: '02' },
          { name: 'Cakaudrove', code: '03' },
          { name: 'Central Division', code: 'C' },
          { name: 'Eastern Division', code: 'E' },
          { name: 'Kadavu', code: '04' },
          { name: 'Lau', code: '05' },
          { name: 'Lomaiviti', code: '06' },
          { name: 'Macuata', code: '07' },
          { name: 'Nadroga-Navosa', code: '08' },
          { name: 'Naitasiri', code: '09' },
          { name: 'Namosi', code: '10' },
          { name: 'Northern Division', code: 'N' },
          { name: 'Ra', code: '11' },
          { name: 'Rewa', code: '12' },
          { name: 'Rotuma', code: 'R' },
          { name: 'Serua', code: '13' },
          { name: 'Tailevu', code: '14' },
          { name: 'Western Division', code: 'W' },
        ],
      },
    ],
    [
      'Finland',
      {
        name: 'Finland',
        code: 'FI',
        emoji: '🇫🇮',
        regions: [
          { name: 'Åland Islands', code: '01' },
          { name: 'Central Finland', code: '08' },
          { name: 'Central Ostrobothnia', code: '07' },
          { name: 'Finland Proper', code: '19' },
          { name: 'Kainuu', code: '05' },
          { name: 'Kymenlaakso', code: '09' },
          { name: 'Lapland', code: '10' },
          { name: 'North Karelia', code: '13' },
          { name: 'Northern Ostrobothnia', code: '14' },
          { name: 'Northern Savonia', code: '15' },
          { name: 'Ostrobothnia', code: '12' },
          { name: 'Päijänne Tavastia', code: '16' },
          { name: 'Pirkanmaa', code: '11' },
          { name: 'Satakunta', code: '17' },
          { name: 'South Karelia', code: '02' },
          { name: 'Southern Ostrobothnia', code: '03' },
          { name: 'Southern Savonia', code: '04' },
          { name: 'Tavastia Proper', code: '06' },
          { name: 'Uusimaa', code: '18' },
        ],
      },
    ],
    [
      'France',
      {
        name: 'France',
        code: 'FR',
        emoji: '🇫🇷',
        regions: [
          { name: 'Ain', code: '01' },
          { name: 'Aisne', code: '02' },
          { name: 'Allier', code: '03' },
          { name: 'Alpes-de-Haute-Provence', code: '04' },
          { name: 'Alpes-Maritimes', code: '06' },
          { name: 'Alsace', code: '6AE' },
          { name: 'Ardèche', code: '07' },
          { name: 'Ardennes', code: '08' },
          { name: 'Ariège', code: '09' },
          { name: 'Aube', code: '10' },
          { name: 'Aude', code: '11' },
          { name: 'Auvergne-Rhône-Alpes', code: 'ARA' },
          { name: 'Aveyron', code: '12' },
          { name: 'Bas-Rhin', code: '67' },
          { name: 'Bouches-du-Rhône', code: '13' },
          { name: 'Bourgogne-Franche-Comté', code: 'BFC' },
          { name: 'Bretagne', code: 'BRE' },
          { name: 'Calvados', code: '14' },
          { name: 'Cantal', code: '15' },
          { name: 'Centre-Val de Loire', code: 'CVL' },
          { name: 'Charente', code: '16' },
          { name: 'Charente-Maritime', code: '17' },
          { name: 'Cher', code: '18' },
          { name: 'Clipperton', code: 'CP' },
          { name: 'Corrèze', code: '19' },
          { name: 'Corse', code: '20R' },
          { name: 'Corse-du-Sud', code: '2A' },
          { name: `Côte-d'Or`, code: '21' },
          { name: `Côtes-d'Armor`, code: '22' },
          { name: 'Creuse', code: '23' },
          { name: 'Deux-Sèvres', code: '79' },
          { name: 'Dordogne', code: '24' },
          { name: 'Doubs', code: '25' },
          { name: 'Drôme', code: '26' },
          { name: 'Essonne', code: '91' },
          { name: 'Eure', code: '27' },
          { name: 'Eure-et-Loir', code: '28' },
          { name: 'Finistère', code: '29' },
          { name: 'French Guiana', code: '973' },
          { name: 'French Polynesia', code: 'PF' },
          { name: 'French Southern and Antarctic Lands', code: 'TF' },
          { name: 'Gard', code: '30' },
          { name: 'Gers', code: '32' },
          { name: 'Gironde', code: '33' },
          { name: 'Grand-Est', code: 'GES' },
          { name: 'Guadeloupe', code: '971' },
          { name: 'Haut-Rhin', code: '68' },
          { name: 'Haute-Corse', code: '2B' },
          { name: 'Haute-Garonne', code: '31' },
          { name: 'Haute-Loire', code: '43' },
          { name: 'Haute-Marne', code: '52' },
          { name: 'Haute-Saône', code: '70' },
          { name: 'Haute-Savoie', code: '74' },
          { name: 'Haute-Vienne', code: '87' },
          { name: 'Hautes-Alpes', code: '05' },
          { name: 'Hautes-Pyrénées', code: '65' },
          { name: 'Hauts-de-France', code: 'HDF' },
          { name: 'Hauts-de-Seine', code: '92' },
          { name: 'Hérault', code: '34' },
          { name: 'Île-de-France', code: 'IDF' },
          { name: 'Ille-et-Vilaine', code: '35' },
          { name: 'Indre', code: '36' },
          { name: 'Indre-et-Loire', code: '37' },
          { name: 'Isère', code: '38' },
          { name: 'Jura', code: '39' },
          { name: 'La Réunion', code: '974' },
          { name: 'Landes', code: '40' },
          { name: 'Loir-et-Cher', code: '41' },
          { name: 'Loire', code: '42' },
          { name: 'Loire-Atlantique', code: '44' },
          { name: 'Loiret', code: '45' },
          { name: 'Lot', code: '46' },
          { name: 'Lot-et-Garonne', code: '47' },
          { name: 'Lozère', code: '48' },
          { name: 'Maine-et-Loire', code: '49' },
          { name: 'Manche', code: '50' },
          { name: 'Marne', code: '51' },
          { name: 'Martinique', code: '972' },
          { name: 'Mayenne', code: '53' },
          { name: 'Mayotte', code: '976' },
          { name: 'Métropole de Lyon', code: '69M' },
          { name: 'Meurthe-et-Moselle', code: '54' },
          { name: 'Meuse', code: '55' },
          { name: 'Morbihan', code: '56' },
          { name: 'Moselle', code: '57' },
          { name: 'Nièvre', code: '58' },
          { name: 'Nord', code: '59' },
          { name: 'Normandie', code: 'NOR' },
          { name: 'Nouvelle-Aquitaine', code: 'NAQ' },
          { name: 'Occitanie', code: 'OCC' },
          { name: 'Oise', code: '60' },
          { name: 'Orne', code: '61' },
          { name: 'Paris', code: '75C' },
          { name: 'Pas-de-Calais', code: '62' },
          { name: 'Pays-de-la-Loire', code: 'PDL' },
          { name: 'Provence-Alpes-Côte-d’Azur', code: 'PAC' },
          { name: 'Puy-de-Dôme', code: '63' },
          { name: 'Pyrénées-Atlantiques', code: '64' },
          { name: 'Pyrénées-Orientales', code: '66' },
          { name: 'Rhône', code: '69' },
          { name: 'Saint Pierre and Miquelon', code: 'PM' },
          { name: 'Saint-Barthélemy', code: 'BL' },
          { name: 'Saint-Martin', code: 'MF' },
          { name: 'Saône-et-Loire', code: '71' },
          { name: 'Sarthe', code: '72' },
          { name: 'Savoie', code: '73' },
          { name: 'Seine-et-Marne', code: '77' },
          { name: 'Seine-Maritime', code: '76' },
          { name: 'Seine-Saint-Denis', code: '93' },
          { name: 'Somme', code: '80' },
          { name: 'Tarn', code: '81' },
          { name: 'Tarn-et-Garonne', code: '82' },
          { name: 'Territoire de Belfort', code: '90' },
          { name: `Val-d'Oise`, code: '95' },
          { name: 'Val-de-Marne', code: '94' },
          { name: 'Var', code: '83' },
          { name: 'Vaucluse', code: '84' },
          { name: 'Vendée', code: '85' },
          { name: 'Vienne', code: '86' },
          { name: 'Vosges', code: '88' },
          { name: 'Wallis and Futuna', code: 'WF' },
          { name: 'Yonne', code: '89' },
          { name: 'Yvelines', code: '78' },
        ],
      },
    ],
    [
      'French Guiana',
      {
        name: 'French Guiana',
        code: 'GF',
        emoji: '🇬🇫',
        regions: [],
      },
    ],
    [
      'French Polynesia',
      {
        name: 'French Polynesia',
        code: 'PF',
        emoji: '🇵🇫',
        regions: [],
      },
    ],
    [
      'French Southern Territories',
      {
        name: 'French Southern Territories',
        code: 'TF',
        emoji: '🇹🇫',
        regions: [],
      },
    ],
    [
      'Gabon',
      {
        name: 'Gabon',
        code: 'GA',
        emoji: '🇬🇦',
        regions: [
          { name: 'Estuaire Province', code: '1' },
          { name: 'Haut-Ogooué Province', code: '2' },
          { name: 'Moyen-Ogooué Province', code: '3' },
          { name: 'Ngounié Province', code: '4' },
          { name: 'Nyanga Province', code: '5' },
          { name: 'Ogooué-Ivindo Province', code: '6' },
          { name: 'Ogooué-Lolo Province', code: '7' },
          { name: 'Ogooué-Maritime Province', code: '8' },
          { name: 'Woleu-Ntem Province', code: '9' },
        ],
      },
    ],
    [
      'Gambia The',
      {
        name: 'Gambia The',
        code: 'GM',
        emoji: '🇬🇲',
        regions: [
          { name: 'Banjul', code: 'B' },
          { name: 'Central River Division', code: 'M' },
          { name: 'Lower River Division', code: 'L' },
          { name: 'North Bank Division', code: 'N' },
          { name: 'Upper River Division', code: 'U' },
          { name: 'West Coast Division', code: 'W' },
        ],
      },
    ],
    [
      'Georgia',
      {
        name: 'Georgia',
        code: 'GE',
        emoji: '🇬🇪',
        regions: [
          { name: 'Abkhazia', code: 'AB' },
          { name: 'Adjara', code: 'AJ' },
          { name: 'Guria', code: 'GU' },
          { name: 'Imereti', code: 'IM' },
          { name: 'Kakheti', code: 'KA' },
          { name: 'Khelvachauri Municipality', code: '29' },
          { name: 'Kvemo Kartli', code: 'KK' },
          { name: 'Mtskheta-Mtianeti', code: 'MM' },
          { name: 'Racha-Lechkhumi and Kvemo Svaneti', code: 'RL' },
          { name: 'Samegrelo-Zemo Svaneti', code: 'SZ' },
          { name: 'Samtskhe-Javakheti', code: 'SJ' },
          { name: 'Senaki Municipality', code: '50' },
          { name: 'Shida Kartli', code: 'SK' },
          { name: 'Tbilisi', code: 'TB' },
        ],
      },
    ],
    [
      'Germany',
      {
        name: 'Germany',
        code: 'DE',
        emoji: '🇩🇪',
        regions: [
          { name: 'Baden-Württemberg', code: 'BW' },
          { name: 'Bavaria', code: 'BY' },
          { name: 'Berlin', code: 'BE' },
          { name: 'Brandenburg', code: 'BB' },
          { name: 'Bremen', code: 'HB' },
          { name: 'Hamburg', code: 'HH' },
          { name: 'Hesse', code: 'HE' },
          { name: 'Lower Saxony', code: 'NI' },
          { name: 'Mecklenburg-Vorpommern', code: 'MV' },
          { name: 'North Rhine-Westphalia', code: 'NW' },
          { name: 'Rhineland-Palatinate', code: 'RP' },
          { name: 'Saarland', code: 'SL' },
          { name: 'Saxony', code: 'SN' },
          { name: 'Saxony-Anhalt', code: 'ST' },
          { name: 'Schleswig-Holstein', code: 'SH' },
          { name: 'Thuringia', code: 'TH' },
        ],
      },
    ],
    [
      'Ghana',
      {
        name: 'Ghana',
        code: 'GH',
        emoji: '🇬🇭',
        regions: [
          { name: 'Ahafo', code: 'AF' },
          { name: 'Ashanti', code: 'AH' },
          { name: 'Bono', code: 'BO' },
          { name: 'Bono East', code: 'BE' },
          { name: 'Central', code: 'CP' },
          { name: 'Eastern', code: 'EP' },
          { name: 'Greater Accra', code: 'AA' },
          { name: 'North East', code: 'NE' },
          { name: 'Northern', code: 'NP' },
          { name: 'Oti', code: 'OT' },
          { name: 'Savannah', code: 'SV' },
          { name: 'Upper East', code: 'UE' },
          { name: 'Upper West', code: 'UW' },
          { name: 'Volta', code: 'TV' },
          { name: 'Western', code: 'WP' },
          { name: 'Western North', code: 'WN' },
        ],
      },
    ],
    [
      'Gibraltar',
      {
        name: 'Gibraltar',
        code: 'GI',
        emoji: '🇬🇮',
        regions: [],
      },
    ],
    [
      'Greece',
      {
        name: 'Greece',
        code: 'GR',
        emoji: '🇬🇷',
        regions: [
          { name: 'Achaea Regional Unit', code: '13' },
          { name: 'Aetolia-Acarnania Regional Unit', code: '01' },
          { name: 'Arcadia Prefecture', code: '12' },
          { name: 'Argolis Regional Unit', code: '11' },
          { name: 'Attica Region', code: 'I' },
          { name: 'Boeotia Regional Unit', code: '03' },
          { name: 'Central Greece Region', code: 'H' },
          { name: 'Central Macedonia', code: 'B' },
          { name: 'Chania Regional Unit', code: '94' },
          { name: 'Corfu Prefecture', code: '22' },
          { name: 'Corinthia Regional Unit', code: '15' },
          { name: 'Crete Region', code: 'M' },
          { name: 'Drama Regional Unit', code: '52' },
          { name: 'East Attica Regional Unit', code: 'A2' },
          { name: 'East Macedonia and Thrace', code: 'A' },
          { name: 'Epirus Region', code: 'D' },
          { name: 'Euboea', code: '04' },
          { name: 'Grevena Prefecture', code: '51' },
          { name: 'Imathia Regional Unit', code: '53' },
          { name: 'Ioannina Regional Unit', code: '33' },
          { name: 'Ionian Islands Region', code: 'F' },
          { name: 'Karditsa Regional Unit', code: '41' },
          { name: 'Kastoria Regional Unit', code: '56' },
          { name: 'Kefalonia Prefecture', code: '23' },
          { name: 'Kilkis Regional Unit', code: '57' },
          { name: 'Kozani Prefecture', code: '58' },
          { name: 'Laconia', code: '16' },
          { name: 'Larissa Prefecture', code: '42' },
          { name: 'Lefkada Regional Unit', code: '24' },
          { name: 'Pella Regional Unit', code: '59' },
          { name: 'Peloponnese Region', code: 'J' },
          { name: 'Phthiotis Prefecture', code: '06' },
          { name: 'Preveza Prefecture', code: '34' },
          { name: 'Serres Prefecture', code: '62' },
          { name: 'South Aegean', code: 'L' },
          { name: 'Thessaloniki Regional Unit', code: '54' },
          { name: 'West Greece Region', code: 'G' },
          { name: 'West Macedonia Region', code: 'C' },
        ],
      },
    ],
    [
      'Greenland',
      {
        name: 'Greenland',
        code: 'GL',
        emoji: '🇬🇱',
        regions: [],
      },
    ],
    [
      'Grenada',
      {
        name: 'Grenada',
        code: 'GD',
        emoji: '🇬🇩',
        regions: [
          { name: 'Carriacou and Petite Martinique', code: '10' },
          { name: 'Saint Andrew Parish', code: '01' },
          { name: 'Saint David Parish', code: '02' },
          { name: 'Saint George Parish', code: '03' },
          { name: 'Saint John Parish', code: '04' },
          { name: 'Saint Mark Parish', code: '05' },
          { name: 'Saint Patrick Parish', code: '06' },
        ],
      },
    ],
    [
      'Guadeloupe',
      {
        name: 'Guadeloupe',
        code: 'GP',
        emoji: '🇬🇵',
        regions: [],
      },
    ],
    [
      'Guam',
      {
        name: 'Guam',
        code: 'GU',
        emoji: '🇬🇺',
        regions: [],
      },
    ],
    [
      'Guatemala',
      {
        name: 'Guatemala',
        code: 'GT',
        emoji: '🇬🇹',
        regions: [
          { name: 'Alta Verapaz Department', code: 'AV' },
          { name: 'Baja Verapaz Department', code: 'BV' },
          { name: 'Chimaltenango Department', code: 'CM' },
          { name: 'Chiquimula Department', code: 'CQ' },
          { name: 'El Progreso Department', code: 'PR' },
          { name: 'Escuintla Department', code: 'ES' },
          { name: 'Guatemala Department', code: 'GU' },
          { name: 'Huehuetenango Department', code: 'HU' },
          { name: 'Izabal Department', code: 'IZ' },
          { name: 'Jalapa Department', code: 'JA' },
          { name: 'Jutiapa Department', code: 'JU' },
          { name: 'Petén Department', code: 'PE' },
          { name: 'Quetzaltenango Department', code: 'QZ' },
          { name: 'Quiché Department', code: 'QC' },
          { name: 'Retalhuleu Department', code: 'RE' },
          { name: 'Sacatepéquez Department', code: 'SA' },
          { name: 'San Marcos Department', code: 'SM' },
          { name: 'Santa Rosa Department', code: 'SR' },
          { name: 'Sololá Department', code: 'SO' },
          { name: 'Suchitepéquez Department', code: 'SU' },
          { name: 'Totonicapán Department', code: 'TO' },
        ],
      },
    ],
    [
      'Guernsey and Alderney',
      {
        name: 'Guernsey and Alderney',
        code: 'GG',
        emoji: '🇬🇬',
        regions: [],
      },
    ],
    [
      'Guinea',
      {
        name: 'Guinea',
        code: 'GN',
        emoji: '🇬🇳',
        regions: [
          { name: 'Beyla Prefecture', code: 'BE' },
          { name: 'Boffa Prefecture', code: 'BF' },
          { name: 'Boké Prefecture', code: 'BK' },
          { name: 'Boké Region', code: 'B' },
          { name: 'Conakry', code: 'C' },
          { name: 'Coyah Prefecture', code: 'CO' },
          { name: 'Dabola Prefecture', code: 'DB' },
          { name: 'Dalaba Prefecture', code: 'DL' },
          { name: 'Dinguiraye Prefecture', code: 'DI' },
          { name: 'Dubréka Prefecture', code: 'DU' },
          { name: 'Faranah Prefecture', code: 'FA' },
          { name: 'Forécariah Prefecture', code: 'FO' },
          { name: 'Fria Prefecture', code: 'FR' },
          { name: 'Gaoual Prefecture', code: 'GA' },
          { name: 'Guéckédou Prefecture', code: 'GU' },
          { name: 'Kankan Prefecture', code: 'KA' },
          { name: 'Kankan Region', code: 'K' },
          { name: 'Kérouané Prefecture', code: 'KE' },
          { name: 'Kindia Prefecture', code: 'KD' },
          { name: 'Kindia Region', code: 'D' },
          { name: 'Kissidougou Prefecture', code: 'KS' },
          { name: 'Koubia Prefecture', code: 'KB' },
          { name: 'Koundara Prefecture', code: 'KN' },
          { name: 'Kouroussa Prefecture', code: 'KO' },
          { name: 'Labé Prefecture', code: 'LA' },
          { name: 'Labé Region', code: 'L' },
          { name: 'Lélouma Prefecture', code: 'LE' },
          { name: 'Lola Prefecture', code: 'LO' },
          { name: 'Macenta Prefecture', code: 'MC' },
          { name: 'Mali Prefecture', code: 'ML' },
          { name: 'Mamou Prefecture', code: 'MM' },
          { name: 'Mamou Region', code: 'M' },
          { name: 'Mandiana Prefecture', code: 'MD' },
          { name: 'Nzérékoré Prefecture', code: 'NZ' },
          { name: 'Nzérékoré Region', code: 'N' },
          { name: 'Pita Prefecture', code: 'PI' },
          { name: 'Siguiri Prefecture', code: 'SI' },
          { name: 'Télimélé Prefecture', code: 'TE' },
          { name: 'Tougué Prefecture', code: 'TO' },
          { name: 'Yomou Prefecture', code: 'YO' },
        ],
      },
    ],
    [
      'Guinea-Bissau',
      {
        name: 'Guinea-Bissau',
        code: 'GW',
        emoji: '🇬🇼',
        regions: [
          { name: 'Bafatá', code: 'BA' },
          { name: 'Biombo Region', code: 'BM' },
          { name: 'Bolama Region', code: 'BL' },
          { name: 'Cacheu Region', code: 'CA' },
          { name: 'Gabú Region', code: 'GA' },
          { name: 'Leste Province', code: 'L' },
          { name: 'Norte Province', code: 'N' },
          { name: 'Oio Region', code: 'OI' },
          { name: 'Quinara Region', code: 'QU' },
          { name: 'Sul Province', code: 'S' },
          { name: 'Tombali Region', code: 'TO' },
        ],
      },
    ],
    [
      'Guyana',
      {
        name: 'Guyana',
        code: 'GY',
        emoji: '🇬🇾',
        regions: [
          { name: 'Barima-Waini', code: 'BA' },
          { name: 'Cuyuni-Mazaruni', code: 'CU' },
          { name: 'Demerara-Mahaica', code: 'DE' },
          { name: 'East Berbice-Corentyne', code: 'EB' },
          { name: 'Essequibo Islands-West Demerara', code: 'ES' },
          { name: 'Mahaica-Berbice', code: 'MA' },
          { name: 'Pomeroon-Supenaam', code: 'PM' },
          { name: 'Potaro-Siparuni', code: 'PT' },
          { name: 'Upper Demerara-Berbice', code: 'UD' },
          { name: 'Upper Takutu-Upper Essequibo', code: 'UT' },
        ],
      },
    ],
    [
      'Haiti',
      {
        name: 'Haiti',
        code: 'HT',
        emoji: '🇭🇹',
        regions: [
          { name: 'Artibonite', code: 'AR' },
          { name: 'Centre', code: 'CE' },
          { name: `Grand'Anse`, code: 'GA' },
          { name: 'Nippes', code: 'NI' },
          { name: 'Nord', code: 'ND' },
          { name: 'Nord-Est', code: 'NE' },
          { name: 'Nord-Ouest', code: 'NO' },
          { name: 'Ouest', code: 'OU' },
          { name: 'Sud', code: 'SD' },
          { name: 'Sud-Est', code: 'SE' },
        ],
      },
    ],
    [
      'Heard Island and McDonald Islands',
      {
        name: 'Heard Island and McDonald Islands',
        code: 'HM',
        emoji: '🇭🇲',
        regions: [],
      },
    ],
    [
      'Honduras',
      {
        name: 'Honduras',
        code: 'HN',
        emoji: '🇭🇳',
        regions: [
          { name: 'Atlántida Department', code: 'AT' },
          { name: 'Bay Islands Department', code: 'IB' },
          { name: 'Choluteca Department', code: 'CH' },
          { name: 'Colón Department', code: 'CL' },
          { name: 'Comayagua Department', code: 'CM' },
          { name: 'Copán Department', code: 'CP' },
          { name: 'Cortés Department', code: 'CR' },
          { name: 'El Paraíso Department', code: 'EP' },
          { name: 'Francisco Morazán Department', code: 'FM' },
          { name: 'Gracias a Dios Department', code: 'GD' },
          { name: 'Intibucá Department', code: 'IN' },
          { name: 'La Paz Department', code: 'LP' },
          { name: 'Lempira Department', code: 'LE' },
          { name: 'Ocotepeque Department', code: 'OC' },
          { name: 'Olancho Department', code: 'OL' },
          { name: 'Santa Bárbara Department', code: 'SB' },
          { name: 'Valle Department', code: 'VA' },
          { name: 'Yoro Department', code: 'YO' },
        ],
      },
    ],
    [
      'Hong Kong S.A.R.',
      {
        name: 'Hong Kong S.A.R.',
        code: 'HK',
        emoji: '🇭🇰',
        regions: [
          { name: 'Central and Western', code: 'HCW' },
          { name: 'Eastern', code: 'HEA' },
          { name: 'Islands', code: 'NIS' },
          { name: 'Kowloon City', code: 'KKC' },
          { name: 'Kwai Tsing', code: 'NKT' },
          { name: 'Kwun Tong', code: 'KKT' },
          { name: 'North', code: 'NNO' },
          { name: 'Sai Kung', code: 'NSK' },
          { name: 'Sha Tin', code: 'NST' },
          { name: 'Sham Shui Po', code: 'KSS' },
          { name: 'Southern', code: 'HSO' },
          { name: 'Tai Po', code: 'NTP' },
          { name: 'Tsuen Wan', code: 'NTW' },
          { name: 'Tuen Mun', code: 'NTM' },
          { name: 'Wan Chai', code: 'HWC' },
          { name: 'Wong Tai Sin', code: 'KWT' },
          { name: 'Yau Tsim Mong', code: 'KYT' },
          { name: 'Yuen Long', code: 'NYL' },
        ],
      },
    ],
    [
      'Hungary',
      {
        name: 'Hungary',
        code: 'HU',
        emoji: '🇭🇺',
        regions: [
          { name: 'Bács-Kiskun', code: 'BK' },
          { name: 'Baranya', code: 'BA' },
          { name: 'Békés', code: 'BE' },
          { name: 'Békéscsaba', code: 'BC' },
          { name: 'Borsod-Abaúj-Zemplén', code: 'BZ' },
          { name: 'Budapest', code: 'BU' },
          { name: 'Csongrád County', code: 'CS' },
          { name: 'Debrecen', code: 'DE' },
          { name: 'Dunaújváros', code: 'DU' },
          { name: 'Eger', code: 'EG' },
          { name: 'Érd', code: 'ER' },
          { name: 'Fejér County', code: 'FE' },
          { name: 'Győr', code: 'GY' },
          { name: 'Győr-Moson-Sopron County', code: 'GS' },
          { name: 'Hajdú-Bihar County', code: 'HB' },
          { name: 'Heves County', code: 'HE' },
          { name: 'Hódmezővásárhely', code: 'HV' },
          { name: 'Jász-Nagykun-Szolnok County', code: 'JN' },
          { name: 'Kaposvár', code: 'KV' },
          { name: 'Kecskemét', code: 'KM' },
          { name: 'Komárom-Esztergom', code: 'KE' },
          { name: 'Miskolc', code: 'MI' },
          { name: 'Nagykanizsa', code: 'NK' },
          { name: 'Nógrád County', code: 'NO' },
          { name: 'Nyíregyháza', code: 'NY' },
          { name: 'Pécs', code: 'PS' },
          { name: 'Pest County', code: 'PE' },
          { name: 'Salgótarján', code: 'ST' },
          { name: 'Somogy County', code: 'SO' },
          { name: 'Sopron', code: 'SN' },
          { name: 'Szabolcs-Szatmár-Bereg County', code: 'SZ' },
          { name: 'Szeged', code: 'SD' },
          { name: 'Székesfehérvár', code: 'SF' },
          { name: 'Szekszárd', code: 'SS' },
          { name: 'Szolnok', code: 'SK' },
          { name: 'Szombathely', code: 'SH' },
          { name: 'Tatabánya', code: 'TB' },
          { name: 'Tolna County', code: 'TO' },
          { name: 'Vas County', code: 'VA' },
          { name: 'Veszprém', code: 'VM' },
          { name: 'Veszprém County', code: 'VE' },
          { name: 'Zala County', code: 'ZA' },
          { name: 'Zalaegerszeg', code: 'ZE' },
        ],
      },
    ],
    [
      'Iceland',
      {
        name: 'Iceland',
        code: 'IS',
        emoji: '🇮🇸',
        regions: [
          { name: 'Capital Region', code: '1' },
          { name: 'Eastern Region', code: '7' },
          { name: 'Northeastern Region', code: '6' },
          { name: 'Northwestern Region', code: '5' },
          { name: 'Southern Peninsula Region', code: '2' },
          { name: 'Southern Region', code: '8' },
          { name: 'Western Region', code: '3' },
          { name: 'Westfjords', code: '4' },
        ],
      },
    ],
    [
      'India',
      {
        name: 'India',
        code: 'IN',
        emoji: '🇮🇳',
        regions: [
          { name: 'Andaman and Nicobar Islands', code: 'AN' },
          { name: 'Andhra Pradesh', code: 'AP' },
          { name: 'Arunachal Pradesh', code: 'AR' },
          { name: 'Assam', code: 'AS' },
          { name: 'Bihar', code: 'BR' },
          { name: 'Chandigarh', code: 'CH' },
          { name: 'Chhattisgarh', code: 'CT' },
          { name: 'Dadra and Nagar Haveli and Daman and Diu', code: 'DH' },
          { name: 'Delhi', code: 'DL' },
          { name: 'Goa', code: 'GA' },
          { name: 'Gujarat', code: 'GJ' },
          { name: 'Haryana', code: 'HR' },
          { name: 'Himachal Pradesh', code: 'HP' },
          { name: 'Jammu and Kashmir', code: 'JK' },
          { name: 'Jharkhand', code: 'JH' },
          { name: 'Karnataka', code: 'KA' },
          { name: 'Kerala', code: 'KL' },
          { name: 'Ladakh', code: 'LA' },
          { name: 'Lakshadweep', code: 'LD' },
          { name: 'Madhya Pradesh', code: 'MP' },
          { name: 'Maharashtra', code: 'MH' },
          { name: 'Manipur', code: 'MN' },
          { name: 'Meghalaya', code: 'ML' },
          { name: 'Mizoram', code: 'MZ' },
          { name: 'Nagaland', code: 'NL' },
          { name: 'Odisha', code: 'OR' },
          { name: 'Puducherry', code: 'PY' },
          { name: 'Punjab', code: 'PB' },
          { name: 'Rajasthan', code: 'RJ' },
          { name: 'Sikkim', code: 'SK' },
          { name: 'Tamil Nadu', code: 'TN' },
          { name: 'Telangana', code: 'TG' },
          { name: 'Tripura', code: 'TR' },
          { name: 'Uttar Pradesh', code: 'UP' },
          { name: 'Uttarakhand', code: 'UK' },
          { name: 'West Bengal', code: 'WB' },
        ],
      },
    ],
    [
      'Indonesia',
      {
        name: 'Indonesia',
        code: 'ID',
        emoji: '🇮🇩',
        regions: [
          { name: 'Aceh', code: 'AC' },
          { name: 'Bali', code: 'BA' },
          { name: 'Banten', code: 'BT' },
          { name: 'Bengkulu', code: 'BE' },
          { name: 'DI Yogyakarta', code: 'YO' },
          { name: 'DKI Jakarta', code: 'JK' },
          { name: 'Gorontalo', code: 'GO' },
          { name: 'Jambi', code: 'JA' },
          { name: 'Jawa Barat', code: 'JB' },
          { name: 'Jawa Tengah', code: 'JT' },
          { name: 'Jawa Timur', code: 'JI' },
          { name: 'Kalimantan Barat', code: 'KB' },
          { name: 'Kalimantan Selatan', code: 'KS' },
          { name: 'Kalimantan Tengah', code: 'KT' },
          { name: 'Kalimantan Timur', code: 'KI' },
          { name: 'Kalimantan Utara', code: 'KU' },
          { name: 'Kepulauan Bangka Belitung', code: 'BB' },
          { name: 'Kepulauan Riau', code: 'KR' },
          { name: 'Lampung', code: 'LA' },
          { name: 'Maluku', code: 'MA' },
          { name: 'Maluku Utara', code: 'MU' },
          { name: 'Nusa Tenggara Barat', code: 'NB' },
          { name: 'Nusa Tenggara Timur', code: 'NT' },
          { name: 'Papua', code: 'PA' },
          { name: 'Papua Barat', code: 'PB' },
          { name: 'Riau', code: 'RI' },
          { name: 'Sulawesi Barat', code: 'SR' },
          { name: 'Sulawesi Selatan', code: 'SN' },
          { name: 'Sulawesi Tengah', code: 'ST' },
          { name: 'Sulawesi Tenggara', code: 'SG' },
          { name: 'Sulawesi Utara', code: 'SA' },
          { name: 'Sumatera Barat', code: 'SB' },
          { name: 'Sumatera Selatan', code: 'SS' },
          { name: 'Sumatera Utara', code: 'SU' },
        ],
      },
    ],
    [
      'Iran',
      {
        name: 'Iran',
        code: 'IR',
        emoji: '🇮🇷',
        regions: [
          { name: 'Alborz', code: '30' },
          { name: 'Ardabil', code: '24' },
          { name: 'Bushehr', code: '18' },
          { name: 'Chaharmahal and Bakhtiari', code: '14' },
          { name: 'East Azerbaijan', code: '03' },
          { name: 'Fars', code: '07' },
          { name: 'Gilan', code: '01' },
          { name: 'Golestan', code: '27' },
          { name: 'Hamadan', code: '13' },
          { name: 'Hormozgan', code: '22' },
          { name: 'Ilam', code: '16' },
          { name: 'Isfahan', code: '10' },
          { name: 'Kerman', code: '08' },
          { name: 'Kermanshah', code: '05' },
          { name: 'Khuzestan', code: '06' },
          { name: 'Kohgiluyeh and Boyer-Ahmad', code: '17' },
          { name: 'Kurdistan', code: '12' },
          { name: 'Lorestan', code: '15' },
          { name: 'Markazi', code: '00' },
          { name: 'Mazandaran', code: '02' },
          { name: 'North Khorasan', code: '28' },
          { name: 'Qazvin', code: '26' },
          { name: 'Qom', code: '25' },
          { name: 'Razavi Khorasan', code: '09' },
          { name: 'Semnan', code: '20' },
          { name: 'Sistan and Baluchestan', code: '11' },
          { name: 'South Khorasan', code: '29' },
          { name: 'Tehran', code: '23' },
          { name: 'West Azarbaijan', code: '04' },
          { name: 'Yazd', code: '21' },
          { name: 'Zanjan', code: '19' },
        ],
      },
    ],
    [
      'Iraq',
      {
        name: 'Iraq',
        code: 'IQ',
        emoji: '🇮🇶',
        regions: [
          { name: 'Al Anbar', code: 'AN' },
          { name: 'Al Muthanna', code: 'MU' },
          { name: 'Al-Qādisiyyah', code: 'QA' },
          { name: 'Babylon', code: 'BB' },
          { name: 'Baghdad', code: 'BG' },
          { name: 'Basra', code: 'BA' },
          { name: 'Dhi Qar', code: 'DQ' },
          { name: 'Diyala', code: 'DI' },
          { name: 'Dohuk', code: 'DA' },
          { name: 'Erbil', code: 'AR' },
          { name: 'Karbala', code: 'KA' },
          { name: 'Kirkuk', code: 'KI' },
          { name: 'Maysan', code: 'MA' },
          { name: 'Najaf', code: 'NA' },
          { name: 'Nineveh', code: 'NI' },
          { name: 'Saladin', code: 'SD' },
          { name: 'Sulaymaniyah', code: 'SU' },
          { name: 'Wasit', code: 'WA' },
        ],
      },
    ],
    [
      'Ireland',
      {
        name: 'Ireland',
        code: 'IE',
        emoji: '🇮🇪',
        regions: [
          { name: 'Carlow', code: 'CW' },
          { name: 'Cavan', code: 'CN' },
          { name: 'Clare', code: 'CE' },
          { name: 'Connacht', code: 'C' },
          { name: 'Cork', code: 'CO' },
          { name: 'Donegal', code: 'DL' },
          { name: 'Dublin', code: 'D' },
          { name: 'Galway', code: 'G' },
          { name: 'Kerry', code: 'KY' },
          { name: 'Kildare', code: 'KE' },
          { name: 'Kilkenny', code: 'KK' },
          { name: 'Laois', code: 'LS' },
          { name: 'Leinster', code: 'L' },
          { name: 'Limerick', code: 'LK' },
          { name: 'Longford', code: 'LD' },
          { name: 'Louth', code: 'LH' },
          { name: 'Mayo', code: 'MO' },
          { name: 'Meath', code: 'MH' },
          { name: 'Monaghan', code: 'MN' },
          { name: 'Munster', code: 'M' },
          { name: 'Offaly', code: 'OY' },
          { name: 'Roscommon', code: 'RN' },
          { name: 'Sligo', code: 'SO' },
          { name: 'Tipperary', code: 'TA' },
          { name: 'Ulster', code: 'U' },
          { name: 'Waterford', code: 'WD' },
          { name: 'Westmeath', code: 'WH' },
          { name: 'Wexford', code: 'WX' },
          { name: 'Wicklow', code: 'WW' },
        ],
      },
    ],
    [
      'Israel',
      {
        name: 'Israel',
        code: 'IL',
        emoji: '🇮🇱',
        regions: [
          { name: 'Central District', code: 'M' },
          { name: 'Haifa District', code: 'HA' },
          { name: 'Jerusalem District', code: 'JM' },
          { name: 'Northern District', code: 'Z' },
          { name: 'Southern District', code: 'D' },
          { name: 'Tel Aviv District', code: 'TA' },
        ],
      },
    ],
    [
      'Italy',
      {
        name: 'Italy',
        code: 'IT',
        emoji: '🇮🇹',
        regions: [
          { name: 'Abruzzo', code: '65' },
          { name: 'Agrigento', code: 'AG' },
          { name: 'Alessandria', code: 'AL' },
          { name: 'Ancona', code: 'AN' },
          { name: 'Aosta Valley', code: '23' },
          { name: 'Apulia', code: '75' },
          { name: 'Ascoli Piceno', code: 'AP' },
          { name: 'Asti', code: 'AT' },
          { name: 'Avellino', code: 'AV' },
          { name: 'Barletta-Andria-Trani', code: 'BT' },
          { name: 'Basilicata', code: '77' },
          { name: 'Belluno', code: 'BL' },
          { name: 'Benevento', code: 'BN' },
          { name: 'Bergamo', code: 'BG' },
          { name: 'Biella', code: 'BI' },
          { name: 'Brescia', code: 'BS' },
          { name: 'Brindisi', code: 'BR' },
          { name: 'Calabria', code: '78' },
          { name: 'Caltanissetta', code: 'CL' },
          { name: 'Campania', code: '72' },
          { name: 'Campobasso', code: 'CB' },
          { name: 'Caserta', code: 'CE' },
          { name: 'Catanzaro', code: 'CZ' },
          { name: 'Chieti', code: 'CH' },
          { name: 'Como', code: 'CO' },
          { name: 'Cosenza', code: 'CS' },
          { name: 'Cremona', code: 'CR' },
          { name: 'Crotone', code: 'KR' },
          { name: 'Cuneo', code: 'CN' },
          { name: 'Emilia-Romagna', code: '45' },
          { name: 'Enna', code: 'EN' },
          { name: 'Fermo', code: 'FM' },
          { name: 'Ferrara', code: 'FE' },
          { name: 'Foggia', code: 'FG' },
          { name: 'Forlì-Cesena', code: 'FC' },
          { name: 'Friuli–Venezia Giulia', code: '36' },
          { name: 'Frosinone', code: 'FR' },
          { name: 'Gorizia', code: 'GO' },
          { name: 'Grosseto', code: 'GR' },
          { name: 'Imperia', code: 'IM' },
          { name: 'Isernia', code: 'IS' },
          { name: `L'Aquila`, code: 'AQ' },
          { name: 'La Spezia', code: 'SP' },
          { name: 'Latina', code: 'LT' },
          { name: 'Lazio', code: '62' },
          { name: 'Lecce', code: 'LE' },
          { name: 'Lecco', code: 'LC' },
          { name: 'Liguria', code: '42' },
          { name: 'Livorno', code: 'LI' },
          { name: 'Lodi', code: 'LO' },
          { name: 'Lombardy', code: '25' },
          { name: 'Lucca', code: 'LU' },
          { name: 'Macerata', code: 'MC' },
          { name: 'Mantua', code: 'MN' },
          { name: 'Marche', code: '57' },
          { name: 'Massa and Carrara', code: 'MS' },
          { name: 'Matera', code: 'MT' },
          { name: 'Medio Campidano', code: 'VS' },
          { name: 'Modena', code: 'MO' },
          { name: 'Molise', code: '67' },
          { name: 'Monza and Brianza', code: 'MB' },
          { name: 'Novara', code: 'NO' },
          { name: 'Nuoro', code: 'NU' },
          { name: 'Oristano', code: 'OR' },
          { name: 'Padua', code: 'PD' },
          { name: 'Palermo', code: 'PA' },
          { name: 'Parma', code: 'PR' },
          { name: 'Pavia', code: 'PV' },
          { name: 'Perugia', code: 'PG' },
          { name: 'Pesaro and Urbino', code: 'PU' },
          { name: 'Pescara', code: 'PE' },
          { name: 'Piacenza', code: 'PC' },
          { name: 'Piedmont', code: '21' },
          { name: 'Pisa', code: 'PI' },
          { name: 'Pistoia', code: 'PT' },
          { name: 'Pordenone', code: 'PN' },
          { name: 'Potenza', code: 'PZ' },
          { name: 'Prato', code: 'PO' },
          { name: 'Ragusa', code: 'RG' },
          { name: 'Ravenna', code: 'RA' },
          { name: 'Reggio Emilia', code: 'RE' },
          { name: 'Rieti', code: 'RI' },
          { name: 'Rimini', code: 'RN' },
          { name: 'Rovigo', code: 'RO' },
          { name: 'Salerno', code: 'SA' },
          { name: 'Sardinia', code: '88' },
          { name: 'Sassari', code: 'SS' },
          { name: 'Savona', code: 'SV' },
          { name: 'Sicily', code: '82' },
          { name: 'Siena', code: 'SI' },
          { name: 'Siracusa', code: 'SR' },
          { name: 'Sondrio', code: 'SO' },
          { name: 'South Sardinia', code: 'SU' },
          { name: 'Taranto', code: 'TA' },
          { name: 'Teramo', code: 'TE' },
          { name: 'Terni', code: 'TR' },
          { name: 'Trapani', code: 'TP' },
          { name: 'Trentino-South Tyrol', code: '32' },
          { name: 'Treviso', code: 'TV' },
          { name: 'Trieste', code: 'TS' },
          { name: 'Tuscany', code: '52' },
          { name: 'Udine', code: 'UD' },
          { name: 'Umbria', code: '55' },
          { name: 'Varese', code: 'VA' },
          { name: 'Veneto', code: '34' },
          { name: 'Verbano-Cusio-Ossola', code: 'VB' },
          { name: 'Vercelli', code: 'VC' },
          { name: 'Verona', code: 'VR' },
          { name: 'Vibo Valentia', code: 'VV' },
          { name: 'Vicenza', code: 'VI' },
          { name: 'Viterbo', code: 'VT' },
        ],
      },
    ],
    [
      'Jamaica',
      {
        name: 'Jamaica',
        code: 'JM',
        emoji: '🇯🇲',
        regions: [
          { name: 'Clarendon Parish', code: '13' },
          { name: 'Hanover Parish', code: '09' },
          { name: 'Kingston Parish', code: '01' },
          { name: 'Manchester Parish', code: '12' },
          { name: 'Portland Parish', code: '04' },
          { name: 'Saint Andrew', code: '02' },
          { name: 'Saint Ann Parish', code: '06' },
          { name: 'Saint Catherine Parish', code: '14' },
          { name: 'Saint Elizabeth Parish', code: '11' },
          { name: 'Saint James Parish', code: '08' },
          { name: 'Saint Mary Parish', code: '05' },
          { name: 'Saint Thomas Parish', code: '03' },
          { name: 'Trelawny Parish', code: '07' },
          { name: 'Westmoreland Parish', code: '10' },
        ],
      },
    ],
    [
      'Japan',
      {
        name: 'Japan',
        code: 'JP',
        emoji: '🇯🇵',
        regions: [
          { name: 'Aichi Prefecture', code: '23' },
          { name: 'Akita Prefecture', code: '05' },
          { name: 'Aomori Prefecture', code: '02' },
          { name: 'Chiba Prefecture', code: '12' },
          { name: 'Ehime Prefecture', code: '38' },
          { name: 'Fukui Prefecture', code: '18' },
          { name: 'Fukuoka Prefecture', code: '40' },
          { name: 'Fukushima Prefecture', code: '07' },
          { name: 'Gifu Prefecture', code: '21' },
          { name: 'Gunma Prefecture', code: '10' },
          { name: 'Hiroshima Prefecture', code: '34' },
          { name: 'Hokkaidō Prefecture', code: '01' },
          { name: 'Hyōgo Prefecture', code: '28' },
          { name: 'Ibaraki Prefecture', code: '08' },
          { name: 'Ishikawa Prefecture', code: '17' },
          { name: 'Iwate Prefecture', code: '03' },
          { name: 'Kagawa Prefecture', code: '37' },
          { name: 'Kagoshima Prefecture', code: '46' },
          { name: 'Kanagawa Prefecture', code: '14' },
          { name: 'Kōchi Prefecture', code: '39' },
          { name: 'Kumamoto Prefecture', code: '43' },
          { name: 'Kyōto Prefecture', code: '26' },
          { name: 'Mie Prefecture', code: '24' },
          { name: 'Miyagi Prefecture', code: '04' },
          { name: 'Miyazaki Prefecture', code: '45' },
          { name: 'Nagano Prefecture', code: '20' },
          { name: 'Nagasaki Prefecture', code: '42' },
          { name: 'Nara Prefecture', code: '29' },
          { name: 'Niigata Prefecture', code: '15' },
          { name: 'Ōita Prefecture', code: '44' },
          { name: 'Okayama Prefecture', code: '33' },
          { name: 'Okinawa Prefecture', code: '47' },
          { name: 'Ōsaka Prefecture', code: '27' },
          { name: 'Saga Prefecture', code: '41' },
          { name: 'Saitama Prefecture', code: '11' },
          { name: 'Shiga Prefecture', code: '25' },
          { name: 'Shimane Prefecture', code: '32' },
          { name: 'Shizuoka Prefecture', code: '22' },
          { name: 'Tochigi Prefecture', code: '09' },
          { name: 'Tokushima Prefecture', code: '36' },
          { name: 'Tokyo', code: '13' },
          { name: 'Tottori Prefecture', code: '31' },
          { name: 'Toyama Prefecture', code: '16' },
          { name: 'Wakayama Prefecture', code: '30' },
          { name: 'Yamagata Prefecture', code: '06' },
          { name: 'Yamaguchi Prefecture', code: '35' },
          { name: 'Yamanashi Prefecture', code: '19' },
        ],
      },
    ],
    [
      'Jersey',
      {
        name: 'Jersey',
        code: 'JE',
        emoji: '🇯🇪',
        regions: [],
      },
    ],
    [
      'Jordan',
      {
        name: 'Jordan',
        code: 'JO',
        emoji: '🇯🇴',
        regions: [
          { name: 'Ajloun', code: 'AJ' },
          { name: 'Amman', code: 'AM' },
          { name: 'Aqaba', code: 'AQ' },
          { name: 'Balqa', code: 'BA' },
          { name: 'Irbid', code: 'IR' },
          { name: 'Jerash', code: 'JA' },
          { name: 'Karak', code: 'KA' },
          { name: `Ma'an`, code: 'MN' },
          { name: 'Madaba', code: 'MD' },
          { name: 'Mafraq', code: 'MA' },
          { name: 'Tafilah', code: 'AT' },
          { name: 'Zarqa', code: 'AZ' },
        ],
      },
    ],
    [
      'Kazakhstan',
      {
        name: 'Kazakhstan',
        code: 'KZ',
        emoji: '🇰🇿',
        regions: [
          { name: 'Akmola Region', code: 'AKM' },
          { name: 'Aktobe Region', code: 'AKT' },
          { name: 'Almaty', code: 'ALA' },
          { name: 'Almaty Region', code: 'ALM' },
          { name: 'Atyrau Region', code: 'ATY' },
          { name: 'Baikonur', code: 'BAY' },
          { name: 'East Kazakhstan Region', code: 'VOS' },
          { name: 'Jambyl Region', code: 'ZHA' },
          { name: 'Karaganda Region', code: 'KAR' },
          { name: 'Kostanay Region', code: 'KUS' },
          { name: 'Kyzylorda Region', code: 'KZY' },
          { name: 'Mangystau Region', code: 'MAN' },
          { name: 'North Kazakhstan Region', code: 'SEV' },
          { name: 'Nur-Sultan', code: 'AST' },
          { name: 'Pavlodar Region', code: 'PAV' },
          { name: 'Turkestan Region', code: 'YUZ' },
          { name: 'West Kazakhstan Province', code: 'ZAP' },
        ],
      },
    ],
    [
      'Kenya',
      {
        name: 'Kenya',
        code: 'KE',
        emoji: '🇰🇪',
        regions: [
          { name: 'Baringo', code: '01' },
          { name: 'Bomet', code: '02' },
          { name: 'Bungoma', code: '03' },
          { name: 'Busia', code: '04' },
          { name: 'Elgeyo-Marakwet', code: '05' },
          { name: 'Embu', code: '06' },
          { name: 'Garissa', code: '07' },
          { name: 'Homa Bay', code: '08' },
          { name: 'Isiolo', code: '09' },
          { name: 'Kajiado', code: '10' },
          { name: 'Kakamega', code: '11' },
          { name: 'Kericho', code: '12' },
          { name: 'Kiambu', code: '13' },
          { name: 'Kilifi', code: '14' },
          { name: 'Kirinyaga', code: '15' },
          { name: 'Kisii', code: '16' },
          { name: 'Kisumu', code: '17' },
          { name: 'Kitui', code: '18' },
          { name: 'Kwale', code: '19' },
          { name: 'Laikipia', code: '20' },
          { name: 'Lamu', code: '21' },
          { name: 'Machakos', code: '22' },
          { name: 'Makueni', code: '23' },
          { name: 'Mandera', code: '24' },
          { name: 'Marsabit', code: '25' },
          { name: 'Meru', code: '26' },
          { name: 'Migori', code: '27' },
          { name: 'Mombasa', code: '28' },
          { name: `Murang'a`, code: '29' },
          { name: 'Nairobi City', code: '30' },
          { name: 'Nakuru', code: '31' },
          { name: 'Nandi', code: '32' },
          { name: 'Narok', code: '33' },
          { name: 'Nyamira', code: '34' },
          { name: 'Nyandarua', code: '35' },
          { name: 'Nyeri', code: '36' },
          { name: 'Samburu', code: '37' },
          { name: 'Siaya', code: '38' },
          { name: 'Taita–Taveta', code: '39' },
          { name: 'Tana River', code: '40' },
          { name: 'Tharaka-Nithi', code: '41' },
          { name: 'Trans Nzoia', code: '42' },
          { name: 'Turkana', code: '43' },
          { name: 'Uasin Gishu', code: '44' },
          { name: 'Vihiga', code: '45' },
          { name: 'Wajir', code: '46' },
          { name: 'West Pokot', code: '47' },
        ],
      },
    ],
    [
      'Kiribati',
      {
        name: 'Kiribati',
        code: 'KI',
        emoji: '🇰🇮',
        regions: [
          { name: 'Gilbert Islands', code: 'G' },
          { name: 'Line Islands', code: 'L' },
          { name: 'Phoenix Islands', code: 'P' },
        ],
      },
    ],
    [
      'Kosovo',
      {
        name: 'Kosovo',
        code: 'XK',
        emoji: '🇽🇰',
        regions: [
          { name: 'Đakovica District (Gjakove)', code: 'XDG' },
          { name: 'Gjilan District', code: 'XGJ' },
          { name: 'Kosovska Mitrovica District', code: 'XKM' },
          { name: 'Peć District', code: 'XPE' },
          { name: 'Pristina (Priştine)', code: 'XPI' },
          { name: 'Prizren District', code: 'XPR' },
          { name: 'Uroševac District (Ferizaj)', code: 'XUF' },
        ],
      },
    ],
    [
      'Kuwait',
      {
        name: 'Kuwait',
        code: 'KW',
        emoji: '🇰🇼',
        regions: [
          { name: 'Al Ahmadi', code: 'AH' },
          { name: 'Al Farwaniyah', code: 'FA' },
          { name: 'Al Jahra', code: 'JA' },
          { name: 'Capital', code: 'KU' },
          { name: 'Hawalli', code: 'HA' },
          { name: 'Mubarak Al-Kabeer', code: 'MU' },
        ],
      },
    ],
    [
      'Kyrgyzstan',
      {
        name: 'Kyrgyzstan',
        code: 'KG',
        emoji: '🇰🇬',
        regions: [
          { name: 'Batken Region', code: 'B' },
          { name: 'Bishkek', code: 'GB' },
          { name: 'Chuy Region', code: 'C' },
          { name: 'Issyk-Kul Region', code: 'Y' },
          { name: 'Jalal-Abad Region', code: 'J' },
          { name: 'Naryn Region', code: 'N' },
          { name: 'Osh', code: 'GO' },
          { name: 'Osh Region', code: 'O' },
          { name: 'Talas Region', code: 'T' },
        ],
      },
    ],
    [
      'Laos',
      {
        name: 'Laos',
        code: 'LA',
        emoji: '🇱🇦',
        regions: [
          { name: 'Attapeu Province', code: 'AT' },
          { name: 'Bokeo Province', code: 'BK' },
          { name: 'Bolikhamsai Province', code: 'BL' },
          { name: 'Champasak Province', code: 'CH' },
          { name: 'Houaphanh Province', code: 'HO' },
          { name: 'Khammouane Province', code: 'KH' },
          { name: 'Luang Namtha Province', code: 'LM' },
          { name: 'Luang Prabang Province', code: 'LP' },
          { name: 'Oudomxay Province', code: 'OU' },
          { name: 'Phongsaly Province', code: 'PH' },
          { name: 'Sainyabuli Province', code: 'XA' },
          { name: 'Salavan Province', code: 'SL' },
          { name: 'Savannakhet Province', code: 'SV' },
          { name: 'Sekong Province', code: 'XE' },
          { name: 'Vientiane Prefecture', code: 'VT' },
          { name: 'Vientiane Province', code: 'VI' },
          { name: 'Xaisomboun', code: 'XN' },
          { name: 'Xaisomboun Province', code: 'XS' },
          { name: 'Xiangkhouang Province', code: 'XI' },
        ],
      },
    ],
    [
      'Latvia',
      {
        name: 'Latvia',
        code: 'LV',
        emoji: '🇱🇻',
        regions: [
          { name: 'Aglona Municipality', code: '001' },
          { name: 'Aizkraukle Municipality', code: '002' },
          { name: 'Aizpute Municipality', code: '003' },
          { name: 'Aknīste Municipality', code: '004' },
          { name: 'Aloja Municipality', code: '005' },
          { name: 'Alsunga Municipality', code: '006' },
          { name: 'Alūksne Municipality', code: '007' },
          { name: 'Amata Municipality', code: '008' },
          { name: 'Ape Municipality', code: '009' },
          { name: 'Auce Municipality', code: '010' },
          { name: 'Babīte Municipality', code: '012' },
          { name: 'Baldone Municipality', code: '013' },
          { name: 'Baltinava Municipality', code: '014' },
          { name: 'Balvi Municipality', code: '015' },
          { name: 'Bauska Municipality', code: '016' },
          { name: 'Beverīna Municipality', code: '017' },
          { name: 'Brocēni Municipality', code: '018' },
          { name: 'Burtnieki Municipality', code: '019' },
          { name: 'Carnikava Municipality', code: '020' },
          { name: 'Cēsis Municipality', code: '022' },
          { name: 'Cesvaine Municipality', code: '021' },
          { name: 'Cibla Municipality', code: '023' },
          { name: 'Dagda Municipality', code: '024' },
          { name: 'Daugavpils', code: 'DGV' },
          { name: 'Daugavpils Municipality', code: '025' },
          { name: 'Dobele Municipality', code: '026' },
          { name: 'Dundaga Municipality', code: '027' },
          { name: 'Durbe Municipality', code: '028' },
          { name: 'Engure Municipality', code: '029' },
          { name: 'Ērgļi Municipality', code: '030' },
          { name: 'Garkalne Municipality', code: '031' },
          { name: 'Grobiņa Municipality', code: '032' },
          { name: 'Gulbene Municipality', code: '033' },
          { name: 'Iecava Municipality', code: '034' },
          { name: 'Ikšķile Municipality', code: '035' },
          { name: 'Ilūkste Municipality', code: '036' },
          { name: 'Inčukalns Municipality', code: '037' },
          { name: 'Jaunjelgava Municipality', code: '038' },
          { name: 'Jaunpiebalga Municipality', code: '039' },
          { name: 'Jaunpils Municipality', code: '040' },
          { name: 'Jēkabpils', code: 'JKB' },
          { name: 'Jēkabpils Municipality', code: '042' },
          { name: 'Jelgava', code: 'JEL' },
          { name: 'Jelgava Municipality', code: '041' },
          { name: 'Jūrmala', code: 'JUR' },
          { name: 'Kandava Municipality', code: '043' },
          { name: 'Kārsava Municipality', code: '044' },
          { name: 'Ķegums Municipality', code: '051' },
          { name: 'Ķekava Municipality', code: '052' },
          { name: 'Kocēni Municipality', code: '045' },
          { name: 'Koknese Municipality', code: '046' },
          { name: 'Krāslava Municipality', code: '047' },
          { name: 'Krimulda Municipality', code: '048' },
          { name: 'Krustpils Municipality', code: '049' },
          { name: 'Kuldīga Municipality', code: '050' },
          { name: 'Lielvārde Municipality', code: '053' },
          { name: 'Liepāja', code: 'LPX' },
          { name: 'Līgatne Municipality', code: '055' },
          { name: 'Limbaži Municipality', code: '054' },
          { name: 'Līvāni Municipality', code: '056' },
          { name: 'Lubāna Municipality', code: '057' },
          { name: 'Ludza Municipality', code: '058' },
          { name: 'Madona Municipality', code: '059' },
          { name: 'Mālpils Municipality', code: '061' },
          { name: 'Mārupe Municipality', code: '062' },
          { name: 'Mazsalaca Municipality', code: '060' },
          { name: 'Mērsrags Municipality', code: '063' },
          { name: 'Naukšēni Municipality', code: '064' },
          { name: 'Nereta Municipality', code: '065' },
          { name: 'Nīca Municipality', code: '066' },
          { name: 'Ogre Municipality', code: '067' },
          { name: 'Olaine Municipality', code: '068' },
          { name: 'Ozolnieki Municipality', code: '069' },
          { name: 'Pārgauja Municipality', code: '070' },
          { name: 'Pāvilosta Municipality', code: '071' },
          { name: 'Pļaviņas Municipality', code: '072' },
          { name: 'Preiļi Municipality', code: '073' },
          { name: 'Priekule Municipality', code: '074' },
          { name: 'Priekuļi Municipality', code: '075' },
          { name: 'Rauna Municipality', code: '076' },
          { name: 'Rēzekne', code: 'REZ' },
          { name: 'Rēzekne Municipality', code: '077' },
          { name: 'Riebiņi Municipality', code: '078' },
          { name: 'Riga', code: 'RIX' },
          { name: 'Roja Municipality', code: '079' },
          { name: 'Ropaži Municipality', code: '080' },
          { name: 'Rucava Municipality', code: '081' },
          { name: 'Rugāji Municipality', code: '082' },
          { name: 'Rūjiena Municipality', code: '084' },
          { name: 'Rundāle Municipality', code: '083' },
          { name: 'Sala Municipality', code: '085' },
          { name: 'Salacgrīva Municipality', code: '086' },
          { name: 'Salaspils Municipality', code: '087' },
          { name: 'Saldus Municipality', code: '088' },
          { name: 'Saulkrasti Municipality', code: '089' },
          { name: 'Sēja Municipality', code: '090' },
          { name: 'Sigulda Municipality', code: '091' },
          { name: 'Skrīveri Municipality', code: '092' },
          { name: 'Skrunda Municipality', code: '093' },
          { name: 'Smiltene Municipality', code: '094' },
          { name: 'Stopiņi Municipality', code: '095' },
          { name: 'Strenči Municipality', code: '096' },
          { name: 'Talsi Municipality', code: '097' },
          { name: 'Tērvete Municipality', code: '098' },
          { name: 'Tukums Municipality', code: '099' },
          { name: 'Vaiņode Municipality', code: '100' },
          { name: 'Valka Municipality', code: '101' },
          { name: 'Valmiera', code: 'VMR' },
          { name: 'Varakļāni Municipality', code: '102' },
          { name: 'Vārkava Municipality', code: '103' },
          { name: 'Vecpiebalga Municipality', code: '104' },
          { name: 'Vecumnieki Municipality', code: '105' },
          { name: 'Ventspils', code: 'VEN' },
          { name: 'Ventspils Municipality', code: '106' },
          { name: 'Viesīte Municipality', code: '107' },
          { name: 'Viļaka Municipality', code: '108' },
          { name: 'Viļāni Municipality', code: '109' },
          { name: 'Zilupe Municipality', code: '110' },
        ],
      },
    ],
    [
      'Lebanon',
      {
        name: 'Lebanon',
        code: 'LB',
        emoji: '🇱🇧',
        regions: [
          { name: 'Akkar', code: 'AK' },
          { name: 'Baalbek-Hermel', code: 'BH' },
          { name: 'Beirut', code: 'BA' },
          { name: 'Beqaa', code: 'BI' },
          { name: 'Mount Lebanon', code: 'JL' },
          { name: 'Nabatieh', code: 'NA' },
          { name: 'North', code: 'AS' },
          { name: 'South', code: 'JA' },
        ],
      },
    ],
    [
      'Lesotho',
      {
        name: 'Lesotho',
        code: 'LS',
        emoji: '🇱🇸',
        regions: [
          { name: 'Berea District', code: 'D' },
          { name: 'Butha-Buthe District', code: 'B' },
          { name: 'Leribe District', code: 'C' },
          { name: 'Mafeteng District', code: 'E' },
          { name: 'Maseru District', code: 'A' },
          { name: `Mohale's Hoek District`, code: 'F' },
          { name: 'Mokhotlong District', code: 'J' },
          { name: `Qacha's Nek District`, code: 'H' },
          { name: 'Quthing District', code: 'G' },
          { name: 'Thaba-Tseka District', code: 'K' },
        ],
      },
    ],
    [
      'Liberia',
      {
        name: 'Liberia',
        code: 'LR',
        emoji: '🇱🇷',
        regions: [
          { name: 'Bomi County', code: 'BM' },
          { name: 'Bong County', code: 'BG' },
          { name: 'Gbarpolu County', code: 'GP' },
          { name: 'Grand Bassa County', code: 'GB' },
          { name: 'Grand Cape Mount County', code: 'CM' },
          { name: 'Grand Gedeh County', code: 'GG' },
          { name: 'Grand Kru County', code: 'GK' },
          { name: 'Lofa County', code: 'LO' },
          { name: 'Margibi County', code: 'MG' },
          { name: 'Maryland County', code: 'MY' },
          { name: 'Montserrado County', code: 'MO' },
          { name: 'Nimba', code: 'NI' },
          { name: 'River Cess County', code: 'RI' },
          { name: 'River Gee County', code: 'RG' },
          { name: 'Sinoe County', code: 'SI' },
        ],
      },
    ],
    [
      'Libya',
      {
        name: 'Libya',
        code: 'LY',
        emoji: '🇱🇾',
        regions: [
          { name: 'Al Wahat District', code: 'WA' },
          { name: 'Benghazi', code: 'BA' },
          { name: 'Derna District', code: 'DR' },
          { name: 'Ghat District', code: 'GT' },
          { name: 'Jabal al Akhdar', code: 'JA' },
          { name: 'Jabal al Gharbi District', code: 'JG' },
          { name: 'Jafara', code: 'JI' },
          { name: 'Jufra', code: 'JU' },
          { name: 'Kufra District', code: 'KF' },
          { name: 'Marj District', code: 'MJ' },
          { name: 'Misrata District', code: 'MI' },
          { name: 'Murqub', code: 'MB' },
          { name: 'Murzuq District', code: 'MQ' },
          { name: 'Nalut District', code: 'NL' },
          { name: 'Nuqat al Khams', code: 'NQ' },
          { name: 'Sabha District', code: 'SB' },
          { name: 'Sirte District', code: 'SR' },
          { name: 'Tripoli District', code: 'TB' },
          { name: 'Wadi al Hayaa District', code: 'WD' },
          { name: 'Wadi al Shatii District', code: 'WS' },
          { name: 'Zawiya District', code: 'ZA' },
        ],
      },
    ],
    [
      'Liechtenstein',
      {
        name: 'Liechtenstein',
        code: 'LI',
        emoji: '🇱🇮',
        regions: [
          { name: 'Balzers', code: '01' },
          { name: 'Eschen', code: '02' },
          { name: 'Gamprin', code: '03' },
          { name: 'Mauren', code: '04' },
          { name: 'Planken', code: '05' },
          { name: 'Ruggell', code: '06' },
          { name: 'Schaan', code: '07' },
          { name: 'Schellenberg', code: '08' },
          { name: 'Triesen', code: '09' },
          { name: 'Triesenberg', code: '10' },
          { name: 'Vaduz', code: '11' },
        ],
      },
    ],
    [
      'Lithuania',
      {
        name: 'Lithuania',
        code: 'LT',
        emoji: '🇱🇹',
        regions: [
          { name: 'Akmenė District Municipality', code: '01' },
          { name: 'Alytus City Municipality', code: '02' },
          { name: 'Alytus County', code: 'AL' },
          { name: 'Alytus District Municipality', code: '03' },
          { name: 'Birštonas Municipality', code: '05' },
          { name: 'Biržai District Municipality', code: '06' },
          { name: 'Druskininkai municipality', code: '07' },
          { name: 'Elektrėnai municipality', code: '08' },
          { name: 'Ignalina District Municipality', code: '09' },
          { name: 'Jonava District Municipality', code: '10' },
          { name: 'Joniškis District Municipality', code: '11' },
          { name: 'Jurbarkas District Municipality', code: '12' },
          { name: 'Kaišiadorys District Municipality', code: '13' },
          { name: 'Kalvarija municipality', code: '14' },
          { name: 'Kaunas City Municipality', code: '15' },
          { name: 'Kaunas County', code: 'KU' },
          { name: 'Kaunas District Municipality', code: '16' },
          { name: 'Kazlų Rūda municipality', code: '17' },
          { name: 'Kėdainiai District Municipality', code: '18' },
          { name: 'Kelmė District Municipality', code: '19' },
          { name: 'Klaipeda City Municipality', code: '20' },
          { name: 'Klaipėda County', code: 'KL' },
          { name: 'Klaipėda District Municipality', code: '21' },
          { name: 'Kretinga District Municipality', code: '22' },
          { name: 'Kupiškis District Municipality', code: '23' },
          { name: 'Lazdijai District Municipality', code: '24' },
          { name: 'Marijampolė County', code: 'MR' },
          { name: 'Marijampolė Municipality', code: '25' },
          { name: 'Mažeikiai District Municipality', code: '26' },
          { name: 'Molėtai District Municipality', code: '27' },
          { name: 'Neringa Municipality', code: '28' },
          { name: 'Pagėgiai municipality', code: '29' },
          { name: 'Pakruojis District Municipality', code: '30' },
          { name: 'Palanga City Municipality', code: '31' },
          { name: 'Panevėžys City Municipality', code: '32' },
          { name: 'Panevėžys County', code: 'PN' },
          { name: 'Panevėžys District Municipality', code: '33' },
          { name: 'Pasvalys District Municipality', code: '34' },
          { name: 'Plungė District Municipality', code: '35' },
          { name: 'Prienai District Municipality', code: '36' },
          { name: 'Radviliškis District Municipality', code: '37' },
          { name: 'Raseiniai District Municipality', code: '38' },
          { name: 'Rietavas municipality', code: '39' },
          { name: 'Rokiškis District Municipality', code: '40' },
          { name: 'Šakiai District Municipality', code: '41' },
          { name: 'Šalčininkai District Municipality', code: '42' },
          { name: 'Šiauliai City Municipality', code: '43' },
          { name: 'Šiauliai County', code: 'SA' },
          { name: 'Šiauliai District Municipality', code: '44' },
          { name: 'Šilalė District Municipality', code: '45' },
          { name: 'Šilutė District Municipality', code: '46' },
          { name: 'Širvintos District Municipality', code: '47' },
          { name: 'Skuodas District Municipality', code: '48' },
          { name: 'Švenčionys District Municipality', code: '49' },
          { name: 'Tauragė County', code: 'TA' },
          { name: 'Tauragė District Municipality', code: '50' },
          { name: 'Telšiai County', code: 'TE' },
          { name: 'Telšiai District Municipality', code: '51' },
          { name: 'Trakai District Municipality', code: '52' },
          { name: 'Ukmergė District Municipality', code: '53' },
          { name: 'Utena County', code: 'UT' },
          { name: 'Utena District Municipality', code: '54' },
          { name: 'Varėna District Municipality', code: '55' },
          { name: 'Vilkaviškis District Municipality', code: '56' },
          { name: 'Vilnius City Municipality', code: '57' },
          { name: 'Vilnius County', code: 'VL' },
          { name: 'Vilnius District Municipality', code: '58' },
          { name: 'Visaginas Municipality', code: '59' },
          { name: 'Zarasai District Municipality', code: '60' },
        ],
      },
    ],
    [
      'Luxembourg',
      {
        name: 'Luxembourg',
        code: 'LU',
        emoji: '🇱🇺',
        regions: [
          { name: 'Canton of Capellen', code: 'CA' },
          { name: 'Canton of Clervaux', code: 'CL' },
          { name: 'Canton of Diekirch', code: 'DI' },
          { name: 'Canton of Echternach', code: 'EC' },
          { name: 'Canton of Esch-sur-Alzette', code: 'ES' },
          { name: 'Canton of Grevenmacher', code: 'GR' },
          { name: 'Canton of Luxembourg', code: 'LU' },
          { name: 'Canton of Mersch', code: 'ME' },
          { name: 'Canton of Redange', code: 'RD' },
          { name: 'Canton of Remich', code: 'RM' },
          { name: 'Canton of Vianden', code: 'VD' },
          { name: 'Canton of Wiltz', code: 'WI' },
          { name: 'Diekirch District', code: 'D' },
          { name: 'Grevenmacher District', code: 'G' },
          { name: 'Luxembourg District', code: 'L' },
        ],
      },
    ],
    [
      'Macau S.A.R.',
      {
        name: 'Macau S.A.R.',
        code: 'MO',
        emoji: '🇲🇴',
        regions: [],
      },
    ],
    [
      'Madagascar',
      {
        name: 'Madagascar',
        code: 'MG',
        emoji: '🇲🇬',
        regions: [
          { name: 'Antananarivo Province', code: 'T' },
          { name: 'Antsiranana Province', code: 'D' },
          { name: 'Fianarantsoa Province', code: 'F' },
          { name: 'Mahajanga Province', code: 'M' },
          { name: 'Toamasina Province', code: 'A' },
          { name: 'Toliara Province', code: 'U' },
        ],
      },
    ],
    [
      'Malawi',
      {
        name: 'Malawi',
        code: 'MW',
        emoji: '🇲🇼',
        regions: [
          { name: 'Balaka District', code: 'BA' },
          { name: 'Blantyre District', code: 'BL' },
          { name: 'Central Region', code: 'C' },
          { name: 'Chikwawa District', code: 'CK' },
          { name: 'Chiradzulu District', code: 'CR' },
          { name: 'Chitipa district', code: 'CT' },
          { name: 'Dedza District', code: 'DE' },
          { name: 'Dowa District', code: 'DO' },
          { name: 'Karonga District', code: 'KR' },
          { name: 'Kasungu District', code: 'KS' },
          { name: 'Likoma District', code: 'LK' },
          { name: 'Lilongwe District', code: 'LI' },
          { name: 'Machinga District', code: 'MH' },
          { name: 'Mangochi District', code: 'MG' },
          { name: 'Mchinji District', code: 'MC' },
          { name: 'Mulanje District', code: 'MU' },
          { name: 'Mwanza District', code: 'MW' },
          { name: 'Mzimba District', code: 'MZ' },
          { name: 'Nkhata Bay District', code: 'NB' },
          { name: 'Nkhotakota District', code: 'NK' },
          { name: 'Northern Region', code: 'N' },
          { name: 'Nsanje District', code: 'NS' },
          { name: 'Ntcheu District', code: 'NU' },
          { name: 'Ntchisi District', code: 'NI' },
          { name: 'Phalombe District', code: 'PH' },
          { name: 'Rumphi District', code: 'RU' },
          { name: 'Salima District', code: 'SA' },
          { name: 'Southern Region', code: 'S' },
          { name: 'Thyolo District', code: 'TH' },
          { name: 'Zomba District', code: 'ZO' },
        ],
      },
    ],
    [
      'Malaysia',
      {
        name: 'Malaysia',
        code: 'MY',
        emoji: '🇲🇾',
        regions: [
          { name: 'Johor', code: '01' },
          { name: 'Kedah', code: '02' },
          { name: 'Kelantan', code: '03' },
          { name: 'Kuala Lumpur', code: '14' },
          { name: 'Labuan', code: '15' },
          { name: 'Malacca', code: '04' },
          { name: 'Negeri Sembilan', code: '05' },
          { name: 'Pahang', code: '06' },
          { name: 'Penang', code: '07' },
          { name: 'Perak', code: '08' },
          { name: 'Perlis', code: '09' },
          { name: 'Putrajaya', code: '16' },
          { name: 'Sabah', code: '12' },
          { name: 'Sarawak', code: '13' },
          { name: 'Selangor', code: '10' },
          { name: 'Terengganu', code: '11' },
        ],
      },
    ],
    [
      'Maldives',
      {
        name: 'Maldives',
        code: 'MV',
        emoji: '🇲🇻',
        regions: [
          { name: 'Addu Atoll', code: '01' },
          { name: 'Alif Alif Atoll', code: '02' },
          { name: 'Alif Dhaal Atoll', code: '00' },
          { name: 'Central Province', code: 'CE' },
          { name: 'Dhaalu Atoll', code: '17' },
          { name: 'Faafu Atoll', code: '14' },
          { name: 'Gaafu Alif Atoll', code: '27' },
          { name: 'Gaafu Dhaalu Atoll', code: '28' },
          { name: 'Gnaviyani Atoll', code: '29' },
          { name: 'Haa Alif Atoll', code: '07' },
          { name: 'Haa Dhaalu Atoll', code: '23' },
          { name: 'Kaafu Atoll', code: '26' },
          { name: 'Laamu Atoll', code: '05' },
          { name: 'Lhaviyani Atoll', code: '03' },
          { name: 'Malé', code: 'MLE' },
          { name: 'Meemu Atoll', code: '12' },
          { name: 'Noonu Atoll', code: '25' },
          { name: 'North Central Province', code: 'NC' },
          { name: 'North Province', code: 'NO' },
          { name: 'Raa Atoll', code: '13' },
          { name: 'Shaviyani Atoll', code: '24' },
          { name: 'South Central Province', code: 'SC' },
          { name: 'South Province', code: 'SU' },
          { name: 'Thaa Atoll', code: '08' },
          { name: 'Upper South Province', code: 'US' },
          { name: 'Vaavu Atoll', code: '04' },
        ],
      },
    ],
    [
      'Mali',
      {
        name: 'Mali',
        code: 'ML',
        emoji: '🇲🇱',
        regions: [
          { name: 'Bamako', code: 'BKO' },
          { name: 'Gao Region', code: '7' },
          { name: 'Kayes Region', code: '1' },
          { name: 'Kidal Region', code: '8' },
          { name: 'Koulikoro Region', code: '2' },
          { name: 'Ménaka Region', code: '9' },
          { name: 'Mopti Region', code: '5' },
          { name: 'Ségou Region', code: '4' },
          { name: 'Sikasso Region', code: '3' },
          { name: 'Taoudénit Region', code: '10' },
          { name: 'Tombouctou Region', code: '6' },
        ],
      },
    ],
    [
      'Malta',
      {
        name: 'Malta',
        code: 'MT',
        emoji: '🇲🇹',
        regions: [
          { name: 'Attard', code: '01' },
          { name: 'Balzan', code: '02' },
          { name: 'Birgu', code: '03' },
          { name: 'Birkirkara', code: '04' },
          { name: 'Birżebbuġa', code: '05' },
          { name: 'Cospicua', code: '06' },
          { name: 'Dingli', code: '07' },
          { name: 'Fgura', code: '08' },
          { name: 'Floriana', code: '09' },
          { name: 'Fontana', code: '10' },
          { name: 'Għajnsielem', code: '13' },
          { name: 'Għarb', code: '14' },
          { name: 'Għargħur', code: '15' },
          { name: 'Għasri', code: '16' },
          { name: 'Għaxaq', code: '17' },
          { name: 'Gudja', code: '11' },
          { name: 'Gżira', code: '12' },
          { name: 'Ħamrun', code: '18' },
          { name: 'Iklin', code: '19' },
          { name: 'Kalkara', code: '21' },
          { name: 'Kerċem', code: '22' },
          { name: 'Kirkop', code: '23' },
          { name: 'Lija', code: '24' },
          { name: 'Luqa', code: '25' },
          { name: 'Marsa', code: '26' },
          { name: 'Marsaskala', code: '27' },
          { name: 'Marsaxlokk', code: '28' },
          { name: 'Mdina', code: '29' },
          { name: 'Mellieħa', code: '30' },
          { name: 'Mġarr', code: '31' },
          { name: 'Mosta', code: '32' },
          { name: 'Mqabba', code: '33' },
          { name: 'Msida', code: '34' },
          { name: 'Mtarfa', code: '35' },
          { name: 'Munxar', code: '36' },
          { name: 'Nadur', code: '37' },
          { name: 'Naxxar', code: '38' },
          { name: 'Paola', code: '39' },
          { name: 'Pembroke', code: '40' },
          { name: 'Pietà', code: '41' },
          { name: 'Qala', code: '42' },
          { name: 'Qormi', code: '43' },
          { name: 'Qrendi', code: '44' },
          { name: 'Rabat', code: '46' },
          { name: 'Saint Lawrence', code: '50' },
          { name: 'San Ġwann', code: '49' },
          { name: 'Sannat', code: '52' },
          { name: 'Santa Luċija', code: '53' },
          { name: 'Santa Venera', code: '54' },
          { name: 'Senglea', code: '20' },
          { name: 'Siġġiewi', code: '55' },
          { name: 'Sliema', code: '56' },
          { name: `St. Julian's`, code: '48' },
          { name: `St. Paul's Bay`, code: '51' },
          { name: 'Swieqi', code: '57' },
          { name: `Ta' Xbiex`, code: '58' },
          { name: 'Tarxien', code: '59' },
          { name: 'Valletta', code: '60' },
          { name: 'Victoria', code: '45' },
          { name: 'Xagħra', code: '61' },
          { name: 'Xewkija', code: '62' },
          { name: 'Xgħajra', code: '63' },
          { name: 'Żabbar', code: '64' },
          { name: 'Żebbuġ Gozo', code: '65' },
          { name: 'Żebbuġ Malta', code: '66' },
          { name: 'Żejtun', code: '67' },
          { name: 'Żurrieq', code: '68' },
        ],
      },
    ],
    [
      'Man (Isle of)',
      {
        name: 'Man (Isle of)',
        code: 'IM',
        emoji: '🇮🇲',
        regions: [],
      },
    ],
    [
      'Marshall Islands',
      {
        name: 'Marshall Islands',
        code: 'MH',
        emoji: '🇲🇭',
        regions: [
          { name: 'Ralik Chain', code: 'L' },
          { name: 'Ratak Chain', code: 'T' },
        ],
      },
    ],
    [
      'Martinique',
      {
        name: 'Martinique',
        code: 'MQ',
        emoji: '🇲🇶',
        regions: [],
      },
    ],
    [
      'Mauritania',
      {
        name: 'Mauritania',
        code: 'MR',
        emoji: '🇲🇷',
        regions: [
          { name: 'Adrar', code: '07' },
          { name: 'Assaba', code: '03' },
          { name: 'Brakna', code: '05' },
          { name: 'Dakhlet Nouadhibou', code: '08' },
          { name: 'Gorgol', code: '04' },
          { name: 'Guidimaka', code: '10' },
          { name: 'Hodh Ech Chargui', code: '01' },
          { name: 'Hodh El Gharbi', code: '02' },
          { name: 'Inchiri', code: '12' },
          { name: 'Nouakchott-Nord', code: '14' },
          { name: 'Nouakchott-Ouest', code: '13' },
          { name: 'Nouakchott-Sud', code: '15' },
          { name: 'Tagant', code: '09' },
          { name: 'Tiris Zemmour', code: '11' },
          { name: 'Trarza', code: '06' },
        ],
      },
    ],
    [
      'Mauritius',
      {
        name: 'Mauritius',
        code: 'MU',
        emoji: '🇲🇺',
        regions: [
          { name: 'Agalega Islands', code: 'AG' },
          { name: 'Black River', code: 'BL' },
          { name: 'Flacq', code: 'FL' },
          { name: 'Grand Port', code: 'GP' },
          { name: 'Moka', code: 'MO' },
          { name: 'Pamplemousses', code: 'PA' },
          { name: 'Plaines Wilhems', code: 'PW' },
          { name: 'Port Louis', code: 'PL' },
          { name: 'Rivière du Rempart', code: 'RR' },
          { name: 'Rodrigues Island', code: 'RO' },
          { name: 'Saint Brandon Islands', code: 'CC' },
          { name: 'Savanne', code: 'SA' },
        ],
      },
    ],
    [
      'Mayotte',
      {
        name: 'Mayotte',
        code: 'YT',
        emoji: '🇾🇹',
        regions: [],
      },
    ],
    [
      'Mexico',
      {
        name: 'Mexico',
        code: 'MX',
        emoji: '🇲🇽',
        regions: [
          { name: 'Aguascalientes', code: 'AGU' },
          { name: 'Baja California', code: 'BCN' },
          { name: 'Baja California Sur', code: 'BCS' },
          { name: 'Campeche', code: 'CAM' },
          { name: 'Chiapas', code: 'CHP' },
          { name: 'Chihuahua', code: 'CHH' },
          { name: 'Ciudad de México', code: 'CMX' },
          { name: 'Coahuila de Zaragoza', code: 'COA' },
          { name: 'Colima', code: 'COL' },
          { name: 'Durango', code: 'DUR' },
          { name: 'Estado de México', code: 'MEX' },
          { name: 'Guanajuato', code: 'GUA' },
          { name: 'Guerrero', code: 'GRO' },
          { name: 'Hidalgo', code: 'HID' },
          { name: 'Jalisco', code: 'JAL' },
          { name: 'Michoacán de Ocampo', code: 'MIC' },
          { name: 'Morelos', code: 'MOR' },
          { name: 'Nayarit', code: 'NAY' },
          { name: 'Nuevo León', code: 'NLE' },
          { name: 'Oaxaca', code: 'OAX' },
          { name: 'Puebla', code: 'PUE' },
          { name: 'Querétaro', code: 'QUE' },
          { name: 'Quintana Roo', code: 'ROO' },
          { name: 'San Luis Potosí', code: 'SLP' },
          { name: 'Sinaloa', code: 'SIN' },
          { name: 'Sonora', code: 'SON' },
          { name: 'Tabasco', code: 'TAB' },
          { name: 'Tamaulipas', code: 'TAM' },
          { name: 'Tlaxcala', code: 'TLA' },
          { name: 'Veracruz de Ignacio de la Llave', code: 'VER' },
          { name: 'Yucatán', code: 'YUC' },
          { name: 'Zacatecas', code: 'ZAC' },
        ],
      },
    ],
    [
      'Micronesia',
      {
        name: 'Micronesia',
        code: 'FM',
        emoji: '🇫🇲',
        regions: [
          { name: 'Chuuk State', code: 'TRK' },
          { name: 'Kosrae State', code: 'KSA' },
          { name: 'Pohnpei State', code: 'PNI' },
          { name: 'Yap State', code: 'YAP' },
        ],
      },
    ],
    [
      'Moldova',
      {
        name: 'Moldova',
        code: 'MD',
        emoji: '🇲🇩',
        regions: [
          { name: 'Anenii Noi District', code: 'AN' },
          { name: 'Bălți Municipality', code: 'BA' },
          { name: 'Basarabeasca District', code: 'BS' },
          { name: 'Bender Municipality', code: 'BD' },
          { name: 'Briceni District', code: 'BR' },
          { name: 'Cahul District', code: 'CA' },
          { name: 'Călărași District', code: 'CL' },
          { name: 'Cantemir District', code: 'CT' },
          { name: 'Căușeni District', code: 'CS' },
          { name: 'Chișinău Municipality', code: 'CU' },
          { name: 'Cimișlia District', code: 'CM' },
          { name: 'Criuleni District', code: 'CR' },
          { name: 'Dondușeni District', code: 'DO' },
          { name: 'Drochia District', code: 'DR' },
          { name: 'Dubăsari District', code: 'DU' },
          { name: 'Edineț District', code: 'ED' },
          { name: 'Fălești District', code: 'FA' },
          { name: 'Florești District', code: 'FL' },
          { name: 'Gagauzia', code: 'GA' },
          { name: 'Glodeni District', code: 'GL' },
          { name: 'Hîncești District', code: 'HI' },
          { name: 'Ialoveni District', code: 'IA' },
          { name: 'Nisporeni District', code: 'NI' },
          { name: 'Ocnița District', code: 'OC' },
          { name: 'Orhei District', code: 'OR' },
          { name: 'Rezina District', code: 'RE' },
          { name: 'Rîșcani District', code: 'RI' },
          { name: 'Sîngerei District', code: 'SI' },
          { name: 'Șoldănești District', code: 'SD' },
          { name: 'Soroca District', code: 'SO' },
          { name: 'Ștefan Vodă District', code: 'SV' },
          { name: 'Strășeni District', code: 'ST' },
          { name: 'Taraclia District', code: 'TA' },
          { name: 'Telenești District', code: 'TE' },
          { name: 'Transnistria autonomous territorial unit', code: 'SN' },
          { name: 'Ungheni District', code: 'UN' },
        ],
      },
    ],
    [
      'Monaco',
      {
        name: 'Monaco',
        code: 'MC',
        emoji: '🇲🇨',
        regions: [
          { name: 'La Colle', code: 'CL' },
          { name: 'La Condamine', code: 'CO' },
          { name: 'Moneghetti', code: 'MG' },
        ],
      },
    ],
    [
      'Mongolia',
      {
        name: 'Mongolia',
        code: 'MN',
        emoji: '🇲🇳',
        regions: [
          { name: 'Arkhangai Province', code: '073' },
          { name: 'Bayan-Ölgii Province', code: '071' },
          { name: 'Bayankhongor Province', code: '069' },
          { name: 'Bulgan Province', code: '067' },
          { name: 'Darkhan-Uul Province', code: '037' },
          { name: 'Dornod Province', code: '061' },
          { name: 'Dornogovi Province', code: '063' },
          { name: 'Dundgovi Province', code: '059' },
          { name: 'Govi-Altai Province', code: '065' },
          { name: 'Govisümber Province', code: '064' },
          { name: 'Khentii Province', code: '039' },
          { name: 'Khovd Province', code: '043' },
          { name: 'Khövsgöl Province', code: '041' },
          { name: 'Ömnögovi Province', code: '053' },
          { name: 'Orkhon Province', code: '035' },
          { name: 'Övörkhangai Province', code: '055' },
          { name: 'Selenge Province', code: '049' },
          { name: 'Sükhbaatar Province', code: '051' },
          { name: 'Töv Province', code: '047' },
          { name: 'Uvs Province', code: '046' },
          { name: 'Zavkhan Province', code: '057' },
        ],
      },
    ],
    [
      'Montenegro',
      {
        name: 'Montenegro',
        code: 'ME',
        emoji: '🇲🇪',
        regions: [
          { name: 'Andrijevica Municipality', code: '01' },
          { name: 'Bar Municipality', code: '02' },
          { name: 'Berane Municipality', code: '03' },
          { name: 'Bijelo Polje Municipality', code: '04' },
          { name: 'Budva Municipality', code: '05' },
          { name: 'Danilovgrad Municipality', code: '07' },
          { name: 'Gusinje Municipality', code: '22' },
          { name: 'Kolašin Municipality', code: '09' },
          { name: 'Kotor Municipality', code: '10' },
          { name: 'Mojkovac Municipality', code: '11' },
          { name: 'Nikšić Municipality', code: '12' },
          { name: 'Old Royal Capital Cetinje', code: '06' },
          { name: 'Petnjica Municipality', code: '23' },
          { name: 'Plav Municipality', code: '13' },
          { name: 'Pljevlja Municipality', code: '14' },
          { name: 'Plužine Municipality', code: '15' },
          { name: 'Podgorica Municipality', code: '16' },
          { name: 'Rožaje Municipality', code: '17' },
          { name: 'Šavnik Municipality', code: '18' },
          { name: 'Tivat Municipality', code: '19' },
          { name: 'Ulcinj Municipality', code: '20' },
          { name: 'Žabljak Municipality', code: '21' },
        ],
      },
    ],
    [
      'Montserrat',
      {
        name: 'Montserrat',
        code: 'MS',
        emoji: '🇲🇸',
        regions: [],
      },
    ],
    [
      'Morocco',
      {
        name: 'Morocco',
        code: 'MA',
        emoji: '🇲🇦',
        regions: [
          { name: 'Agadir-Ida-Ou-Tanane', code: 'AGD' },
          { name: 'Al Haouz', code: 'HAO' },
          { name: 'Al Hoceïma', code: 'HOC' },
          { name: 'Aousserd (EH)', code: 'AOU' },
          { name: 'Assa-Zag (EH-partial)', code: 'ASZ' },
          { name: 'Azilal', code: 'AZI' },
          { name: 'Béni Mellal', code: 'BEM' },
          { name: 'Béni Mellal-Khénifra', code: '05' },
          { name: 'Benslimane', code: 'BES' },
          { name: 'Berkane', code: 'BER' },
          { name: 'Berrechid', code: 'BRR' },
          { name: 'Boujdour (EH)', code: 'BOD' },
          { name: 'Boulemane', code: 'BOM' },
          { name: 'Casablanca', code: 'CAS' },
          { name: 'Casablanca-Settat', code: '06' },
          { name: 'Chefchaouen', code: 'CHE' },
          { name: 'Chichaoua', code: 'CHI' },
          { name: 'Chtouka-Ait Baha', code: 'CHT' },
          { name: 'Dakhla-Oued Ed-Dahab (EH)', code: '12' },
          { name: 'Drâa-Tafilalet', code: '08' },
          { name: 'Driouch', code: 'DRI' },
          { name: 'El Hajeb', code: 'HAJ' },
          { name: 'El Jadida', code: 'JDI' },
          { name: 'El Kelâa des Sraghna', code: 'KES' },
          { name: 'Errachidia', code: 'ERR' },
          { name: 'Es-Semara (EH-partial)', code: 'ESM' },
          { name: 'Essaouira', code: 'ESI' },
          { name: 'Fahs-Anjra', code: 'FAH' },
          { name: 'Fès', code: 'FES' },
          { name: 'Fès-Meknès', code: '03' },
          { name: 'Figuig', code: 'FIG' },
          { name: 'Fquih Ben Salah', code: 'FQH' },
          { name: 'Guelmim', code: 'GUE' },
          { name: 'Guelmim-Oued Noun (EH-partial)', code: '10' },
          { name: 'Guercif', code: 'GUF' },
          { name: 'Ifrane', code: 'IFR' },
          { name: 'Inezgane-Ait Melloul', code: 'INE' },
          { name: 'Jerada', code: 'JRA' },
          { name: 'Kénitra', code: 'KEN' },
          { name: 'Khémisset', code: 'KHE' },
          { name: 'Khénifra', code: 'KHN' },
          { name: 'Khouribga', code: 'KHO' },
          { name: `L'Oriental`, code: '02' },
          { name: 'Laâyoune (EH)', code: 'LAA' },
          { name: 'Laâyoune-Sakia El Hamra (EH-partial)', code: '11' },
          { name: 'Larache', code: 'LAR' },
          { name: 'M’diq-Fnideq', code: 'MDF' },
          { name: 'Marrakech', code: 'MAR' },
          { name: 'Marrakesh-Safi', code: '07' },
          { name: 'Médiouna', code: 'MED' },
          { name: 'Meknès', code: 'MEK' },
          { name: 'Midelt', code: 'MID' },
          { name: 'Mohammadia', code: 'MOH' },
          { name: 'Moulay Yacoub', code: 'MOU' },
          { name: 'Nador', code: 'NAD' },
          { name: 'Nouaceur', code: 'NOU' },
          { name: 'Ouarzazate', code: 'OUA' },
          { name: 'Oued Ed-Dahab (EH)', code: 'OUD' },
          { name: 'Ouezzane', code: 'OUZ' },
          { name: 'Oujda-Angad', code: 'OUJ' },
          { name: 'Rabat', code: 'RAB' },
          { name: 'Rabat-Salé-Kénitra', code: '04' },
          { name: 'Rehamna', code: 'REH' },
          { name: 'Safi', code: 'SAF' },
          { name: 'Salé', code: 'SAL' },
          { name: 'Sefrou', code: 'SEF' },
          { name: 'Settat', code: 'SET' },
          { name: 'Sidi Bennour', code: 'SIB' },
          { name: 'Sidi Ifni', code: 'SIF' },
          { name: 'Sidi Kacem', code: 'SIK' },
          { name: 'Sidi Slimane', code: 'SIL' },
          { name: 'Skhirate-Témara', code: 'SKH' },
          { name: 'Souss-Massa', code: '09' },
          { name: 'Tan-Tan (EH-partial)', code: 'TNT' },
          { name: 'Tanger-Assilah', code: 'TNG' },
          { name: 'Tanger-Tétouan-Al Hoceïma', code: '01' },
          { name: 'Taounate', code: 'TAO' },
          { name: 'Taourirt', code: 'TAI' },
          { name: 'Tarfaya (EH-partial)', code: 'TAF' },
          { name: 'Taroudannt', code: 'TAR' },
          { name: 'Tata', code: 'TAT' },
          { name: 'Taza', code: 'TAZ' },
          { name: 'Tétouan', code: 'TET' },
          { name: 'Tinghir', code: 'TIN' },
          { name: 'Tiznit', code: 'TIZ' },
          { name: 'Youssoufia', code: 'YUS' },
          { name: 'Zagora', code: 'ZAG' },
        ],
      },
    ],
    [
      'Mozambique',
      {
        name: 'Mozambique',
        code: 'MZ',
        emoji: '🇲🇿',
        regions: [
          { name: 'Cabo Delgado Province', code: 'P' },
          { name: 'Gaza Province', code: 'G' },
          { name: 'Inhambane Province', code: 'I' },
          { name: 'Manica Province', code: 'B' },
          { name: 'Maputo', code: 'MPM' },
          { name: 'Maputo Province', code: 'L' },
          { name: 'Nampula Province', code: 'N' },
          { name: 'Niassa Province', code: 'A' },
          { name: 'Sofala Province', code: 'S' },
          { name: 'Tete Province', code: 'T' },
          { name: 'Zambezia Province', code: 'Q' },
        ],
      },
    ],
    [
      'Myanmar',
      {
        name: 'Myanmar',
        code: 'MM',
        emoji: '🇲🇲',
        regions: [
          { name: 'Ayeyarwady Region', code: '07' },
          { name: 'Bago', code: '02' },
          { name: 'Chin State', code: '14' },
          { name: 'Kachin State', code: '11' },
          { name: 'Kayah State', code: '12' },
          { name: 'Kayin State', code: '13' },
          { name: 'Magway Region', code: '03' },
          { name: 'Mandalay Region', code: '04' },
          { name: 'Mon State', code: '15' },
          { name: 'Naypyidaw Union Territory', code: '18' },
          { name: 'Rakhine State', code: '16' },
          { name: 'Sagaing Region', code: '01' },
          { name: 'Shan State', code: '17' },
          { name: 'Tanintharyi Region', code: '05' },
          { name: 'Yangon Region', code: '06' },
        ],
      },
    ],
    [
      'Namibia',
      {
        name: 'Namibia',
        code: 'NA',
        emoji: '🇳🇦',
        regions: [
          { name: 'Erongo Region', code: 'ER' },
          { name: 'Hardap Region', code: 'HA' },
          { name: 'Karas Region', code: 'KA' },
          { name: 'Kavango East Region', code: 'KE' },
          { name: 'Kavango West Region', code: 'KW' },
          { name: 'Khomas Region', code: 'KH' },
          { name: 'Kunene Region', code: 'KU' },
          { name: 'Ohangwena Region', code: 'OW' },
          { name: 'Omaheke Region', code: 'OH' },
          { name: 'Omusati Region', code: 'OS' },
          { name: 'Oshana Region', code: 'ON' },
          { name: 'Oshikoto Region', code: 'OT' },
          { name: 'Otjozondjupa Region', code: 'OD' },
          { name: 'Zambezi Region', code: 'CA' },
        ],
      },
    ],
    [
      'Nauru',
      {
        name: 'Nauru',
        code: 'NR',
        emoji: '🇳🇷',
        regions: [
          { name: 'Aiwo District', code: '01' },
          { name: 'Anabar District', code: '02' },
          { name: 'Anetan District', code: '03' },
          { name: 'Anibare District', code: '04' },
          { name: 'Baiti District', code: '05' },
          { name: 'Boe District', code: '06' },
          { name: 'Buada District', code: '07' },
          { name: 'Denigomodu District', code: '08' },
          { name: 'Ewa District', code: '09' },
          { name: 'Ijuw District', code: '10' },
          { name: 'Meneng District', code: '11' },
          { name: 'Nibok District', code: '12' },
          { name: 'Uaboe District', code: '13' },
          { name: 'Yaren District', code: '14' },
        ],
      },
    ],
    [
      'Nepal',
      {
        name: 'Nepal',
        code: 'NP',
        emoji: '🇳🇵',
        regions: [
          { name: 'Bagmati Zone', code: 'BA' },
          { name: 'Bheri Zone', code: 'BH' },
          { name: 'Central Region', code: '1' },
          { name: 'Dhaulagiri Zone', code: 'DH' },
          { name: 'Eastern Development Region', code: '4' },
          { name: 'Far-Western Development Region', code: '5' },
          { name: 'Gandaki Zone', code: 'GA' },
          { name: 'Janakpur Zone', code: 'JA' },
          { name: 'Karnali Zone', code: 'KA' },
          { name: 'Kosi Zone', code: 'KO' },
          { name: 'Lumbini Zone', code: 'LU' },
          { name: 'Mahakali Zone', code: 'MA' },
          { name: 'Mechi Zone', code: 'ME' },
          { name: 'Mid-Western Region', code: '2' },
          { name: 'Narayani Zone', code: 'NA' },
          { name: 'Rapti Zone', code: 'RA' },
          { name: 'Sagarmatha Zone', code: 'SA' },
          { name: 'Seti Zone', code: 'SE' },
          { name: 'Western Region', code: '3' },
        ],
      },
    ],
    [
      'Netherlands',
      {
        name: 'Netherlands',
        code: 'NL',
        emoji: '🇳🇱',
        regions: [
          { name: 'Bonaire', code: 'BQ1' },
          { name: 'Drenthe', code: 'DR' },
          { name: 'Flevoland', code: 'FL' },
          { name: 'Friesland', code: 'FR' },
          { name: 'Gelderland', code: 'GE' },
          { name: 'Groningen', code: 'GR' },
          { name: 'Limburg', code: 'LI' },
          { name: 'North Brabant', code: 'NB' },
          { name: 'North Holland', code: 'NH' },
          { name: 'Overijssel', code: 'OV' },
          { name: 'Saba', code: 'BQ2' },
          { name: 'Sint Eustatius', code: 'BQ3' },
          { name: 'South Holland', code: 'ZH' },
          { name: 'Utrecht', code: 'UT' },
          { name: 'Zeeland', code: 'ZE' },
        ],
      },
    ],
    [
      'New Caledonia',
      {
        name: 'New Caledonia',
        code: 'NC',
        emoji: '🇳🇨',
        regions: [
          { name: 'Loyalty Islands Province', code: '03' },
          { name: 'North Province', code: '02' },
          { name: 'South Province', code: '01' },
        ],
      },
    ],
    [
      'New Zealand',
      {
        name: 'New Zealand',
        code: 'NZ',
        emoji: '🇳🇿',
        regions: [
          { name: 'Auckland Region', code: 'AUK' },
          { name: 'Bay of Plenty Region', code: 'BOP' },
          { name: 'Canterbury Region', code: 'CAN' },
          { name: 'Chatham Islands', code: 'CIT' },
          { name: 'Gisborne District', code: 'GIS' },
          { name: `Hawke's Bay Region`, code: 'HKB' },
          { name: 'Manawatu-Wanganui Region', code: 'MWT' },
          { name: 'Marlborough Region', code: 'MBH' },
          { name: 'Nelson Region', code: 'NSN' },
          { name: 'Northland Region', code: 'NTL' },
          { name: 'Otago Region', code: 'OTA' },
          { name: 'Southland Region', code: 'STL' },
          { name: 'Taranaki Region', code: 'TKI' },
          { name: 'Tasman District', code: 'TAS' },
          { name: 'Waikato Region', code: 'WKO' },
          { name: 'Wellington Region', code: 'WGN' },
          { name: 'West Coast Region', code: 'WTC' },
        ],
      },
    ],
    [
      'Nicaragua',
      {
        name: 'Nicaragua',
        code: 'NI',
        emoji: '🇳🇮',
        regions: [
          { name: 'Boaco', code: 'BO' },
          { name: 'Carazo', code: 'CA' },
          { name: 'Chinandega', code: 'CI' },
          { name: 'Chontales', code: 'CO' },
          { name: 'Estelí', code: 'ES' },
          { name: 'Granada', code: 'GR' },
          { name: 'Jinotega', code: 'JI' },
          { name: 'León', code: 'LE' },
          { name: 'Madriz', code: 'MD' },
          { name: 'Managua', code: 'MN' },
          { name: 'Masaya', code: 'MS' },
          { name: 'Matagalpa', code: 'MT' },
          { name: 'North Caribbean Coast', code: 'AN' },
          { name: 'Nueva Segovia', code: 'NS' },
          { name: 'Río San Juan', code: 'SJ' },
          { name: 'Rivas', code: 'RI' },
          { name: 'South Caribbean Coast', code: 'AS' },
        ],
      },
    ],
    [
      'Niger',
      {
        name: 'Niger',
        code: 'NE',
        emoji: '🇳🇪',
        regions: [
          { name: 'Agadez Region', code: '1' },
          { name: 'Diffa Region', code: '2' },
          { name: 'Dosso Region', code: '3' },
          { name: 'Maradi Region', code: '4' },
          { name: 'Tahoua Region', code: '5' },
          { name: 'Tillabéri Region', code: '6' },
          { name: 'Zinder Region', code: '7' },
        ],
      },
    ],
    [
      'Nigeria',
      {
        name: 'Nigeria',
        code: 'NG',
        emoji: '🇳🇬',
        regions: [
          { name: 'Abia', code: 'AB' },
          { name: 'Abuja Federal Capital Territory', code: 'FC' },
          { name: 'Adamawa', code: 'AD' },
          { name: 'Akwa Ibom', code: 'AK' },
          { name: 'Anambra', code: 'AN' },
          { name: 'Bauchi', code: 'BA' },
          { name: 'Bayelsa', code: 'BY' },
          { name: 'Benue', code: 'BE' },
          { name: 'Borno', code: 'BO' },
          { name: 'Cross River', code: 'CR' },
          { name: 'Delta', code: 'DE' },
          { name: 'Ebonyi', code: 'EB' },
          { name: 'Edo', code: 'ED' },
          { name: 'Ekiti', code: 'EK' },
          { name: 'Enugu', code: 'EN' },
          { name: 'Gombe', code: 'GO' },
          { name: 'Imo', code: 'IM' },
          { name: 'Jigawa', code: 'JI' },
          { name: 'Kaduna', code: 'KD' },
          { name: 'Kano', code: 'KN' },
          { name: 'Katsina', code: 'KT' },
          { name: 'Kebbi', code: 'KE' },
          { name: 'Kogi', code: 'KO' },
          { name: 'Kwara', code: 'KW' },
          { name: 'Lagos', code: 'LA' },
          { name: 'Nasarawa', code: 'NA' },
          { name: 'Niger', code: 'NI' },
          { name: 'Ogun', code: 'OG' },
          { name: 'Ondo', code: 'ON' },
          { name: 'Osun', code: 'OS' },
          { name: 'Oyo', code: 'OY' },
          { name: 'Plateau', code: 'PL' },
          { name: 'Rivers', code: 'RI' },
          { name: 'Sokoto', code: 'SO' },
          { name: 'Taraba', code: 'TA' },
          { name: 'Yobe', code: 'YO' },
          { name: 'Zamfara', code: 'ZA' },
        ],
      },
    ],
    [
      'Niue',
      {
        name: 'Niue',
        code: 'NU',
        emoji: '🇳🇺',
        regions: [],
      },
    ],
    [
      'Norfolk Island',
      {
        name: 'Norfolk Island',
        code: 'NF',
        emoji: '🇳🇫',
        regions: [],
      },
    ],
    [
      'North Korea',
      {
        name: 'North Korea',
        code: 'KP',
        emoji: '🇰🇵',
        regions: [
          { name: 'Chagang Province', code: '04' },
          { name: 'Kangwon Province', code: '07' },
          { name: 'North Hamgyong Province', code: '09' },
          { name: 'North Hwanghae Province', code: '06' },
          { name: 'North Pyongan Province', code: '03' },
          { name: 'Pyongyang', code: '01' },
          { name: 'Rason', code: '13' },
          { name: 'Ryanggang Province', code: '10' },
          { name: 'South Hamgyong Province', code: '08' },
          { name: 'South Hwanghae Province', code: '05' },
          { name: 'South Pyongan Province', code: '02' },
        ],
      },
    ],
    [
      'North Macedonia',
      {
        name: 'North Macedonia',
        code: 'MK',
        emoji: '🇲🇰',
        regions: [
          { name: 'Aerodrom Municipality', code: '01' },
          { name: 'Aračinovo Municipality', code: '02' },
          { name: 'Berovo Municipality', code: '03' },
          { name: 'Bitola Municipality', code: '04' },
          { name: 'Bogdanci Municipality', code: '05' },
          { name: 'Bogovinje Municipality', code: '06' },
          { name: 'Bosilovo Municipality', code: '07' },
          { name: 'Brvenica Municipality', code: '08' },
          { name: 'Butel Municipality', code: '09' },
          { name: 'Čair Municipality', code: '79' },
          { name: 'Čaška Municipality', code: '80' },
          { name: 'Centar Municipality', code: '77' },
          { name: 'Centar Župa Municipality', code: '78' },
          { name: 'Češinovo-Obleševo Municipality', code: '81' },
          { name: 'Čučer-Sandevo Municipality', code: '82' },
          { name: 'Debarca Municipality', code: '22' },
          { name: 'Delčevo Municipality', code: '23' },
          { name: 'Demir Hisar Municipality', code: '25' },
          { name: 'Demir Kapija Municipality', code: '24' },
          { name: 'Dojran Municipality', code: '26' },
          { name: 'Dolneni Municipality', code: '27' },
          { name: 'Drugovo Municipality', code: '28' },
          { name: 'Gazi Baba Municipality', code: '17' },
          { name: 'Gevgelija Municipality', code: '18' },
          { name: 'Gjorče Petrov Municipality', code: '29' },
          { name: 'Gostivar Municipality', code: '19' },
          { name: 'Gradsko Municipality', code: '20' },
          { name: 'Greater Skopje', code: '85' },
          { name: 'Ilinden Municipality', code: '34' },
          { name: 'Jegunovce Municipality', code: '35' },
          { name: 'Karbinci', code: '37' },
          { name: 'Karpoš Municipality', code: '38' },
          { name: 'Kavadarci Municipality', code: '36' },
          { name: 'Kičevo Municipality', code: '40' },
          { name: 'Kisela Voda Municipality', code: '39' },
          { name: 'Kočani Municipality', code: '42' },
          { name: 'Konče Municipality', code: '41' },
          { name: 'Kratovo Municipality', code: '43' },
          { name: 'Kriva Palanka Municipality', code: '44' },
          { name: 'Krivogaštani Municipality', code: '45' },
          { name: 'Kruševo Municipality', code: '46' },
          { name: 'Kumanovo Municipality', code: '47' },
          { name: 'Lipkovo Municipality', code: '48' },
          { name: 'Lozovo Municipality', code: '49' },
          { name: 'Makedonska Kamenica Municipality', code: '51' },
          { name: 'Makedonski Brod Municipality', code: '52' },
          { name: 'Mavrovo and Rostuša Municipality', code: '50' },
          { name: 'Mogila Municipality', code: '53' },
          { name: 'Negotino Municipality', code: '54' },
          { name: 'Novaci Municipality', code: '55' },
          { name: 'Novo Selo Municipality', code: '56' },
          { name: 'Ohrid Municipality', code: '58' },
          { name: 'Oslomej Municipality', code: '57' },
          { name: 'Pehčevo Municipality', code: '60' },
          { name: 'Petrovec Municipality', code: '59' },
          { name: 'Plasnica Municipality', code: '61' },
          { name: 'Prilep Municipality', code: '62' },
          { name: 'Probištip Municipality', code: '63' },
          { name: 'Radoviš Municipality', code: '64' },
          { name: 'Rankovce Municipality', code: '65' },
          { name: 'Resen Municipality', code: '66' },
          { name: 'Rosoman Municipality', code: '67' },
          { name: 'Saraj Municipality', code: '68' },
          { name: 'Sopište Municipality', code: '70' },
          { name: 'Staro Nagoričane Municipality', code: '71' },
          { name: 'Štip Municipality', code: '83' },
          { name: 'Struga Municipality', code: '72' },
          { name: 'Strumica Municipality', code: '73' },
          { name: 'Studeničani Municipality', code: '74' },
          { name: 'Šuto Orizari Municipality', code: '84' },
          { name: 'Sveti Nikole Municipality', code: '69' },
          { name: 'Tearce Municipality', code: '75' },
          { name: 'Tetovo Municipality', code: '76' },
          { name: 'Valandovo Municipality', code: '10' },
          { name: 'Vasilevo Municipality', code: '11' },
          { name: 'Veles Municipality', code: '13' },
          { name: 'Vevčani Municipality', code: '12' },
          { name: 'Vinica Municipality', code: '14' },
          { name: 'Vraneštica Municipality', code: '15' },
          { name: 'Vrapčište Municipality', code: '16' },
          { name: 'Zajas Municipality', code: '31' },
          { name: 'Zelenikovo Municipality', code: '32' },
          { name: 'Želino Municipality', code: '30' },
          { name: 'Zrnovci Municipality', code: '33' },
        ],
      },
    ],
    [
      'Northern Mariana Islands',
      {
        name: 'Northern Mariana Islands',
        code: 'MP',
        emoji: '🇲🇵',
        regions: [],
      },
    ],
    [
      'Norway',
      {
        name: 'Norway',
        code: 'NO',
        emoji: '🇳🇴',
        regions: [
          { name: 'Agder', code: '42' },
          { name: 'Innlandet', code: '34' },
          { name: 'Jan Mayen', code: '22' },
          { name: 'Møre og Romsdal', code: '15' },
          { name: 'Nordland', code: '18' },
          { name: 'Oslo', code: '03' },
          { name: 'Rogaland', code: '11' },
          { name: 'Svalbard', code: '21' },
          { name: 'Troms og Finnmark', code: '54' },
          { name: 'Trøndelag', code: '50' },
          { name: 'Vestfold og Telemark', code: '38' },
          { name: 'Vestland', code: '46' },
          { name: 'Viken', code: '30' },
        ],
      },
    ],
    [
      'Oman',
      {
        name: 'Oman',
        code: 'OM',
        emoji: '🇴🇲',
        regions: [
          { name: 'Ad Dakhiliyah', code: 'DA' },
          { name: 'Ad Dhahirah', code: 'ZA' },
          { name: 'Al Batinah North', code: 'BS' },
          { name: 'Al Batinah Region', code: 'BA' },
          { name: 'Al Batinah South', code: 'BJ' },
          { name: 'Al Buraimi', code: 'BU' },
          { name: 'Al Wusta', code: 'WU' },
          { name: 'Ash Sharqiyah North', code: 'SS' },
          { name: 'Ash Sharqiyah Region', code: 'SH' },
          { name: 'Ash Sharqiyah South', code: 'SJ' },
          { name: 'Dhofar', code: 'ZU' },
          { name: 'Musandam', code: 'MU' },
          { name: 'Muscat', code: 'MA' },
        ],
      },
    ],
    [
      'Pakistan',
      {
        name: 'Pakistan',
        code: 'PK',
        emoji: '🇵🇰',
        regions: [
          { name: 'Azad Kashmir', code: 'JK' },
          { name: 'Balochistan', code: 'BA' },
          { name: 'Federally Administered Tribal Areas', code: 'TA' },
          { name: 'Gilgit-Baltistan', code: 'GB' },
          { name: 'Islamabad Capital Territory', code: 'IS' },
          { name: 'Khyber Pakhtunkhwa', code: 'KP' },
          { name: 'Punjab', code: 'PB' },
          { name: 'Sindh', code: 'SD' },
        ],
      },
    ],
    [
      'Palau',
      {
        name: 'Palau',
        code: 'PW',
        emoji: '🇵🇼',
        regions: [
          { name: 'Aimeliik', code: '002' },
          { name: 'Airai', code: '004' },
          { name: 'Angaur', code: '010' },
          { name: 'Hatohobei', code: '050' },
          { name: 'Kayangel', code: '100' },
          { name: 'Koror', code: '150' },
          { name: 'Melekeok', code: '212' },
          { name: 'Ngaraard', code: '214' },
          { name: 'Ngarchelong', code: '218' },
          { name: 'Ngardmau', code: '222' },
          { name: 'Ngatpang', code: '224' },
          { name: 'Ngchesar', code: '226' },
          { name: 'Ngeremlengui', code: '227' },
          { name: 'Ngiwal', code: '228' },
          { name: 'Peleliu', code: '350' },
          { name: 'Sonsorol', code: '370' },
        ],
      },
    ],
    [
      'Palestinian Territory Occupied',
      {
        name: 'Palestinian Territory Occupied',
        code: 'PS',
        emoji: '🇵🇸',
        regions: [
          { name: 'Bethlehem', code: 'BTH' },
          { name: 'Deir El Balah', code: 'DEB' },
          { name: 'Gaza', code: 'GZA' },
          { name: 'Hebron', code: 'HBN' },
          { name: 'Jenin', code: 'JEN' },
          { name: 'Jericho and Al Aghwar', code: 'JRH' },
          { name: 'Jerusalem', code: 'JEM' },
          { name: 'Khan Yunis', code: 'KYS' },
          { name: 'Nablus', code: 'NBS' },
          { name: 'North Gaza', code: 'NGZ' },
          { name: 'Qalqilya', code: 'QQA' },
          { name: 'Rafah', code: 'RFH' },
          { name: 'Ramallah', code: 'RBH' },
          { name: 'Salfit', code: 'SLT' },
          { name: 'Tubas', code: 'TBS' },
          { name: 'Tulkarm', code: 'TKM' },
        ],
      },
    ],
    [
      'Panama',
      {
        name: 'Panama',
        code: 'PA',
        emoji: '🇵🇦',
        regions: [
          { name: 'Bocas del Toro Province', code: '1' },
          { name: 'Chiriquí Province', code: '4' },
          { name: 'Coclé Province', code: '2' },
          { name: 'Colón Province', code: '3' },
          { name: 'Darién Province', code: '5' },
          { name: 'Emberá-Wounaan Comarca', code: 'EM' },
          { name: 'Guna Yala', code: 'KY' },
          { name: 'Herrera Province', code: '6' },
          { name: 'Los Santos Province', code: '7' },
          { name: 'Ngöbe-Buglé Comarca', code: 'NB' },
          { name: 'Panamá Oeste Province', code: '10' },
          { name: 'Panamá Province', code: '8' },
          { name: 'Veraguas Province', code: '9' },
        ],
      },
    ],
    [
      'Papua New Guinea',
      {
        name: 'Papua New Guinea',
        code: 'PG',
        emoji: '🇵🇬',
        regions: [
          { name: 'Bougainville', code: 'NSB' },
          { name: 'Central Province', code: 'CPM' },
          { name: 'Chimbu Province', code: 'CPK' },
          { name: 'East New Britain', code: 'EBR' },
          { name: 'Eastern Highlands Province', code: 'EHG' },
          { name: 'Enga Province', code: 'EPW' },
          { name: 'Gulf', code: 'GPK' },
          { name: 'Hela', code: 'HLA' },
          { name: 'Jiwaka Province', code: 'JWK' },
          { name: 'Madang Province', code: 'MPM' },
          { name: 'Manus Province', code: 'MRL' },
          { name: 'Milne Bay Province', code: 'MBA' },
          { name: 'Morobe Province', code: 'MPL' },
          { name: 'New Ireland Province', code: 'NIK' },
          { name: 'Oro Province', code: 'NPP' },
          { name: 'Port Moresby', code: 'NCD' },
          { name: 'Sandaun Province', code: 'SAN' },
          { name: 'Southern Highlands Province', code: 'SHM' },
          { name: 'West New Britain Province', code: 'WBK' },
          { name: 'Western Highlands Province', code: 'WHM' },
          { name: 'Western Province', code: 'WPD' },
        ],
      },
    ],
    [
      'Paraguay',
      {
        name: 'Paraguay',
        code: 'PY',
        emoji: '🇵🇾',
        regions: [
          { name: 'Alto Paraguay Department', code: '16' },
          { name: 'Alto Paraná Department', code: '10' },
          { name: 'Amambay Department', code: '13' },
          { name: 'Asuncion', code: 'ASU' },
          { name: 'Boquerón Department', code: '19' },
          { name: 'Caaguazú', code: '5' },
          { name: 'Caazapá', code: '6' },
          { name: 'Canindeyú', code: '14' },
          { name: 'Central Department', code: '11' },
          { name: 'Concepción Department', code: '1' },
          { name: 'Cordillera Department', code: '3' },
          { name: 'Guairá Department', code: '4' },
          { name: 'Itapúa', code: '7' },
          { name: 'Misiones Department', code: '8' },
          { name: 'Ñeembucú Department', code: '12' },
          { name: 'Paraguarí Department', code: '9' },
          { name: 'Presidente Hayes Department', code: '15' },
          { name: 'San Pedro Department', code: '2' },
        ],
      },
    ],
    [
      'Peru',
      {
        name: 'Peru',
        code: 'PE',
        emoji: '🇵🇪',
        regions: [
          { name: 'Amazonas', code: 'AMA' },
          { name: 'Áncash', code: 'ANC' },
          { name: 'Apurímac', code: 'APU' },
          { name: 'Arequipa', code: 'ARE' },
          { name: 'Ayacucho', code: 'AYA' },
          { name: 'Cajamarca', code: 'CAJ' },
          { name: 'Callao', code: 'CAL' },
          { name: 'Cusco', code: 'CUS' },
          { name: 'Huancavelica', code: 'HUV' },
          { name: 'Huanuco', code: 'HUC' },
          { name: 'Ica', code: 'ICA' },
          { name: 'Junín', code: 'JUN' },
          { name: 'La Libertad', code: 'LAL' },
          { name: 'Lambayeque', code: 'LAM' },
          { name: 'Lima', code: 'LIM' },
          { name: 'Loreto', code: 'LOR' },
          { name: 'Madre de Dios', code: 'MDD' },
          { name: 'Moquegua', code: 'MOQ' },
          { name: 'Pasco', code: 'PAS' },
          { name: 'Piura', code: 'PIU' },
          { name: 'Puno', code: 'PUN' },
          { name: 'San Martín', code: 'SAM' },
          { name: 'Tacna', code: 'TAC' },
          { name: 'Tumbes', code: 'TUM' },
          { name: 'Ucayali', code: 'UCA' },
        ],
      },
    ],
    [
      'Philippines',
      {
        name: 'Philippines',
        code: 'PH',
        emoji: '🇵🇭',
        regions: [
          { name: 'Abra', code: 'ABR' },
          { name: 'Agusan del Norte', code: 'AGN' },
          { name: 'Agusan del Sur', code: 'AGS' },
          { name: 'Aklan', code: 'AKL' },
          { name: 'Albay', code: 'ALB' },
          { name: 'Antique', code: 'ANT' },
          { name: 'Apayao', code: 'APA' },
          { name: 'Aurora', code: 'AUR' },
          { name: 'Autonomous Region in Muslim Mindanao', code: '14' },
          { name: 'Basilan', code: 'BAS' },
          { name: 'Bataan', code: 'BAN' },
          { name: 'Batanes', code: 'BTN' },
          { name: 'Batangas', code: 'BTG' },
          { name: 'Benguet', code: 'BEN' },
          { name: 'Bicol', code: '05' },
          { name: 'Biliran', code: 'BIL' },
          { name: 'Bohol', code: 'BOH' },
          { name: 'Bukidnon', code: 'BUK' },
          { name: 'Bulacan', code: 'BUL' },
          { name: 'Cagayan', code: 'CAG' },
          { name: 'Cagayan Valley', code: '02' },
          { name: 'Calabarzon', code: '40' },
          { name: 'Camarines Norte', code: 'CAN' },
          { name: 'Camarines Sur', code: 'CAS' },
          { name: 'Camiguin', code: 'CAM' },
          { name: 'Capiz', code: 'CAP' },
          { name: 'Caraga', code: '13' },
          { name: 'Catanduanes', code: 'CAT' },
          { name: 'Cavite', code: 'CAV' },
          { name: 'Cebu', code: 'CEB' },
          { name: 'Central Luzon', code: '03' },
          { name: 'Central Visayas', code: '07' },
          { name: 'Compostela Valley', code: 'COM' },
          { name: 'Cordillera Administrative', code: '15' },
          { name: 'Cotabato', code: 'NCO' },
          { name: 'Davao', code: '11' },
          { name: 'Davao del Norte', code: 'DAV' },
          { name: 'Davao del Sur', code: 'DAS' },
          { name: 'Davao Occidental', code: 'DVO' },
          { name: 'Davao Oriental', code: 'DAO' },
          { name: 'Dinagat Islands', code: 'DIN' },
          { name: 'Eastern Samar', code: 'EAS' },
          { name: 'Eastern Visayas', code: '08' },
          { name: 'Guimaras', code: 'GUI' },
          { name: 'Ifugao', code: 'IFU' },
          { name: 'Ilocos', code: '01' },
          { name: 'Ilocos Norte', code: 'ILN' },
          { name: 'Ilocos Sur', code: 'ILS' },
          { name: 'Iloilo', code: 'ILI' },
          { name: 'Isabela', code: 'ISA' },
          { name: 'Kalinga', code: 'KAL' },
          { name: 'La Union', code: 'LUN' },
          { name: 'Laguna', code: 'LAG' },
          { name: 'Lanao del Norte', code: 'LAN' },
          { name: 'Lanao del Sur', code: 'LAS' },
          { name: 'Leyte', code: 'LEY' },
          { name: 'Maguindanao', code: 'MAG' },
          { name: 'Marinduque', code: 'MAD' },
          { name: 'Masbate', code: 'MAS' },
          { name: 'Metro Manila', code: 'NCR' },
          { name: 'Mimaropa', code: '41' },
          { name: 'Misamis Occidental', code: 'MSC' },
          { name: 'Misamis Oriental', code: 'MSR' },
          { name: 'Mountain Province', code: 'MOU' },
          { name: 'Negros Occidental', code: 'NEC' },
          { name: 'Negros Oriental', code: 'NER' },
          { name: 'Northern Mindanao', code: '10' },
          { name: 'Northern Samar', code: 'NSA' },
          { name: 'Nueva Ecija', code: 'NUE' },
          { name: 'Nueva Vizcaya', code: 'NUV' },
          { name: 'Occidental Mindoro', code: 'MDC' },
          { name: 'Oriental Mindoro', code: 'MDR' },
          { name: 'Palawan', code: 'PLW' },
          { name: 'Pampanga', code: 'PAM' },
          { name: 'Pangasinan', code: 'PAN' },
          { name: 'Quezon', code: 'QUE' },
          { name: 'Quirino', code: 'QUI' },
          { name: 'Rizal', code: 'RIZ' },
          { name: 'Romblon', code: 'ROM' },
          { name: 'Sarangani', code: 'SAR' },
          { name: 'Siquijor', code: 'SIG' },
          { name: 'Soccsksargen', code: '12' },
          { name: 'Sorsogon', code: 'SOR' },
          { name: 'South Cotabato', code: 'SCO' },
          { name: 'Southern Leyte', code: 'SLE' },
          { name: 'Sultan Kudarat', code: 'SUK' },
          { name: 'Sulu', code: 'SLU' },
          { name: 'Surigao del Norte', code: 'SUN' },
          { name: 'Surigao del Sur', code: 'SUR' },
          { name: 'Tarlac', code: 'TAR' },
          { name: 'Tawi-Tawi', code: 'TAW' },
          { name: 'Western Samar', code: 'WSA' },
          { name: 'Western Visayas', code: '06' },
          { name: 'Zambales', code: 'ZMB' },
          { name: 'Zamboanga del Norte', code: 'ZAN' },
          { name: 'Zamboanga del Sur', code: 'ZAS' },
          { name: 'Zamboanga Peninsula', code: '09' },
          { name: 'Zamboanga Sibugay', code: 'ZSI' },
        ],
      },
    ],
    [
      'Pitcairn Island',
      {
        name: 'Pitcairn Island',
        code: 'PN',
        emoji: '🇵🇳',
        regions: [],
      },
    ],
    [
      'Poland',
      {
        name: 'Poland',
        code: 'PL',
        emoji: '🇵🇱',
        regions: [
          { name: 'Greater Poland Voivodeship', code: 'WP' },
          { name: 'Kuyavian-Pomeranian Voivodeship', code: 'KP' },
          { name: 'Lesser Poland Voivodeship', code: 'MA' },
          { name: 'Lower Silesian Voivodeship', code: 'DS' },
          { name: 'Lublin Voivodeship', code: 'LU' },
          { name: 'Lubusz Voivodeship', code: 'LB' },
          { name: 'Łódź Voivodeship', code: 'LD' },
          { name: 'Masovian Voivodeship', code: 'MZ' },
          { name: 'Opole Voivodeship', code: 'OP' },
          { name: 'Podkarpackie Voivodeship', code: 'PK' },
          { name: 'Podlaskie Voivodeship', code: 'PD' },
          { name: 'Pomeranian Voivodeship', code: 'PM' },
          { name: 'Silesian Voivodeship', code: 'SL' },
          { name: 'Świętokrzyskie Voivodeship', code: 'SK' },
          { name: 'Warmian-Masurian Voivodeship', code: 'WN' },
          { name: 'West Pomeranian Voivodeship', code: 'ZP' },
        ],
      },
    ],
    [
      'Portugal',
      {
        name: 'Portugal',
        code: 'PT',
        emoji: '🇵🇹',
        regions: [
          { name: 'Açores', code: '20' },
          { name: 'Aveiro', code: '01' },
          { name: 'Beja', code: '02' },
          { name: 'Braga', code: '03' },
          { name: 'Bragança', code: '04' },
          { name: 'Castelo Branco', code: '05' },
          { name: 'Coimbra', code: '06' },
          { name: 'Évora', code: '07' },
          { name: 'Faro', code: '08' },
          { name: 'Guarda', code: '09' },
          { name: 'Leiria', code: '10' },
          { name: 'Lisbon', code: '11' },
          { name: 'Madeira', code: '30' },
          { name: 'Portalegre', code: '12' },
          { name: 'Porto', code: '13' },
          { name: 'Santarém', code: '14' },
          { name: 'Setúbal', code: '15' },
          { name: 'Viana do Castelo', code: '16' },
          { name: 'Vila Real', code: '17' },
          { name: 'Viseu', code: '18' },
        ],
      },
    ],
    [
      'Puerto Rico',
      {
        name: 'Puerto Rico',
        code: 'PR',
        emoji: '🇵🇷',
        regions: [
          { name: 'Adjuntas', code: '001' },
          { name: 'Aguada', code: '003' },
          { name: 'Aguadilla', code: '005' },
          { name: 'Aguas Buenas', code: '007' },
          { name: 'Aibonito', code: '009' },
          { name: 'Añasco', code: '011' },
          { name: 'Arecibo', code: '013' },
          { name: 'Arecibo', code: 'AR' },
          { name: 'Arroyo', code: '015' },
          { name: 'Barceloneta', code: '017' },
          { name: 'Barranquitas', code: '019' },
          { name: 'Bayamon', code: 'BY' },
          { name: 'Bayamón', code: '021' },
          { name: 'Cabo Rojo', code: '023' },
          { name: 'Caguas', code: 'CG' },
          { name: 'Caguas', code: '025' },
          { name: 'Camuy', code: '027' },
          { name: 'Canóvanas', code: '029' },
          { name: 'Carolina', code: 'CL' },
          { name: 'Carolina', code: '031' },
          { name: 'Cataño', code: '033' },
          { name: 'Cayey', code: '035' },
          { name: 'Ceiba', code: '037' },
          { name: 'Ciales', code: '039' },
          { name: 'Cidra', code: '041' },
          { name: 'Coamo', code: '043' },
          { name: 'Comerío', code: '045' },
          { name: 'Corozal', code: '047' },
          { name: 'Culebra', code: '049' },
          { name: 'Dorado', code: '051' },
          { name: 'Fajardo', code: '053' },
          { name: 'Florida', code: '054' },
          { name: 'Guánica', code: '055' },
          { name: 'Guayama', code: '057' },
          { name: 'Guayanilla', code: '059' },
          { name: 'Guaynabo', code: 'GN' },
          { name: 'Guaynabo', code: '061' },
          { name: 'Gurabo', code: '063' },
          { name: 'Hatillo', code: '065' },
          { name: 'Hormigueros', code: '067' },
          { name: 'Humacao', code: '069' },
          { name: 'Isabela', code: '071' },
          { name: 'Jayuya', code: '073' },
          { name: 'Juana Díaz', code: '075' },
          { name: 'Juncos', code: '077' },
          { name: 'Lajas', code: '079' },
          { name: 'Lares', code: '081' },
          { name: 'Las Marías', code: '083' },
          { name: 'Las Piedras', code: '085' },
          { name: 'Loíza', code: '087' },
          { name: 'Luquillo', code: '089' },
          { name: 'Manatí', code: '091' },
          { name: 'Maricao', code: '093' },
          { name: 'Maunabo', code: '095' },
          { name: 'Mayagüez', code: 'MG' },
          { name: 'Mayagüez', code: '097' },
          { name: 'Moca', code: '099' },
          { name: 'Morovis', code: '101' },
          { name: 'Naguabo', code: '103' },
          { name: 'Naranjito', code: '105' },
          { name: 'Orocovis', code: '107' },
          { name: 'Patillas', code: '109' },
          { name: 'Peñuelas', code: '111' },
          { name: 'Ponce', code: 'PO' },
          { name: 'Ponce', code: '113' },
          { name: 'Quebradillas', code: '115' },
          { name: 'Rincón', code: '117' },
          { name: 'Río Grande', code: '119' },
          { name: 'Sabana Grande', code: '121' },
          { name: 'Salinas', code: '123' },
          { name: 'San Germán', code: '125' },
          { name: 'San Juan', code: '127' },
          { name: 'San Lorenzo', code: '129' },
          { name: 'San Sebastián', code: '131' },
          { name: 'Santa Isabel', code: '133' },
          { name: 'Toa Alta', code: '135' },
          { name: 'Toa Baja', code: 'TB' },
          { name: 'Trujillo Alto', code: 'TA' },
          { name: 'Trujillo Alto', code: '139' },
          { name: 'Utuado', code: '141' },
          { name: 'Vega Alta', code: '143' },
          { name: 'Vega Baja', code: '145' },
          { name: 'Vieques', code: '147' },
          { name: 'Villalba', code: '149' },
          { name: 'Yabucoa', code: '151' },
          { name: 'Yauco', code: '153' },
        ],
      },
    ],
    [
      'Qatar',
      {
        name: 'Qatar',
        code: 'QA',
        emoji: '🇶🇦',
        regions: [
          { name: 'Al Daayen', code: 'ZA' },
          { name: 'Al Khor', code: 'KH' },
          { name: 'Al Rayyan Municipality', code: 'RA' },
          { name: 'Al Wakrah', code: 'WA' },
          { name: 'Al-Shahaniya', code: 'SH' },
          { name: 'Doha', code: 'DA' },
          { name: 'Madinat ash Shamal', code: 'MS' },
          { name: 'Umm Salal Municipality', code: 'US' },
        ],
      },
    ],
    [
      'Reunion',
      {
        name: 'Reunion',
        code: 'RE',
        emoji: '🇷🇪',
        regions: [],
      },
    ],
    [
      'Romania',
      {
        name: 'Romania',
        code: 'RO',
        emoji: '🇷🇴',
        regions: [
          { name: 'Alba', code: 'AB' },
          { name: 'Arad County', code: 'AR' },
          { name: 'Arges', code: 'AG' },
          { name: 'Bacău County', code: 'BC' },
          { name: 'Bihor County', code: 'BH' },
          { name: 'Bistrița-Năsăud County', code: 'BN' },
          { name: 'Botoșani County', code: 'BT' },
          { name: 'Braila', code: 'BR' },
          { name: 'Brașov County', code: 'BV' },
          { name: 'Bucharest', code: 'B' },
          { name: 'Buzău County', code: 'BZ' },
          { name: 'Călărași County', code: 'CL' },
          { name: 'Caraș-Severin County', code: 'CS' },
          { name: 'Cluj County', code: 'CJ' },
          { name: 'Constanța County', code: 'CT' },
          { name: 'Covasna County', code: 'CV' },
          { name: 'Dâmbovița County', code: 'DB' },
          { name: 'Dolj County', code: 'DJ' },
          { name: 'Galați County', code: 'GL' },
          { name: 'Giurgiu County', code: 'GR' },
          { name: 'Gorj County', code: 'GJ' },
          { name: 'Harghita County', code: 'HR' },
          { name: 'Hunedoara County', code: 'HD' },
          { name: 'Ialomița County', code: 'IL' },
          { name: 'Iași County', code: 'IS' },
          { name: 'Ilfov County', code: 'IF' },
          { name: 'Maramureș County', code: 'MM' },
          { name: 'Mehedinți County', code: 'MH' },
          { name: 'Mureș County', code: 'MS' },
          { name: 'Neamț County', code: 'NT' },
          { name: 'Olt County', code: 'OT' },
          { name: 'Prahova County', code: 'PH' },
          { name: 'Sălaj County', code: 'SJ' },
          { name: 'Satu Mare County', code: 'SM' },
          { name: 'Sibiu County', code: 'SB' },
          { name: 'Suceava County', code: 'SV' },
          { name: 'Teleorman County', code: 'TR' },
          { name: 'Timiș County', code: 'TM' },
          { name: 'Tulcea County', code: 'TL' },
          { name: 'Vâlcea County', code: 'VL' },
          { name: 'Vaslui County', code: 'VS' },
          { name: 'Vrancea County', code: 'VN' },
        ],
      },
    ],
    [
      'Russia',
      {
        name: 'Russia',
        code: 'RU',
        emoji: '🇷🇺',
        regions: [
          { name: 'Altai Krai', code: 'ALT' },
          { name: 'Altai Republic', code: 'AL' },
          { name: 'Amur Oblast', code: 'AMU' },
          { name: 'Arkhangelsk', code: 'ARK' },
          { name: 'Astrakhan Oblast', code: 'AST' },
          { name: 'Belgorod Oblast', code: 'BEL' },
          { name: 'Bryansk Oblast', code: 'BRY' },
          { name: 'Chechen Republic', code: 'CE' },
          { name: 'Chelyabinsk Oblast', code: 'CHE' },
          { name: 'Chukotka Autonomous Okrug', code: 'CHU' },
          { name: 'Chuvash Republic', code: 'CU' },
          { name: 'Irkutsk', code: 'IRK' },
          { name: 'Ivanovo Oblast', code: 'IVA' },
          { name: 'Jewish Autonomous Oblast', code: 'YEV' },
          { name: 'Kabardino-Balkar Republic', code: 'KB' },
          { name: 'Kaliningrad', code: 'KGD' },
          { name: 'Kaluga Oblast', code: 'KLU' },
          { name: 'Kamchatka Krai', code: 'KAM' },
          { name: 'Karachay-Cherkess Republic', code: 'KC' },
          { name: 'Kemerovo Oblast', code: 'KEM' },
          { name: 'Khabarovsk Krai', code: 'KHA' },
          { name: 'Khanty-Mansi Autonomous Okrug', code: 'KHM' },
          { name: 'Kirov Oblast', code: 'KIR' },
          { name: 'Komi Republic', code: 'KO' },
          { name: 'Kostroma Oblast', code: 'KOS' },
          { name: 'Krasnodar Krai', code: 'KDA' },
          { name: 'Krasnoyarsk Krai', code: 'KYA' },
          { name: 'Kurgan Oblast', code: 'KGN' },
          { name: 'Kursk Oblast', code: 'KRS' },
          { name: 'Leningrad Oblast', code: 'LEN' },
          { name: 'Lipetsk Oblast', code: 'LIP' },
          { name: 'Magadan Oblast', code: 'MAG' },
          { name: 'Mari El Republic', code: 'ME' },
          { name: 'Moscow', code: 'MOW' },
          { name: 'Moscow Oblast', code: 'MOS' },
          { name: 'Murmansk Oblast', code: 'MUR' },
          { name: 'Nenets Autonomous Okrug', code: 'NEN' },
          { name: 'Nizhny Novgorod Oblast', code: 'NIZ' },
          { name: 'Novgorod Oblast', code: 'NGR' },
          { name: 'Novosibirsk', code: 'NVS' },
          { name: 'Omsk Oblast', code: 'OMS' },
          { name: 'Orenburg Oblast', code: 'ORE' },
          { name: 'Oryol Oblast', code: 'ORL' },
          { name: 'Penza Oblast', code: 'PNZ' },
          { name: 'Perm Krai', code: 'PER' },
          { name: 'Primorsky Krai', code: 'PRI' },
          { name: 'Pskov Oblast', code: 'PSK' },
          { name: 'Republic of Adygea', code: 'AD' },
          { name: 'Republic of Bashkortostan', code: 'BA' },
          { name: 'Republic of Buryatia', code: 'BU' },
          { name: 'Republic of Dagestan', code: 'DA' },
          { name: 'Republic of Ingushetia', code: 'IN' },
          { name: 'Republic of Kalmykia', code: 'KL' },
          { name: 'Republic of Karelia', code: 'KR' },
          { name: 'Republic of Khakassia', code: 'KK' },
          { name: 'Republic of Mordovia', code: 'MO' },
          { name: 'Republic of North Ossetia-Alania', code: 'SE' },
          { name: 'Republic of Tatarstan', code: 'TA' },
          { name: 'Rostov Oblast', code: 'ROS' },
          { name: 'Ryazan Oblast', code: 'RYA' },
          { name: 'Saint Petersburg', code: 'SPE' },
          { name: 'Sakha Republic', code: 'SA' },
          { name: 'Sakhalin', code: 'SAK' },
          { name: 'Samara Oblast', code: 'SAM' },
          { name: 'Saratov Oblast', code: 'SAR' },
          { name: 'Smolensk Oblast', code: 'SMO' },
          { name: 'Stavropol Krai', code: 'STA' },
          { name: 'Sverdlovsk', code: 'SVE' },
          { name: 'Tambov Oblast', code: 'TAM' },
          { name: 'Tomsk Oblast', code: 'TOM' },
          { name: 'Tula Oblast', code: 'TUL' },
          { name: 'Tuva Republic', code: 'TY' },
          { name: 'Tver Oblast', code: 'TVE' },
          { name: 'Tyumen Oblast', code: 'TYU' },
          { name: 'Udmurt Republic', code: 'UD' },
          { name: 'Ulyanovsk Oblast', code: 'ULY' },
          { name: 'Vladimir Oblast', code: 'VLA' },
          { name: 'Volgograd Oblast', code: 'VGG' },
          { name: 'Vologda Oblast', code: 'VLG' },
          { name: 'Voronezh Oblast', code: 'VOR' },
          { name: 'Yamalo-Nenets Autonomous Okrug', code: 'YAN' },
          { name: 'Yaroslavl Oblast', code: 'YAR' },
          { name: 'Zabaykalsky Krai', code: 'ZAB' },
        ],
      },
    ],
    [
      'Rwanda',
      {
        name: 'Rwanda',
        code: 'RW',
        emoji: '🇷🇼',
        regions: [
          { name: 'Eastern Province', code: '02' },
          { name: 'Kigali district', code: '01' },
          { name: 'Northern Province', code: '03' },
          { name: 'Southern Province', code: '05' },
          { name: 'Western Province', code: '04' },
        ],
      },
    ],
    [
      'Saint Helena',
      {
        name: 'Saint Helena',
        code: 'SH',
        emoji: '🇸🇭',
        regions: [],
      },
    ],
    [
      'Saint Kitts and Nevis',
      {
        name: 'Saint Kitts and Nevis',
        code: 'KN',
        emoji: '🇰🇳',
        regions: [
          { name: 'Christ Church Nichola Town Parish', code: '01' },
          { name: 'Nevis', code: 'N' },
          { name: 'Saint Anne Sandy Point Parish', code: '02' },
          { name: 'Saint George Gingerland Parish', code: '04' },
          { name: 'Saint James Windward Parish', code: '05' },
          { name: 'Saint John Capisterre Parish', code: '06' },
          { name: 'Saint John Figtree Parish', code: '07' },
          { name: 'Saint Kitts', code: 'K' },
          { name: 'Saint Mary Cayon Parish', code: '08' },
          { name: 'Saint Paul Capisterre Parish', code: '09' },
          { name: 'Saint Paul Charlestown Parish', code: '10' },
          { name: 'Saint Peter Basseterre Parish', code: '11' },
          { name: 'Saint Thomas Lowland Parish', code: '12' },
          { name: 'Saint Thomas Middle Island Parish', code: '13' },
          { name: 'Trinity Palmetto Point Parish', code: '15' },
        ],
      },
    ],
    [
      'Saint Lucia',
      {
        name: 'Saint Lucia',
        code: 'LC',
        emoji: '🇱🇨',
        regions: [
          { name: 'Anse la Raye Quarter', code: '01' },
          { name: 'Canaries', code: '12' },
          { name: 'Castries Quarter', code: '02' },
          { name: 'Choiseul Quarter', code: '03' },
          { name: 'Dauphin Quarter', code: '04' },
          { name: 'Dennery Quarter', code: '05' },
          { name: 'Gros Islet Quarter', code: '06' },
          { name: 'Laborie Quarter', code: '07' },
          { name: 'Micoud Quarter', code: '08' },
          { name: 'Praslin Quarter', code: '09' },
          { name: 'Soufrière Quarter', code: '10' },
          { name: 'Vieux Fort Quarter', code: '11' },
        ],
      },
    ],
    [
      'Saint Pierre and Miquelon',
      {
        name: 'Saint Pierre and Miquelon',
        code: 'PM',
        emoji: '🇵🇲',
        regions: [],
      },
    ],
    [
      'Saint Vincent and the Grenadines',
      {
        name: 'Saint Vincent and the Grenadines',
        code: 'VC',
        emoji: '🇻🇨',
        regions: [
          { name: 'Charlotte Parish', code: '01' },
          { name: 'Grenadines Parish', code: '06' },
          { name: 'Saint Andrew Parish', code: '02' },
          { name: 'Saint David Parish', code: '03' },
          { name: 'Saint George Parish', code: '04' },
          { name: 'Saint Patrick Parish', code: '05' },
        ],
      },
    ],
    [
      'Saint-Barthelemy',
      {
        name: 'Saint-Barthelemy',
        code: 'BL',
        emoji: '🇧🇱',
        regions: [],
      },
    ],
    [
      'Saint-Martin (French part)',
      {
        name: 'Saint-Martin (French part)',
        code: 'MF',
        emoji: '🇲🇫',
        regions: [],
      },
    ],
    [
      'Samoa',
      {
        name: 'Samoa',
        code: 'WS',
        emoji: '🇼🇸',
        regions: [
          { name: `A'ana`, code: 'AA' },
          { name: 'Aiga-i-le-Tai', code: 'AL' },
          { name: 'Atua', code: 'AT' },
          { name: `Fa'asaleleaga`, code: 'FA' },
          { name: `Gaga'emauga`, code: 'GE' },
          { name: `Gaga'ifomauga`, code: 'GI' },
          { name: 'Palauli', code: 'PA' },
          { name: `Satupa'itea`, code: 'SA' },
          { name: 'Tuamasaga', code: 'TU' },
          { name: `Va'a-o-Fonoti`, code: 'VF' },
          { name: 'Vaisigano', code: 'VS' },
        ],
      },
    ],
    [
      'San Marino',
      {
        name: 'San Marino',
        code: 'SM',
        emoji: '🇸🇲',
        regions: [
          { name: 'Acquaviva', code: '01' },
          { name: 'Borgo Maggiore', code: '06' },
          { name: 'Chiesanuova', code: '02' },
          { name: 'Domagnano', code: '03' },
          { name: 'Faetano', code: '04' },
          { name: 'Fiorentino', code: '05' },
          { name: 'Montegiardino', code: '08' },
          { name: 'San Marino', code: '07' },
          { name: 'Serravalle', code: '09' },
        ],
      },
    ],
    [
      'Sao Tome and Principe',
      {
        name: 'Sao Tome and Principe',
        code: 'ST',
        emoji: '🇸🇹',
        regions: [
          { name: 'Príncipe Province', code: 'P' },
          { name: 'São Tomé Province', code: 'S' },
        ],
      },
    ],
    [
      'Saudi Arabia',
      {
        name: 'Saudi Arabia',
        code: 'SA',
        emoji: '🇸🇦',
        regions: [
          { name: `'Asir`, code: '14' },
          { name: 'Al Bahah', code: '11' },
          { name: 'Al Jawf', code: '12' },
          { name: 'Al Madinah', code: '03' },
          { name: 'Al-Qassim', code: '05' },
          { name: 'Eastern Province', code: '04' },
          { name: `Ha'il`, code: '06' },
          { name: 'Jizan', code: '09' },
          { name: 'Makkah', code: '02' },
          { name: 'Najran', code: '10' },
          { name: 'Northern Borders', code: '08' },
          { name: 'Riyadh', code: '01' },
          { name: 'Tabuk', code: '07' },
        ],
      },
    ],
    [
      'Senegal',
      {
        name: 'Senegal',
        code: 'SN',
        emoji: '🇸🇳',
        regions: [
          { name: 'Dakar', code: 'DK' },
          { name: 'Diourbel Region', code: 'DB' },
          { name: 'Fatick', code: 'FK' },
          { name: 'Kaffrine', code: 'KA' },
          { name: 'Kaolack', code: 'KL' },
          { name: 'Kédougou', code: 'KE' },
          { name: 'Kolda', code: 'KD' },
          { name: 'Louga', code: 'LG' },
          { name: 'Matam', code: 'MT' },
          { name: 'Saint-Louis', code: 'SL' },
          { name: 'Sédhiou', code: 'SE' },
          { name: 'Tambacounda Region', code: 'TC' },
          { name: 'Thiès Region', code: 'TH' },
          { name: 'Ziguinchor', code: 'ZG' },
        ],
      },
    ],
    [
      'Serbia',
      {
        name: 'Serbia',
        code: 'RS',
        emoji: '🇷🇸',
        regions: [
          { name: 'Belgrade', code: '00' },
          { name: 'Bor District', code: '14' },
          { name: 'Braničevo District', code: '11' },
          { name: 'Central Banat District', code: '02' },
          { name: 'Jablanica District', code: '23' },
          { name: 'Kolubara District', code: '09' },
          { name: 'Mačva District', code: '08' },
          { name: 'Moravica District', code: '17' },
          { name: 'Nišava District', code: '20' },
          { name: 'North Bačka District', code: '01' },
          { name: 'North Banat District', code: '03' },
          { name: 'Pčinja District', code: '24' },
          { name: 'Pirot District', code: '22' },
          { name: 'Podunavlje District', code: '10' },
          { name: 'Pomoravlje District', code: '13' },
          { name: 'Rasina District', code: '19' },
          { name: 'Raška District', code: '18' },
          { name: 'South Bačka District', code: '06' },
          { name: 'South Banat District', code: '04' },
          { name: 'Srem District', code: '07' },
          { name: 'Šumadija District', code: '12' },
          { name: 'Toplica District', code: '21' },
          { name: 'Vojvodina', code: 'VO' },
          { name: 'West Bačka District', code: '05' },
          { name: 'Zaječar District', code: '15' },
          { name: 'Zlatibor District', code: '16' },
        ],
      },
    ],
    [
      'Seychelles',
      {
        name: 'Seychelles',
        code: 'SC',
        emoji: '🇸🇨',
        regions: [
          { name: 'Anse Boileau', code: '02' },
          { name: 'Anse Royale', code: '05' },
          { name: 'Anse-aux-Pins', code: '01' },
          { name: 'Au Cap', code: '04' },
          { name: 'Baie Lazare', code: '06' },
          { name: 'Baie Sainte Anne', code: '07' },
          { name: 'Beau Vallon', code: '08' },
          { name: 'Bel Air', code: '09' },
          { name: 'Bel Ombre', code: '10' },
          { name: 'Cascade', code: '11' },
          { name: 'Glacis', code: '12' },
          { name: `Grand'Anse Mahé`, code: '13' },
          { name: `Grand'Anse Praslin`, code: '14' },
          { name: 'La Digue', code: '15' },
          { name: 'La Rivière Anglaise', code: '16' },
          { name: 'Les Mamelles', code: '24' },
          { name: 'Mont Buxton', code: '17' },
          { name: 'Mont Fleuri', code: '18' },
          { name: 'Plaisance', code: '19' },
          { name: 'Pointe La Rue', code: '20' },
          { name: 'Port Glaud', code: '21' },
          { name: 'Roche Caiman', code: '25' },
          { name: 'Saint Louis', code: '22' },
          { name: 'Takamaka', code: '23' },
        ],
      },
    ],
    [
      'Sierra Leone',
      {
        name: 'Sierra Leone',
        code: 'SL',
        emoji: '🇸🇱',
        regions: [
          { name: 'Eastern Province', code: 'E' },
          { name: 'Northern Province', code: 'N' },
          { name: 'Southern Province', code: 'S' },
          { name: 'Western Area', code: 'W' },
        ],
      },
    ],
    [
      'Singapore',
      {
        name: 'Singapore',
        code: 'SG',
        emoji: '🇸🇬',
        regions: [
          { name: 'Central Singapore', code: '01' },
          { name: 'North East', code: '02' },
          { name: 'North West', code: '03' },
          { name: 'South East', code: '04' },
          { name: 'South West', code: '05' },
        ],
      },
    ],
    [
      'Sint Maarten (Dutch part)',
      {
        name: 'Sint Maarten (Dutch part)',
        code: 'SX',
        emoji: '🇸🇽',
        regions: [],
      },
    ],
    [
      'Slovakia',
      {
        name: 'Slovakia',
        code: 'SK',
        emoji: '🇸🇰',
        regions: [
          { name: 'Banská Bystrica Region', code: 'BC' },
          { name: 'Bratislava Region', code: 'BL' },
          { name: 'Košice Region', code: 'KI' },
          { name: 'Nitra Region', code: 'NI' },
          { name: 'Prešov Region', code: 'PV' },
          { name: 'Trenčín Region', code: 'TC' },
          { name: 'Trnava Region', code: 'TA' },
          { name: 'Žilina Region', code: 'ZI' },
        ],
      },
    ],
    [
      'Slovenia',
      {
        name: 'Slovenia',
        code: 'SI',
        emoji: '🇸🇮',
        regions: [
          { name: 'Ajdovščina Municipality', code: '001' },
          { name: 'Ankaran Municipality', code: '213' },
          { name: 'Beltinci Municipality', code: '002' },
          { name: 'Benedikt Municipality', code: '148' },
          { name: 'Bistrica ob Sotli Municipality', code: '149' },
          { name: 'Bled Municipality', code: '003' },
          { name: 'Bloke Municipality', code: '150' },
          { name: 'Bohinj Municipality', code: '004' },
          { name: 'Borovnica Municipality', code: '005' },
          { name: 'Bovec Municipality', code: '006' },
          { name: 'Braslovče Municipality', code: '151' },
          { name: 'Brda Municipality', code: '007' },
          { name: 'Brežice Municipality', code: '009' },
          { name: 'Brezovica Municipality', code: '008' },
          { name: 'Cankova Municipality', code: '152' },
          { name: 'Cerklje na Gorenjskem Municipality', code: '012' },
          { name: 'Cerknica Municipality', code: '013' },
          { name: 'Cerkno Municipality', code: '014' },
          { name: 'Cerkvenjak Municipality', code: '153' },
          { name: 'City Municipality of Celje', code: '011' },
          { name: 'City Municipality of Novo Mesto', code: '085' },
          { name: 'Črenšovci Municipality', code: '015' },
          { name: 'Črna na Koroškem Municipality', code: '016' },
          { name: 'Črnomelj Municipality', code: '017' },
          { name: 'Destrnik Municipality', code: '018' },
          { name: 'Divača Municipality', code: '019' },
          { name: 'Dobje Municipality', code: '154' },
          { name: 'Dobrepolje Municipality', code: '020' },
          { name: 'Dobrna Municipality', code: '155' },
          { name: 'Dobrova–Polhov Gradec Municipality', code: '021' },
          { name: 'Dobrovnik Municipality', code: '156' },
          { name: 'Dol pri Ljubljani Municipality', code: '022' },
          { name: 'Dolenjske Toplice Municipality', code: '157' },
          { name: 'Domžale Municipality', code: '023' },
          { name: 'Dornava Municipality', code: '024' },
          { name: 'Dravograd Municipality', code: '025' },
          { name: 'Duplek Municipality', code: '026' },
          { name: 'Gorenja Vas–Poljane Municipality', code: '027' },
          { name: 'Gorišnica Municipality', code: '028' },
          { name: 'Gorje Municipality', code: '207' },
          { name: 'Gornja Radgona Municipality', code: '029' },
          { name: 'Gornji Grad Municipality', code: '030' },
          { name: 'Gornji Petrovci Municipality', code: '031' },
          { name: 'Grad Municipality', code: '158' },
          { name: 'Grosuplje Municipality', code: '032' },
          { name: 'Hajdina Municipality', code: '159' },
          { name: 'Hoče–Slivnica Municipality', code: '160' },
          { name: 'Hodoš Municipality', code: '161' },
          { name: 'Horjul Municipality', code: '162' },
          { name: 'Hrastnik Municipality', code: '034' },
          { name: 'Hrpelje–Kozina Municipality', code: '035' },
          { name: 'Idrija Municipality', code: '036' },
          { name: 'Ig Municipality', code: '037' },
          { name: 'Ivančna Gorica Municipality', code: '039' },
          { name: 'Izola Municipality', code: '040' },
          { name: 'Jesenice Municipality', code: '041' },
          { name: 'Jezersko Municipality', code: '163' },
          { name: 'Juršinci Municipality', code: '042' },
          { name: 'Kamnik Municipality', code: '043' },
          { name: 'Kanal ob Soči Municipality', code: '044' },
          { name: 'Kidričevo Municipality', code: '045' },
          { name: 'Kobarid Municipality', code: '046' },
          { name: 'Kobilje Municipality', code: '047' },
          { name: 'Kočevje Municipality', code: '048' },
          { name: 'Komen Municipality', code: '049' },
          { name: 'Komenda Municipality', code: '164' },
          { name: 'Koper City Municipality', code: '050' },
          { name: 'Kostanjevica na Krki Municipality', code: '197' },
          { name: 'Kostel Municipality', code: '165' },
          { name: 'Kozje Municipality', code: '051' },
          { name: 'Kranj City Municipality', code: '052' },
          { name: 'Kranjska Gora Municipality', code: '053' },
          { name: 'Križevci Municipality', code: '166' },
          { name: 'Kungota', code: '055' },
          { name: 'Kuzma Municipality', code: '056' },
          { name: 'Laško Municipality', code: '057' },
          { name: 'Lenart Municipality', code: '058' },
          { name: 'Lendava Municipality', code: '059' },
          { name: 'Litija Municipality', code: '060' },
          { name: 'Ljubljana City Municipality', code: '061' },
          { name: 'Ljubno Municipality', code: '062' },
          { name: 'Ljutomer Municipality', code: '063' },
          { name: 'Log–Dragomer Municipality', code: '208' },
          { name: 'Logatec Municipality', code: '064' },
          { name: 'Loška Dolina Municipality', code: '065' },
          { name: 'Loški Potok Municipality', code: '066' },
          { name: 'Lovrenc na Pohorju Municipality', code: '167' },
          { name: 'Luče Municipality', code: '067' },
          { name: 'Lukovica Municipality', code: '068' },
          { name: 'Majšperk Municipality', code: '069' },
          { name: 'Makole Municipality', code: '198' },
          { name: 'Maribor City Municipality', code: '070' },
          { name: 'Markovci Municipality', code: '168' },
          { name: 'Medvode Municipality', code: '071' },
          { name: 'Mengeš Municipality', code: '072' },
          { name: 'Metlika Municipality', code: '073' },
          { name: 'Mežica Municipality', code: '074' },
          { name: 'Miklavž na Dravskem Polju Municipality', code: '169' },
          { name: 'Miren–Kostanjevica Municipality', code: '075' },
          { name: 'Mirna Municipality', code: '212' },
          { name: 'Mirna Peč Municipality', code: '170' },
          { name: 'Mislinja Municipality', code: '076' },
          { name: 'Mokronog–Trebelno Municipality', code: '199' },
          { name: 'Moravče Municipality', code: '077' },
          { name: 'Moravske Toplice Municipality', code: '078' },
          { name: 'Mozirje Municipality', code: '079' },
          { name: 'Municipality of Apače', code: '195' },
          { name: 'Municipality of Cirkulane', code: '196' },
          { name: 'Municipality of Ilirska Bistrica', code: '038' },
          { name: 'Municipality of Krško', code: '054' },
          { name: 'Municipality of Škofljica', code: '123' },
          { name: 'Murska Sobota City Municipality', code: '080' },
          { name: 'Muta Municipality', code: '081' },
          { name: 'Naklo Municipality', code: '082' },
          { name: 'Nazarje Municipality', code: '083' },
          { name: 'Nova Gorica City Municipality', code: '084' },
          { name: 'Odranci Municipality', code: '086' },
          { name: 'Oplotnica', code: '171' },
          { name: 'Ormož Municipality', code: '087' },
          { name: 'Osilnica Municipality', code: '088' },
          { name: 'Pesnica Municipality', code: '089' },
          { name: 'Piran Municipality', code: '090' },
          { name: 'Pivka Municipality', code: '091' },
          { name: 'Podčetrtek Municipality', code: '092' },
          { name: 'Podlehnik Municipality', code: '172' },
          { name: 'Podvelka Municipality', code: '093' },
          { name: 'Poljčane Municipality', code: '200' },
          { name: 'Polzela Municipality', code: '173' },
          { name: 'Postojna Municipality', code: '094' },
          { name: 'Prebold Municipality', code: '174' },
          { name: 'Preddvor Municipality', code: '095' },
          { name: 'Prevalje Municipality', code: '175' },
          { name: 'Ptuj City Municipality', code: '096' },
          { name: 'Puconci Municipality', code: '097' },
          { name: 'Rače–Fram Municipality', code: '098' },
          { name: 'Radeče Municipality', code: '099' },
          { name: 'Radenci Municipality', code: '100' },
          { name: 'Radlje ob Dravi Municipality', code: '101' },
          { name: 'Radovljica Municipality', code: '102' },
          { name: 'Ravne na Koroškem Municipality', code: '103' },
          { name: 'Razkrižje Municipality', code: '176' },
          { name: 'Rečica ob Savinji Municipality', code: '209' },
          { name: 'Renče–Vogrsko Municipality', code: '201' },
          { name: 'Ribnica Municipality', code: '104' },
          { name: 'Ribnica na Pohorju Municipality', code: '177' },
          { name: 'Rogaška Slatina Municipality', code: '106' },
          { name: 'Rogašovci Municipality', code: '105' },
          { name: 'Rogatec Municipality', code: '107' },
          { name: 'Ruše Municipality', code: '108' },
          { name: 'Šalovci Municipality', code: '033' },
          { name: 'Selnica ob Dravi Municipality', code: '178' },
          { name: 'Semič Municipality', code: '109' },
          { name: 'Šempeter–Vrtojba Municipality', code: '183' },
          { name: 'Šenčur Municipality', code: '117' },
          { name: 'Šentilj Municipality', code: '118' },
          { name: 'Šentjernej Municipality', code: '119' },
          { name: 'Šentjur Municipality', code: '120' },
          { name: 'Šentrupert Municipality', code: '211' },
          { name: 'Sevnica Municipality', code: '110' },
          { name: 'Sežana Municipality', code: '111' },
          { name: 'Škocjan Municipality', code: '121' },
          { name: 'Škofja Loka Municipality', code: '122' },
          { name: 'Slovenj Gradec City Municipality', code: '112' },
          { name: 'Slovenska Bistrica Municipality', code: '113' },
          { name: 'Slovenske Konjice Municipality', code: '114' },
          { name: 'Šmarje pri Jelšah Municipality', code: '124' },
          { name: 'Šmarješke Toplice Municipality', code: '206' },
          { name: 'Šmartno ob Paki Municipality', code: '125' },
          { name: 'Šmartno pri Litiji Municipality', code: '194' },
          { name: 'Sodražica Municipality', code: '179' },
          { name: 'Solčava Municipality', code: '180' },
          { name: 'Šoštanj Municipality', code: '126' },
          { name: 'Središče ob Dravi', code: '202' },
          { name: 'Starše Municipality', code: '115' },
          { name: 'Štore Municipality', code: '127' },
          { name: 'Straža Municipality', code: '203' },
          { name: 'Sveta Ana Municipality', code: '181' },
          { name: 'Sveta Trojica v Slovenskih Goricah Municipality', code: '204' },
          { name: 'Sveti Andraž v Slovenskih Goricah Municipality', code: '182' },
          { name: 'Sveti Jurij ob Ščavnici Municipality', code: '116' },
          { name: 'Sveti Jurij v Slovenskih Goricah Municipality', code: '210' },
          { name: 'Sveti Tomaž Municipality', code: '205' },
          { name: 'Tabor Municipality', code: '184' },
          { name: 'Tišina Municipality', code: '010' },
          { name: 'Tolmin Municipality', code: '128' },
          { name: 'Trbovlje Municipality', code: '129' },
          { name: 'Trebnje Municipality', code: '130' },
          { name: 'Trnovska Vas Municipality', code: '185' },
          { name: 'Tržič Municipality', code: '131' },
          { name: 'Trzin Municipality', code: '186' },
          { name: 'Turnišče Municipality', code: '132' },
          { name: 'Velika Polana Municipality', code: '187' },
          { name: 'Velike Lašče Municipality', code: '134' },
          { name: 'Veržej Municipality', code: '188' },
          { name: 'Videm Municipality', code: '135' },
          { name: 'Vipava Municipality', code: '136' },
          { name: 'Vitanje Municipality', code: '137' },
          { name: 'Vodice Municipality', code: '138' },
          { name: 'Vojnik Municipality', code: '139' },
          { name: 'Vransko Municipality', code: '189' },
          { name: 'Vrhnika Municipality', code: '140' },
          { name: 'Vuzenica Municipality', code: '141' },
          { name: 'Zagorje ob Savi Municipality', code: '142' },
          { name: 'Žalec Municipality', code: '190' },
          { name: 'Zavrč Municipality', code: '143' },
          { name: 'Železniki Municipality', code: '146' },
          { name: 'Žetale Municipality', code: '191' },
          { name: 'Žiri Municipality', code: '147' },
          { name: 'Žirovnica Municipality', code: '192' },
          { name: 'Zreče Municipality', code: '144' },
          { name: 'Žužemberk Municipality', code: '193' },
        ],
      },
    ],
    [
      'Solomon Islands',
      {
        name: 'Solomon Islands',
        code: 'SB',
        emoji: '🇸🇧',
        regions: [
          { name: 'Central Province', code: 'CE' },
          { name: 'Choiseul Province', code: 'CH' },
          { name: 'Guadalcanal Province', code: 'GU' },
          { name: 'Honiara', code: 'CT' },
          { name: 'Isabel Province', code: 'IS' },
          { name: 'Makira-Ulawa Province', code: 'MK' },
          { name: 'Malaita Province', code: 'ML' },
          { name: 'Rennell and Bellona Province', code: 'RB' },
          { name: 'Temotu Province', code: 'TE' },
          { name: 'Western Province', code: 'WE' },
        ],
      },
    ],
    [
      'Somalia',
      {
        name: 'Somalia',
        code: 'SO',
        emoji: '🇸🇴',
        regions: [
          { name: 'Awdal Region', code: 'AW' },
          { name: 'Bakool', code: 'BK' },
          { name: 'Banaadir', code: 'BN' },
          { name: 'Bari', code: 'BR' },
          { name: 'Bay', code: 'BY' },
          { name: 'Galguduud', code: 'GA' },
          { name: 'Gedo', code: 'GE' },
          { name: 'Hiran', code: 'HI' },
          { name: 'Lower Juba', code: 'JH' },
          { name: 'Lower Shebelle', code: 'SH' },
          { name: 'Middle Juba', code: 'JD' },
          { name: 'Middle Shebelle', code: 'SD' },
          { name: 'Mudug', code: 'MU' },
          { name: 'Nugal', code: 'NU' },
          { name: 'Sanaag Region', code: 'SA' },
          { name: 'Togdheer Region', code: 'TO' },
        ],
      },
    ],
    [
      'South Africa',
      {
        name: 'South Africa',
        code: 'ZA',
        emoji: '🇿🇦',
        regions: [
          { name: 'Eastern Cape', code: 'EC' },
          { name: 'Free State', code: 'FS' },
          { name: 'Gauteng', code: 'GP' },
          { name: 'KwaZulu-Natal', code: 'KZN' },
          { name: 'Limpopo', code: 'LP' },
          { name: 'Mpumalanga', code: 'MP' },
          { name: 'North West', code: 'NW' },
          { name: 'Northern Cape', code: 'NC' },
          { name: 'Western Cape', code: 'WC' },
        ],
      },
    ],
    [
      'South Georgia',
      {
        name: 'South Georgia',
        code: 'GS',
        emoji: '🇬🇸',
        regions: [],
      },
    ],
    [
      'South Korea',
      {
        name: 'South Korea',
        code: 'KR',
        emoji: '🇰🇷',
        regions: [
          { name: 'Busan', code: '26' },
          { name: 'Daegu', code: '27' },
          { name: 'Daejeon', code: '30' },
          { name: 'Gangwon Province', code: '42' },
          { name: 'Gwangju', code: '29' },
          { name: 'Gyeonggi Province', code: '41' },
          { name: 'Incheon', code: '28' },
          { name: 'Jeju', code: '49' },
          { name: 'North Chungcheong Province', code: '43' },
          { name: 'North Gyeongsang Province', code: '47' },
          { name: 'North Jeolla Province', code: '45' },
          { name: 'Sejong City', code: '50' },
          { name: 'Seoul', code: '11' },
          { name: 'South Chungcheong Province', code: '44' },
          { name: 'South Gyeongsang Province', code: '48' },
          { name: 'South Jeolla Province', code: '46' },
          { name: 'Ulsan', code: '31' },
        ],
      },
    ],
    [
      'South Sudan',
      {
        name: 'South Sudan',
        code: 'SS',
        emoji: '🇸🇸',
        regions: [
          { name: 'Central Equatoria', code: 'EC' },
          { name: 'Eastern Equatoria', code: 'EE' },
          { name: 'Jonglei State', code: 'JG' },
          { name: 'Lakes', code: 'LK' },
          { name: 'Northern Bahr el Ghazal', code: 'BN' },
          { name: 'Unity', code: 'UY' },
          { name: 'Upper Nile', code: 'NU' },
          { name: 'Warrap', code: 'WR' },
          { name: 'Western Bahr el Ghazal', code: 'BW' },
          { name: 'Western Equatoria', code: 'EW' },
        ],
      },
    ],
    [
      'Spain',
      {
        name: 'Spain',
        code: 'ES',
        emoji: '🇪🇸',
        regions: [
          { name: 'A Coruña', code: 'C' },
          { name: 'Albacete', code: 'AB' },
          { name: 'Alicante', code: 'A' },
          { name: 'Almeria', code: 'AL' },
          { name: 'Araba', code: 'VI' },
          { name: 'Asturias', code: 'O' },
          { name: 'Ávila', code: 'AV' },
          { name: 'Badajoz', code: 'BA' },
          { name: 'Barcelona', code: 'B' },
          { name: 'Bizkaia', code: 'BI' },
          { name: 'Burgos', code: 'BU' },
          { name: 'Caceres', code: 'CC' },
          { name: 'Cádiz', code: 'CA' },
          { name: 'Canarias', code: 'CN' },
          { name: 'Cantabria', code: 'S' },
          { name: 'Castellón', code: 'CS' },
          { name: 'Ceuta', code: 'CE' },
          { name: 'Ciudad Real', code: 'CR' },
          { name: 'Córdoba', code: 'CO' },
          { name: 'Cuenca', code: 'CU' },
          { name: 'Gipuzkoa', code: 'SS' },
          { name: 'Girona', code: 'GI' },
          { name: 'Granada', code: 'GR' },
          { name: 'Guadalajara', code: 'GU' },
          { name: 'Huelva', code: 'H' },
          { name: 'Huesca', code: 'HU' },
          { name: 'Islas Baleares', code: 'PM' },
          { name: 'Jaén', code: 'J' },
          { name: 'La Rioja', code: 'LO' },
          { name: 'Las Palmas', code: 'GC' },
          { name: 'León', code: 'LE' },
          { name: 'Lleida', code: 'L' },
          { name: 'Lugo', code: 'LU' },
          { name: 'Madrid', code: 'M' },
          { name: 'Málaga', code: 'MA' },
          { name: 'Melilla', code: 'ML' },
          { name: 'Murcia', code: 'MU' },
          { name: 'Navarra', code: 'NA' },
          { name: 'Ourense', code: 'OR' },
          { name: 'Palencia', code: 'P' },
          { name: 'Pontevedra', code: 'PO' },
          { name: 'Salamanca', code: 'SA' },
          { name: 'Santa Cruz de Tenerife', code: 'TF' },
          { name: 'Segovia', code: 'SG' },
          { name: 'Sevilla', code: 'SE' },
          { name: 'Soria', code: 'SO' },
          { name: 'Tarragona', code: 'T' },
          { name: 'Teruel', code: 'TE' },
          { name: 'Toledo', code: 'TO' },
          { name: 'Valencia', code: 'V' },
          { name: 'Valladolid', code: 'VA' },
          { name: 'Zamora', code: 'ZA' },
          { name: 'Zaragoza', code: 'Z' },
        ],
      },
    ],
    [
      'Sri Lanka',
      {
        name: 'Sri Lanka',
        code: 'LK',
        emoji: '🇱🇰',
        regions: [
          { name: 'Ampara District', code: '52' },
          { name: 'Anuradhapura District', code: '71' },
          { name: 'Badulla District', code: '81' },
          { name: 'Batticaloa District', code: '51' },
          { name: 'Central Province', code: '2' },
          { name: 'Colombo District', code: '11' },
          { name: 'Eastern Province', code: '5' },
          { name: 'Galle District', code: '31' },
          { name: 'Gampaha District', code: '12' },
          { name: 'Hambantota District', code: '33' },
          { name: 'Jaffna District', code: '41' },
          { name: 'Kalutara District', code: '13' },
          { name: 'Kandy District', code: '21' },
          { name: 'Kegalle District', code: '92' },
          { name: 'Kilinochchi District', code: '42' },
          { name: 'Mannar District', code: '43' },
          { name: 'Matale District', code: '22' },
          { name: 'Matara District', code: '32' },
          { name: 'Monaragala District', code: '82' },
          { name: 'Mullaitivu District', code: '45' },
          { name: 'North Central Province', code: '7' },
          { name: 'North Western Province', code: '6' },
          { name: 'Northern Province', code: '4' },
          { name: 'Nuwara Eliya District', code: '23' },
          { name: 'Polonnaruwa District', code: '72' },
          { name: 'Puttalam District', code: '62' },
          { name: 'Ratnapura district', code: '91' },
          { name: 'Sabaragamuwa Province', code: '9' },
          { name: 'Southern Province', code: '3' },
          { name: 'Trincomalee District', code: '53' },
          { name: 'Uva Province', code: '8' },
          { name: 'Vavuniya District', code: '44' },
          { name: 'Western Province', code: '1' },
        ],
      },
    ],
    [
      'Sudan',
      {
        name: 'Sudan',
        code: 'SD',
        emoji: '🇸🇩',
        regions: [
          { name: 'Al Jazirah', code: 'GZ' },
          { name: 'Al Qadarif', code: 'GD' },
          { name: 'Blue Nile', code: 'NB' },
          { name: 'Central Darfur', code: 'DC' },
          { name: 'East Darfur', code: 'DE' },
          { name: 'Kassala', code: 'KA' },
          { name: 'Khartoum', code: 'KH' },
          { name: 'North Darfur', code: 'DN' },
          { name: 'North Kordofan', code: 'KN' },
          { name: 'Northern', code: 'NO' },
          { name: 'Red Sea', code: 'RS' },
          { name: 'River Nile', code: 'NR' },
          { name: 'Sennar', code: 'SI' },
          { name: 'South Darfur', code: 'DS' },
          { name: 'South Kordofan', code: 'KS' },
          { name: 'West Darfur', code: 'DW' },
          { name: 'West Kordofan', code: 'GK' },
          { name: 'White Nile', code: 'NW' },
        ],
      },
    ],
    [
      'Suriname',
      {
        name: 'Suriname',
        code: 'SR',
        emoji: '🇸🇷',
        regions: [
          { name: 'Brokopondo District', code: 'BR' },
          { name: 'Commewijne District', code: 'CM' },
          { name: 'Coronie District', code: 'CR' },
          { name: 'Marowijne District', code: 'MA' },
          { name: 'Nickerie District', code: 'NI' },
          { name: 'Para District', code: 'PR' },
          { name: 'Paramaribo District', code: 'PM' },
          { name: 'Saramacca District', code: 'SA' },
          { name: 'Sipaliwini District', code: 'SI' },
          { name: 'Wanica District', code: 'WA' },
        ],
      },
    ],
    [
      'Svalbard and Jan Mayen Islands',
      {
        name: 'Svalbard and Jan Mayen Islands',
        code: 'SJ',
        emoji: '🇸🇯',
        regions: [],
      },
    ],
    [
      'Sweden',
      {
        name: 'Sweden',
        code: 'SE',
        emoji: '🇸🇪',
        regions: [
          { name: 'Blekinge County', code: 'K' },
          { name: 'Dalarna County', code: 'W' },
          { name: 'Gävleborg County', code: 'X' },
          { name: 'Gotland County', code: 'I' },
          { name: 'Halland County', code: 'N' },
          { name: 'Jämtland County', code: '0' },
          { name: 'Jönköping County', code: 'F' },
          { name: 'Kalmar County', code: 'H' },
          { name: 'Kronoberg County', code: 'G' },
          { name: 'Norrbotten County', code: 'BD' },
          { name: 'Örebro County', code: 'T' },
          { name: 'Östergötland County', code: 'E' },
          { name: 'Skåne County', code: 'M' },
          { name: 'Södermanland County', code: 'D' },
          { name: 'Stockholm County', code: 'AB' },
          { name: 'Uppsala County', code: 'C' },
          { name: 'Värmland County', code: 'S' },
          { name: 'Västerbotten County', code: 'AC' },
          { name: 'Västernorrland County', code: 'Y' },
          { name: 'Västmanland County', code: 'U' },
          { name: 'Västra Götaland County', code: 'O' },
        ],
      },
    ],
    [
      'Switzerland',
      {
        name: 'Switzerland',
        code: 'CH',
        emoji: '🇨🇭',
        regions: [
          { name: 'Aargau', code: 'AG' },
          { name: 'Appenzell Ausserrhoden', code: 'AR' },
          { name: 'Appenzell Innerrhoden', code: 'AI' },
          { name: 'Basel-Land', code: 'BL' },
          { name: 'Basel-Stadt', code: 'BS' },
          { name: 'Bern', code: 'BE' },
          { name: 'Fribourg', code: 'FR' },
          { name: 'Geneva', code: 'GE' },
          { name: 'Glarus', code: 'GL' },
          { name: 'Graubünden', code: 'GR' },
          { name: 'Jura', code: 'JU' },
          { name: 'Lucerne', code: 'LU' },
          { name: 'Neuchâtel', code: 'NE' },
          { name: 'Nidwalden', code: 'NW' },
          { name: 'Obwalden', code: 'OW' },
          { name: 'Schaffhausen', code: 'SH' },
          { name: 'Schwyz', code: 'SZ' },
          { name: 'Solothurn', code: 'SO' },
          { name: 'St. Gallen', code: 'SG' },
          { name: 'Thurgau', code: 'TG' },
          { name: 'Ticino', code: 'TI' },
          { name: 'Uri', code: 'UR' },
          { name: 'Valais', code: 'VS' },
          { name: 'Vaud', code: 'VD' },
          { name: 'Zug', code: 'ZG' },
          { name: 'Zürich', code: 'ZH' },
        ],
      },
    ],
    [
      'Syria',
      {
        name: 'Syria',
        code: 'SY',
        emoji: '🇸🇾',
        regions: [
          { name: 'Al-Hasakah', code: 'HA' },
          { name: 'Al-Raqqah', code: 'RA' },
          { name: 'Aleppo', code: 'HL' },
          { name: 'As-Suwayda', code: 'SU' },
          { name: 'Damascus', code: 'DI' },
          { name: 'Daraa', code: 'DR' },
          { name: 'Deir ez-Zor', code: 'DY' },
          { name: 'Hama', code: 'HM' },
          { name: 'Homs', code: 'HI' },
          { name: 'Idlib', code: 'ID' },
          { name: 'Latakia', code: 'LA' },
          { name: 'Quneitra', code: 'QU' },
          { name: 'Rif Dimashq', code: 'RD' },
          { name: 'Tartus', code: 'TA' },
        ],
      },
    ],
    [
      'Taiwan',
      {
        name: 'Taiwan',
        code: 'TW',
        emoji: '🇹🇼',
        regions: [
          { name: 'Changhua', code: 'CHA' },
          { name: 'Chiayi', code: 'CYI' },
          { name: 'Chiayi', code: 'CYQ' },
          { name: 'Hsinchu', code: 'HSQ' },
          { name: 'Hsinchu', code: 'HSZ' },
          { name: 'Hualien', code: 'HUA' },
          { name: 'Kaohsiung', code: 'KHH' },
          { name: 'Keelung', code: 'KEE' },
          { name: 'Kinmen', code: 'KIN' },
          { name: 'Lienchiang', code: 'LIE' },
          { name: 'Miaoli', code: 'MIA' },
          { name: 'Nantou', code: 'NAN' },
          { name: 'New Taipei', code: 'NWT' },
          { name: 'Penghu', code: 'PEN' },
          { name: 'Pingtung', code: 'PIF' },
          { name: 'Taichung', code: 'TXG' },
          { name: 'Tainan', code: 'TNN' },
          { name: 'Taipei', code: 'TPE' },
          { name: 'Taitung', code: 'TTT' },
          { name: 'Taoyuan', code: 'TAO' },
          { name: 'Yilan', code: 'ILA' },
          { name: 'Yunlin', code: 'YUN' },
        ],
      },
    ],
    [
      'Tajikistan',
      {
        name: 'Tajikistan',
        code: 'TJ',
        emoji: '🇹🇯',
        regions: [
          { name: 'districts of Republican Subordination', code: 'RA' },
          { name: 'Gorno-Badakhshan Autonomous Province', code: 'GB' },
          { name: 'Khatlon Province', code: 'KT' },
          { name: 'Sughd Province', code: 'SU' },
        ],
      },
    ],
    [
      'Tanzania',
      {
        name: 'Tanzania',
        code: 'TZ',
        emoji: '🇹🇿',
        regions: [
          { name: 'Arusha', code: '01' },
          { name: 'Dar es Salaam', code: '02' },
          { name: 'Dodoma', code: '03' },
          { name: 'Geita', code: '27' },
          { name: 'Iringa', code: '04' },
          { name: 'Kagera', code: '05' },
          { name: 'Katavi', code: '28' },
          { name: 'Kigoma', code: '08' },
          { name: 'Kilimanjaro', code: '09' },
          { name: 'Lindi', code: '12' },
          { name: 'Manyara', code: '26' },
          { name: 'Mara', code: '13' },
          { name: 'Mbeya', code: '14' },
          { name: 'Morogoro', code: '16' },
          { name: 'Mtwara', code: '17' },
          { name: 'Mwanza', code: '18' },
          { name: 'Njombe', code: '29' },
          { name: 'Pemba North', code: '06' },
          { name: 'Pemba South', code: '10' },
          { name: 'Pwani', code: '19' },
          { name: 'Rukwa', code: '20' },
          { name: 'Ruvuma', code: '21' },
          { name: 'Shinyanga', code: '22' },
          { name: 'Simiyu', code: '30' },
          { name: 'Singida', code: '23' },
          { name: 'Songwe', code: '31' },
          { name: 'Tabora', code: '24' },
          { name: 'Tanga', code: '25' },
          { name: 'Zanzibar North', code: '07' },
          { name: 'Zanzibar South', code: '11' },
          { name: 'Zanzibar West', code: '15' },
        ],
      },
    ],
    [
      'Thailand',
      {
        name: 'Thailand',
        code: 'TH',
        emoji: '🇹🇭',
        regions: [
          { name: 'Amnat Charoen', code: '37' },
          { name: 'Ang Thong', code: '15' },
          { name: 'Bangkok', code: '10' },
          { name: 'Bueng Kan', code: '38' },
          { name: 'Buri Ram', code: '31' },
          { name: 'Chachoengsao', code: '24' },
          { name: 'Chai Nat', code: '18' },
          { name: 'Chaiyaphum', code: '36' },
          { name: 'Chanthaburi', code: '22' },
          { name: 'Chiang Mai', code: '50' },
          { name: 'Chiang Rai', code: '57' },
          { name: 'Chon Buri', code: '20' },
          { name: 'Chumphon', code: '86' },
          { name: 'Kalasin', code: '46' },
          { name: 'Kamphaeng Phet', code: '62' },
          { name: 'Kanchanaburi', code: '71' },
          { name: 'Khon Kaen', code: '40' },
          { name: 'Krabi', code: '81' },
          { name: 'Lampang', code: '52' },
          { name: 'Lamphun', code: '51' },
          { name: 'Loei', code: '42' },
          { name: 'Lop Buri', code: '16' },
          { name: 'Mae Hong Son', code: '58' },
          { name: 'Maha Sarakham', code: '44' },
          { name: 'Mukdahan', code: '49' },
          { name: 'Nakhon Nayok', code: '26' },
          { name: 'Nakhon Pathom', code: '73' },
          { name: 'Nakhon Phanom', code: '48' },
          { name: 'Nakhon Ratchasima', code: '30' },
          { name: 'Nakhon Sawan', code: '60' },
          { name: 'Nakhon Si Thammarat', code: '80' },
          { name: 'Nan', code: '55' },
          { name: 'Narathiwat', code: '96' },
          { name: 'Nong Bua Lam Phu', code: '39' },
          { name: 'Nong Khai', code: '43' },
          { name: 'Nonthaburi', code: '12' },
          { name: 'Pathum Thani', code: '13' },
          { name: 'Pattani', code: '94' },
          { name: 'Pattaya', code: 'S' },
          { name: 'Phangnga', code: '82' },
          { name: 'Phatthalung', code: '93' },
          { name: 'Phayao', code: '56' },
          { name: 'Phetchabun', code: '67' },
          { name: 'Phetchaburi', code: '76' },
          { name: 'Phichit', code: '66' },
          { name: 'Phitsanulok', code: '65' },
          { name: 'Phra Nakhon Si Ayutthaya', code: '14' },
          { name: 'Phrae', code: '54' },
          { name: 'Phuket', code: '83' },
          { name: 'Prachin Buri', code: '25' },
          { name: 'Prachuap Khiri Khan', code: '77' },
          { name: 'Ranong', code: '85' },
          { name: 'Ratchaburi', code: '70' },
          { name: 'Rayong', code: '21' },
          { name: 'Roi Et', code: '45' },
          { name: 'Sa Kaeo', code: '27' },
          { name: 'Sakon Nakhon', code: '47' },
          { name: 'Samut Prakan', code: '11' },
          { name: 'Samut Sakhon', code: '74' },
          { name: 'Samut Songkhram', code: '75' },
          { name: 'Saraburi', code: '19' },
          { name: 'Satun', code: '91' },
          { name: 'Si Sa Ket', code: '33' },
          { name: 'Sing Buri', code: '17' },
          { name: 'Songkhla', code: '90' },
          { name: 'Sukhothai', code: '64' },
          { name: 'Suphan Buri', code: '72' },
          { name: 'Surat Thani', code: '84' },
          { name: 'Surin', code: '32' },
          { name: 'Tak', code: '63' },
          { name: 'Trang', code: '92' },
          { name: 'Trat', code: '23' },
          { name: 'Ubon Ratchathani', code: '34' },
          { name: 'Udon Thani', code: '41' },
          { name: 'Uthai Thani', code: '61' },
          { name: 'Uttaradit', code: '53' },
          { name: 'Yala', code: '95' },
          { name: 'Yasothon', code: '35' },
        ],
      },
    ],
    [
      'The Bahamas',
      {
        name: 'The Bahamas',
        code: 'BS',
        emoji: '🇧🇸',
        regions: [
          { name: 'Acklins', code: 'AK' },
          { name: 'Acklins and Crooked Islands', code: 'AC' },
          { name: 'Berry Islands', code: 'BY' },
          { name: 'Bimini', code: 'BI' },
          { name: 'Black Point', code: 'BP' },
          { name: 'Cat Island', code: 'CI' },
          { name: 'Central Abaco', code: 'CO' },
          { name: 'Central Andros', code: 'CS' },
          { name: 'Central Eleuthera', code: 'CE' },
          { name: 'Crooked Island', code: 'CK' },
          { name: 'East Grand Bahama', code: 'EG' },
          { name: 'Exuma', code: 'EX' },
          { name: 'Freeport', code: 'FP' },
          { name: 'Fresh Creek', code: 'FC' },
          { name: `Governor's Harbour`, code: 'GH' },
          { name: 'Grand Cay', code: 'GC' },
          { name: 'Green Turtle Cay', code: 'GT' },
          { name: 'Harbour Island', code: 'HI' },
          { name: 'High Rock', code: 'HR' },
          { name: 'Hope Town', code: 'HT' },
          { name: 'Inagua', code: 'IN' },
          { name: 'Kemps Bay', code: 'KB' },
          { name: 'Long Island', code: 'LI' },
          { name: 'Mangrove Cay', code: 'MC' },
          { name: 'Marsh Harbour', code: 'MH' },
          { name: 'Mayaguana District', code: 'MG' },
          { name: 'New Providence', code: 'NP' },
          { name: 'Nichollstown and Berry Islands', code: 'NB' },
          { name: 'North Abaco', code: 'NO' },
          { name: 'North Andros', code: 'NS' },
          { name: 'North Eleuthera', code: 'NE' },
          { name: 'Ragged Island', code: 'RI' },
          { name: 'Rock Sound', code: 'RS' },
          { name: 'Rum Cay District', code: 'RC' },
          { name: 'San Salvador and Rum Cay', code: 'SR' },
          { name: 'San Salvador Island', code: 'SS' },
          { name: 'Sandy Point', code: 'SP' },
          { name: 'South Abaco', code: 'SO' },
          { name: 'South Andros', code: 'SA' },
          { name: 'South Eleuthera', code: 'SE' },
          { name: 'Spanish Wells', code: 'SW' },
          { name: 'West Grand Bahama', code: 'WG' },
        ],
      },
    ],
    [
      'Timor-Leste',
      {
        name: 'Timor-Leste',
        code: 'TL',
        emoji: '🇹🇱',
        regions: [
          { name: 'Aileu municipality', code: 'AL' },
          { name: 'Ainaro Municipality', code: 'AN' },
          { name: 'Baucau Municipality', code: 'BA' },
          { name: 'Bobonaro Municipality', code: 'BO' },
          { name: 'Cova Lima Municipality', code: 'CO' },
          { name: 'Dili municipality', code: 'DI' },
          { name: 'Ermera District', code: 'ER' },
          { name: 'Lautém Municipality', code: 'LA' },
          { name: 'Liquiçá Municipality', code: 'LI' },
          { name: 'Manatuto District', code: 'MT' },
          { name: 'Manufahi Municipality', code: 'MF' },
          { name: 'Viqueque Municipality', code: 'VI' },
        ],
      },
    ],
    [
      'Togo',
      {
        name: 'Togo',
        code: 'TG',
        emoji: '🇹🇬',
        regions: [
          { name: 'Centrale Region', code: 'C' },
          { name: 'Kara Region', code: 'K' },
          { name: 'Maritime', code: 'M' },
          { name: 'Plateaux Region', code: 'P' },
          { name: 'Savanes Region', code: 'S' },
        ],
      },
    ],
    [
      'Tokelau',
      {
        name: 'Tokelau',
        code: 'TK',
        emoji: '🇹🇰',
        regions: [],
      },
    ],
    [
      'Tonga',
      {
        name: 'Tonga',
        code: 'TO',
        emoji: '🇹🇴',
        regions: [
          { name: 'Haʻapai', code: '02' },
          { name: 'ʻEua', code: '01' },
          { name: 'Niuas', code: '03' },
          { name: 'Tongatapu', code: '04' },
          { name: 'Vavaʻu', code: '05' },
        ],
      },
    ],
    [
      'Trinidad and Tobago',
      {
        name: 'Trinidad and Tobago',
        code: 'TT',
        emoji: '🇹🇹',
        regions: [
          { name: 'Arima', code: 'ARI' },
          { name: 'Chaguanas', code: 'CHA' },
          { name: 'Couva-Tabaquite-Talparo Regional Corporation', code: 'CTT' },
          { name: 'Diego Martin Regional Corporation', code: 'DMN' },
          { name: 'Eastern Tobago', code: 'ETO' },
          { name: 'Penal-Debe Regional Corporation', code: 'PED' },
          { name: 'Point Fortin', code: 'PTF' },
          { name: 'Port of Spain', code: 'POS' },
          { name: 'Princes Town Regional Corporation', code: 'PRT' },
          { name: 'Rio Claro-Mayaro Regional Corporation', code: 'MRC' },
          { name: 'San Fernando', code: 'SFO' },
          { name: 'San Juan-Laventille Regional Corporation', code: 'SJL' },
          { name: 'Sangre Grande Regional Corporation', code: 'SGE' },
          { name: 'Siparia Regional Corporation', code: 'SIP' },
          { name: 'Tunapuna-Piarco Regional Corporation', code: 'TUP' },
          { name: 'Western Tobago', code: 'WTO' },
        ],
      },
    ],
    [
      'Tunisia',
      {
        name: 'Tunisia',
        code: 'TN',
        emoji: '🇹🇳',
        regions: [
          { name: 'Ariana', code: '12' },
          { name: 'Béja', code: '31' },
          { name: 'Ben Arous', code: '13' },
          { name: 'Bizerte', code: '23' },
          { name: 'Gabès', code: '81' },
          { name: 'Gafsa', code: '71' },
          { name: 'Jendouba', code: '32' },
          { name: 'Kairouan', code: '41' },
          { name: 'Kasserine', code: '42' },
          { name: 'Kebili', code: '73' },
          { name: 'Kef', code: '33' },
          { name: 'Mahdia', code: '53' },
          { name: 'Manouba', code: '14' },
          { name: 'Medenine', code: '82' },
          { name: 'Monastir', code: '52' },
          { name: 'Nabeul', code: '21' },
          { name: 'Sfax', code: '61' },
          { name: 'Sidi Bouzid', code: '43' },
          { name: 'Siliana', code: '34' },
          { name: 'Sousse', code: '51' },
          { name: 'Tataouine', code: '83' },
          { name: 'Tozeur', code: '72' },
          { name: 'Tunis', code: '11' },
          { name: 'Zaghouan', code: '22' },
        ],
      },
    ],
    [
      'Turkey',
      {
        name: 'Turkey',
        code: 'TR',
        emoji: '🇹🇷',
        regions: [
          { name: 'Adana', code: '01' },
          { name: 'Adıyaman', code: '02' },
          { name: 'Afyonkarahisar', code: '03' },
          { name: 'Ağrı', code: '04' },
          { name: 'Aksaray', code: '68' },
          { name: 'Amasya', code: '05' },
          { name: 'Ankara', code: '06' },
          { name: 'Antalya', code: '07' },
          { name: 'Ardahan', code: '75' },
          { name: 'Artvin', code: '08' },
          { name: 'Aydın', code: '09' },
          { name: 'Balıkesir', code: '10' },
          { name: 'Bartın', code: '74' },
          { name: 'Batman', code: '72' },
          { name: 'Bayburt', code: '69' },
          { name: 'Bilecik', code: '11' },
          { name: 'Bingöl', code: '12' },
          { name: 'Bitlis', code: '13' },
          { name: 'Bolu', code: '14' },
          { name: 'Burdur', code: '15' },
          { name: 'Bursa', code: '16' },
          { name: 'Çanakkale', code: '17' },
          { name: 'Çankırı', code: '18' },
          { name: 'Çorum', code: '19' },
          { name: 'Denizli', code: '20' },
          { name: 'Diyarbakır', code: '21' },
          { name: 'Düzce', code: '81' },
          { name: 'Edirne', code: '22' },
          { name: 'Elazığ', code: '23' },
          { name: 'Erzincan', code: '24' },
          { name: 'Erzurum', code: '25' },
          { name: 'Eskişehir', code: '26' },
          { name: 'Gaziantep', code: '27' },
          { name: 'Giresun', code: '28' },
          { name: 'Gümüşhane', code: '29' },
          { name: 'Hakkâri', code: '30' },
          { name: 'Hatay', code: '31' },
          { name: 'Iğdır', code: '76' },
          { name: 'Isparta', code: '32' },
          { name: 'İstanbul', code: '34' },
          { name: 'İzmir', code: '35' },
          { name: 'Kahramanmaraş', code: '46' },
          { name: 'Karabük', code: '78' },
          { name: 'Karaman', code: '70' },
          { name: 'Kars', code: '36' },
          { name: 'Kastamonu', code: '37' },
          { name: 'Kayseri', code: '38' },
          { name: 'Kilis', code: '79' },
          { name: 'Kırıkkale', code: '71' },
          { name: 'Kırklareli', code: '39' },
          { name: 'Kırşehir', code: '40' },
          { name: 'Kocaeli', code: '41' },
          { name: 'Konya', code: '42' },
          { name: 'Kütahya', code: '43' },
          { name: 'Malatya', code: '44' },
          { name: 'Manisa', code: '45' },
          { name: 'Mardin', code: '47' },
          { name: 'Mersin', code: '33' },
          { name: 'Muğla', code: '48' },
          { name: 'Muş', code: '49' },
          { name: 'Nevşehir', code: '50' },
          { name: 'Niğde', code: '51' },
          { name: 'Ordu', code: '52' },
          { name: 'Osmaniye', code: '80' },
          { name: 'Rize', code: '53' },
          { name: 'Sakarya', code: '54' },
          { name: 'Samsun', code: '55' },
          { name: 'Şanlıurfa', code: '63' },
          { name: 'Siirt', code: '56' },
          { name: 'Sinop', code: '57' },
          { name: 'Sivas', code: '58' },
          { name: 'Şırnak', code: '73' },
          { name: 'Tekirdağ', code: '59' },
          { name: 'Tokat', code: '60' },
          { name: 'Trabzon', code: '61' },
          { name: 'Tunceli', code: '62' },
          { name: 'Uşak', code: '64' },
          { name: 'Van', code: '65' },
          { name: 'Yalova', code: '77' },
          { name: 'Yozgat', code: '66' },
          { name: 'Zonguldak', code: '67' },
        ],
      },
    ],
    [
      'Turkmenistan',
      {
        name: 'Turkmenistan',
        code: 'TM',
        emoji: '🇹🇲',
        regions: [
          { name: 'Ahal Region', code: 'A' },
          { name: 'Ashgabat', code: 'S' },
          { name: 'Balkan Region', code: 'B' },
          { name: 'Daşoguz Region', code: 'D' },
          { name: 'Lebap Region', code: 'L' },
          { name: 'Mary Region', code: 'M' },
        ],
      },
    ],
    [
      'Turks and Caicos Islands',
      {
        name: 'Turks and Caicos Islands',
        code: 'TC',
        emoji: '🇹🇨',
        regions: [],
      },
    ],
    [
      'Tuvalu',
      {
        name: 'Tuvalu',
        code: 'TV',
        emoji: '🇹🇻',
        regions: [
          { name: 'Funafuti', code: 'FUN' },
          { name: 'Nanumanga', code: 'NMG' },
          { name: 'Nanumea', code: 'NMA' },
          { name: 'Niutao Island Council', code: 'NIT' },
          { name: 'Nui', code: 'NUI' },
          { name: 'Nukufetau', code: 'NKF' },
          { name: 'Nukulaelae', code: 'NKL' },
          { name: 'Vaitupu', code: 'VAI' },
        ],
      },
    ],
    [
      'Uganda',
      {
        name: 'Uganda',
        code: 'UG',
        emoji: '🇺🇬',
        regions: [
          { name: 'Abim District', code: '314' },
          { name: 'Adjumani District', code: '301' },
          { name: 'Agago District', code: '322' },
          { name: 'Alebtong District', code: '323' },
          { name: 'Amolatar District', code: '315' },
          { name: 'Amudat District', code: '324' },
          { name: 'Amuria District', code: '216' },
          { name: 'Amuru District', code: '316' },
          { name: 'Apac District', code: '302' },
          { name: 'Arua District', code: '303' },
          { name: 'Budaka District', code: '217' },
          { name: 'Bududa District', code: '218' },
          { name: 'Bugiri District', code: '201' },
          { name: 'Buhweju District', code: '420' },
          { name: 'Buikwe District', code: '117' },
          { name: 'Bukedea District', code: '219' },
          { name: 'Bukomansimbi District', code: '118' },
          { name: 'Bukwo District', code: '220' },
          { name: 'Bulambuli District', code: '225' },
          { name: 'Buliisa District', code: '416' },
          { name: 'Bundibugyo District', code: '401' },
          { name: 'Bunyangabu District', code: '430' },
          { name: 'Bushenyi District', code: '402' },
          { name: 'Busia District', code: '202' },
          { name: 'Butaleja District', code: '221' },
          { name: 'Butambala District', code: '119' },
          { name: 'Butebo District', code: '233' },
          { name: 'Buvuma District', code: '120' },
          { name: 'Buyende District', code: '226' },
          { name: 'Central Region', code: 'C' },
          { name: 'Dokolo District', code: '317' },
          { name: 'Eastern Region', code: 'E' },
          { name: 'Gomba District', code: '121' },
          { name: 'Gulu District', code: '304' },
          { name: 'Ibanda District', code: '417' },
          { name: 'Iganga District', code: '203' },
          { name: 'Isingiro District', code: '418' },
          { name: 'Jinja District', code: '204' },
          { name: 'Kaabong District', code: '318' },
          { name: 'Kabale District', code: '404' },
          { name: 'Kabarole District', code: '405' },
          { name: 'Kaberamaido District', code: '213' },
          { name: 'Kagadi District', code: '427' },
          { name: 'Kakumiro District', code: '428' },
          { name: 'Kalangala District', code: '101' },
          { name: 'Kaliro District', code: '222' },
          { name: 'Kalungu District', code: '122' },
          { name: 'Kampala District', code: '102' },
          { name: 'Kamuli District', code: '205' },
          { name: 'Kamwenge District', code: '413' },
          { name: 'Kanungu District', code: '414' },
          { name: 'Kapchorwa District', code: '206' },
          { name: 'Kasese District', code: '406' },
          { name: 'Katakwi District', code: '207' },
          { name: 'Kayunga District', code: '112' },
          { name: 'Kibaale District', code: '407' },
          { name: 'Kiboga District', code: '103' },
          { name: 'Kibuku District', code: '227' },
          { name: 'Kiruhura District', code: '419' },
          { name: 'Kiryandongo District', code: '421' },
          { name: 'Kisoro District', code: '408' },
          { name: 'Kitgum District', code: '305' },
          { name: 'Koboko District', code: '319' },
          { name: 'Kole District', code: '325' },
          { name: 'Kotido District', code: '306' },
          { name: 'Kumi District', code: '208' },
          { name: 'Kween District', code: '228' },
          { name: 'Kyankwanzi District', code: '123' },
          { name: 'Kyegegwa District', code: '422' },
          { name: 'Kyenjojo District', code: '415' },
          { name: 'Kyotera District', code: '125' },
          { name: 'Lamwo District', code: '326' },
          { name: 'Lira District', code: '307' },
          { name: 'Luuka District', code: '229' },
          { name: 'Luwero District', code: '104' },
          { name: 'Lwengo District', code: '124' },
          { name: 'Lyantonde District', code: '114' },
          { name: 'Manafwa District', code: '223' },
          { name: 'Maracha District', code: '320' },
          { name: 'Masaka District', code: '105' },
          { name: 'Masindi District', code: '409' },
          { name: 'Mayuge District', code: '214' },
          { name: 'Mbale District', code: '209' },
          { name: 'Mbarara District', code: '410' },
          { name: 'Mitooma District', code: '423' },
          { name: 'Mityana District', code: '115' },
          { name: 'Moroto District', code: '308' },
          { name: 'Moyo District', code: '309' },
          { name: 'Mpigi District', code: '106' },
          { name: 'Mubende District', code: '107' },
          { name: 'Mukono District', code: '108' },
          { name: 'Nakapiripirit District', code: '311' },
          { name: 'Nakaseke District', code: '116' },
          { name: 'Nakasongola District', code: '109' },
          { name: 'Namayingo District', code: '230' },
          { name: 'Namisindwa District', code: '234' },
          { name: 'Namutumba District', code: '224' },
          { name: 'Napak District', code: '327' },
          { name: 'Nebbi District', code: '310' },
          { name: 'Ngora District', code: '231' },
          { name: 'Northern Region', code: 'N' },
          { name: 'Ntoroko District', code: '424' },
          { name: 'Ntungamo District', code: '411' },
          { name: 'Nwoya District', code: '328' },
          { name: 'Omoro District', code: '331' },
          { name: 'Otuke District', code: '329' },
          { name: 'Oyam District', code: '321' },
          { name: 'Pader District', code: '312' },
          { name: 'Pakwach District', code: '332' },
          { name: 'Pallisa District', code: '210' },
          { name: 'Rakai District', code: '110' },
          { name: 'Rubanda District', code: '429' },
          { name: 'Rubirizi District', code: '425' },
          { name: 'Rukiga District', code: '431' },
          { name: 'Rukungiri District', code: '412' },
          { name: 'Sembabule District', code: '111' },
          { name: 'Serere District', code: '232' },
          { name: 'Sheema District', code: '426' },
          { name: 'Sironko District', code: '215' },
          { name: 'Soroti District', code: '211' },
          { name: 'Tororo District', code: '212' },
          { name: 'Wakiso District', code: '113' },
          { name: 'Western Region', code: 'W' },
          { name: 'Yumbe District', code: '313' },
          { name: 'Zombo District', code: '330' },
        ],
      },
    ],
    [
      'Ukraine',
      {
        name: 'Ukraine',
        code: 'UA',
        emoji: '🇺🇦',
        regions: [
          { name: 'Autonomous Republic of Crimea', code: '43' },
          { name: 'Cherkaska oblast', code: '71' },
          { name: 'Chernihivska oblast', code: '74' },
          { name: 'Chernivetska oblast', code: '77' },
          { name: 'Dnipropetrovska oblast', code: '12' },
          { name: 'Donetska oblast', code: '14' },
          { name: 'Ivano-Frankivska oblast', code: '26' },
          { name: 'Kharkivska oblast', code: '63' },
          { name: 'Khersonska oblast', code: '65' },
          { name: 'Khmelnytska oblast', code: '68' },
          { name: 'Kirovohradska oblast', code: '35' },
          { name: 'Kyiv', code: '30' },
          { name: 'Kyivska oblast', code: '32' },
          { name: 'Luhanska oblast', code: '09' },
          { name: 'Lvivska oblast', code: '46' },
          { name: 'Mykolaivska oblast', code: '48' },
          { name: 'Odeska oblast', code: '51' },
          { name: 'Poltavska oblast', code: '53' },
          { name: 'Rivnenska oblast', code: '56' },
          { name: 'Sevastopol', code: '40' },
          { name: 'Sumska oblast', code: '59' },
          { name: 'Ternopilska oblast', code: '61' },
          { name: 'Vinnytska oblast', code: '05' },
          { name: 'Volynska oblast', code: '07' },
          { name: 'Zakarpatska Oblast', code: '21' },
          { name: 'Zaporizka oblast', code: '23' },
          { name: 'Zhytomyrska oblast', code: '18' },
        ],
      },
    ],
    [
      'United Arab Emirates',
      {
        name: 'United Arab Emirates',
        code: 'AE',
        emoji: '🇦🇪',
        regions: [
          { name: 'Abu Dhabi Emirate', code: 'AZ' },
          { name: 'Ajman Emirate', code: 'AJ' },
          { name: 'Dubai', code: 'DU' },
          { name: 'Fujairah', code: 'FU' },
          { name: 'Ras al-Khaimah', code: 'RK' },
          { name: 'Sharjah Emirate', code: 'SH' },
          { name: 'Umm al-Quwain', code: 'UQ' },
        ],
      },
    ],
    [
      'United Kingdom',
      {
        name: 'United Kingdom',
        code: 'GB',
        emoji: '🇬🇧',
        regions: [
          { name: 'Aberdeen', code: 'ABE' },
          { name: 'Aberdeenshire', code: 'ABD' },
          { name: 'Angus', code: 'ANS' },
          { name: 'Antrim', code: 'ANT' },
          { name: 'Antrim and Newtownabbey', code: 'ANN' },
          { name: 'Ards', code: 'ARD' },
          { name: 'Ards and North Down', code: 'AND' },
          { name: 'Argyll and Bute', code: 'AGB' },
          { name: 'Armagh City and District Council', code: 'ARM' },
          { name: 'Armagh, Banbridge and Craigavon', code: 'ABC' },
          { name: 'Ascension Island', code: 'SH-AC' },
          { name: 'Ballymena Borough', code: 'BLA' },
          { name: 'Ballymoney', code: 'BLY' },
          { name: 'Banbridge', code: 'BNB' },
          { name: 'Barnsley', code: 'BNS' },
          { name: 'Bath and North East Somerset', code: 'BAS' },
          { name: 'Bedford', code: 'BDF' },
          { name: 'Belfast district', code: 'BFS' },
          { name: 'Birmingham', code: 'BIR' },
          { name: 'Blackburn with Darwen', code: 'BBD' },
          { name: 'Blackpool', code: 'BPL' },
          { name: 'Blaenau Gwent County Borough', code: 'BGW' },
          { name: 'Bolton', code: 'BOL' },
          { name: 'Bournemouth', code: 'BMH' },
          { name: 'Bracknell Forest', code: 'BRC' },
          { name: 'Bradford', code: 'BRD' },
          { name: 'Bridgend County Borough', code: 'BGE' },
          { name: 'Brighton and Hove', code: 'BNH' },
          { name: 'Buckinghamshire', code: 'BKM' },
          { name: 'Bury', code: 'BUR' },
          { name: 'Caerphilly County Borough', code: 'CAY' },
          { name: 'Calderdale', code: 'CLD' },
          { name: 'Cambridgeshire', code: 'CAM' },
          { name: 'Carmarthenshire', code: 'CMN' },
          { name: 'Carrickfergus Borough Council', code: 'CKF' },
          { name: 'Castlereagh', code: 'CSR' },
          { name: 'Causeway Coast and Glens', code: 'CCG' },
          { name: 'Central Bedfordshire', code: 'CBF' },
          { name: 'Ceredigion', code: 'CGN' },
          { name: 'Cheshire East', code: 'CHE' },
          { name: 'Cheshire West and Chester', code: 'CHW' },
          { name: 'City and County of Cardiff', code: 'CRF' },
          { name: 'City and County of Swansea', code: 'SWA' },
          { name: 'City of Bristol', code: 'BST' },
          { name: 'City of Derby', code: 'DER' },
          { name: 'City of Kingston upon Hull', code: 'KHL' },
          { name: 'City of Leicester', code: 'LCE' },
          { name: 'City of London', code: 'LND' },
          { name: 'City of Nottingham', code: 'NGM' },
          { name: 'City of Peterborough', code: 'PTE' },
          { name: 'City of Plymouth', code: 'PLY' },
          { name: 'City of Portsmouth', code: 'POR' },
          { name: 'City of Southampton', code: 'STH' },
          { name: 'City of Stoke-on-Trent', code: 'STE' },
          { name: 'City of Sunderland', code: 'SND' },
          { name: 'City of Westminster', code: 'WSM' },
          { name: 'City of Wolverhampton', code: 'WLV' },
          { name: 'City of York', code: 'YOR' },
          { name: 'Clackmannanshire', code: 'CLK' },
          { name: 'Coleraine Borough Council', code: 'CLR' },
          { name: 'Conwy County Borough', code: 'CWY' },
          { name: 'Cookstown District Council', code: 'CKT' },
          { name: 'Cornwall', code: 'CON' },
          { name: 'County Durham', code: 'DUR' },
          { name: 'Coventry', code: 'COV' },
          { name: 'Craigavon Borough Council', code: 'CGV' },
          { name: 'Cumbria', code: 'CMA' },
          { name: 'Darlington', code: 'DAL' },
          { name: 'Denbighshire', code: 'DEN' },
          { name: 'Derbyshire', code: 'DBY' },
          { name: 'Derry City and Strabane', code: 'DRS' },
          { name: 'Derry City Council', code: 'DRY' },
          { name: 'Devon', code: 'DEV' },
          { name: 'Doncaster', code: 'DNC' },
          { name: 'Dorset', code: 'DOR' },
          { name: 'Down District Council', code: 'DOW' },
          { name: 'Dudley', code: 'DUD' },
          { name: 'Dumfries and Galloway', code: 'DGY' },
          { name: 'Dundee', code: 'DND' },
          { name: 'Dungannon and South Tyrone Borough Council', code: 'DGN' },
          { name: 'East Ayrshire', code: 'EAY' },
          { name: 'East Dunbartonshire', code: 'EDU' },
          { name: 'East Lothian', code: 'ELN' },
          { name: 'East Renfrewshire', code: 'ERW' },
          { name: 'East Riding of Yorkshire', code: 'ERY' },
          { name: 'East Sussex', code: 'ESX' },
          { name: 'Edinburgh', code: 'EDH' },
          { name: 'England', code: 'ENG' },
          { name: 'Essex', code: 'ESS' },
          { name: 'Falkirk', code: 'FAL' },
          { name: 'Fermanagh and Omagh', code: 'FMO' },
          { name: 'Fermanagh District Council', code: 'FER' },
          { name: 'Fife', code: 'FIF' },
          { name: 'Flintshire', code: 'FLN' },
          { name: 'Gateshead', code: 'GAT' },
          { name: 'Glasgow', code: 'GLG' },
          { name: 'Gloucestershire', code: 'GLS' },
          { name: 'Gwynedd', code: 'GWN' },
          { name: 'Halton', code: 'HAL' },
          { name: 'Hampshire', code: 'HAM' },
          { name: 'Hartlepool', code: 'HPL' },
          { name: 'Herefordshire', code: 'HEF' },
          { name: 'Hertfordshire', code: 'HRT' },
          { name: 'Highland', code: 'HLD' },
          { name: 'Inverclyde', code: 'IVC' },
          { name: 'Isle of Wight', code: 'IOW' },
          { name: 'Isles of Scilly', code: 'IOS' },
          { name: 'Kent', code: 'KEN' },
          { name: 'Kirklees', code: 'KIR' },
          { name: 'Knowsley', code: 'KWL' },
          { name: 'Lancashire', code: 'LAN' },
          { name: 'Larne Borough Council', code: 'LRN' },
          { name: 'Leeds', code: 'LDS' },
          { name: 'Leicestershire', code: 'LEC' },
          { name: 'Limavady Borough Council', code: 'LMV' },
          { name: 'Lincolnshire', code: 'LIN' },
          { name: 'Lisburn and Castlereagh', code: 'LBC' },
          { name: 'Lisburn City Council', code: 'LSB' },
          { name: 'Liverpool', code: 'LIV' },
          { name: 'London Borough of Barking and Dagenham', code: 'BDG' },
          { name: 'London Borough of Barnet', code: 'BNE' },
          { name: 'London Borough of Bexley', code: 'BEX' },
          { name: 'London Borough of Brent', code: 'BEN' },
          { name: 'London Borough of Bromley', code: 'BRY' },
          { name: 'London Borough of Camden', code: 'CMD' },
          { name: 'London Borough of Croydon', code: 'CRY' },
          { name: 'London Borough of Ealing', code: 'EAL' },
          { name: 'London Borough of Enfield', code: 'ENF' },
          { name: 'London Borough of Hackney', code: 'HCK' },
          { name: 'London Borough of Hammersmith and Fulham', code: 'HMF' },
          { name: 'London Borough of Haringey', code: 'HRY' },
          { name: 'London Borough of Harrow', code: 'HRW' },
          { name: 'London Borough of Havering', code: 'HAV' },
          { name: 'London Borough of Hillingdon', code: 'HIL' },
          { name: 'London Borough of Hounslow', code: 'HNS' },
          { name: 'London Borough of Islington', code: 'ISL' },
          { name: 'London Borough of Lambeth', code: 'LBH' },
          { name: 'London Borough of Lewisham', code: 'LEW' },
          { name: 'London Borough of Merton', code: 'MRT' },
          { name: 'London Borough of Newham', code: 'NWM' },
          { name: 'London Borough of Redbridge', code: 'RDB' },
          { name: 'London Borough of Richmond upon Thames', code: 'RIC' },
          { name: 'London Borough of Southwark', code: 'SWK' },
          { name: 'London Borough of Sutton', code: 'STN' },
          { name: 'London Borough of Tower Hamlets', code: 'TWH' },
          { name: 'London Borough of Waltham Forest', code: 'WFT' },
          { name: 'London Borough of Wandsworth', code: 'WND' },
          { name: 'Magherafelt District Council', code: 'MFT' },
          { name: 'Manchester', code: 'MAN' },
          { name: 'Medway', code: 'MDW' },
          { name: 'Merthyr Tydfil County Borough', code: 'MTY' },
          { name: 'Metropolitan Borough of Wigan', code: 'WGN' },
          { name: 'Mid and East Antrim', code: 'MEA' },
          { name: 'Mid Ulster', code: 'MUL' },
          { name: 'Middlesbrough', code: 'MDB' },
          { name: 'Midlothian', code: 'MLN' },
          { name: 'Milton Keynes', code: 'MIK' },
          { name: 'Monmouthshire', code: 'MON' },
          { name: 'Moray', code: 'MRY' },
          { name: 'Moyle District Council', code: 'MYL' },
          { name: 'Neath Port Talbot County Borough', code: 'NTL' },
          { name: 'Newcastle upon Tyne', code: 'NET' },
          { name: 'Newport', code: 'NWP' },
          { name: 'Newry and Mourne District Council', code: 'NYM' },
          { name: 'Newry, Mourne and Down', code: 'NMD' },
          { name: 'Newtownabbey Borough Council', code: 'NTA' },
          { name: 'Norfolk', code: 'NFK' },
          { name: 'North Ayrshire', code: 'NAY' },
          { name: 'North Down Borough Council', code: 'NDN' },
          { name: 'North East Lincolnshire', code: 'NEL' },
          { name: 'North Lanarkshire', code: 'NLK' },
          { name: 'North Lincolnshire', code: 'NLN' },
          { name: 'North Somerset', code: 'NSM' },
          { name: 'North Tyneside', code: 'NTY' },
          { name: 'North Yorkshire', code: 'NYK' },
          { name: 'Northamptonshire', code: 'NTH' },
          { name: 'Northern Ireland', code: 'NIR' },
          { name: 'Northumberland', code: 'NBL' },
          { name: 'Nottinghamshire', code: 'NTT' },
          { name: 'Oldham', code: 'OLD' },
          { name: 'Omagh District Council', code: 'OMH' },
          { name: 'Orkney Islands', code: 'ORK' },
          { name: 'Outer Hebrides', code: 'ELS' },
          { name: 'Oxfordshire', code: 'OXF' },
          { name: 'Pembrokeshire', code: 'PEM' },
          { name: 'Perth and Kinross', code: 'PKN' },
          { name: 'Poole', code: 'POL' },
          { name: 'Powys', code: 'POW' },
          { name: 'Reading', code: 'RDG' },
          { name: 'Redcar and Cleveland', code: 'RCC' },
          { name: 'Renfrewshire', code: 'RFW' },
          { name: 'Rhondda Cynon Taf', code: 'RCT' },
          { name: 'Rochdale', code: 'RCH' },
          { name: 'Rotherham', code: 'ROT' },
          { name: 'Royal Borough of Greenwich', code: 'GRE' },
          { name: 'Royal Borough of Kensington and Chelsea', code: 'KEC' },
          { name: 'Royal Borough of Kingston upon Thames', code: 'KTT' },
          { name: 'Rutland', code: 'RUT' },
          { name: 'Saint Helena', code: 'SH-HL' },
          { name: 'Salford', code: 'SLF' },
          { name: 'Sandwell', code: 'SAW' },
          { name: 'Scotland', code: 'SCT' },
          { name: 'Scottish Borders', code: 'SCB' },
          { name: 'Sefton', code: 'SFT' },
          { name: 'Sheffield', code: 'SHF' },
          { name: 'Shetland Islands', code: 'ZET' },
          { name: 'Shropshire', code: 'SHR' },
          { name: 'Slough', code: 'SLG' },
          { name: 'Solihull', code: 'SOL' },
          { name: 'Somerset', code: 'SOM' },
          { name: 'South Ayrshire', code: 'SAY' },
          { name: 'South Gloucestershire', code: 'SGC' },
          { name: 'South Lanarkshire', code: 'SLK' },
          { name: 'South Tyneside', code: 'STY' },
          { name: 'Southend-on-Sea', code: 'SOS' },
          { name: 'St Helens', code: 'SHN' },
          { name: 'Staffordshire', code: 'STS' },
          { name: 'Stirling', code: 'STG' },
          { name: 'Stockport', code: 'SKP' },
          { name: 'Stockton-on-Tees', code: 'STT' },
          { name: 'Strabane District Council', code: 'STB' },
          { name: 'Suffolk', code: 'SFK' },
          { name: 'Surrey', code: 'SRY' },
          { name: 'Swindon', code: 'SWD' },
          { name: 'Tameside', code: 'TAM' },
          { name: 'Telford and Wrekin', code: 'TFW' },
          { name: 'Thurrock', code: 'THR' },
          { name: 'Torbay', code: 'TOB' },
          { name: 'Torfaen', code: 'TOF' },
          { name: 'Trafford', code: 'TRF' },
          { name: 'United Kingdom', code: 'UKM' },
          { name: 'Vale of Glamorgan', code: 'VGL' },
          { name: 'Wakefield', code: 'WKF' },
          { name: 'Wales', code: 'WLS' },
          { name: 'Walsall', code: 'WLL' },
          { name: 'Warrington', code: 'WRT' },
          { name: 'Warwickshire', code: 'WAR' },
          { name: 'West Berkshire', code: 'WBK' },
          { name: 'West Dunbartonshire', code: 'WDU' },
          { name: 'West Lothian', code: 'WLN' },
          { name: 'West Sussex', code: 'WSX' },
          { name: 'Wiltshire', code: 'WIL' },
          { name: 'Windsor and Maidenhead', code: 'WNM' },
          { name: 'Wirral', code: 'WRL' },
          { name: 'Wokingham', code: 'WOK' },
          { name: 'Worcestershire', code: 'WOR' },
          { name: 'Wrexham County Borough', code: 'WRX' },
        ],
      },
    ],
    [
      'United States',
      {
        name: 'United States',
        code: 'US',
        emoji: '🇺🇸',
        regions: [
          { name: 'Alabama', code: 'AL' },
          { name: 'Alaska', code: 'AK' },
          { name: 'American Samoa', code: 'AS' },
          { name: 'Arizona', code: 'AZ' },
          { name: 'Arkansas', code: 'AR' },
          { name: 'Baker Island', code: 'UM-81' },
          { name: 'California', code: 'CA' },
          { name: 'Colorado', code: 'CO' },
          { name: 'Connecticut', code: 'CT' },
          { name: 'Delaware', code: 'DE' },
          { name: 'District of Columbia', code: 'DC' },
          { name: 'Florida', code: 'FL' },
          { name: 'Georgia', code: 'GA' },
          { name: 'Guam', code: 'GU' },
          { name: 'Hawaii', code: 'HI' },
          { name: 'Howland Island', code: 'UM-84' },
          { name: 'Idaho', code: 'ID' },
          { name: 'Illinois', code: 'IL' },
          { name: 'Indiana', code: 'IN' },
          { name: 'Iowa', code: 'IA' },
          { name: 'Jarvis Island', code: 'UM-86' },
          { name: 'Johnston Atoll', code: 'UM-67' },
          { name: 'Kansas', code: 'KS' },
          { name: 'Kentucky', code: 'KY' },
          { name: 'Kingman Reef', code: 'UM-89' },
          { name: 'Louisiana', code: 'LA' },
          { name: 'Maine', code: 'ME' },
          { name: 'Maryland', code: 'MD' },
          { name: 'Massachusetts', code: 'MA' },
          { name: 'Michigan', code: 'MI' },
          { name: 'Midway Atoll', code: 'UM-71' },
          { name: 'Minnesota', code: 'MN' },
          { name: 'Mississippi', code: 'MS' },
          { name: 'Missouri', code: 'MO' },
          { name: 'Montana', code: 'MT' },
          { name: 'Navassa Island', code: 'UM-76' },
          { name: 'Nebraska', code: 'NE' },
          { name: 'Nevada', code: 'NV' },
          { name: 'New Hampshire', code: 'NH' },
          { name: 'New Jersey', code: 'NJ' },
          { name: 'New Mexico', code: 'NM' },
          { name: 'New York', code: 'NY' },
          { name: 'North Carolina', code: 'NC' },
          { name: 'North Dakota', code: 'ND' },
          { name: 'Northern Mariana Islands', code: 'MP' },
          { name: 'Ohio', code: 'OH' },
          { name: 'Oklahoma', code: 'OK' },
          { name: 'Oregon', code: 'OR' },
          { name: 'Palmyra Atoll', code: 'UM-95' },
          { name: 'Pennsylvania', code: 'PA' },
          { name: 'Puerto Rico', code: 'PR' },
          { name: 'Rhode Island', code: 'RI' },
          { name: 'South Carolina', code: 'SC' },
          { name: 'South Dakota', code: 'SD' },
          { name: 'Tennessee', code: 'TN' },
          { name: 'Texas', code: 'TX' },
          { name: 'United States Minor Outlying Islands', code: 'UM' },
          { name: 'United States Virgin Islands', code: 'VI' },
          { name: 'Utah', code: 'UT' },
          { name: 'Vermont', code: 'VT' },
          { name: 'Virginia', code: 'VA' },
          { name: 'Wake Island', code: 'UM-79' },
          { name: 'Washington', code: 'WA' },
          { name: 'West Virginia', code: 'WV' },
          { name: 'Wisconsin', code: 'WI' },
          { name: 'Wyoming', code: 'WY' },
        ],
      },
    ],
    [
      'United States Minor Outlying Islands',
      {
        name: 'United States Minor Outlying Islands',
        code: 'UM',
        emoji: '🇺🇲',
        regions: [
          { name: 'Baker Island', code: '81' },
          { name: 'Howland Island', code: '84' },
          { name: 'Jarvis Island', code: '86' },
          { name: 'Johnston Atoll', code: '67' },
          { name: 'Kingman Reef', code: '89' },
          { name: 'Midway Islands', code: '71' },
          { name: 'Navassa Island', code: '76' },
          { name: 'Palmyra Atoll', code: '95' },
          { name: 'Wake Island', code: '79' },
        ],
      },
    ],
    [
      'Uruguay',
      {
        name: 'Uruguay',
        code: 'UY',
        emoji: '🇺🇾',
        regions: [
          { name: 'Artigas', code: 'AR' },
          { name: 'Canelones', code: 'CA' },
          { name: 'Cerro Largo', code: 'CL' },
          { name: 'Colonia', code: 'CO' },
          { name: 'Durazno', code: 'DU' },
          { name: 'Flores', code: 'FS' },
          { name: 'Florida', code: 'FD' },
          { name: 'Lavalleja', code: 'LA' },
          { name: 'Maldonado', code: 'MA' },
          { name: 'Montevideo', code: 'MO' },
          { name: 'Paysandú', code: 'PA' },
          { name: 'Río Negro', code: 'RN' },
          { name: 'Rivera', code: 'RV' },
          { name: 'Rocha', code: 'RO' },
          { name: 'Salto', code: 'SA' },
          { name: 'San José', code: 'SJ' },
          { name: 'Soriano', code: 'SO' },
          { name: 'Tacuarembó', code: 'TA' },
          { name: 'Treinta y Tres', code: 'TT' },
        ],
      },
    ],
    [
      'Uzbekistan',
      {
        name: 'Uzbekistan',
        code: 'UZ',
        emoji: '🇺🇿',
        regions: [
          { name: 'Andijan Region', code: 'AN' },
          { name: 'Bukhara Region', code: 'BU' },
          { name: 'Fergana Region', code: 'FA' },
          { name: 'Jizzakh Region', code: 'JI' },
          { name: 'Karakalpakstan', code: 'QR' },
          { name: 'Namangan Region', code: 'NG' },
          { name: 'Navoiy Region', code: 'NW' },
          { name: 'Qashqadaryo Region', code: 'QA' },
          { name: 'Samarqand Region', code: 'SA' },
          { name: 'Sirdaryo Region', code: 'SI' },
          { name: 'Surxondaryo Region', code: 'SU' },
          { name: 'Tashkent', code: 'TK' },
          { name: 'Tashkent Region', code: 'TO' },
          { name: 'Xorazm Region', code: 'XO' },
        ],
      },
    ],
    [
      'Vanuatu',
      {
        name: 'Vanuatu',
        code: 'VU',
        emoji: '🇻🇺',
        regions: [
          { name: 'Malampa', code: 'MAP' },
          { name: 'Penama', code: 'PAM' },
          { name: 'Sanma', code: 'SAM' },
          { name: 'Shefa', code: 'SEE' },
          { name: 'Tafea', code: 'TAE' },
          { name: 'Torba', code: 'TOB' },
        ],
      },
    ],
    [
      'Vatican City State (Holy See)',
      {
        name: 'Vatican City State (Holy See)',
        code: 'VA',
        emoji: '🇻🇦',
        regions: [],
      },
    ],
    [
      'Venezuela',
      {
        name: 'Venezuela',
        code: 'VE',
        emoji: '🇻🇪',
        regions: [
          { name: 'Amazonas', code: 'Z' },
          { name: 'Anzoátegui', code: 'B' },
          { name: 'Apure', code: 'C' },
          { name: 'Aragua', code: 'D' },
          { name: 'Barinas', code: 'E' },
          { name: 'Bolívar', code: 'F' },
          { name: 'Carabobo', code: 'G' },
          { name: 'Cojedes', code: 'H' },
          { name: 'Delta Amacuro', code: 'Y' },
          { name: 'Distrito Capital', code: 'A' },
          { name: 'Falcón', code: 'I' },
          { name: 'Federal Dependencies of Venezuela', code: 'W' },
          { name: 'Guárico', code: 'J' },
          { name: 'La Guaira', code: 'X' },
          { name: 'Lara', code: 'K' },
          { name: 'Mérida', code: 'L' },
          { name: 'Miranda', code: 'M' },
          { name: 'Monagas', code: 'N' },
          { name: 'Nueva Esparta', code: 'O' },
          { name: 'Portuguesa', code: 'P' },
          { name: 'Sucre', code: 'R' },
          { name: 'Táchira', code: 'S' },
          { name: 'Trujillo', code: 'T' },
          { name: 'Yaracuy', code: 'U' },
          { name: 'Zulia', code: 'V' },
        ],
      },
    ],
    [
      'Vietnam',
      {
        name: 'Vietnam',
        code: 'VN',
        emoji: '🇻🇳',
        regions: [
          { name: 'An Giang', code: '44' },
          { name: 'Bà Rịa-Vũng Tàu', code: '43' },
          { name: 'Bắc Giang', code: '54' },
          { name: 'Bắc Kạn', code: '53' },
          { name: 'Bạc Liêu', code: '55' },
          { name: 'Bắc Ninh', code: '56' },
          { name: 'Bến Tre', code: '50' },
          { name: 'Bình Dương', code: '57' },
          { name: 'Bình Định', code: '31' },
          { name: 'Bình Phước', code: '58' },
          { name: 'Bình Thuận', code: '40' },
          { name: 'Cà Mau', code: '59' },
          { name: 'Cần Thơ', code: 'CT' },
          { name: 'Cao Bằng', code: '04' },
          { name: 'Đà Nẵng', code: 'DN' },
          { name: 'Đắk Lắk', code: '33' },
          { name: 'Đắk Nông', code: '72' },
          { name: 'Điện Biên', code: '71' },
          { name: 'Đồng Nai', code: '39' },
          { name: 'Đồng Tháp', code: '45' },
          { name: 'Gia Lai', code: '30' },
          { name: 'Hà Giang', code: '03' },
          { name: 'Hà Nam', code: '63' },
          { name: 'Hà Nội', code: 'HN' },
          { name: 'Hà Tĩnh', code: '23' },
          { name: 'Hải Dương', code: '61' },
          { name: 'Hải Phòng', code: 'HP' },
          { name: 'Hậu Giang', code: '73' },
          { name: 'Hồ Chí Minh', code: 'SG' },
          { name: 'Hòa Bình', code: '14' },
          { name: 'Hưng Yên', code: '66' },
          { name: 'Khánh Hòa', code: '34' },
          { name: 'Kiên Giang', code: '47' },
          { name: 'Kon Tum', code: '28' },
          { name: 'Lai Châu', code: '01' },
          { name: 'Lâm Đồng', code: '35' },
          { name: 'Lạng Sơn', code: '09' },
          { name: 'Lào Cai', code: '02' },
          { name: 'Long An', code: '41' },
          { name: 'Nam Định', code: '67' },
          { name: 'Nghệ An', code: '22' },
          { name: 'Ninh Bình', code: '18' },
          { name: 'Ninh Thuận', code: '36' },
          { name: 'Phú Thọ', code: '68' },
          { name: 'Phú Yên', code: '32' },
          { name: 'Quảng Bình', code: '24' },
          { name: 'Quảng Nam', code: '27' },
          { name: 'Quảng Ngãi', code: '29' },
          { name: 'Quảng Ninh', code: '13' },
          { name: 'Quảng Trị', code: '25' },
          { name: 'Sóc Trăng', code: '52' },
          { name: 'Sơn La', code: '05' },
          { name: 'Tây Ninh', code: '37' },
          { name: 'Thái Bình', code: '20' },
          { name: 'Thái Nguyên', code: '69' },
          { name: 'Thanh Hóa', code: '21' },
          { name: 'Thừa Thiên-Huế', code: '26' },
          { name: 'Tiền Giang', code: '46' },
          { name: 'Trà Vinh', code: '51' },
          { name: 'Tuyên Quang', code: '07' },
          { name: 'Vĩnh Long', code: '49' },
          { name: 'Vĩnh Phúc', code: '70' },
          { name: 'Yên Bái', code: '06' },
        ],
      },
    ],
    [
      'Virgin Islands (British)',
      {
        name: 'Virgin Islands (British)',
        code: 'VG',
        emoji: '🇻🇬',
        regions: [],
      },
    ],
    [
      'Virgin Islands (US)',
      {
        name: 'Virgin Islands (US)',
        code: 'VI',
        emoji: '🇻🇮',
        regions: [
          { name: 'Saint Croix', code: 'SC' },
          { name: 'Saint John', code: 'SJ' },
          { name: 'Saint Thomas', code: 'ST' },
        ],
      },
    ],
    [
      'Wallis and Futuna Islands',
      {
        name: 'Wallis and Futuna Islands',
        code: 'WF',
        emoji: '🇼🇫',
        regions: [],
      },
    ],
    [
      'Western Sahara',
      {
        name: 'Western Sahara',
        code: 'EH',
        emoji: '🇪🇭',
        regions: [],
      },
    ],
    [
      'Yemen',
      {
        name: 'Yemen',
        code: 'YE',
        emoji: '🇾🇪',
        regions: [
          { name: `'Adan`, code: 'AD' },
          { name: `'Amran`, code: 'AM' },
          { name: 'Abyan', code: 'AB' },
          { name: `Al Bayda'`, code: 'BA' },
          { name: 'Al Hudaydah', code: 'HU' },
          { name: 'Al Jawf', code: 'JA' },
          { name: 'Al Mahrah', code: 'MR' },
          { name: 'Al Mahwit', code: 'MW' },
          { name: 'Amanat Al Asimah', code: 'SA' },
          { name: 'Dhamar', code: 'DH' },
          { name: 'Hadhramaut', code: 'HD' },
          { name: 'Hajjah', code: 'HJ' },
          { name: 'Ibb', code: 'IB' },
          { name: 'Lahij', code: 'LA' },
          { name: `Ma'rib`, code: 'MA' },
          { name: 'Raymah', code: 'RA' },
          { name: 'Saada', code: 'SD' },
          { name: `Sana'a`, code: 'SN' },
          { name: 'Shabwah', code: 'SH' },
          { name: 'Socotra', code: 'SU' },
          { name: `Ta'izz`, code: 'TA' },
        ],
      },
    ],
    [
      'Zambia',
      {
        name: 'Zambia',
        code: 'ZM',
        emoji: '🇿🇲',
        regions: [
          { name: 'Central Province', code: '02' },
          { name: 'Copperbelt Province', code: '08' },
          { name: 'Eastern Province', code: '03' },
          { name: 'Luapula Province', code: '04' },
          { name: 'Lusaka Province', code: '09' },
          { name: 'Muchinga Province', code: '10' },
          { name: 'Northern Province', code: '05' },
          { name: 'Northwestern Province', code: '06' },
          { name: 'Southern Province', code: '07' },
          { name: 'Western Province', code: '01' },
        ],
      },
    ],
    [
      'Zimbabwe',
      {
        name: 'Zimbabwe',
        code: 'ZW',
        emoji: '🇿🇼',
        regions: [
          { name: 'Bulawayo Province', code: 'BU' },
          { name: 'Harare Province', code: 'HA' },
          { name: 'Manicaland', code: 'MA' },
          { name: 'Mashonaland Central Province', code: 'MC' },
          { name: 'Mashonaland East Province', code: 'ME' },
          { name: 'Mashonaland West Province', code: 'MW' },
          { name: 'Masvingo Province', code: 'MV' },
          { name: 'Matabeleland North Province', code: 'MN' },
          { name: 'Matabeleland South Province', code: 'MS' },
          { name: 'Midlands Province', code: 'MI' },
        ],
      },
    ],
  ])
}
