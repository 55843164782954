// @generated by protoc-gen-es v1.10.0
// @generated from file service/admin/v1/admin_service.proto (package service.admin.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message service.admin.v1.ImpersonateRequest
 */
export const ImpersonateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.admin.v1.ImpersonateRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.admin.v1.ImpersonateResponse
 */
export const ImpersonateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.admin.v1.ImpersonateResponse",
  () => [
    { no: 1, name: "id_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "expires_in", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

