import type { LinguiConfig } from '@lingui/conf'
import { formatter } from '@lingui/format-po'

export const locales = [
  'cs',
  'da',
  'de',
  'el',
  'es',
  'en',
  'et',
  'fi',
  'fr',
  'hu',
  'it',
  'ja',
  'ko',
  'lb',
  'lt',
  'lv',
  'nb',
  'nl',
  'pl',
  'pt',
  'ru',
  'sk',
  'sl',
  'sv',
]

const config: LinguiConfig = {
  format: formatter({ lineNumbers: false }),
  locales: locales,
  catalogs: [
    {
      path: '<rootDir>/src/locales/{locale}',
      include: ['src/routes', 'src/utils', 'src/i18n'],
      exclude: ['src/pb', 'src/components'],
    },
  ],
  fallbackLocales: {
    default: 'en',
  },
  sourceLocale: 'en',
  orderBy: 'origin',
}

export default config
