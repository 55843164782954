import { Button } from '@/components/button.tsx'
import { Heading } from '@/components/heading.tsx'
import { Text } from '@/components/text.tsx'
import { confirmVerifyAndChangeEmailCode } from '@/pb/service/auth/v1/auth_service-AuthService_connectquery'
import { clearTokens } from '@/utils/token-util.ts'
import { callUnaryMethod } from '@connectrpc/connect-query'
import { createFileRoute } from '@tanstack/react-router'

import LogoIcon from '@/assets/LogoIcon.tsx'
import { getLocalizedError } from '@/i18n/error-localization.ts'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import * as Sentry from '@sentry/react'
import { z } from 'zod'

const emailConfirmSchema = z.object({
  code: z.string().catch(''),
})

export const Route = createFileRoute('/email/confirm')({
  loaderDeps: ({ search: { code } }) => ({ code }),
  loader: async ({ context: { connectTransport, queryClient }, deps: { code } }) => {
    await callUnaryMethod(confirmVerifyAndChangeEmailCode, { oobCode: code }, { transport: connectTransport })
    queryClient.clear()
    Sentry.getCurrentScope().clear()
    await clearTokens()
  },
  validateSearch: emailConfirmSchema,
  errorComponent: ({ error }) => EmailConfirmChangeError(error),
  component: EmailConfirmChangeSuccess,
})

function EmailConfirmChangeSuccess() {
  const navigate = Route.useNavigate()

  return (
    <div className='relative flex h-full w-full items-center justify-center sm:bg-gray-100 sm:dark:bg-zinc-950'>
      <LogoIcon className='absolute top-6 left-6 h-4 w-18' />
      <div className='sm:card w-full gap-4 rounded-none px-6 py-12 sm:max-w-[440px] sm:rounded-xl sm:px-12'>
        <div className='flex flex-col gap-y-6'>
          <Heading>
            <Trans context='ConfirmEmailChangePage' comment="Heading text that's shown on a succesful email change">
              Your email has been changed
            </Trans>
          </Heading>
          <Text>
            <Trans context='ConfirmEmailChangePage' comment="Detail text that's shown on a succesful email change">
              Please log in with your new email
            </Trans>
          </Text>
          <Button onClick={async () => await navigate({ to: '/login', replace: true })}>
            <Trans
              context='ConfirmEmailChangePage'
              comment='Button text for navigating to the login page after a succesful email change'
            >
              Log in
            </Trans>
          </Button>
        </div>
      </div>
    </div>
  )
}

function EmailConfirmChangeError(e: unknown) {
  const { _ } = useLingui()

  const { isExpectedError, message } = getLocalizedError(e)
  if (!isExpectedError) {
    Sentry.captureException(e)
  }

  return (
    <div className='relative flex h-full w-full items-center justify-center sm:bg-gray-100 sm:dark:bg-zinc-950'>
      <LogoIcon className='absolute top-6 left-6 h-4 w-18' />
      <div className='sm:card w-full rounded-none px-6 py-12 sm:max-w-[440px] sm:rounded-xl sm:px-12'>
        <div className='flex flex-col gap-y-6'>
          <Heading>
            <Trans
              context='ConfirmEmailChangePage'
              comment="Heading text that's shown when the new email is unable to be confirmed due to some error"
            >
              Failed to confirm new email
            </Trans>
          </Heading>
          <Text>{_(message)}</Text>
        </div>
      </div>
    </div>
  )
}
