// @generated by protoc-gen-es v1.10.0
// @generated from file service/feedback/v1/feedback_service.proto (package service.feedback.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message service.feedback.v1.CreateFeedbackRequest
 */
export const CreateFeedbackRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.feedback.v1.CreateFeedbackRequest",
  () => [
    { no: 1, name: "feedback", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.feedback.v1.CreateFeedbackResponse
 */
export const CreateFeedbackResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.feedback.v1.CreateFeedbackResponse",
  [],
);

