import { ErrorMessage, Field, Fieldset, Label } from '@/components/fieldset.tsx'
import { Heading } from '@/components/heading.tsx'
import { Input } from '@/components/input.tsx'
import { MessageBox } from '@/routes/-components/message-box.tsx'
import { SpinnerButton } from '@/routes/-components/spinner-button.tsx'
import { clearTokens, setTokens } from '@/utils/token-util.ts'
import { callUnaryMethod } from '@connectrpc/connect-query'
import * as Sentry from '@sentry/react'
import { useForm } from '@tanstack/react-form'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { type ReactNode, useState } from 'react'

import LogoIcon from '@/assets/LogoIcon.tsx'
import { getLocalizedError } from '@/i18n/error-localization.ts'
import { impersonate } from '@/pb/service/admin/v1/admin_service-AdminService_connectquery'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { z } from 'zod'

export const Route = createFileRoute('/_protected/admin')({
  component: Admin,
})

const impersonateSchema = z.object({
  email: z.string().email(),
})

type ImpersonateForm = z.infer<typeof impersonateSchema>

export function Admin(): ReactNode {
  const { connectTransport } = Route.useRouteContext()
  const navigate = useNavigate()

  const [serverError, setServerError] = useState<string | null>(null)

  const { _ } = useLingui()

  const form = useForm({
    defaultValues: {
      email: '',
    },
    onSubmit: handleSubmit,
    validatorAdapter: zodValidator(),
  })

  async function handleSubmit({ value }: { value: ImpersonateForm }) {
    setServerError(null)
    try {
      const response = await callUnaryMethod(
        impersonate,
        {
          email: value.email,
        },
        { transport: connectTransport },
      )
      await clearTokens()
      await setTokens(response.idToken, response.refreshToken)
      await navigate({ to: '/listings' })
    } catch (e) {
      const { isExpectedError, message } = getLocalizedError(e)
      if (!isExpectedError) {
        Sentry.captureException(e)
      }
      setServerError(_(message))
    }
  }

  const emailField = (
    <form.Field
      name='email'
      validators={{
        onSubmit: impersonateSchema.shape.email,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='LoginPage' comment='Label text for email input field'>
              Email
            </Trans>
          </Label>
          <Input
            id={field.name}
            name={field.name}
            type='email'
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            invalid={field.state.meta.errors.length > 0}
          />
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='LoginPage'
                comment='Input error message that is displayed when the user does not enter an email'
              >
                Please enter your email
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  return (
    <div className='relative flex h-full w-full items-center justify-center sm:bg-gray-100 sm:dark:bg-zinc-950'>
      <LogoIcon className='absolute top-6 left-6 h-4 w-18' />
      <div className='sm:card w-full rounded-none px-6 py-12 sm:max-w-[440px] sm:rounded-xl sm:px-12'>
        <form
          className='inline'
          onSubmit={async (e) => {
            e.preventDefault()
            e.stopPropagation()
            setServerError(null)
            await form.handleSubmit()
          }}
        >
          <form.Subscribe selector={(state) => [state.isSubmitting, state.canSubmit]}>
            {([isSubmitting, canSubmit]) => (
              <Fieldset disabled={isSubmitting} className='flex w-full flex-col space-y-5'>
                <Heading>
                  <Trans context='LoginPage' comment='Header text for the login page'>
                    Admin
                  </Trans>
                </Heading>
                {emailField}
                {serverError && <MessageBox error>{serverError}</MessageBox>}
                <SpinnerButton
                  className='w-full'
                  type='submit'
                  disabled={!canSubmit || isSubmitting}
                  showSpinner={isSubmitting}
                >
                  <Trans context='AdminPage' comment='Button text that impersonates an account'>
                    Impersonate account
                  </Trans>
                </SpinnerButton>
              </Fieldset>
            )}
          </form.Subscribe>
        </form>
      </div>
    </div>
  )
}
