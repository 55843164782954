import type { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 48 48' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M.5 24C.5 36.979 11.021 47.5 24 47.5c10.656 0 19.656-7.093 22.535-16.815a6.404 6.404 0 0 1-1.95.4l-13.826.59c-.03.522-.061 1.012-.092 1.465-.129 1.935-1.25 3.65-3.012 4.454-1.797.82-3.89.516-5.446-.8-1.051-.89-2.4-2.106-4.078-3.752-2.843-2.79-4.35-4.866-5.149-6.238a5.546 5.546 0 0 1 0-5.612c.799-1.371 2.306-3.448 5.15-6.238 1.677-1.646 3.025-2.862 4.076-3.75 1.556-1.318 3.65-1.622 5.447-.801 1.762.804 2.883 2.52 3.012 4.455.03.452.062.943.092 1.465l13.826.591a6.401 6.401 0 0 1 1.95.4C43.655 7.592 34.655.5 24 .5 11.021.5.5 11.021.5 24Zm25.91-10.868c-.708-.322-1.566-.229-2.264.362-.988.836-2.282 2.001-3.914 3.602-2.695 2.644-4.02 4.512-4.657 5.606a2.546 2.546 0 0 0 0 2.593c.636 1.093 1.963 2.961 4.657 5.606 1.632 1.601 2.927 2.767 3.914 3.603.698.59 1.556.684 2.264.362.702-.32 1.204-1.027 1.264-1.926.078-1.168.16-2.592.223-4.145l16.56-.707c1.434-.062 2.77-1.022 2.951-2.551.055-.461.092-.98.092-1.537 0-.557-.037-1.076-.092-1.537-.18-1.53-1.517-2.49-2.951-2.55l-16.56-.709a157.85 157.85 0 0 0-.223-4.146c-.06-.899-.563-1.605-1.265-1.926Z'
      clipRule='evenodd'
    />
  </svg>
)
export default SvgComponent
