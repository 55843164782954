import { Button } from '@/components/button.tsx'
import { Subheading } from '@/components/heading.tsx'
import { Text } from '@/components/text.tsx'
import { XCircleIcon } from '@heroicons/react/20/solid'
import { Trans } from '@lingui/macro'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { useRouter } from '@tanstack/react-router'
import { type ReactNode, useEffect } from 'react'

export function ErrorPage(): ReactNode {
  const router = useRouter()
  const queryErrorResetBoundary = useQueryErrorResetBoundary()

  useEffect(() => {
    queryErrorResetBoundary.reset()
  }, [queryErrorResetBoundary])

  return (
    <div className='flex h-full w-full items-center justify-center'>
      <div className='flex flex-col items-center gap-3'>
        <XCircleIcon className='size-8' />
        <Subheading>
          <Trans context='ErrorPage' comment='Subheading text for when page failed to load due to an unexpected error'>
            An unexpected error occurred
          </Trans>
        </Subheading>
        <Text>
          <Trans context='ErrorPage' comment='Detail text for when page failed to load due to an unexpected error'>
            Try reloading the page or try again later
          </Trans>
        </Text>
        <Button onClick={() => router.invalidate()}>
          <Trans
            context='ErrorPage'
            comment='Button text for reloading the page after it failed to load due to an unexpected error'
          >
            Reload page
          </Trans>
        </Button>
      </div>
    </div>
  )
}
