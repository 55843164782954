import { Divider } from '@/components/divider.tsx'
import { Description, Field, Label } from '@/components/fieldset.tsx'
import { Subheading } from '@/components/heading.tsx'
import { Text } from '@/components/text.tsx'
import { Trans } from '@lingui/macro'
import type { ReactNode } from 'react'

export function AdditionalDetailsSection(props: {
  amenitiesField: ReactNode
  nearbyAttractionsField: ReactNode
  featuresField: ReactNode
}): ReactNode {
  return (
    <section className='grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2'>
      <div className='space-y-1'>
        <Subheading>
          <Trans context='EditListingDetailsPage' comment='Subheading text for the additional details section'>
            Additional Details
          </Trans>
        </Subheading>
        <Text>
          <Trans context='EditListingDetailsPage' comment='Detail text for the additional details section'>
            Highlight amenities, nearby attractions, and special features that make your listing stand out
          </Trans>
        </Text>
      </div>
      <div className='flex flex-col gap-4'>
        <Field>
          <Label>
            <Trans context='EditListingDetailsPage' comment='Subheading text for the amenities section'>
              Amenities
            </Trans>
          </Label>
          <Description>
            <Trans context='EditListingDetailsPage' comment='Detail text for the amenities section'>
              List notable amenities such as a gym, pool, parking, or laundry facilities
            </Trans>
          </Description>
          <div className='mt-6 w-full'>{props.amenitiesField}</div>
        </Field>
        <Divider soft />
        <Field>
          <Label>
            <Trans context='EditListingDetailsPage' comment='Subheading text for the nearby attractions section'>
              Nearby attractions
            </Trans>
          </Label>
          <Description>
            <Trans context='EditListingDetailsPage' comment='Detail text for the nearby attractions section'>
              List popular local spots such as parks, schools, restaurants, shopping centers, or any other attractions
            </Trans>
          </Description>
          <div className='mt-6 w-full'>{props.nearbyAttractionsField}</div>
        </Field>
        <Divider soft />
        <Field>
          <Label>
            <Trans
              context='EditListingDetailsPage'
              comment='Subheading text for the features or selling points section'
            >
              Features or selling points
            </Trans>
          </Label>
          <Description>
            <Trans context='EditListingDetailsPage' comment='Detail text for the features or selling points section'>
              List any standout features, recent improvements, or unique aspects that make your listing special
            </Trans>
          </Description>
          <div className='mt-6 w-full'>{props.featuresField}</div>
        </Field>
      </div>
    </section>
  )
}
