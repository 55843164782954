import LogoIcon from '@/assets/LogoIcon.tsx'
import { Button } from '@/components/button.tsx'
import { ErrorMessage, Field, Fieldset, Label } from '@/components/fieldset.tsx'
import { Heading } from '@/components/heading.tsx'
import { Input } from '@/components/input.tsx'
import { Text } from '@/components/text.tsx'
import { getLocalizedError } from '@/i18n/error-localization.ts'
import { sendPasswordResetEmail } from '@/pb/service/auth/v1/auth_service-AuthService_connectquery'
import { MessageBox } from '@/routes/-components/message-box.tsx'
import { SpinnerButton } from '@/routes/-components/spinner-button.tsx'
import { callUnaryMethod } from '@connectrpc/connect-query'
import type { MessageDescriptor } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import * as Sentry from '@sentry/react'
import { useForm } from '@tanstack/react-form'
import { createFileRoute } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { useImmer } from 'use-immer'
import { z } from 'zod'

export const Route = createFileRoute('/_public/reset')({
  component: Reset,
})

const resetPasswordFormSchema = z.object({
  email: z.string().email(),
})

type ResetPasswordForm = z.infer<typeof resetPasswordFormSchema>

type FormStatus = 'initial' | 'success' | 'error'

interface FormState {
  status: FormStatus
  error: MessageDescriptor | null
}

export function Reset() {
  const { connectTransport } = Route.useRouteContext()
  const navigate = Route.useNavigate()

  const [formsState, setFormsState] = useImmer<FormState>({
    status: 'initial',
    error: null,
  })

  const { _ } = useLingui()

  const form = useForm({
    defaultValues: {
      email: '',
    },
    onSubmit: handleSubmit,
    validatorAdapter: zodValidator(),
  })

  async function handleSubmit({ value }: { value: ResetPasswordForm }) {
    try {
      await callUnaryMethod(
        sendPasswordResetEmail,
        {
          email: value.email,
        },
        { transport: connectTransport },
      )
      setFormsState((draft) => {
        draft.error = null
        draft.status = 'initial'
      })
    } catch (e) {
      const { isExpectedError, message } = getLocalizedError(e)
      if (!isExpectedError) {
        Sentry.captureException(e)
      }
      setFormsState((draft) => {
        draft.error = message
        draft.status = 'error'
      })
    }
  }

  const emailField = (
    <form.Field
      name='email'
      validators={{
        onSubmit: resetPasswordFormSchema.shape.email,
      }}
    >
      {(field) => (
        <Field disabled={form.state.isSubmitting}>
          <Label>
            <Trans context='ResetPasswordPage' comment="Label text that's shown for the email input field">
              Email
            </Trans>
          </Label>
          <Input
            id={field.name}
            name={field.name}
            type='email'
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            invalid={field.state.meta.errors.length > 0}
          />
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='ResetPasswordPage'
                comment='Input error message for when an email is not provided or is invalid'
              >
                Please enter a valid email
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  return (
    <div className='relative flex h-full w-full items-center justify-center sm:bg-gray-100 sm:dark:bg-zinc-950'>
      <LogoIcon className='absolute top-6 left-6 h-4 w-18' />
      <div className='sm:card w-full rounded-none px-6 py-12 sm:max-w-[440px] sm:rounded-xl sm:px-12'>
        <form
          className='inline'
          onSubmit={async (e) => {
            setFormsState((draft) => {
              draft.error = null
              draft.status = 'initial'
            })
            e.preventDefault()
            e.stopPropagation()
            await form.handleSubmit()
          }}
        >
          <form.Subscribe selector={(state) => [state.isSubmitting, state.canSubmit]}>
            {([isSubmitting, canSubmit]) => (
              <Fieldset disabled={isSubmitting} className='flex w-full flex-col space-y-5'>
                {formsState.status === 'initial' ? (
                  <>
                    <Heading>
                      <Trans context='ResetPasswordPage' comment='Heading text for reset password page'>
                        Reset your password
                      </Trans>
                    </Heading>
                    <Text>
                      <Trans context='ResetPasswordPage' comment='Detail text for reset password page'>
                        Enter the email address associated with your account and we'll send you a link to reset your
                        password.
                      </Trans>
                    </Text>
                  </>
                ) : (
                  <>
                    <Heading>
                      <Trans
                        context='ResetPasswordPage'
                        comment='Heading text for successfully sent password reset email'
                      >
                        Check your email
                      </Trans>
                    </Heading>
                    <Text>
                      <Trans
                        context='ResetPasswordPage'
                        comment='Detail text for successfully sent password reset email'
                      >
                        Thanks! If <strong>{form.state.values.email}</strong> is associated with your account, then
                        we've sent you an email containing further instructions for resetting your password. If you
                        haven't received an email in 5 minutes, check your spam.
                      </Trans>
                    </Text>
                  </>
                )}

                {formsState.status === 'initial' && (
                  <>
                    {emailField}
                    {/* biome-ignore lint/style/noNonNullAssertion: <explanation> */}
                    {formsState.error && <MessageBox error>{_(formsState.error!)}</MessageBox>}
                    <SpinnerButton
                      className='w-full'
                      type='submit'
                      showSpinner={isSubmitting}
                      disabled={!canSubmit || isSubmitting}
                    >
                      <Trans context='ResetPasswordPage' comment='Button text for submitting password reset request'>
                        Continue
                      </Trans>
                    </SpinnerButton>
                    <Button plain className='min-h-10' onClick={async () => await navigate({ to: '/login' })}>
                      <Trans context='ResetPasswordPage' comment='Button text for navigating back to the log in page'>
                        Return to log in
                      </Trans>
                    </Button>
                  </>
                )}
              </Fieldset>
            )}
          </form.Subscribe>
        </form>
      </div>
    </div>
  )
}
