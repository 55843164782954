import { Button } from '@/components/button'
import { Spinner } from '@/routes/-components/spinner'
import clsx from 'clsx'
import type React from 'react'
import type { ReactNode } from 'react'

type ButtonProps = React.ComponentProps<typeof Button>

type SpinnerButtonProps = ButtonProps & {
  showSpinner: boolean
  children: React.ReactNode
}

export function SpinnerButton({ showSpinner, children, className, ...buttonProps }: SpinnerButtonProps): ReactNode {
  return (
    <Button className={clsx('relative flex min-h-10 items-center justify-center', className)} {...buttonProps}>
      <span className='invisible'>{children}</span>
      <span className='absolute inset-0 flex items-center justify-center'>
        {showSpinner ? <Spinner className='h-5 w-5' /> : children}
      </span>
    </Button>
  )
}
