// @generated by protoc-gen-connect-query v1.4.1
// @generated from file service/description/v1/description_service.proto (package service.description.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { ListDescriptionsForAccountRequest, ListDescriptionsForAccountResponse, ListDescriptionsForListingRequest, ListDescriptionsForListingResponse } from "./description_service_pb.js";

/**
 * @generated from rpc service.description.v1.DescriptionService.ListDescriptionsForAccount
 */
export const listDescriptionsForAccount = {
  localName: "listDescriptionsForAccount",
  name: "ListDescriptionsForAccount",
  kind: MethodKind.Unary,
  I: ListDescriptionsForAccountRequest,
  O: ListDescriptionsForAccountResponse,
  service: {
    typeName: "service.description.v1.DescriptionService"
  }
};

/**
 * @generated from rpc service.description.v1.DescriptionService.ListDescriptionsForListing
 */
export const listDescriptionsForListing = {
  localName: "listDescriptionsForListing",
  name: "ListDescriptionsForListing",
  kind: MethodKind.Unary,
  I: ListDescriptionsForListingRequest,
  O: ListDescriptionsForListingResponse,
  service: {
    typeName: "service.description.v1.DescriptionService"
  }
};
