import { Divider } from '@/components/divider.tsx'
import { Heading } from '@/components/heading.tsx'
import { Link } from '@/components/link.tsx'
import { getListing } from '@/pb/service/listing/v1/listing_service-ListingsService_connectquery'
import { ErrorPage } from '@/routes/-components/error-page.tsx'
import ResponsiveImage from '@/routes/-components/responsive-image.tsx'
import { createQueryOptions, useSuspenseQuery } from '@connectrpc/connect-query'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { Trans } from '@lingui/macro'
import { createFileRoute } from '@tanstack/react-router'
import type { ReactNode } from 'react'

export const Route = createFileRoute('/_protected/_dashboard/listings/$id/photos')({
  component: ListingPhotos,
  loader: async ({ context: { queryClient, connectTransport }, params }) => {
    await queryClient.prefetchQuery({
      ...createQueryOptions(getListing, { id: params.id }, { transport: connectTransport }),
    })
  },
})

function ListingPhotos(): ReactNode {
  const params = Route.useParams()

  const { data } = useSuspenseQuery(getListing, { id: params.id })
  if (data.listing === undefined) {
    return <ErrorPage />
  }

  const listing = data.listing

  return (
    <>
      <div className='hidden lg:block'>
        <Link
          from={Route.fullPath}
          href='/listings/$id'
          params={{ id: params.id }}
          className='inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400'
        >
          <ChevronLeftIcon className='size-4 fill-zinc-400 dark:fill-zinc-500' />
          <Trans context='ListingPhotosPage' comment='Link text for navigating back to listing details page'>
            Listing
          </Trans>
        </Link>
      </div>
      <div className='lg:mt-10'>
        <Heading>
          <Trans context='ListingPhotosPage' comment='Heading text for listing photos page'>
            Photos
          </Trans>
        </Heading>
        <Divider className='mt-6' />
      </div>
      <ul className='mt-10 grid w-full grid-cols-1 gap-4 sm:grid-cols-2'>
        {listing.images.map((image) => (
          <li key={image.id} className='aspect-[3/2]'>
            <Link href='/listings/$id/photos/$photoId' params={{ id: params.id, photoId: image.id }} aria-hidden='true'>
              <ResponsiveImage
                sizes='(min-width: 640px) 100vw, (min-width: 768px) 45vw, (min-width: 1024px) 45vw, (min-width: 1280px) 35vw, (min-width: 1536px) 35vw, 25vw'
                alt='Listing image'
                variants={image.variants}
                className='h-full w-full rounded-lg bg-clip-content object-cover object-center'
              />
            </Link>
          </li>
        ))}
      </ul>
    </>
  )
}
