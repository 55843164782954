import type { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 48 48' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M10.049 1.333c.758-1.387 2.75-1.387 3.51 0l1.324 2.423a11 11 0 0 0 4.373 4.373l2.423 1.325c1.387.759 1.387 2.751 0 3.51l-2.423 1.325a11 11 0 0 0-4.373 4.373l-1.325 2.423c-.759 1.387-2.75 1.387-3.51 0l-1.325-2.423a11 11 0 0 0-4.372-4.373l-2.423-1.325c-1.387-.759-1.387-2.751 0-3.51L4.35 8.13a11 11 0 0 0 4.372-4.373l1.326-2.423ZM31.38 11.946c2.09-2.09 5.462-2.88 8.136-.929a29.717 29.717 0 0 1 3.483 2.996 29.715 29.715 0 0 1 2.996 3.484c1.95 2.673 1.161 6.045-.93 8.135l-1.755 1.756c-.12-.247-.253-.51-.402-.788-1.044-1.945-2.836-4.609-5.862-7.634-2.763-2.763-5.225-4.498-7.112-5.573l1.446-1.447Zm-3.658 3.659L11.934 31.393c1.888 1.075 4.35 2.81 7.112 5.573 3.026 3.025 4.818 5.69 5.862 7.634.149.278.282.54.402.788L41.038 29.66a16.18 16.18 0 0 0-.774-1.642c-.904-1.685-2.52-4.112-5.339-6.93-2.818-2.82-5.246-4.436-6.931-5.34l-.272-.143ZM9.465 35.489c.055-.63.232-1.22.501-1.756l.028.015c1.685.904 4.113 2.52 6.931 5.34 2.818 2.818 4.435 5.245 5.34 6.93.247.463.442.87.594 1.215a4.795 4.795 0 0 1-1.335.314c-1.622.141-4.631.284-8.86-.01a3.436 3.436 0 0 1-3.19-3.19c-.293-4.228-.15-7.236-.009-8.858Z'
      clipRule='evenodd'
    />
  </svg>
)
export default SvgComponent
