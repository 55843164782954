import { getListing, listListings } from '@/pb/service/listing/v1/listing_service-ListingsService_connectquery'
import { deleteImageFromListing } from '@/pb/service/media/v1/media_service-MediaService_connectquery'
import { createConnectInfiniteQueryKey, createConnectQueryKey, useMutation } from '@connectrpc/connect-query'
import type { QueryClient } from '@tanstack/react-query'

export function useDeleteImageFromListingMutation(queryClient: QueryClient) {
  return useMutation(deleteImageFromListing, {
    onSuccess: async ({ listingId }, _variables) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getListing, { id: listingId }),
          exact: true,
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectInfiniteQueryKey(listListings),
          exact: true,
        }),
      ])
    },
  })
}
