import type { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 48 48' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M8.143 1.966C11.408 1.74 16.597 1.5 24 1.5s12.592.239 15.857.466c3.265.228 5.862 2.693 6.152 5.993.249 2.835.491 7.115.491 13.041s-.242 10.206-.491 13.041c-.29 3.3-2.887 5.765-6.152 5.993-3.265.227-8.454.466-15.857.466-3.835 0-7.075-.064-9.761-.157l-6.961 5.966c-2.27 1.946-5.778.333-5.778-2.657V21c0-5.926.242-10.206.491-13.041.29-3.3 2.887-5.765 6.152-5.993Zm3.035 18.19C10.876 18.344 12.398 17 14 17h20c1.602 0 3.125 1.344 2.822 3.156C35.794 26.31 30.446 31 24 31c-6.446 0-11.794-4.69-12.822-10.844Z'
      clipRule='evenodd'
    />
  </svg>
)
export default SvgComponent
