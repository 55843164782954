// @generated by protoc-gen-es v1.10.0
// @generated from file service/auth/v1/error.proto (package service.auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum service.auth.v1.AuthServiceError
 */
export const AuthServiceError = /*@__PURE__*/ proto3.makeEnum(
  "service.auth.v1.AuthServiceError",
  [
    {no: 0, name: "INVALID_EMAIL_OR_PASSWORD"},
    {no: 1, name: "ACCOUNT_DISABLED"},
    {no: 2, name: "TOO_MANY_ATTEMPTS"},
    {no: 3, name: "EMAIL_NOT_VERIFIED"},
    {no: 4, name: "EMAIL_ALREADY_EXISTS"},
    {no: 5, name: "WEAK_PASSWORD"},
    {no: 6, name: "INVALID_NEW_EMAIL"},
    {no: 7, name: "INVALID_OR_EXPIRED_CODE"},
    {no: 8, name: "INVALID_OR_EXPIRED_REFRESH_TOKEN"},
    {no: 9, name: "ACCOUNT_NOT_FOUND"},
    {no: 10, name: "INVALID_ID_TOKEN"},
  ],
);

