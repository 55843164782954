// @generated by protoc-gen-es v1.10.0
// @generated from file service/media/v1/media_service.proto (package service.media.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum service.media.v1.VariantType
 */
export const VariantType = /*@__PURE__*/ proto3.makeEnum(
  "service.media.v1.VariantType",
  [
    {no: 0, name: "UNSPECIFIED"},
    {no: 1, name: "ORIGINAL"},
    {no: 2, name: "FULL_SIZE"},
    {no: 3, name: "X_SMALL"},
    {no: 4, name: "SMALL"},
    {no: 5, name: "MEDIUM"},
    {no: 6, name: "LARGE"},
    {no: 7, name: "X_LARGE"},
    {no: 8, name: "XX_LARGE"},
    {no: 9, name: "XXX_LARGE"},
  ],
);

/**
 * @generated from message service.media.v1.GetImageUploadUrlRequest
 */
export const GetImageUploadUrlRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.media.v1.GetImageUploadUrlRequest",
  () => [
    { no: 1, name: "listing_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.media.v1.GetImageUploadUrlResponse
 */
export const GetImageUploadUrlResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.media.v1.GetImageUploadUrlResponse",
  () => [
    { no: 1, name: "image_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "signed_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "object_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.media.v1.AddImagesToListingRequest
 */
export const AddImagesToListingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.media.v1.AddImagesToListingRequest",
  () => [
    { no: 1, name: "listing_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "upload_details", kind: "message", T: UploadDetails, repeated: true },
  ],
);

/**
 * @generated from message service.media.v1.AddImagesToListingResponse
 */
export const AddImagesToListingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.media.v1.AddImagesToListingResponse",
  [],
);

/**
 * @generated from message service.media.v1.DeleteImageFromListingRequest
 */
export const DeleteImageFromListingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.media.v1.DeleteImageFromListingRequest",
  () => [
    { no: 1, name: "image_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.media.v1.DeleteImageFromListingResponse
 */
export const DeleteImageFromListingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.media.v1.DeleteImageFromListingResponse",
  () => [
    { no: 1, name: "listing_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.media.v1.ImageVariant
 */
export const ImageVariant = /*@__PURE__*/ proto3.makeMessageType(
  "service.media.v1.ImageVariant",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(VariantType) },
    { no: 6, name: "content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.media.v1.Image
 */
export const Image = /*@__PURE__*/ proto3.makeMessageType(
  "service.media.v1.Image",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "variants", kind: "message", T: ImageVariant, repeated: true },
    { no: 3, name: "created_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message service.media.v1.UploadDetails
 */
export const UploadDetails = /*@__PURE__*/ proto3.makeMessageType(
  "service.media.v1.UploadDetails",
  () => [
    { no: 1, name: "object_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

