import { Button } from '@/components/button.tsx'
import { Subheading } from '@/components/heading.tsx'
import { Text } from '@/components/text.tsx'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { Trans } from '@lingui/macro'
import { useNavigate } from '@tanstack/react-router'
import type { ReactNode } from 'react'

export function NotFoundPage(): ReactNode {
  const navigate = useNavigate()

  return (
    <div className='flex h-full w-full items-center justify-center'>
      <div className='flex flex-col items-center gap-3'>
        <ExclamationTriangleIcon className='size-8' />
        <Subheading>
          <Trans context='NotFoundPage' comment='Subheading text for when page or item is not found'>
            Not found
          </Trans>
        </Subheading>
        <Text>
          <Trans context='NotFoundPage' comment='Detail text for when page or item is not found'>
            The page or item you are looking for was not found
          </Trans>
        </Text>
        <Button onClick={() => navigate({ to: '/listings' })}>
          <Trans context='NotFoundPage' comment='Button text for navigating to the main dashboard page'>
            Go to dashboard
          </Trans>
        </Button>
      </div>
    </div>
  )
}
