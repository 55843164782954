import { Subheading } from '@/components/heading.tsx'
import { Text } from '@/components/text.tsx'
import { Trans } from '@lingui/macro'
import type { ReactNode } from 'react'

export function LocationSection(props: {
  cityField: ReactNode
  stateOrRegionField: ReactNode
  countryField: ReactNode
}) {
  return (
    <section className='grid gap-x-8 gap-y-6 sm:grid-cols-2'>
      <div className='space-y-1'>
        <Subheading>
          <Trans context='EditListingDetailsPage' comment='Subheading text for the location section'>
            Location
          </Trans>
        </Subheading>
        <Text>
          <Trans context='EditListingDetailsPage' comment='Specify the location details for your listing here'>
            This is the location of your listing
          </Trans>
        </Text>
      </div>
      <div className='grid grid-cols-2 gap-6'>
        {props.countryField}
        {props.stateOrRegionField}
        {props.cityField}
      </div>
    </section>
  )
}