// @generated by protoc-gen-connect-query v1.4.1
// @generated from file service/account/v1/account_service.proto (package service.account.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { CreateAccountRequest, CreateAccountResponse, DeleteAccountRequest, DeleteAccountResponse, GetAccountCreditsRequest, GetAccountCreditsResponse, GetAccountRequest, GetAccountResponse } from "./account_service_pb.js";

/**
 * @generated from rpc service.account.v1.AccountService.CreateAccount
 */
export const createAccount = {
  localName: "createAccount",
  name: "CreateAccount",
  kind: MethodKind.Unary,
  I: CreateAccountRequest,
  O: CreateAccountResponse,
  service: {
    typeName: "service.account.v1.AccountService"
  }
};

/**
 * @generated from rpc service.account.v1.AccountService.DeleteAccount
 */
export const deleteAccount = {
  localName: "deleteAccount",
  name: "DeleteAccount",
  kind: MethodKind.Unary,
  I: DeleteAccountRequest,
  O: DeleteAccountResponse,
  service: {
    typeName: "service.account.v1.AccountService"
  }
};

/**
 * @generated from rpc service.account.v1.AccountService.GetAccount
 */
export const getAccount = {
  localName: "getAccount",
  name: "GetAccount",
  kind: MethodKind.Unary,
  I: GetAccountRequest,
  O: GetAccountResponse,
  service: {
    typeName: "service.account.v1.AccountService"
  }
};

/**
 * @generated from rpc service.account.v1.AccountService.GetAccountCredits
 */
export const getAccountCredits = {
  localName: "getAccountCredits",
  name: "GetAccountCredits",
  kind: MethodKind.Unary,
  I: GetAccountCreditsRequest,
  O: GetAccountCreditsResponse,
  service: {
    typeName: "service.account.v1.AccountService"
  }
};
