import type { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 48 48' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M30.749 30.75c8.946-8.946 11.363-21.033 5.4-26.997C30.183-2.211 18.096.206 9.15 9.153c-8.946 8.945-11.363 21.032-5.4 26.996 5.965 5.964 18.052 3.547 26.998-5.4m11.585-13.956a26 26 0 0 1-.911 3.36l5.407 5.407c1.43-5.389.67-10.46-2.582-13.71l-1.595-1.596c.247 2.132.119 4.351-.319 6.54m-2.299 6.724c-1.414 2.922-3.35 5.776-5.748 8.395l5.72 5.72c2.446-2.694 4.325-5.621 5.593-8.55zM31.911 34.29c-2.62 2.4-5.475 4.335-8.397 5.75l5.563 5.563c2.93-1.267 5.858-3.147 8.554-5.593zm-15.12 8.049c-2.185.437-4.4.565-6.53.319l1.59 1.59c3.25 3.25 8.317 4.011 13.703 2.584l-5.405-5.404a26 26 0 0 1-3.358.91'
      clipRule='evenodd'
    />
  </svg>
)

export default SvgComponent
