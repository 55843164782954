import { createFileRoute } from '@tanstack/react-router'

import { Alert, AlertActions, AlertDescription, AlertTitle } from '@/components/alert.tsx'
import { Badge } from '@/components/badge.tsx'
import { Button } from '@/components/button.tsx'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '@/components/dialog.tsx'
import { Divider } from '@/components/divider.tsx'
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '@/components/dropdown.tsx'
import { ErrorMessage, Field, Fieldset, Label } from '@/components/fieldset.tsx'
import { Heading, Subheading } from '@/components/heading.tsx'
import { Input } from '@/components/input.tsx'
import { Link } from '@/components/link.tsx'
import { Text, TextLink } from '@/components/text.tsx'
import { listListings } from '@/pb/service/listing/v1/listing_service-ListingsService_connectquery'
import { Listing, ListingType, PropertyType } from '@/pb/service/listing/v1/listing_service_pb'
import { MessageBox } from '@/routes/-components/message-box.tsx'
import ResponsiveImage from '@/routes/-components/responsive-image.tsx'
import { SpinnerButton } from '@/routes/-components/spinner-button.tsx'
import { Spinner } from '@/routes/-components/spinner.tsx'
import { useCreateListingMutation } from '@/routes/_protected/_dashboard/-listings/mutations/useCreateListingMutation.ts'
import { useDeleteListingMutation } from '@/routes/_protected/_dashboard/-listings/mutations/useDeleteListingMutation.ts'
import { isDarkMode } from '@/utils/theme-util.ts'
import { toastOptions } from '@/utils/toast-util.ts'
import { callUnaryMethod, createConnectInfiniteQueryKey } from '@connectrpc/connect-query'
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/16/solid'
import { useForm } from '@tanstack/react-form'
import { useSuspenseInfiniteQuery } from '@tanstack/react-query'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { Fragment, type ReactNode, useEffect, useState } from 'react'

import { getLocalizedError } from '@/i18n/error-localization.ts'
import { getLocalizedListingType, getLocalizedPropertyType } from '@/i18n/listing-localization.ts'
import { Plural, Trans, msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import * as Sentry from '@sentry/react'
import { useInView } from 'react-intersection-observer'
import { toast } from 'sonner'
import { z } from 'zod'

const itemsPerPage = 15

export const Route = createFileRoute('/_protected/_dashboard/listings/')({
  component: Listings,
  loader: async ({ context: { queryClient, connectTransport } }) => {
    await queryClient.prefetchInfiniteQuery({
      queryKey: createConnectInfiniteQueryKey(listListings),
      initialPageParam: 0,
      queryFn: ({ pageParam = 0 }) =>
        callUnaryMethod(listListings, { limit: itemsPerPage, offset: pageParam }, { transport: connectTransport }),
    })
  },
})

const createListingFormSchema = z.object({
  name: z.string().min(1),
})

type CreateListingForm = z.infer<typeof createListingFormSchema>

function Listings(): ReactNode {
  const { queryClient, connectTransport } = Route.useRouteContext()
  const navigate = Route.useNavigate()

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useSuspenseInfiniteQuery({
    queryKey: createConnectInfiniteQueryKey(listListings),
    initialPageParam: 0,
    queryFn: ({ pageParam = 0 }) =>
      callUnaryMethod(listListings, { limit: itemsPerPage, offset: pageParam }, { transport: connectTransport }),
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (allPages.reduce((total, response) => total + response.listings.length, 0) === lastPage.total) {
        return undefined
      }
      if (lastPageParam === undefined) {
        return undefined
      }
      return lastPageParam + lastPage.listings.length
    },
  })

  const deleteListingMutation = useDeleteListingMutation(queryClient)
  const createListingMutation = useCreateListingMutation(queryClient)

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
  const [selectedListingIdToDelete, setSelectedListingIdToDelete] = useState<string | null>(null)
  const [createServerError, setCreateServerError] = useState<string | null>(null)

  const { _, i18n } = useLingui()

  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      fetchNextPage()
        .then()
        .catch((e) => {
          Sentry.captureException(e)
        })
    }
  }, [fetchNextPage, inView])

  function handleSelectListingToDelete(id: string) {
    setSelectedListingIdToDelete(id)
    setIsDeleteAlertOpen(true)
  }

  async function handleDeleteListing() {
    if (selectedListingIdToDelete) {
      try {
        await deleteListingMutation.mutateAsync({ id: selectedListingIdToDelete })
        setIsDeleteAlertOpen(false)
        toast.success(
          _(
            msg({
              context: 'ToastAlert',
              comment: `Toast alert text that's shown when a listing is successfully deleted`,
              message: 'Listing deleted',
            }),
          ),
          toastOptions,
        )
      } catch (e) {
        const { isExpectedError, message } = getLocalizedError(e)
        if (!isExpectedError) {
          Sentry.captureException(e)
        }
        toast.error(_(message), toastOptions)
      }
    }
  }

  async function handleCreateListing({ value }: { value: CreateListingForm }) {
    try {
      const createdListing = await createListingMutation.mutateAsync({ listing: new Listing({ name: value.name }) })
      if (createdListing?.listing?.id) {
        await navigate({
          to: '/listings/$id/edit/details',
          params: { id: createdListing.listing.id },
          search: { isNew: true },
        })
      }
    } catch (e) {
      const { isExpectedError, message } = getLocalizedError(e)
      if (!isExpectedError) {
        Sentry.captureException(e)
      }
      toast.error(_(message), toastOptions)
    }
  }

  const createListingForm = useForm({
    defaultValues: {
      name: '',
    },
    onSubmit: handleCreateListing,
    validatorAdapter: zodValidator(),
  })

  const nameField = (
    <createListingForm.Field
      name='name'
      validators={{
        onSubmit: createListingFormSchema.shape.name,
      }}
    >
      {(field) => (
        <Field disabled={createListingForm.state.isSubmitting}>
          <Label>
            <Trans context='ListListingsPage' comment='Dialog label text for listing name input field'>
              Name
            </Trans>
          </Label>
          <Input
            id={field.name}
            name={field.name}
            type='text'
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            invalid={field.state.meta.errors.length > 0}
            autoFocus
          />
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='ListListingsPage'
                comment='Input error message that is displayed when the user does not specify a name for the new listing'
              >
                Please provide a name for your listing
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </createListingForm.Field>
  )

  return (
    <>
      {data.pages.reduce((total, response) => total + response.listings.length, 0) === 0 ? (
        <Empty onCreateClicked={() => setIsCreateDialogOpen(true)} />
      ) : (
        <>
          <div className='flex items-center justify-between gap-4'>
            <Heading>
              <Trans context='ListListingsPage' comment='Heading text for the list listings page'>
                Listings
              </Trans>
            </Heading>
            <Button onClick={() => setIsCreateDialogOpen(true)}>
              <PlusIcon className='text-white' />
              <Trans context='ListListingsPage' comment='Button text for creating a new listing'>
                Create listing
              </Trans>
            </Button>
          </div>
          <ul className='mt-10 overflow-hidden'>
            {data.pages.map((group, i) => {
              return (
                <Fragment key={`group-${i}-${group.listings.length}`}>
                  {group.listings.map((listing) => {
                    const id = listing.id
                    return (
                      <li key={id}>
                        <Divider soft={i > 0} />
                        <div className='flex items-center'>
                          <div className='flex grow gap-4 py-4 sm:gap-6 sm:py-6'>
                            <div className='w-28 shrink-0 sm:w-32'>
                              <Link
                                href='/listings/$id'
                                params={{
                                  id: id,
                                }}
                                aria-hidden='true'
                              >
                                {listing.images.length > 0 ? (
                                  <ResponsiveImage
                                    sizes='128px'
                                    alt='Listing photo'
                                    className='aspect-[3/2] w-full rounded-lg object-cover object-center'
                                    variants={listing.images[0].variants}
                                  />
                                ) : (
                                  <div className='aspect-[3/2] w-full rounded-lg bg-gray-100 dark:bg-zinc-800' />
                                )}
                              </Link>
                            </div>
                            <div className='mt-0.5 space-y-1.5'>
                              <TextLink
                                className='font-semibold'
                                href='/listings/$id'
                                params={{
                                  id: id,
                                }}
                                aria-hidden='true'
                              >
                                {listing.name}
                              </TextLink>
                              <Text className='text-sm sm:text-base'>
                                <Trans
                                  context='ListListingsPage'
                                  comment='Text for combined bedroom and bathroom count'
                                >
                                  <Plural
                                    context='ListListingsPage'
                                    comment='Text that displays the bedroom count'
                                    value={listing.bedrooms}
                                    _0={<Trans>- bedrooms</Trans>}
                                    one={<Trans>{i18n.number(listing.bedrooms)} bedroom</Trans>}
                                    two={<Trans>{i18n.number(listing.bedrooms)} bedrooms</Trans>}
                                    few={<Trans>{i18n.number(listing.bedrooms)} bedrooms</Trans>}
                                    many={<Trans>{i18n.number(listing.bedrooms)} bedrooms</Trans>}
                                    other={<Trans>{i18n.number(listing.bedrooms)} bedrooms</Trans>}
                                  />
                                  {', '}
                                  <Plural
                                    value={listing.bathrooms}
                                    _0={<Trans>- bathrooms</Trans>}
                                    one={<Trans>{i18n.number(listing.bathrooms)} bathroom</Trans>}
                                    two={<Trans>{i18n.number(listing.bathrooms)} bathrooms</Trans>}
                                    few={<Trans>{i18n.number(listing.bathrooms)} bathrooms</Trans>}
                                    many={<Trans>{i18n.number(listing.bathrooms)} bathrooms</Trans>}
                                    other={<Trans>{i18n.number(listing.bathrooms)} bathrooms</Trans>}
                                  />
                                </Trans>
                              </Text>
                              {listing.cityOrNeighborhood !== '' && listing.stateOrRegion !== '' ? (
                                <Text className='text-sm sm:text-base'>
                                  <Trans
                                    context='ListListingsPage'
                                    comment='Text for combined city or neighborhood and state or region'
                                  >
                                    {listing.cityOrNeighborhood}
                                    {', '}
                                    {listing.stateOrRegion}
                                  </Trans>
                                </Text>
                              ) : (
                                <Text className='text-sm sm:text-base'>
                                  <Trans context='ListListingsPage' comment='Text for when location is unspecified'>
                                    Location unspecified
                                  </Trans>
                                </Text>
                              )}
                            </div>
                          </div>
                          <div className='flex items-center gap-4'>
                            {listing.propertyType !== PropertyType.PROPERTY_TYPE_UNSPECIFIED &&
                              listing.listingType !== ListingType.LISTING_TYPE_UNSPECIFIED && (
                                <Badge className='max-sm:hidden' color={'zinc'}>
                                  <Trans
                                    context='ListListingsPage'
                                    comment='Badge text that joins the property and listing type'
                                  >
                                    {_(getLocalizedPropertyType(listing.propertyType))} -{' '}
                                    {_(getLocalizedListingType(listing.listingType))}
                                  </Trans>
                                </Badge>
                              )}
                            <Dropdown>
                              <DropdownButton plain aria-label='More options'>
                                <EllipsisVerticalIcon />
                              </DropdownButton>
                              <DropdownMenu anchor='bottom end'>
                                <DropdownItem
                                  href='/listings/$id'
                                  params={{
                                    id: id,
                                  }}
                                >
                                  <Trans
                                    context='ListListingsPage'
                                    comment='Dropdown button text that navigates to the listings detail page'
                                  >
                                    View
                                  </Trans>
                                </DropdownItem>
                                <DropdownItem
                                  href='/listings/$id/edit/details'
                                  params={{
                                    id: id,
                                  }}
                                >
                                  <Trans
                                    context='ListListingsPage'
                                    comment='Dropdown button text that navigates to the edit listing details page'
                                  >
                                    Edit
                                  </Trans>
                                </DropdownItem>
                                <DropdownItem onClick={() => handleSelectListingToDelete(id)}>
                                  <Trans
                                    context='ListListingsPage'
                                    comment='Dropdown button text that brings up the delete confirmation alert'
                                  >
                                    Delete
                                  </Trans>
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </Fragment>
              )
            })}
          </ul>
          <div ref={ref} className='flex items-center justify-center'>
            {isFetchingNextPage ? (
              <Spinner {...(!isDarkMode() && { dark: true })} className='my-4' />
            ) : hasNextPage ? (
              <div className='h-4' />
            ) : null}
          </div>
          <Alert open={isDeleteAlertOpen} onClose={() => setIsDeleteAlertOpen(false)}>
            <AlertTitle>
              <Trans context='ListingDetailPage' comment='Alert title text for delete listing confirmation'>
                Are you sure you want to delete this listing?
              </Trans>
            </AlertTitle>
            <AlertDescription>
              <Trans context='ListingDetailPage' comment='Alert description text for delete listing confirmation'>
                You will delete everything associated with this listing, including generated descriptions.
              </Trans>
            </AlertDescription>
            <AlertActions>
              <Button plain onClick={() => setIsDeleteAlertOpen(false)} disabled={deleteListingMutation.isPending}>
                <Trans context='ListingDetailPage' comment='Alert action button text for cancelling listing deletion'>
                  Cancel
                </Trans>
              </Button>
              <SpinnerButton
                onClick={handleDeleteListing}
                type='submit'
                disabled={deleteListingMutation.isPending}
                showSpinner={deleteListingMutation.isPending}
              >
                <Trans context='ListingDetailPage' comment='Alert action button text for submitting listing deletion'>
                  Delete
                </Trans>
              </SpinnerButton>
            </AlertActions>
          </Alert>
        </>
      )}
      <Dialog open={isCreateDialogOpen} onClose={() => setIsCreateDialogOpen(false)}>
        <DialogTitle>
          <Trans context='ListListingsPage' comment='Dialog title text for creating a new listing'>
            Create Listing
          </Trans>
        </DialogTitle>
        <DialogDescription>
          <Trans context='ListListingsPage' comment='Dialog description text for creating a new listing'>
            Enter a name that will help you identify your listing
          </Trans>
        </DialogDescription>
        <form
          onSubmit={async (e) => {
            e.preventDefault()
            e.stopPropagation()
            setCreateServerError(null)
            await createListingForm.handleSubmit()
          }}
        >
          <DialogBody>
            <createListingForm.Subscribe selector={(state) => [state.isSubmitting]}>
              {([isSubmitting]) => (
                <Fieldset disabled={isSubmitting} className='flex w-full flex-col'>
                  {nameField}
                  {createServerError && (
                    <MessageBox className='mt-4' error>
                      {createServerError}
                    </MessageBox>
                  )}
                </Fieldset>
              )}
            </createListingForm.Subscribe>
          </DialogBody>
          <DialogActions>
            <Button
              type='button'
              plain
              onClick={() => {
                setCreateServerError(null)
                setIsCreateDialogOpen(false)
              }}
            >
              <Trans context='ListListingsPage' comment='Dialog action button text for cancelling listing creation'>
                Cancel
              </Trans>
            </Button>
            <createListingForm.Subscribe selector={(state) => [state.isSubmitting, state.canSubmit]}>
              {([isSubmitting, canSubmit]) => (
                <SpinnerButton type='submit' showSpinner={isSubmitting} disabled={!canSubmit || isSubmitting}>
                  <Trans
                    context='ListListingsPage'
                    comment='Dialog action button text for submitting new listing creation'
                  >
                    Create
                  </Trans>
                </SpinnerButton>
              )}
            </createListingForm.Subscribe>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

function Empty(props: { onCreateClicked: () => void }): ReactNode {
  return (
    <div className='flex h-full w-full items-center justify-center'>
      <div className='mb-48 flex flex-col items-center'>
        <Subheading className='mt-2'>
          <Trans context='ListListingsPage' comment='Subheading text for empty state'>
            No listings
          </Trans>
        </Subheading>
        <Text className='mt-2'>
          <Trans context='ListListingsPage' comment='Detail text for empty state'>
            Get started by creating a listing
          </Trans>
        </Text>
        <div className='mt-6'>
          <Button onClick={props.onCreateClicked}>
            <PlusIcon className='text-white' />
            <Trans context='ListListingsPage' comment='Button text for creating a listing'>
              Create listing
            </Trans>
          </Button>
        </div>
      </div>
    </div>
  )
}
