import { Button } from '@/components/button.tsx'
import { ErrorMessage, Field, Fieldset, Label } from '@/components/fieldset.tsx'
import { Heading } from '@/components/heading.tsx'
import { Input } from '@/components/input.tsx'
import { Text } from '@/components/text.tsx'
import { confirmPasswordResetCode } from '@/pb/service/auth/v1/auth_service-AuthService_connectquery'
import { MessageBox } from '@/routes/-components/message-box.tsx'
import { SpinnerButton } from '@/routes/-components/spinner-button.tsx'
import { callUnaryMethod } from '@connectrpc/connect-query'
import { useForm } from '@tanstack/react-form'
import { createFileRoute } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-form-adapter'
import type { ReactNode } from 'react'

import LogoIcon from '@/assets/LogoIcon.tsx'
import { getLocalizedError } from '@/i18n/error-localization.ts'
import type { MessageDescriptor } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import * as Sentry from '@sentry/react'
import { useImmer } from 'use-immer'
import { z } from 'zod'

const confirmResetSearchSchema = z.object({
  code: z.string().catch(''),
})

export const Route = createFileRoute('/_public/reset/confirm')({
  component: ResetConfirm,
  validateSearch: confirmResetSearchSchema,
})

const resetPasswordConfirmSchema = z.object({
  password: z.string().min(6),
  confirmPassword: z.string().min(6),
})

type ResetPasswordConfirmForm = z.infer<typeof resetPasswordConfirmSchema>

type FormStatus = 'initial' | 'success' | 'error'

interface FormState {
  status: FormStatus
  error: MessageDescriptor | null
}

export function ResetConfirm(): ReactNode {
  const { code } = Route.useSearch()
  const navigate = Route.useNavigate()
  const { connectTransport } = Route.useRouteContext()

  const [formsState, setFormsState] = useImmer<FormState>({
    status: 'initial',
    error: null,
  })

  const { _ } = useLingui()

  const form = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: handleSubmit,
    validatorAdapter: zodValidator(),
  })

  async function handleSubmit({ value }: { value: ResetPasswordConfirmForm }) {
    try {
      await callUnaryMethod(
        confirmPasswordResetCode,
        {
          oobCode: code,
          newPassword: value.confirmPassword,
        },
        { transport: connectTransport },
      )
      setFormsState((draft) => {
        draft.error = null
        draft.status = 'success'
      })
    } catch (e) {
      const { isExpectedError, message } = getLocalizedError(e)
      if (!isExpectedError) {
        Sentry.captureException(e)
      }
      setFormsState((draft) => {
        draft.error = message
        draft.status = 'error'
      })
    }
  }

  const passwordField = (
    <form.Field
      name='password'
      validators={{
        onSubmit: resetPasswordConfirmSchema.shape.password,
      }}
    >
      {(field) => (
        <Field>
          <Label>
            <Trans context='ConfirmResetPasswordPage' comment="Label text that's shown for the password input field">
              Password
            </Trans>
          </Label>
          <Input
            id={field.name}
            name={field.name}
            type='password'
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            invalid={field.state.meta.errors.length > 0}
          />
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='ConfirmResetPasswordPage'
                comment='Input error message for when an invalid password is provided'
              >
                Password must be at least 6 characters long
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  const confirmPasswordField = (
    <form.Field
      name='confirmPassword'
      validators={{
        onChangeListenTo: ['password'],
        onSubmit: ({ value, fieldApi }) => {
          if (value !== fieldApi.form.getFieldValue('password')) {
            return 'passwords must match'
          }
          return undefined
        },
      }}
    >
      {(field) => (
        <Field>
          <Label>
            <Trans
              context='ConfirmResetPasswordPage'
              comment="Label text that's shown for the confirm password input field"
            >
              Confirm password
            </Trans>
          </Label>
          <Input
            id={field.name}
            name={field.name}
            type='password'
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            invalid={field.state.meta.errors.length > 0}
          />
          {field.state.meta.errors.length > 0 ? (
            <ErrorMessage>
              <Trans
                context='ConfirmResetPasswordPage'
                comment='Input error message for when an invalid password is provided'
              >
                Passwords do not match
              </Trans>
            </ErrorMessage>
          ) : null}
        </Field>
      )}
    </form.Field>
  )

  return (
    <div className='relative flex h-full w-full items-center justify-center sm:bg-gray-100 sm:dark:bg-zinc-950'>
      <LogoIcon className='absolute top-6 left-6 h-4 w-18' />
      <div className='sm:card w-full rounded-none px-6 py-12 sm:max-w-[440px] sm:rounded-xl sm:px-12'>
        <form
          className='inline'
          onSubmit={async (e) => {
            setFormsState((draft) => {
              draft.error = null
              draft.status = 'initial'
            })
            e.preventDefault()
            e.stopPropagation()
            await form.handleSubmit()
          }}
        >
          <form.Subscribe selector={(state) => [state.isSubmitting, state.canSubmit]}>
            {([isSubmitting, canSubmit]) => (
              <Fieldset disabled={isSubmitting} className='flex w-full flex-col space-y-5'>
                {formsState.status === 'initial' ? (
                  <>
                    <Heading>
                      <Trans context='ConfirmResetPasswordPage' comment='Heading text for confirm reset password page'>
                        Reset your password
                      </Trans>
                    </Heading>
                  </>
                ) : (
                  <>
                    <Heading>
                      <Trans context='ConfirmResetPasswordPage' comment='Heading text for successful password reset'>
                        Password successfully reset
                      </Trans>
                    </Heading>
                    <Text>
                      <Trans context='ConfirmResetPasswordPage' comment='Detail text for successful password reset'>
                        You can now log in with your new password
                      </Trans>
                    </Text>
                    <Button onClick={async () => await navigate({ to: '/login' })}>
                      <Trans context='ConfirmResetPasswordPage' comment='Button text for navigating to the log in page'>
                        Go to log in
                      </Trans>
                    </Button>
                  </>
                )}

                {formsState.status === 'initial' && (
                  <>
                    {passwordField}
                    {confirmPasswordField}
                    {/* biome-ignore lint/style/noNonNullAssertion: <explanation> */}
                    {formsState.error && <MessageBox error>{_(formsState.error!)}</MessageBox>}
                    <SpinnerButton
                      type='submit'
                      className='w-full'
                      disabled={!canSubmit || isSubmitting}
                      showSpinner={isSubmitting}
                    >
                      <Trans
                        context='ConfirmResetPasswordPage'
                        comment='Button text for reseting and submitting new password'
                      >
                        Continue
                      </Trans>
                    </SpinnerButton>
                  </>
                )}
              </Fieldset>
            )}
          </form.Subscribe>
        </form>
      </div>
    </div>
  )
}
