// @generated by protoc-gen-connect-es v1.4.0
// @generated from file service/description/v1/description_service.proto (package service.description.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GenerateDescriptionRequest, GenerateDescriptionResponse, ListDescriptionsForAccountRequest, ListDescriptionsForAccountResponse, ListDescriptionsForListingRequest, ListDescriptionsForListingResponse } from "./description_service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service service.description.v1.DescriptionService
 */
export const DescriptionService = {
  typeName: "service.description.v1.DescriptionService",
  methods: {
    /**
     * @generated from rpc service.description.v1.DescriptionService.GenerateDescription
     */
    generateDescription: {
      name: "GenerateDescription",
      I: GenerateDescriptionRequest,
      O: GenerateDescriptionResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc service.description.v1.DescriptionService.ListDescriptionsForAccount
     */
    listDescriptionsForAccount: {
      name: "ListDescriptionsForAccount",
      I: ListDescriptionsForAccountRequest,
      O: ListDescriptionsForAccountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc service.description.v1.DescriptionService.ListDescriptionsForListing
     */
    listDescriptionsForListing: {
      name: "ListDescriptionsForListing",
      I: ListDescriptionsForListingRequest,
      O: ListDescriptionsForListingResponse,
      kind: MethodKind.Unary,
    },
  }
};

