// @generated by protoc-gen-es v1.10.0
// @generated from file service/checkout/v1/checkout_service.proto (package service.checkout.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum service.checkout.v1.Currency
 */
export const Currency = /*@__PURE__*/ proto3.makeEnum(
  "service.checkout.v1.Currency",
  [
    {no: 0, name: "CURRENCY_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CURRENCY_ARS", localName: "ARS"},
    {no: 2, name: "CURRENCY_AUD", localName: "AUD"},
    {no: 3, name: "CURRENCY_BGN", localName: "BGN"},
    {no: 4, name: "CURRENCY_BRL", localName: "BRL"},
    {no: 5, name: "CURRENCY_CAD", localName: "CAD"},
    {no: 6, name: "CURRENCY_CHF", localName: "CHF"},
    {no: 7, name: "CURRENCY_CLP", localName: "CLP"},
    {no: 8, name: "CURRENCY_COP", localName: "COP"},
    {no: 9, name: "CURRENCY_CRC", localName: "CRC"},
    {no: 10, name: "CURRENCY_CZK", localName: "CZK"},
    {no: 11, name: "CURRENCY_DKK", localName: "DKK"},
    {no: 12, name: "CURRENCY_EUR", localName: "EUR"},
    {no: 13, name: "CURRENCY_GBP", localName: "GBP"},
    {no: 14, name: "CURRENCY_HUF", localName: "HUF"},
    {no: 15, name: "CURRENCY_ILS", localName: "ILS"},
    {no: 16, name: "CURRENCY_ISK", localName: "ISK"},
    {no: 17, name: "CURRENCY_JPY", localName: "JPY"},
    {no: 18, name: "CURRENCY_KRW", localName: "KRW"},
    {no: 19, name: "CURRENCY_MXN", localName: "MXN"},
    {no: 20, name: "CURRENCY_NOK", localName: "NOK"},
    {no: 21, name: "CURRENCY_NZD", localName: "NZD"},
    {no: 22, name: "CURRENCY_PLN", localName: "PLN"},
    {no: 23, name: "CURRENCY_RON", localName: "RON"},
    {no: 24, name: "CURRENCY_RUB", localName: "RUB"},
    {no: 25, name: "CURRENCY_SEK", localName: "SEK"},
    {no: 26, name: "CURRENCY_TRY", localName: "TRY"},
    {no: 27, name: "CURRENCY_USD", localName: "USD"},
  ],
);

/**
 * @generated from message service.checkout.v1.CreateCheckoutSessionRequest
 */
export const CreateCheckoutSessionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.checkout.v1.CreateCheckoutSessionRequest",
  () => [
    { no: 1, name: "price_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "from_listing_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.checkout.v1.CreateCheckoutSessionResponse
 */
export const CreateCheckoutSessionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.checkout.v1.CreateCheckoutSessionResponse",
  () => [
    { no: 1, name: "checkout_session_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.checkout.v1.ListProductsRequest
 */
export const ListProductsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.checkout.v1.ListProductsRequest",
  [],
);

/**
 * @generated from message service.checkout.v1.ListProductsResponse
 */
export const ListProductsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.checkout.v1.ListProductsResponse",
  () => [
    { no: 1, name: "products", kind: "message", T: Product, repeated: true },
  ],
);

/**
 * @generated from message service.checkout.v1.Product
 */
export const Product = /*@__PURE__*/ proto3.makeMessageType(
  "service.checkout.v1.Product",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "price_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "credit_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "unit_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "currency", kind: "enum", T: proto3.getEnumType(Currency) },
  ],
);

