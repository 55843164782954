// @generated by protoc-gen-connect-query v1.4.1
// @generated from file service/auth/v1/auth_service.proto (package service.auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { ChangePasswordRequest, ChangePasswordResponse, ConfirmEmailRecoveryCodeRequest, ConfirmEmailRecoveryCodeResponse, ConfirmEmailVerificationCodeRequest, ConfirmEmailVerificationCodeResponse, ConfirmPasswordResetCodeRequest, ConfirmPasswordResetCodeResponse, ConfirmVerifyAndChangeEmailCodeRequest, ConfirmVerifyAndChangeEmailCodeResponse, LoginRequest, LoginResponse, RefreshIdTokenRequest, RefreshIdTokenResponse, SendPasswordResetEmailRequest, SendPasswordResetEmailResponse, SendVerificationEmailRequest, SendVerificationEmailResponse, SendVerifyAndChangeEmailRequest, SendVerifyAndChangeEmailResponse, SignUpRequest, SignUpResponse } from "./auth_service_pb.js";

/**
 * @generated from rpc service.auth.v1.AuthService.Login
 */
export const login = {
  localName: "login",
  name: "Login",
  kind: MethodKind.Unary,
  I: LoginRequest,
  O: LoginResponse,
  service: {
    typeName: "service.auth.v1.AuthService"
  }
};

/**
 * @generated from rpc service.auth.v1.AuthService.SignUp
 */
export const signUp = {
  localName: "signUp",
  name: "SignUp",
  kind: MethodKind.Unary,
  I: SignUpRequest,
  O: SignUpResponse,
  service: {
    typeName: "service.auth.v1.AuthService"
  }
};

/**
 * @generated from rpc service.auth.v1.AuthService.RefreshIdToken
 */
export const refreshIdToken = {
  localName: "refreshIdToken",
  name: "RefreshIdToken",
  kind: MethodKind.Unary,
  I: RefreshIdTokenRequest,
  O: RefreshIdTokenResponse,
  service: {
    typeName: "service.auth.v1.AuthService"
  }
};

/**
 * @generated from rpc service.auth.v1.AuthService.SendPasswordResetEmail
 */
export const sendPasswordResetEmail = {
  localName: "sendPasswordResetEmail",
  name: "SendPasswordResetEmail",
  kind: MethodKind.Unary,
  I: SendPasswordResetEmailRequest,
  O: SendPasswordResetEmailResponse,
  service: {
    typeName: "service.auth.v1.AuthService"
  }
};

/**
 * @generated from rpc service.auth.v1.AuthService.SendVerificationEmail
 */
export const sendVerificationEmail = {
  localName: "sendVerificationEmail",
  name: "SendVerificationEmail",
  kind: MethodKind.Unary,
  I: SendVerificationEmailRequest,
  O: SendVerificationEmailResponse,
  service: {
    typeName: "service.auth.v1.AuthService"
  }
};

/**
 * @generated from rpc service.auth.v1.AuthService.SendVerifyAndChangeEmail
 */
export const sendVerifyAndChangeEmail = {
  localName: "sendVerifyAndChangeEmail",
  name: "SendVerifyAndChangeEmail",
  kind: MethodKind.Unary,
  I: SendVerifyAndChangeEmailRequest,
  O: SendVerifyAndChangeEmailResponse,
  service: {
    typeName: "service.auth.v1.AuthService"
  }
};

/**
 * @generated from rpc service.auth.v1.AuthService.ConfirmEmailVerificationCode
 */
export const confirmEmailVerificationCode = {
  localName: "confirmEmailVerificationCode",
  name: "ConfirmEmailVerificationCode",
  kind: MethodKind.Unary,
  I: ConfirmEmailVerificationCodeRequest,
  O: ConfirmEmailVerificationCodeResponse,
  service: {
    typeName: "service.auth.v1.AuthService"
  }
};

/**
 * @generated from rpc service.auth.v1.AuthService.ConfirmPasswordResetCode
 */
export const confirmPasswordResetCode = {
  localName: "confirmPasswordResetCode",
  name: "ConfirmPasswordResetCode",
  kind: MethodKind.Unary,
  I: ConfirmPasswordResetCodeRequest,
  O: ConfirmPasswordResetCodeResponse,
  service: {
    typeName: "service.auth.v1.AuthService"
  }
};

/**
 * @generated from rpc service.auth.v1.AuthService.ConfirmVerifyAndChangeEmailCode
 */
export const confirmVerifyAndChangeEmailCode = {
  localName: "confirmVerifyAndChangeEmailCode",
  name: "ConfirmVerifyAndChangeEmailCode",
  kind: MethodKind.Unary,
  I: ConfirmVerifyAndChangeEmailCodeRequest,
  O: ConfirmVerifyAndChangeEmailCodeResponse,
  service: {
    typeName: "service.auth.v1.AuthService"
  }
};

/**
 * @generated from rpc service.auth.v1.AuthService.ConfirmEmailRecoveryCode
 */
export const confirmEmailRecoveryCode = {
  localName: "confirmEmailRecoveryCode",
  name: "ConfirmEmailRecoveryCode",
  kind: MethodKind.Unary,
  I: ConfirmEmailRecoveryCodeRequest,
  O: ConfirmEmailRecoveryCodeResponse,
  service: {
    typeName: "service.auth.v1.AuthService"
  }
};

/**
 * @generated from rpc service.auth.v1.AuthService.ChangePassword
 */
export const changePassword = {
  localName: "changePassword",
  name: "ChangePassword",
  kind: MethodKind.Unary,
  I: ChangePasswordRequest,
  O: ChangePasswordResponse,
  service: {
    typeName: "service.auth.v1.AuthService"
  }
};
