// @generated by protoc-gen-es v1.10.0
// @generated from file service/account/v1/account_service.proto (package service.account.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message service.account.v1.CreateAccountRequest
 */
export const CreateAccountRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.account.v1.CreateAccountRequest",
  () => [
    { no: 1, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.account.v1.CreateAccountResponse
 */
export const CreateAccountResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.account.v1.CreateAccountResponse",
  () => [
    { no: 1, name: "account", kind: "message", T: Account },
    { no: 2, name: "id_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "expires_in", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message service.account.v1.DeleteAccountRequest
 */
export const DeleteAccountRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.account.v1.DeleteAccountRequest",
  [],
);

/**
 * @generated from message service.account.v1.DeleteAccountResponse
 */
export const DeleteAccountResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.account.v1.DeleteAccountResponse",
  [],
);

/**
 * @generated from message service.account.v1.GetAccountRequest
 */
export const GetAccountRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.account.v1.GetAccountRequest",
  [],
);

/**
 * @generated from message service.account.v1.GetAccountResponse
 */
export const GetAccountResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.account.v1.GetAccountResponse",
  () => [
    { no: 1, name: "account", kind: "message", T: Account },
  ],
);

/**
 * @generated from message service.account.v1.GetAccountCreditsRequest
 */
export const GetAccountCreditsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.account.v1.GetAccountCreditsRequest",
  [],
);

/**
 * @generated from message service.account.v1.GetAccountCreditsResponse
 */
export const GetAccountCreditsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.account.v1.GetAccountCreditsResponse",
  () => [
    { no: 1, name: "credits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message service.account.v1.Account
 */
export const Account = /*@__PURE__*/ proto3.makeMessageType(
  "service.account.v1.Account",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created_at", kind: "message", T: Timestamp },
  ],
);

