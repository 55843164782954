import { Spinner } from '@/routes/-components/spinner.tsx'
import { isDarkMode } from '@/utils/theme-util.ts'
import type { ReactNode } from 'react'

export function PendingPage(): ReactNode {
  return (
    <div className='flex h-full w-full items-center justify-center'>
      <Spinner {...(!isDarkMode() && { dark: true })} />
    </div>
  )
}
