// @generated by protoc-gen-es v1.10.0
// @generated from file service/listing/v1/listing_service.proto (package service.listing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Image } from "../../media/v1/media_service_pb.js";

/**
 * @generated from enum service.listing.v1.PropertyType
 */
export const PropertyType = /*@__PURE__*/ proto3.makeEnum(
  "service.listing.v1.PropertyType",
  [
    {no: 0, name: "PROPERTY_TYPE_UNSPECIFIED"},
    {no: 1, name: "SINGLE_FAMILY_HOME"},
    {no: 2, name: "MULTI_FAMILY_HOME"},
    {no: 3, name: "APARTMENT"},
    {no: 4, name: "CONDO"},
    {no: 5, name: "PENTHOUSE"},
    {no: 6, name: "TOWNHOUSE"},
    {no: 7, name: "LOFT"},
    {no: 8, name: "STUDIO"},
    {no: 9, name: "DUPLEX"},
    {no: 10, name: "TRIPLEX"},
    {no: 11, name: "FOURPLEX"},
    {no: 12, name: "CO_OP"},
    {no: 13, name: "MOBILE_HOME"},
    {no: 14, name: "GUEST_HOUSE"},
    {no: 15, name: "CABIN"},
    {no: 16, name: "COTTAGE"},
    {no: 17, name: "BUNGALOW"},
    {no: 18, name: "VILLA"},
    {no: 19, name: "MANSION"},
    {no: 20, name: "RANCH"},
    {no: 21, name: "FARM_HOUSE"},
  ],
);

/**
 * @generated from enum service.listing.v1.ListingType
 */
export const ListingType = /*@__PURE__*/ proto3.makeEnum(
  "service.listing.v1.ListingType",
  [
    {no: 0, name: "LISTING_TYPE_UNSPECIFIED"},
    {no: 1, name: "FOR_SALE"},
    {no: 2, name: "FOR_RENT"},
    {no: 3, name: "LEASE"},
    {no: 4, name: "VACATION_RENTAL"},
    {no: 5, name: "SHORT_TERM_RENTAL"},
    {no: 6, name: "LONG_TERM_RENTAL"},
    {no: 7, name: "SUBLEASE"},
    {no: 8, name: "AIRBNB_ENTIRE_PLACE"},
    {no: 9, name: "AIRBNB_PRIVATE_ROOM"},
    {no: 10, name: "AIRBNB_SHARED_ROOM"},
  ],
);

/**
 * @generated from enum service.listing.v1.Style
 */
export const Style = /*@__PURE__*/ proto3.makeEnum(
  "service.listing.v1.Style",
  [
    {no: 0, name: "STYLE_UNSPECIFIED"},
    {no: 1, name: "MODERN"},
    {no: 2, name: "CONTEMPORARY"},
    {no: 3, name: "COLONIAL"},
    {no: 4, name: "VICTORIAN"},
    {no: 5, name: "MEDITERRANEAN"},
    {no: 6, name: "CAPE_COD"},
    {no: 7, name: "STYLE_RANCH"},
    {no: 8, name: "CRAFTSMAN"},
    {no: 9, name: "FARMHOUSE"},
    {no: 10, name: "TUDOR"},
    {no: 11, name: "STYLE_COTTAGE"},
    {no: 12, name: "MID_CENTURY_MODERN"},
    {no: 13, name: "SPANISH"},
    {no: 14, name: "DUTCH_COLONIAL"},
    {no: 15, name: "GEORGIAN"},
    {no: 16, name: "PRAIRIE"},
    {no: 17, name: "GOTHIC"},
    {no: 18, name: "SOUTHWESTERN"},
    {no: 19, name: "FRENCH_COUNTRY"},
  ],
);

/**
 * @generated from enum service.listing.v1.MeasurementUnit
 */
export const MeasurementUnit = /*@__PURE__*/ proto3.makeEnum(
  "service.listing.v1.MeasurementUnit",
  [
    {no: 0, name: "METRIC"},
    {no: 1, name: "IMPERIAL"},
  ],
);

/**
 * @generated from message service.listing.v1.Listing
 */
export const Listing = /*@__PURE__*/ proto3.makeMessageType(
  "service.listing.v1.Listing",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "city_or_neighborhood", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "state_or_region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "property_type", kind: "enum", T: proto3.getEnumType(PropertyType) },
    { no: 7, name: "listing_type", kind: "enum", T: proto3.getEnumType(ListingType) },
    { no: 8, name: "style", kind: "enum", T: proto3.getEnumType(Style) },
    { no: 9, name: "bedrooms", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "beds", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "bathrooms", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 12, name: "area", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "lot_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "year_built", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "amenities", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 16, name: "nearby_attractions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 17, name: "features", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 18, name: "measurement_unit", kind: "enum", T: proto3.getEnumType(MeasurementUnit) },
    { no: 19, name: "images", kind: "message", T: Image, repeated: true },
    { no: 20, name: "created_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message service.listing.v1.CreateListingRequest
 */
export const CreateListingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.listing.v1.CreateListingRequest",
  () => [
    { no: 1, name: "listing", kind: "message", T: Listing },
  ],
);

/**
 * @generated from message service.listing.v1.CreateListingResponse
 */
export const CreateListingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.listing.v1.CreateListingResponse",
  () => [
    { no: 1, name: "listing", kind: "message", T: Listing },
  ],
);

/**
 * @generated from message service.listing.v1.GetListingRequest
 */
export const GetListingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.listing.v1.GetListingRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.listing.v1.GetListingResponse
 */
export const GetListingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.listing.v1.GetListingResponse",
  () => [
    { no: 1, name: "listing", kind: "message", T: Listing },
  ],
);

/**
 * @generated from message service.listing.v1.UpdateListingRequest
 */
export const UpdateListingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.listing.v1.UpdateListingRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "listing", kind: "message", T: Listing },
  ],
);

/**
 * @generated from message service.listing.v1.UpdateListingResponse
 */
export const UpdateListingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.listing.v1.UpdateListingResponse",
  () => [
    { no: 1, name: "listing", kind: "message", T: Listing },
  ],
);

/**
 * @generated from message service.listing.v1.DeleteListingRequest
 */
export const DeleteListingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.listing.v1.DeleteListingRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.listing.v1.DeleteListingResponse
 */
export const DeleteListingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.listing.v1.DeleteListingResponse",
  [],
);

/**
 * @generated from message service.listing.v1.ListListingsRequest
 */
export const ListListingsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.listing.v1.ListListingsRequest",
  () => [
    { no: 1, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message service.listing.v1.ListListingsResponse
 */
export const ListListingsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.listing.v1.ListListingsResponse",
  () => [
    { no: 1, name: "listings", kind: "message", T: Listing, repeated: true },
    { no: 2, name: "total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

