import { Divider } from '@/components/divider.tsx'
import { Heading, Subheading } from '@/components/heading'
import { Link } from '@/components/link.tsx'
import { Text } from '@/components/text.tsx'
import { ErrorPage } from '@/routes/-components/error-page.tsx'
import { Spinner } from '@/routes/-components/spinner.tsx'
import { getRelativeTimeString } from '@/utils/date-util.ts'
import { callUnaryMethod, createConnectInfiniteQueryKey } from '@connectrpc/connect-query'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import * as Sentry from '@sentry/react'
import { useSuspenseInfiniteQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { Fragment, type ReactNode, useEffect } from 'react'

import { listDescriptionsForListing } from '@/pb/service/description/v1/description_service-DescriptionService_connectquery'
import { Trans } from '@lingui/macro'
import { useInView } from 'react-intersection-observer'

const itemsPerPage = 5

export const Route = createFileRoute('/_protected/_dashboard/listings/$id/generated')({
  component: ListGeneratedForListing,
  loader: async ({ context: { queryClient, connectTransport }, params: { id } }) => {
    await queryClient.prefetchInfiniteQuery({
      queryKey: createConnectInfiniteQueryKey(listDescriptionsForListing, { listingId: id }),
      initialPageParam: 0,
      queryFn: ({ pageParam = 0 }) =>
        callUnaryMethod(
          listDescriptionsForListing,
          { listingId: id, limit: itemsPerPage, offset: pageParam },
          { transport: connectTransport },
        ),
    })
  },
  errorComponent: ErrorPage,
})

function ListGeneratedForListing() {
  const { connectTransport } = Route.useRouteContext()
  const params = Route.useParams()

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useSuspenseInfiniteQuery({
    queryKey: createConnectInfiniteQueryKey(listDescriptionsForListing, { listingId: params.id }),
    initialPageParam: 0,
    queryFn: ({ pageParam = 0 }) =>
      callUnaryMethod(
        listDescriptionsForListing,
        { listingId: params.id, limit: itemsPerPage, offset: pageParam },
        { transport: connectTransport },
      ),
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (allPages.reduce((total, response) => total + response.descriptions.length, 0) === lastPage.total) {
        return undefined
      }

      if (lastPageParam === undefined) {
        return undefined
      }

      return lastPageParam + lastPage.descriptions.length
    },
  })

  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      fetchNextPage()
        .then()
        .catch((e) => {
          Sentry.captureException(e)
        })
    }
  }, [fetchNextPage, inView])

  return (
    <div className='mx-auto flex h-full max-w-prose flex-col'>
      <div className='hidden lg:block'>
        <Link
          from={Route.fullPath}
          href='/listings/$id/generate'
          params={{ id: params.id }}
          className='inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400'
        >
          <ChevronLeftIcon className='size-4 fill-zinc-400 dark:fill-zinc-500' />
          <Trans
            context='ListGeneratedForListingPage'
            comment='Link text for navigating back to the generate description page'
          >
            Generate
          </Trans>
        </Link>
      </div>
      {data.pages.reduce((total, response) => total + response.descriptions.length, 0) === 0 ? (
        <Empty />
      ) : (
        <>
          <Heading className='mt-4 lg:mt-8'>
            <Trans
              context='ListGeneratedForListingPage'
              comment='Header text for previously generated for listing page'
            >
              Previously generated
            </Trans>
          </Heading>
          <ul className='mt-4 flex-grow'>
            {data.pages.map((group, i) => {
              return (
                <Fragment key={`group-${i}-${group.descriptions.length}`}>
                  {group.descriptions.map((description) => {
                    return (
                      <li key={description.id} className='flex flex-col gap-4 pb-4'>
                        <Divider />
                        <div className='space-y-4'>
                          <Text className='whitespace-pre-wrap font-serif text-zinc-900 dark:text-white'>
                            {description.content}
                          </Text>
                          <Text className='font-medium font-serif'>
                            {getRelativeTimeString(description.createdAt?.toDate())}
                          </Text>
                        </div>
                      </li>
                    )
                  })}
                </Fragment>
              )
            })}
          </ul>
          <div ref={ref} className='flex items-center justify-center'>
            {isFetchingNextPage ? <Spinner dark className='my-4' /> : hasNextPage ? <div className='h-4' /> : null}
          </div>
        </>
      )}
    </div>
  )
}

function Empty(): ReactNode {
  return (
    <div className='flex h-full w-full items-center justify-center'>
      <div className='flex flex-col items-center'>
        <Subheading className='mt-2 text-center'>
          <Trans context='ListGeneratedPage' comment='Subheading text for empty state'>
            No generated descriptions
          </Trans>
        </Subheading>
        <Text className='mt-2 text-center'>
          <Trans context='ListGeneratedPage' comment='Detail text for empty state'>
            Any descriptions you generate will appear here
          </Trans>
        </Text>
      </div>
    </div>
  )
}
