import {
  DescriptionLanguage,
  DescriptionLength,
  DescriptionTarget,
  DescriptionTone,
} from '@/pb/service/description/v1/description_service_pb'
import type { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'

const unspecifiedDescriptionToneOption = msg({
  context: 'GenerateDescriptionToneOption',
  comment: 'Default value when tone option is not specified',
  message: 'Unspecified',
})

const unspecifiedDescriptionToneOptionDescription = msg({
  context: 'GenerateDescriptionToneOptionDescription',
  comment: 'Default value when tone option description is not specified',
  message: 'Unspecified',
})

const localizedDescriptionTones = new Map<DescriptionTone, MessageDescriptor>([
  [DescriptionTone.TONE_UNSPECIFIED, unspecifiedDescriptionToneOption],
  [
    DescriptionTone.TONE_PROFESSIONAL,
    msg({
      context: 'GenerateDescriptionToneOption',
      comment: 'Tone option for professional writing style',
      message: 'Professional',
    }),
  ],
  [
    DescriptionTone.TONE_FRIENDLY,
    msg({
      context: 'GenerateDescriptionToneOption',
      comment: 'Tone option for friendly writing style',
      message: 'Friendly',
    }),
  ],
  [
    DescriptionTone.TONE_LUXURIOUS,
    msg({
      context: 'GenerateDescriptionToneOption',
      comment: 'Tone option for luxurious writing style',
      message: 'Luxurious',
    }),
  ],
  [
    DescriptionTone.TONE_MODERN,
    msg({
      context: 'GenerateDescriptionToneOption',
      comment: 'Tone option for modern writing style',
      message: 'Modern',
    }),
  ],
  [
    DescriptionTone.TONE_RUSTIC,
    msg({
      context: 'GenerateDescriptionToneOption',
      comment: 'Tone option for rustic writing style',
      message: 'Rustic',
    }),
  ],
  [
    DescriptionTone.TONE_ENERGETIC,
    msg({
      context: 'GenerateDescriptionToneOption',
      comment: 'Tone option for energetic writing style',
      message: 'Energetic',
    }),
  ],
  [
    DescriptionTone.TONE_SERENE,
    msg({
      context: 'GenerateDescriptionToneOption',
      comment: 'Tone option for serene writing style',
      message: 'Serene',
    }),
  ],
  [
    DescriptionTone.TONE_INFORMATIVE,
    msg({
      context: 'GenerateDescriptionToneOption',
      comment: 'Tone option for informative writing style',
      message: 'Informative',
    }),
  ],
  [
    DescriptionTone.TONE_PERSUASIVE,
    msg({
      context: 'GenerateDescriptionToneOption',
      comment: 'Tone option for persuasive writing style',
      message: 'Persuasive',
    }),
  ],
  [
    DescriptionTone.TONE_NOSTALGIC,
    msg({
      context: 'GenerateDescriptionToneOption',
      comment: 'Tone option for nostalgic writing style',
      message: 'Nostalgic',
    }),
  ],
  [
    DescriptionTone.TONE_ROMANTIC,
    msg({
      context: 'GenerateDescriptionToneOption',
      comment: 'Tone option for romantic writing style',
      message: 'Romantic',
    }),
  ],
  [
    DescriptionTone.TONE_ADVENTUROUS,
    msg({
      context: 'GenerateDescriptionToneOption',
      comment: 'Tone option for adventurous writing style',
      message: 'Adventurous',
    }),
  ],
  [
    DescriptionTone.TONE_COZY,
    msg({
      context: 'GenerateDescriptionToneOption',
      comment: 'Tone option for cozy writing style',
      message: 'Cozy',
    }),
  ],
  [
    DescriptionTone.TONE_ECO_FRIENDLY,
    msg({
      context: 'GenerateDescriptionToneOption',
      comment: 'Tone option for eco-friendly writing style',
      message: 'Eco-Friendly',
    }),
  ],
  [
    DescriptionTone.TONE_ARTSY,
    msg({
      context: 'GenerateDescriptionToneOption',
      comment: 'Tone option for artsy writing style',
      message: 'Artsy',
    }),
  ],
  [
    DescriptionTone.TONE_MINIMALIST,
    msg({
      context: 'GenerateDescriptionToneOption',
      comment: 'Tone option for minimalist writing style',
      message: 'Minimalist',
    }),
  ],
])

export function getLocalizedToneName(tone: DescriptionTone): MessageDescriptor {
  return localizedDescriptionTones.get(tone) ?? unspecifiedDescriptionToneOption
}

const localizedDescriptionToneDescriptions = new Map<DescriptionTone, MessageDescriptor>([
  [DescriptionTone.TONE_UNSPECIFIED, unspecifiedDescriptionToneOptionDescription],
  [
    DescriptionTone.TONE_PROFESSIONAL,
    msg({
      context: 'GenerateDescriptionToneOptionDescription',
      comment: 'Description for professional tone',
      message: 'Formal and business-like, suitable for high-end properties',
    }),
  ],
  [
    DescriptionTone.TONE_FRIENDLY,
    msg({
      context: 'GenerateDescriptionToneOptionDescription',
      comment: 'Description for friendly tone',
      message: 'Warm and welcoming, great for family homes or vacation rentals',
    }),
  ],
  [
    DescriptionTone.TONE_LUXURIOUS,
    msg({
      context: 'GenerateDescriptionToneOptionDescription',
      comment: 'Description for luxurious tone',
      message: 'Emphasizing elegance and high-quality features',
    }),
  ],
  [
    DescriptionTone.TONE_MODERN,
    msg({
      context: 'GenerateDescriptionToneOptionDescription',
      comment: 'Description for modern tone',
      message: 'Focusing on contemporary design and amenities',
    }),
  ],
  [
    DescriptionTone.TONE_RUSTIC,
    msg({
      context: 'GenerateDescriptionToneOptionDescription',
      comment: 'Description for rustic tone',
      message: 'Highlighting natural and traditional aspects of a property',
    }),
  ],
  [
    DescriptionTone.TONE_ENERGETIC,
    msg({
      context: 'GenerateDescriptionToneOptionDescription',
      comment: 'Description for energetic tone',
      message: 'Upbeat and exciting, good for urban or trendy locations',
    }),
  ],
  [
    DescriptionTone.TONE_SERENE,
    msg({
      context: 'GenerateDescriptionToneOptionDescription',
      comment: 'Description for serene tone',
      message: 'Calm and peaceful, ideal for countryside or quiet neighborhoods',
    }),
  ],
  [
    DescriptionTone.TONE_INFORMATIVE,
    msg({
      context: 'GenerateDescriptionToneOptionDescription',
      comment: 'Description for informative tone',
      message: 'Factual and detailed, providing comprehensive information',
    }),
  ],
  [
    DescriptionTone.TONE_PERSUASIVE,
    msg({
      context: 'GenerateDescriptionToneOptionDescription',
      comment: 'Description for persuasive tone',
      message: 'Convincing and highlighting the best features',
    }),
  ],
  [
    DescriptionTone.TONE_NOSTALGIC,
    msg({
      context: 'GenerateDescriptionToneOptionDescription',
      comment: 'Description for nostalgic tone',
      message: 'Evoking a sense of history or tradition',
    }),
  ],
  [
    DescriptionTone.TONE_ROMANTIC,
    msg({
      context: 'GenerateDescriptionToneOptionDescription',
      comment: 'Description for romantic tone',
      message: 'Emphasizing intimate and charming aspects, perfect for couples',
    }),
  ],
  [
    DescriptionTone.TONE_ADVENTUROUS,
    msg({
      context: 'GenerateDescriptionToneOptionDescription',
      comment: 'Description for adventurous tone',
      message: 'Highlighting exciting and unique experiences',
    }),
  ],
  [
    DescriptionTone.TONE_COZY,
    msg({
      context: 'GenerateDescriptionToneOptionDescription',
      comment: 'Description for cozy tone',
      message: 'Focusing on comfort and homely feel',
    }),
  ],
  [
    DescriptionTone.TONE_ECO_FRIENDLY,
    msg({
      context: 'GenerateDescriptionToneOptionDescription',
      comment: 'Description for eco-friendly tone',
      message: 'Emphasizing sustainable and green aspects of the property',
    }),
  ],
  [
    DescriptionTone.TONE_ARTSY,
    msg({
      context: 'GenerateDescriptionToneOptionDescription',
      comment: 'Description for artsy tone',
      message: 'Highlighting creative and cultural aspects of the property or area',
    }),
  ],
  [
    DescriptionTone.TONE_MINIMALIST,
    msg({
      context: 'GenerateDescriptionToneOptionDescription',
      comment: 'Description for minimalist tone',
      message: 'Focusing on simplicity and essential features',
    }),
  ],
])

export function getLocalizedToneDescription(tone: DescriptionTone): MessageDescriptor {
  return localizedDescriptionToneDescriptions.get(tone) ?? unspecifiedDescriptionToneOptionDescription
}

const unspecifiedDescriptionTargetOption = msg({
  context: 'GenerateDescriptionTargetOption',
  comment: 'Default value when target option is not specified',
  message: 'Unspecified',
})

const unspecifiedDescriptionTargetOptionDescription = msg({
  context: 'GenerateDescriptionTargetOptionDescription',
  comment: 'Default value when target option description is not specified',
  message: 'Unspecified',
})

const localizedDescriptionTargets = new Map<DescriptionTarget, MessageDescriptor>([
  [DescriptionTarget.TARGET_UNSPECIFIED, unspecifiedDescriptionTargetOption],
  [
    DescriptionTarget.TARGET_FIRST_TIME_BUYERS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for first-time property buyers',
      message: 'First-Time Buyers',
    }),
  ],
  [
    DescriptionTarget.TARGET_LUXURY_SEEKERS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for luxury property seekers',
      message: 'Luxury Seekers',
    }),
  ],
  [
    DescriptionTarget.TARGET_INVESTORS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for property investors',
      message: 'Investors',
    }),
  ],
  [
    DescriptionTarget.TARGET_RETIREES,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for retirees',
      message: 'Retirees',
    }),
  ],
  [
    DescriptionTarget.TARGET_YOUNG_PROFESSIONALS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for young professionals',
      message: 'Young Professionals',
    }),
  ],
  [
    DescriptionTarget.TARGET_FAMILIES,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for families',
      message: 'Families',
    }),
  ],
  [
    DescriptionTarget.TARGET_VACATION_RENTERS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for vacation renters',
      message: 'Vacation Renters',
    }),
  ],
  [
    DescriptionTarget.TARGET_CORPORATE_RENTERS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for corporate renters',
      message: 'Corporate Renters',
    }),
  ],
  [
    DescriptionTarget.TARGET_STUDENTS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for students',
      message: 'Students',
    }),
  ],
  [
    DescriptionTarget.TARGET_ECO_CONSCIOUS_TRAVELERS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for eco-conscious travelers',
      message: 'Eco-Conscious Travelers',
    }),
  ],
  [
    DescriptionTarget.TARGET_COUPLES,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for couples',
      message: 'Couples',
    }),
  ],
  [
    DescriptionTarget.TARGET_SOLO_TRAVELERS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for solo travelers',
      message: 'Solo Travelers',
    }),
  ],
  [
    DescriptionTarget.TARGET_DIGITAL_NOMADS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for digital nomads',
      message: 'Digital Nomads',
    }),
  ],
  [
    DescriptionTarget.TARGET_ADVENTURE_SEEKERS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for adventure seekers',
      message: 'Adventure Seekers',
    }),
  ],
  [
    DescriptionTarget.TARGET_PET_OWNERS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for pet owners',
      message: 'Pet Owners',
    }),
  ],
  [
    DescriptionTarget.TARGET_LUXURY_TRAVELERS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for luxury travelers',
      message: 'Luxury Travelers',
    }),
  ],
  [
    DescriptionTarget.TARGET_GROUP_TRAVELERS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for group travelers',
      message: 'Group Travelers',
    }),
  ],
  [
    DescriptionTarget.TARGET_WELLNESS_ENTHUSIASTS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for wellness enthusiasts',
      message: 'Wellness Enthusiasts',
    }),
  ],
  [
    DescriptionTarget.TARGET_CULTURE_ENTHUSIASTS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for culture enthusiasts',
      message: 'Culture Enthusiasts',
    }),
  ],
  [
    DescriptionTarget.TARGET_BUDGET_TRAVELERS,
    msg({
      context: 'GenerateDescriptionTargetOption',
      comment: 'Target audience option for budget travelers',
      message: 'Budget Travelers',
    }),
  ],
])

export function getLocalizedTargetName(target: DescriptionTarget): MessageDescriptor {
  return localizedDescriptionTargets.get(target) ?? unspecifiedDescriptionTargetOption
}

const localizedDescriptionTargetDescriptions = new Map<DescriptionTarget, MessageDescriptor>([
  [DescriptionTarget.TARGET_UNSPECIFIED, unspecifiedDescriptionTargetOptionDescription],
  [
    DescriptionTarget.TARGET_FIRST_TIME_BUYERS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for first-time buyers target',
      message: 'People looking to purchase their first property',
    }),
  ],
  [
    DescriptionTarget.TARGET_LUXURY_SEEKERS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for luxury seekers target',
      message: 'High-net-worth individuals looking for premium properties',
    }),
  ],
  [
    DescriptionTarget.TARGET_INVESTORS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for investors target',
      message: 'Those looking for properties with good return on investment',
    }),
  ],
  [
    DescriptionTarget.TARGET_RETIREES,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for retirees target',
      message: 'Older adults looking for retirement homes or communities',
    }),
  ],
  [
    DescriptionTarget.TARGET_YOUNG_PROFESSIONALS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for young professionals target',
      message: 'Career-focused individuals, often seeking urban living',
    }),
  ],
  [
    DescriptionTarget.TARGET_FAMILIES,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for families target',
      message: 'Groups with children, often prioritizing space and safety',
    }),
  ],
  [
    DescriptionTarget.TARGET_VACATION_RENTERS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for vacation renters target',
      message: 'People looking for short-term vacation properties',
    }),
  ],
  [
    DescriptionTarget.TARGET_CORPORATE_RENTERS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for corporate renters target',
      message: 'Businesses seeking temporary housing for employees',
    }),
  ],
  [
    DescriptionTarget.TARGET_STUDENTS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for students target',
      message: 'Young adults looking for housing near educational institutions',
    }),
  ],
  [
    DescriptionTarget.TARGET_ECO_CONSCIOUS_TRAVELERS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for eco-conscious travelers target',
      message: 'Individuals prioritizing sustainable and green properties',
    }),
  ],
  [
    DescriptionTarget.TARGET_COUPLES,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for couples target',
      message: 'Partners looking for romantic getaways or intimate living spaces',
    }),
  ],
  [
    DescriptionTarget.TARGET_SOLO_TRAVELERS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for solo travelers target',
      message: 'Individuals traveling alone, often seeking unique experiences',
    }),
  ],
  [
    DescriptionTarget.TARGET_DIGITAL_NOMADS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for digital nomads target',
      message: 'Remote workers looking for well-equipped, long-term stays',
    }),
  ],
  [
    DescriptionTarget.TARGET_ADVENTURE_SEEKERS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for adventure seekers target',
      message: 'Travelers looking for unique, exciting experiences',
    }),
  ],
  [
    DescriptionTarget.TARGET_PET_OWNERS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for pet owners target',
      message: 'Travelers looking for pet-friendly accommodations',
    }),
  ],
  [
    DescriptionTarget.TARGET_LUXURY_TRAVELERS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for luxury travelers target',
      message: 'High-end tourists seeking premium experiences',
    }),
  ],
  [
    DescriptionTarget.TARGET_GROUP_TRAVELERS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for group travelers target',
      message: 'Large groups or multiple families traveling together',
    }),
  ],
  [
    DescriptionTarget.TARGET_WELLNESS_ENTHUSIASTS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for wellness enthusiasts target',
      message: 'Individuals focused on health and relaxation experiences',
    }),
  ],
  [
    DescriptionTarget.TARGET_CULTURE_ENTHUSIASTS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for culture enthusiasts target',
      message: 'Travelers interested in local history, art, and traditions',
    }),
  ],
  [
    DescriptionTarget.TARGET_BUDGET_TRAVELERS,
    msg({
      context: 'GenerateDescriptionTargetOptionDescription',
      comment: 'Description for budget travelers target',
      message: 'Cost-conscious individuals seeking affordable accommodations',
    }),
  ],
])

export function getLocalizedTargetDescription(target: DescriptionTarget): MessageDescriptor {
  return localizedDescriptionTargetDescriptions.get(target) ?? unspecifiedDescriptionTargetOptionDescription
}

const unspecifiedDescriptionLanguageOption = msg({
  context: 'GenerateDescriptionLanguageOption',
  comment: 'Default value when DescriptionLanguage option is not specified',
  message: 'Unspecified',
})

const unspecifiedDescriptionLanguageOptionDescription = msg({
  context: 'GenerateDescriptionLanguageOptionDescription',
  comment: 'Default value when DescriptionLanguage option description is not specified',
  message: 'Unspecified',
})

const localizedDescriptionLanguages = new Map<DescriptionLanguage, MessageDescriptor>([
  [DescriptionLanguage.LANGUAGE_UNSPECIFIED, unspecifiedDescriptionLanguageOption],
  [
    DescriptionLanguage.LANGUAGE_BG_BG,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Bulgarian (Bulgaria)',
      message: 'Bulgarian',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_CS_CZ,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Czech (Czech Republic)',
      message: 'Czech',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_DA_DK,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Danish (Denmark)',
      message: 'Danish',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_DE_AT,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for German (Austria)',
      message: 'German',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_DE_CH,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for German (Switzerland)',
      message: 'German',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_DE_DE,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for German (Germany)',
      message: 'German',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_DE_LU,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for German (Luxembourg)',
      message: 'German',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_EL_GR,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Greek (Greece)',
      message: 'Greek',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_EN_AU,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for English (Australia)',
      message: 'English',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_EN_CA,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for English (Canada)',
      message: 'English',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_EN_GB,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for English (UK)',
      message: 'English',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_EN_IE,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for English (Ireland)',
      message: 'English',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_EN_NZ,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for English (New Zealand)',
      message: 'English',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_EN_US,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for English (US)',
      message: 'English',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_ES_AR,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Spanish (Argentina)',
      message: 'Spanish',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_ES_CL,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Spanish (Chile)',
      message: 'Spanish',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_ES_CO,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Spanish (Colombia)',
      message: 'Spanish',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_ES_CR,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Spanish (Costa Rica)',
      message: 'Spanish',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_ES_ES,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Spanish (Spain)',
      message: 'Spanish',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_ES_MX,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Spanish (Mexico)',
      message: 'Spanish',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_ET_EE,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Estonian (Estonia)',
      message: 'Estonian',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_FI_FI,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Finnish (Finland)',
      message: 'Finnish',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_FR_BE,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for French (Belgium)',
      message: 'French',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_FR_CA,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for French (Canada)',
      message: 'French',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_FR_CH,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for French (Switzerland)',
      message: 'French',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_FR_FR,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for French (France)',
      message: 'French',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_FR_LU,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for French (Luxembourg)',
      message: 'French',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_HE_IL,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Hebrew (Israel)',
      message: 'Hebrew',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_HR_HR,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Croatian (Croatia)',
      message: 'Croatian',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_HU_HU,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Hungarian (Hungary)',
      message: 'Hungarian',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_IS_IS,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Icelandic (Iceland)',
      message: 'Icelandic',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_IT_CH,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Italian (Switzerland)',
      message: 'Italian',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_IT_IT,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Italian (Italy)',
      message: 'Italian',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_JA_JP,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Japanese (Japan)',
      message: 'Japanese',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_KO_KR,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Korean (South Korea)',
      message: 'Korean',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_LB_LU,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Luxembourgish (Luxembourg)',
      message: 'Luxembourgish',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_LT_LT,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Lithuanian (Lithuania)',
      message: 'Lithuanian',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_LV_LV,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Latvian (Latvia)',
      message: 'Latvian',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_NB_NO,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Norwegian Bokmål (Norway)',
      message: 'Norwegian Bokmål',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_NL_BE,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Dutch (Belgium)',
      message: 'Dutch',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_NL_NL,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Dutch (Netherlands)',
      message: 'Dutch',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_NN_NO,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Norwegian Nynorsk (Norway)',
      message: 'Norwegian Nynorsk',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_PL_PL,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Polish (Poland)',
      message: 'Polish',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_PT_BR,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Portuguese (Brazil)',
      message: 'Portuguese',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_PT_PT,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Portuguese (Portugal)',
      message: 'Portuguese',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_RM_CH,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Romansh (Switzerland)',
      message: 'Romansh',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_RO_RO,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Romanian (Romania)',
      message: 'Romanian',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_RU_RU,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Russian (Russia)',
      message: 'Russian',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_SK_SK,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Slovak (Slovakia)',
      message: 'Slovak',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_SL_SI,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Slovenian (Slovenia)',
      message: 'Slovenian',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_SV_SE,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Swedish (Sweden)',
      message: 'Swedish',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_TR_TR,
    msg({
      context: 'GenerateDescriptionLanguageOption',
      comment: 'Language option for Turkish (Turkey)',
      message: 'Turkish',
    }),
  ],
])

export function getLocalizedLanguageName(DescriptionLanguage: DescriptionLanguage): MessageDescriptor {
  return localizedDescriptionLanguages.get(DescriptionLanguage) ?? unspecifiedDescriptionLanguageOption
}

const localizedDescriptionLanguageRegions = new Map<DescriptionLanguage, MessageDescriptor>([
  [DescriptionLanguage.LANGUAGE_UNSPECIFIED, unspecifiedDescriptionLanguageOptionDescription],
  [
    DescriptionLanguage.LANGUAGE_BG_BG,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Bulgarian language',
      message: 'Bulgaria',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_CS_CZ,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Czech language',
      message: 'Czech Republic',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_DA_DK,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Danish language',
      message: 'Denmark',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_DE_AT,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for German language',
      message: 'Austria',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_DE_CH,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for German language',
      message: 'Switzerland',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_DE_DE,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for German language',
      message: 'Germany',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_DE_LU,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for German language',
      message: 'Luxembourg',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_EL_GR,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Greek language',
      message: 'Greece',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_EN_AU,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for English language',
      message: 'Australia',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_EN_CA,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for English language',
      message: 'Canada',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_EN_GB,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for English language',
      message: 'UK',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_EN_IE,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for English language',
      message: 'Ireland',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_EN_NZ,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for English language',
      message: 'New Zealand',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_EN_US,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for English language',
      message: 'United States',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_ES_AR,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Spanish language',
      message: 'Argentina',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_ES_CL,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Spanish language',
      message: 'Chile',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_ES_CO,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Spanish language',
      message: 'Colombia',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_ES_CR,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Spanish language',
      message: 'Costa Rica',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_ES_ES,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Spanish language',
      message: 'Spain',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_ES_MX,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Spanish language',
      message: 'Mexico',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_ET_EE,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Estonian language',
      message: 'Estonia',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_FI_FI,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Finnish language',
      message: 'Finland',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_FR_BE,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for French language',
      message: 'Belgium',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_FR_CA,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for French language',
      message: 'Canada',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_FR_CH,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for French language',
      message: 'Switzerland',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_FR_FR,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for French language',
      message: 'France',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_FR_LU,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for French language',
      message: 'Luxembourg',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_HE_IL,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Hebrew language',
      message: 'Israel',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_HR_HR,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Croatian language',
      message: 'Croatia',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_HU_HU,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Hungarian language',
      message: 'Hungary',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_IS_IS,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Icelandic language',
      message: 'Iceland',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_IT_CH,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Italian language',
      message: 'Switzerland',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_IT_IT,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Italian language',
      message: 'Italy',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_JA_JP,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Japanese language',
      message: 'Japan',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_KO_KR,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Korean language',
      message: 'South Korea',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_LB_LU,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Luxembourgish language',
      message: 'Luxembourg',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_LT_LT,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Lithuanian language',
      message: 'Lithuania',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_LV_LV,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Latvian language',
      message: 'Latvia',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_NB_NO,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Norwegian Bokmål language',
      message: 'Norway',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_NL_BE,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Dutch language',
      message: 'Belgium',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_NL_NL,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Dutch language',
      message: 'Netherlands',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_NN_NO,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Norwegian Nynorsk language',
      message: 'Norway',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_PL_PL,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Polish language',
      message: 'Poland',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_PT_BR,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Portuguese language',
      message: 'Brazil',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_PT_PT,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Portuguese language',
      message: 'Portugal',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_RM_CH,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Romansh language',
      message: 'Switzerland',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_RO_RO,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Romanian language',
      message: 'Romania',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_RU_RU,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Russian language',
      message: 'Russia',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_SK_SK,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Slovak language',
      message: 'Slovakia',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_SL_SI,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Slovenian language',
      message: 'Slovenia',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_SV_SE,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Swedish language',
      message: 'Sweden',
    }),
  ],
  [
    DescriptionLanguage.LANGUAGE_TR_TR,
    msg({
      context: 'GenerateDescriptionLanguageOptionDescription',
      comment: 'Region for Turkish language',
      message: 'Turkey',
    }),
  ],
])

export function getLocalizedLanguageDescription(DescriptionLanguage: DescriptionLanguage): MessageDescriptor {
  return localizedDescriptionLanguageRegions.get(DescriptionLanguage) ?? unspecifiedDescriptionLanguageOptionDescription
}

const unspecifiedDescriptionLengthOption = msg({
  context: 'GenerateDescriptionLengthOption',
  comment: 'Default value when length option is not specified',
  message: 'Unspecified',
})

const unspecifiedDescriptionLengthOptionDescription = msg({
  context: 'GenerateDescriptionLengthOptionDescription',
  comment: 'Default value when length option description is not specified',
  message: 'Unspecified',
})

const localizedDescriptionLengths = new Map<DescriptionLength, MessageDescriptor>([
  [DescriptionLength.LENGTH_UNSPECIFIED, unspecifiedDescriptionLengthOption],
  [
    DescriptionLength.LENGTH_SHORT,
    msg({ context: 'GenerateDescriptionLengthOption', comment: 'Option for short content length', message: 'Short' }),
  ],
  [
    DescriptionLength.LENGTH_MEDIUM,
    msg({ context: 'GenerateDescriptionLengthOption', comment: 'Option for medium content length', message: 'Medium' }),
  ],
  [
    DescriptionLength.LENGTH_LONG,
    msg({ context: 'GenerateDescriptionLengthOption', comment: 'Option for long content length', message: 'Long' }),
  ],
  [
    DescriptionLength.LENGTH_CAPTION,
    msg({
      context: 'GenerateDescriptionLengthOption',
      comment: 'Option for social media caption length',
      message: 'Caption',
    }),
  ],
])

export function getLocalizedLengthName(length: DescriptionLength): MessageDescriptor {
  return localizedDescriptionLengths.get(length) ?? unspecifiedDescriptionLengthOption
}

const localizedParagraphDescriptionLengths = new Map<DescriptionLength, MessageDescriptor>([
  [DescriptionLength.LENGTH_UNSPECIFIED, unspecifiedDescriptionLengthOptionDescription],
  [
    DescriptionLength.LENGTH_SHORT,
    msg({
      context: 'GenerateDescriptionLengthOptionDescription',
      comment: 'Option for short paragraph length',
      message: '1 paragraph',
    }),
  ],
  [
    DescriptionLength.LENGTH_MEDIUM,
    msg({
      context: 'GenerateDescriptionLengthOptionDescription',
      comment: 'Option for medium paragraph length',
      message: '2 paragraphs',
    }),
  ],
  [
    DescriptionLength.LENGTH_LONG,
    msg({
      context: 'GenerateDescriptionLengthOptionDescription',
      comment: 'Option for long paragraph length',
      message: '3 paragraphs',
    }),
  ],
  [
    DescriptionLength.LENGTH_CAPTION,
    msg({
      context: 'GenerateDescriptionLengthOptionDescription',
      comment: 'Option for social media caption length',
      message: 'For social media',
    }),
  ],
])

export function getLocalizedLengthDescription(length: DescriptionLength): MessageDescriptor {
  return localizedParagraphDescriptionLengths.get(length) ?? unspecifiedDescriptionLengthOptionDescription
}
