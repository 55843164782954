import type { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 48 48' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M18.992 1.439C19.67 1.075 21.188.5 23.999.5c2.811 0 4.33.575 5.007.939.357.191.587.5.732.802.202.421.552 1.168.931 2.059.563 1.322 1.618 2.403 2.896 3.143 1.277.74 2.729 1.105 4.159.93a51.716 51.716 0 0 1 2.25-.222c.335-.026.717.019 1.06.232.655.405 1.912 1.432 3.318 3.867 1.405 2.435 1.666 4.037 1.69 4.806.013.405-.14.758-.33 1.034a51.578 51.578 0 0 1-1.32 1.842c-.864 1.149-1.275 2.594-1.275 4.068 0 1.474.411 2.919 1.275 4.068a51.658 51.658 0 0 1 1.321 1.842c.19.277.342.63.33 1.034-.024.77-.285 2.372-1.69 4.806-1.407 2.435-2.664 3.462-3.318 3.867-.344.214-.726.258-1.06.232a51.618 51.618 0 0 1-2.252-.223c-1.43-.173-2.882.192-4.159.931-1.277.74-2.332 1.821-2.895 3.143-.38.89-.73 1.638-.931 2.059-.145.302-.375.61-.732.802-.677.364-2.195.939-5.007.939s-4.33-.575-5.007-.939c-.357-.191-.586-.5-.731-.802a51.778 51.778 0 0 1-.931-2.057c-.563-1.322-1.62-2.404-2.897-3.144-1.278-.74-2.73-1.105-4.16-.932-.96.117-1.782.187-2.247.223-.334.025-.717-.019-1.06-.232-.655-.405-1.912-1.432-3.318-3.867-1.405-2.435-1.666-4.037-1.69-4.806-.013-.405.14-.758.33-1.034.262-.385.734-1.061 1.315-1.834.865-1.152 1.276-2.6 1.276-4.076 0-1.476-.411-2.924-1.276-4.076a51.715 51.715 0 0 1-1.316-1.833c-.189-.277-.342-.63-.329-1.035.024-.769.285-2.37 1.69-4.806 1.406-2.434 2.663-3.462 3.317-3.867.344-.213.727-.257 1.06-.232.466.036 1.287.106 2.247.223 1.43.173 2.883-.192 4.16-.932 1.279-.74 2.335-1.822 2.898-3.144.38-.89.729-1.636.93-2.057.146-.302.375-.61.732-.802ZM15 24a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z'
      clipRule='evenodd'
    />
  </svg>
)

export default SvgComponent
