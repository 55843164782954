import { DescriptionLanguage } from '@/pb/service/description/v1/description_service_pb'
import { detect, fromNavigator } from '@lingui/detect-locale'

export function getGenerateLanguageFromLocale(): DescriptionLanguage {
  const fallbackLocale = 'en-US'

  const locale = detect(fromNavigator()) ?? fallbackLocale

  const formattedLocale = `LANGUAGE_${locale.toUpperCase().replace('-', '_')}`

  const matchedLanguage = Object.entries(DescriptionLanguage).find(
    ([key, value]) => typeof value === 'number' && key === formattedLocale,
  )

  if (matchedLanguage) {
    return matchedLanguage[1] as DescriptionLanguage
  }

  return DescriptionLanguage.LANGUAGE_EN_US
}
