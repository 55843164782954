import { ListingType, MeasurementUnit, PropertyType, Style } from '@/pb/service/listing/v1/listing_service_pb'
import type { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'

const unspecifiedPropertyTypeOption = msg({
  context: 'ListingPropertyTypeOption',
  comment: 'Default value when property type is not specified',
  message: 'Unspecified',
})

const localizedPropertyTypes = new Map<PropertyType, MessageDescriptor>([
  [PropertyType.PROPERTY_TYPE_UNSPECIFIED, unspecifiedPropertyTypeOption],
  [
    PropertyType.SINGLE_FAMILY_HOME,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A standalone house for a single family',
      message: 'Single Family Home',
    }),
  ],
  [
    PropertyType.MULTI_FAMILY_HOME,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A house designed for multiple families',
      message: 'Multi Family Home',
    }),
  ],
  [
    PropertyType.APARTMENT,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A unit in a larger residential building',
      message: 'Apartment',
    }),
  ],
  [
    PropertyType.CONDO,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'An individually owned unit in a larger building',
      message: 'Condominium',
    }),
  ],
  [
    PropertyType.PENTHOUSE,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A luxury apartment on the top floor of a building',
      message: 'Penthouse',
    }),
  ],
  [
    PropertyType.TOWNHOUSE,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A multi-floor home that shares walls with adjacent properties',
      message: 'Townhouse',
    }),
  ],
  [
    PropertyType.LOFT,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'An open-plan apartment, often in a converted industrial building',
      message: 'Loft',
    }),
  ],
  [
    PropertyType.STUDIO,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A small apartment with a single main room',
      message: 'Studio',
    }),
  ],
  [
    PropertyType.DUPLEX,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A building with two separate living units',
      message: 'Duplex',
    }),
  ],
  [
    PropertyType.TRIPLEX,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A building with three separate living units',
      message: 'Triplex',
    }),
  ],
  [
    PropertyType.FOURPLEX,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A building with four separate living units',
      message: 'Fourplex',
    }),
  ],
  [
    PropertyType.CO_OP,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A housing cooperative where residents own shares',
      message: 'Co-op',
    }),
  ],
  [
    PropertyType.MOBILE_HOME,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A prefabricated home designed to be movable',
      message: 'Mobile Home',
    }),
  ],
  [
    PropertyType.GUEST_HOUSE,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A separate, smaller dwelling on the same property as a larger home',
      message: 'Guest House',
    }),
  ],
  [
    PropertyType.CABIN,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A small, simple house typically in a rural or wilderness area',
      message: 'Cabin',
    }),
  ],
  [
    PropertyType.COTTAGE,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A small, often cozy house, typically in a rural or semi-rural location',
      message: 'Cottage',
    }),
  ],
  [
    PropertyType.BUNGALOW,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A small, single-story house with a sloping roof',
      message: 'Bungalow',
    }),
  ],
  [
    PropertyType.VILLA,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A large, luxurious house, often in a vacation setting',
      message: 'Villa',
    }),
  ],
  [
    PropertyType.MANSION,
    msg({ context: 'ListingPropertyTypeOption', comment: 'A very large, impressive house', message: 'Mansion' }),
  ],
  [
    PropertyType.RANCH,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A single-story house, typically with an open floor plan',
      message: 'Ranch',
    }),
  ],
  [
    PropertyType.FARM_HOUSE,
    msg({
      context: 'ListingPropertyTypeOption',
      comment: 'A house on a farm, often part of a larger agricultural property',
      message: 'Farm House',
    }),
  ],
])

export function getLocalizedPropertyType(propertyType: PropertyType): MessageDescriptor {
  return localizedPropertyTypes.get(propertyType) ?? unspecifiedPropertyTypeOption
}

const unspecifiedListingTypeOption = msg({
  context: 'ListingTypeOption',
  comment: 'Default value when listing type is not specified',
  message: 'Unspecified',
})

const localizedListingTypes = new Map<ListingType, MessageDescriptor>([
  [ListingType.LISTING_TYPE_UNSPECIFIED, unspecifiedListingTypeOption],
  [
    ListingType.FOR_SALE,
    msg({ context: 'ListingTypeOption', comment: 'Listing is for purchase', message: 'For Sale' }),
  ],
  [ListingType.FOR_RENT, msg({ context: 'ListingTypeOption', comment: 'Listing is for rent', message: 'For Rent' })],
  [ListingType.LEASE, msg({ context: 'ListingTypeOption', comment: 'Listing is for lease', message: 'Lease' })],
  [
    ListingType.VACATION_RENTAL,
    msg({
      context: 'ListingTypeOption',
      comment: 'Listing is for short-term vacation stays',
      message: 'Vacation Rental',
    }),
  ],
  [
    ListingType.SHORT_TERM_RENTAL,
    msg({
      context: 'ListingTypeOption',
      comment: 'Listing is for short-term rental (typically less than 6 months)',
      message: 'Short-Term Rental',
    }),
  ],
  [
    ListingType.LONG_TERM_RENTAL,
    msg({
      context: 'ListingTypeOption',
      comment: 'Listing is for long-term rental (typically 6 months or more)',
      message: 'Long-Term Rental',
    }),
  ],
  [
    ListingType.SUBLEASE,
    msg({
      context: 'ListingTypeOption',
      comment: 'Listing is for sublease from the current tenant',
      message: 'Sublease',
    }),
  ],
  [
    ListingType.AIRBNB_ENTIRE_PLACE,
    msg({
      context: 'ListingTypeOption',
      comment: 'Entire property is available for rent on Airbnb',
      message: 'Airbnb (Entire Place)',
    }),
  ],
  [
    ListingType.AIRBNB_PRIVATE_ROOM,
    msg({
      context: 'ListingTypeOption',
      comment: 'Private room in a shared property is available for rent on Airbnb',
      message: 'Airbnb (Private Room)',
    }),
  ],
  [
    ListingType.AIRBNB_SHARED_ROOM,
    msg({
      context: 'ListingTypeOption',
      comment: 'Shared room in a property is available for rent on Airbnb',
      message: 'Airbnb (Shared Room)',
    }),
  ],
])

export function getLocalizedListingType(listingType: ListingType): MessageDescriptor {
  return localizedListingTypes.get(listingType) ?? unspecifiedListingTypeOption
}

const unspecifiedStyleOption = msg({
  context: 'ListingStyleOption',
  comment: 'Default value when architectural style is not specified',
  message: 'Unspecified',
})

const localizedStyles = new Map<Style, MessageDescriptor>([
  [Style.STYLE_UNSPECIFIED, unspecifiedStyleOption],
  [
    Style.MODERN,
    msg({
      context: 'ListingStyleOption',
      comment: 'Characterized by clean lines, minimal ornamentation, and use of glass, steel, and concrete',
      message: 'Modern',
    }),
  ],
  [
    Style.CONTEMPORARY,
    msg({
      context: 'ListingStyleOption',
      comment: 'Current architectural trends, often blending styles and using eco-friendly materials',
      message: 'Contemporary',
    }),
  ],
  [
    Style.COLONIAL,
    msg({
      context: 'ListingStyleOption',
      comment: 'Symmetrical design, often two stories with evenly spaced windows',
      message: 'Colonial',
    }),
  ],
  [
    Style.VICTORIAN,
    msg({
      context: 'ListingStyleOption',
      comment: 'Ornate style from the 19th century, often featuring intricate woodwork and bright colors',
      message: 'Victorian',
    }),
  ],
  [
    Style.MEDITERRANEAN,
    msg({
      context: 'ListingStyleOption',
      comment: 'Inspired by seaside villas, featuring stucco walls and red-tiled roofs',
      message: 'Mediterranean',
    }),
  ],
  [
    Style.CAPE_COD,
    msg({
      context: 'ListingStyleOption',
      comment: 'Compact, symmetrical design with steep rooflines and shuttered windows',
      message: 'Cape Cod',
    }),
  ],
  [
    Style.STYLE_RANCH,
    msg({
      context: 'ListingStyleOption',
      comment: 'Single-story homes with open floor plans and low-pitched roofs',
      message: 'Ranch',
    }),
  ],
  [
    Style.CRAFTSMAN,
    msg({
      context: 'ListingStyleOption',
      comment: 'Emphasizes handcrafted details, natural materials, and exposed beams',
      message: 'Craftsman',
    }),
  ],
  [
    Style.FARMHOUSE,
    msg({
      context: 'ListingStyleOption',
      comment: 'Rustic style with practical features, often featuring a large porch',
      message: 'Farmhouse',
    }),
  ],
  [
    Style.TUDOR,
    msg({
      context: 'ListingStyleOption',
      comment: 'Medieval English style with steep roofs, cross gables, and half-timbering',
      message: 'Tudor',
    }),
  ],
  [
    Style.STYLE_COTTAGE,
    msg({
      context: 'ListingStyleOption',
      comment: 'Small, cozy homes often featuring a garden and irregular floor plan',
      message: 'Cottage',
    }),
  ],
  [
    Style.MID_CENTURY_MODERN,
    msg({
      context: 'ListingStyleOption',
      comment: '1950s-1960s style emphasizing clean lines, organic forms, and integration with nature',
      message: 'Mid-Century Modern',
    }),
  ],
  [
    Style.SPANISH,
    msg({
      context: 'ListingStyleOption',
      comment: 'Influenced by Spanish colonial architecture, featuring stucco walls and red tile roofs',
      message: 'Spanish',
    }),
  ],
  [
    Style.DUTCH_COLONIAL,
    msg({
      context: 'ListingStyleOption',
      comment: 'Characterized by a broad gambrel roof that gives a barn-like appearance',
      message: 'Dutch Colonial',
    }),
  ],
  [
    Style.GEORGIAN,
    msg({
      context: 'ListingStyleOption',
      comment: 'Symmetrical style with rigid rules for proportion and symmetry',
      message: 'Georgian',
    }),
  ],
  [
    Style.PRAIRIE,
    msg({
      context: 'ListingStyleOption',
      comment: 'Horizontal lines and flat or hipped roofs with overhanging eaves',
      message: 'Prairie',
    }),
  ],
  [
    Style.GOTHIC,
    msg({
      context: 'ListingStyleOption',
      comment: 'Features pointed arches, steep roofs, and ornate decorations',
      message: 'Gothic',
    }),
  ],
  [
    Style.SOUTHWESTERN,
    msg({
      context: 'ListingStyleOption',
      comment: 'Inspired by Native American and Spanish styles, often using adobe or stucco',
      message: 'Southwestern',
    }),
  ],
  [
    Style.FRENCH_COUNTRY,
    msg({
      context: 'ListingStyleOption',
      comment: 'Rustic elegance inspired by homes in the French countryside',
      message: 'French Country',
    }),
  ],
])

export function getLocalizedStyle(style: Style): MessageDescriptor {
  return localizedStyles.get(style) ?? unspecifiedStyleOption
}

const localizedAreaMeasurementUnits = new Map<MeasurementUnit, MessageDescriptor>([
  [
    MeasurementUnit.METRIC,
    msg({
      context: 'ListingMeasurementUnitOption',
      comment: 'Metric unit for measuring area, commonly used in most countries worldwide',
      message: 'Square Meters (m²)',
    }),
  ],
  [
    MeasurementUnit.IMPERIAL,
    msg({
      context: 'ListingMeasurementUnitOption',
      comment: 'Imperial unit for measuring area, commonly used in the United States and some other countries',
      message: 'Square Feet (sq ft)',
    }),
  ],
])

export function getLocalizedAreaMeasurementUnit(unit: MeasurementUnit): MessageDescriptor {
  // biome-ignore lint/style/noNonNullAssertion: Only two possible options here, no fallback available
  return localizedAreaMeasurementUnits.get(unit)!
}

const localizedShortAreaMeasurementUnits = new Map<MeasurementUnit, MessageDescriptor>([
  [
    MeasurementUnit.METRIC,
    msg({
      context: 'ListingAbbreviatedMeasurementUnit',
      comment: 'Abbreviated metric unit for measuring area, used where space is limited',
      message: 'm²',
    }),
  ],
  [
    MeasurementUnit.IMPERIAL,
    msg({
      context: 'ListingAbbreviatedMeasurementUnit',
      comment: 'Abbreviated imperial unit for measuring area, used where space is limited',
      message: 'sq ft',
    }),
  ],
])

export function getLocalizedShortAreaMeasurementUnit(unit: MeasurementUnit): MessageDescriptor {
  // biome-ignore lint/style/noNonNullAssertion: Only two possible options here, no fallback available
  return localizedShortAreaMeasurementUnits.get(unit)!
}
