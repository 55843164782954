import { Subheading } from '@/components/heading.tsx'
import { Text } from '@/components/text.tsx'
import { Trans } from '@lingui/macro'
import type { ReactNode } from 'react'

export function NameSection(props: { nameField: ReactNode }): ReactNode {
  return (
    <section className='grid gap-x-8 gap-y-6 sm:grid-cols-2'>
      <div className='space-y-1'>
        <Subheading>
          <Trans context='EditListingDetailsPage' comment='Subheading text for the name section'>
            Name
          </Trans>
        </Subheading>
        <Text>
          <Trans context='EditListingDetailsPage' comment='Detail text for the name section'>
            Provide a name for your listing that helps you identify it. This can be the address or another descriptive
            title.
          </Trans>
        </Text>
      </div>
      {props.nameField}
    </section>
  )
}
