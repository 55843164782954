// @generated by protoc-gen-es v1.10.0
// @generated from file service/description/v1/error.proto (package service.description.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum service.description.v1.DescriptionServiceError
 */
export const DescriptionServiceError = /*@__PURE__*/ proto3.makeEnum(
  "service.description.v1.DescriptionServiceError",
  [
    {no: 0, name: "NO_CREDITS"},
  ],
);

