import { Button } from '@/components/button.tsx'
import { Heading } from '@/components/heading.tsx'
import { Strong, Text } from '@/components/text.tsx'
import { confirmEmailRecoveryCode } from '@/pb/service/auth/v1/auth_service-AuthService_connectquery'
import { clearTokens } from '@/utils/token-util.ts'
import { callUnaryMethod } from '@connectrpc/connect-query'
import { createFileRoute } from '@tanstack/react-router'

import LogoIcon from '@/assets/LogoIcon.tsx'
import { getLocalizedError } from '@/i18n/error-localization.ts'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import * as Sentry from '@sentry/react'
import { z } from 'zod'

const emailConfirmSchema = z.object({
  oobCode: z.string().catch(''),
})

export const Route = createFileRoute('/email/recovery')({
  loaderDeps: ({ search: { oobCode } }) => ({ oobCode }),
  loader: async ({ context: { connectTransport, queryClient }, deps: { oobCode } }) => {
    const { recoveredEmail } = await callUnaryMethod(
      confirmEmailRecoveryCode,
      { oobCode: oobCode },
      { transport: connectTransport },
    )
    queryClient.clear()
    Sentry.getCurrentScope().clear()
    await clearTokens()
    return { recoveredEmail }
  },
  validateSearch: emailConfirmSchema,
  errorComponent: ({ error }) => EmailRecoveryError(error),
  component: EmailRecoverySuccess,
})

function EmailRecoverySuccess() {
  const navigate = Route.useNavigate()
  const { recoveredEmail } = Route.useLoaderData()

  return (
    <div className='relative flex h-full w-full items-center justify-center sm:bg-gray-100 sm:dark:bg-zinc-950'>
      <LogoIcon className='absolute top-6 left-6 h-4 w-18' />
      <div className='sm:card w-full gap-4 rounded-none px-6 py-12 sm:max-w-[440px] sm:rounded-xl sm:px-12'>
        <div className='flex flex-col gap-y-6'>
          <Heading>
            <Trans context='ConfirmEmailRecoveryPage' comment="Heading text that's shown on a succesful email recovery">
              Your email has been recovered
            </Trans>
          </Heading>
          <Text>
            <Trans context='ConfirmEmailRecoveryPage' comment="Detail text that's shown on a succesful email recovery">
              <Strong>{recoveredEmail}</Strong> has been recovered. Please login to get back into your account.
            </Trans>
          </Text>
          <Button onClick={async () => await navigate({ to: '/login', replace: true })}>
            <Trans
              context='ConfirmEmailRecoveryPage'
              comment='Button text for navigating to the login page after a succesful email recovery'
            >
              Log in
            </Trans>
          </Button>
        </div>
      </div>
    </div>
  )
}

function EmailRecoveryError(e: unknown) {
  const { _ } = useLingui()

  const { isExpectedError, message } = getLocalizedError(e)
  if (!isExpectedError) {
    Sentry.captureException(e)
  }

  return (
    <div className='relative flex h-full w-full items-center justify-center sm:bg-gray-100 sm:dark:bg-zinc-950'>
      <LogoIcon className='absolute top-6 left-6 h-4 w-18' />
      <div className='sm:card w-full rounded-none px-6 py-12 sm:max-w-[440px] sm:rounded-xl sm:px-12'>
        <div className='flex flex-col gap-y-6'>
          <Heading>
            <Trans
              context='ConfirmEmailRecoveryPage'
              comment="Heading text that's shown when email is unable to be recovered"
            >
              Failed to recover email
            </Trans>
          </Heading>
          <Text>{_(message)}</Text>
        </div>
      </div>
    </div>
  )
}
