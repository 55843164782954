// @generated by protoc-gen-es v1.10.0
// @generated from file service/common/v1/error.proto (package service.common.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum service.common.v1.CommonServiceError
 */
export const CommonServiceError = /*@__PURE__*/ proto3.makeEnum(
  "service.common.v1.CommonServiceError",
  [
    {no: 0, name: "INTERNAL"},
    {no: 1, name: "PERMISSION_DENIED"},
    {no: 2, name: "VALIDATION"},
    {no: 3, name: "NOT_FOUND"},
  ],
);

