import { Link as RouterLink, type LinkProps as RouterLinkProps } from '@tanstack/react-router'

import * as Headless from '@headlessui/react'
import React from 'react'

type LinkProps = RouterLinkProps &
  React.RefAttributes<HTMLAnchorElement> & {
    className?: string
  }

export const Link = React.forwardRef(function Link(
  props: { href: LinkProps['to'] } & Omit<LinkProps, 'to'>,
  ref: React.ForwardedRef<HTMLAnchorElement>,
) {
  return (
    <Headless.DataInteractive>
      <RouterLink {...props} to={props.href} ref={ref} />
    </Headless.DataInteractive>
  )
})
