export function formatValue(
  value: number | string | string[] | undefined,
  formatter?: (val: number) => string,
): string {
  if (
    value === undefined ||
    value === null ||
    value === 0 ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return '-'
  }

  if (Array.isArray(value)) {
    return value.join(', ')
  }

  return formatter && typeof value === 'number' ? formatter(value) : value.toString()
}
