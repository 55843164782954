import type { ImageVariant } from '@/pb/service/media/v1/media_service_pb'
import type { ReactNode } from 'react'

function ResponsiveImage(props: {
  variants: ImageVariant[]
  className?: string
  sizes: string
  alt: string
}): ReactNode {
  const { variants, className, sizes, alt } = props

  const srcSet = variants.map((variant) => `${variant.url} ${variant.width}w`).join(', ')

  const smallestVariant = variants.reduce((min, variant) => (variant.width < min.width ? variant : min), variants[0])

  return (
    <img
      src={smallestVariant?.url}
      srcSet={srcSet}
      sizes={sizes}
      className={className}
      alt={alt}
      loading='lazy'
      decoding='async'
    />
  )
}

export default ResponsiveImage
