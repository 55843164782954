import { ErrorInfo } from '@/pb/google/rpc/error_details_pb'
import { AuthServiceError } from '@/pb/service/auth/v1/error_pb'
import { CommonServiceError } from '@/pb/service/common/v1/error_pb'
import { DescriptionServiceError } from '@/pb/service/description/v1/error_pb'
import { ConnectError } from '@connectrpc/connect'
import type { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'

const localizedErrorReasons = new Map<string, MessageDescriptor>([
  [
    DescriptionServiceError[DescriptionServiceError.NO_CREDITS],
    msg({
      context: 'ServerError',
      comment: 'Error text for when there are no more credits available',
      message: 'No more credits available',
    }),
  ],
  [
    AuthServiceError[AuthServiceError.INVALID_EMAIL_OR_PASSWORD],
    msg({
      context: 'ServerError',
      comment: 'Error text for invalid email or password during login',
      message: 'Invalid email or password',
    }),
  ],
  [
    AuthServiceError[AuthServiceError.ACCOUNT_DISABLED],
    msg({
      context: 'ServerError',
      comment: 'Error text for when the user account has been disabled',
      message: 'Your account has been disabled',
    }),
  ],
  [
    AuthServiceError[AuthServiceError.TOO_MANY_ATTEMPTS],
    msg({
      context: 'ServerError',
      comment: 'Error text for too many login attempts',
      message: 'Too many attempts. Please try again later.',
    }),
  ],
  [
    AuthServiceError[AuthServiceError.EMAIL_NOT_VERIFIED],
    msg({ context: 'ServerError', comment: 'Error text for unverified email address', message: 'Email not verified' }),
  ],
  [
    AuthServiceError[AuthServiceError.EMAIL_ALREADY_EXISTS],
    msg({
      context: 'ServerError',
      comment: 'Error text for attempting to register with an existing email',
      message: 'An account with that email already exists',
    }),
  ],
  [
    AuthServiceError[AuthServiceError.WEAK_PASSWORD],
    msg({
      context: 'ServerError',
      comment: 'Error text for password that does not meet strength requirements',
      message: 'Password must be more than 6 characters long',
    }),
  ],
  [
    AuthServiceError[AuthServiceError.INVALID_NEW_EMAIL],
    msg({
      context: 'ServerError',
      comment: 'Error text for invalid or already used email during email change',
      message: 'Email is invalid or already in use',
    }),
  ],
  [
    AuthServiceError[AuthServiceError.INVALID_OR_EXPIRED_CODE],
    msg({
      context: 'ServerError',
      comment: 'Error text for invalid or expired verification code',
      message: 'Invalid or expired code',
    }),
  ],
  [
    AuthServiceError[AuthServiceError.INVALID_OR_EXPIRED_REFRESH_TOKEN],
    msg({
      context: 'ServerError',
      comment: 'Error text for invalid or expired authentication token',
      message: 'Invalid or expired auth token',
    }),
  ],
  [
    AuthServiceError[AuthServiceError.ACCOUNT_NOT_FOUND],
    msg({
      context: 'ServerError',
      comment: 'Error text for when an account is not found',
      message: 'Account not found',
    }),
  ],
  [
    AuthServiceError[AuthServiceError.INVALID_ID_TOKEN],
    msg({
      context: 'ServerError',
      comment: 'Error text for invalid authentication token',
      message: 'Invalid auth token',
    }),
  ],
  [
    CommonServiceError[CommonServiceError.INTERNAL],
    msg({
      context: 'ServerError',
      comment: 'Error text for internal server errors',
      message: 'Server error. Please try again later.',
    }),
  ],
  [
    CommonServiceError[CommonServiceError.NOT_FOUND],
    msg({ context: 'ServerError', comment: 'Error text for resource not found', message: 'Not found' }),
  ],
  [
    CommonServiceError[CommonServiceError.PERMISSION_DENIED],
    msg({
      context: 'ServerError',
      comment: 'Error text for when the user lacks necessary permissions',
      message: 'Permission denied',
    }),
  ],
])

export interface LocalizedErrorResult {
  message: MessageDescriptor
  isExpectedError: boolean
}

export function getReasonFromError(e: unknown): string {
  if (e instanceof ConnectError) {
    const errorInfo = e.findDetails<ErrorInfo>(ErrorInfo)[0]
    return errorInfo?.reason ?? ''
  }
  return ''
}

export function getLocalizedError(e: unknown): LocalizedErrorResult {
  const fallbackMessage = msg({
    context: 'ServerError',
    comment: 'Error text for internal server errors',
    message: 'Server error. Please try again later.',
  })

  if (!(e instanceof ConnectError)) {
    return { message: fallbackMessage, isExpectedError: false }
  }

  const errorInfo = e.findDetails<ErrorInfo>(ErrorInfo)[0]
  const errorReason = errorInfo?.reason ?? ''
  const localizedMessage = localizedErrorReasons.get(errorReason) || fallbackMessage

  return {
    message: localizedMessage,
    isExpectedError: localizedErrorReasons.has(errorReason),
  }
}
