// @generated by protoc-gen-connect-query v1.4.1
// @generated from file service/listing/v1/listing_service.proto (package service.listing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { CreateListingRequest, CreateListingResponse, DeleteListingRequest, DeleteListingResponse, GetListingRequest, GetListingResponse, ListListingsRequest, ListListingsResponse, UpdateListingRequest, UpdateListingResponse } from "./listing_service_pb.js";

/**
 * @generated from rpc service.listing.v1.ListingsService.CreateListing
 */
export const createListing = {
  localName: "createListing",
  name: "CreateListing",
  kind: MethodKind.Unary,
  I: CreateListingRequest,
  O: CreateListingResponse,
  service: {
    typeName: "service.listing.v1.ListingsService"
  }
};

/**
 * @generated from rpc service.listing.v1.ListingsService.GetListing
 */
export const getListing = {
  localName: "getListing",
  name: "GetListing",
  kind: MethodKind.Unary,
  I: GetListingRequest,
  O: GetListingResponse,
  service: {
    typeName: "service.listing.v1.ListingsService"
  }
};

/**
 * @generated from rpc service.listing.v1.ListingsService.UpdateListing
 */
export const updateListing = {
  localName: "updateListing",
  name: "UpdateListing",
  kind: MethodKind.Unary,
  I: UpdateListingRequest,
  O: UpdateListingResponse,
  service: {
    typeName: "service.listing.v1.ListingsService"
  }
};

/**
 * @generated from rpc service.listing.v1.ListingsService.DeleteListing
 */
export const deleteListing = {
  localName: "deleteListing",
  name: "DeleteListing",
  kind: MethodKind.Unary,
  I: DeleteListingRequest,
  O: DeleteListingResponse,
  service: {
    typeName: "service.listing.v1.ListingsService"
  }
};

/**
 * @generated from rpc service.listing.v1.ListingsService.ListListings
 */
export const listListings = {
  localName: "listListings",
  name: "ListListings",
  kind: MethodKind.Unary,
  I: ListListingsRequest,
  O: ListListingsResponse,
  service: {
    typeName: "service.listing.v1.ListingsService"
  }
};
