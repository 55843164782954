import { Divider } from '@/components/divider.tsx'
import { Heading } from '@/components/heading.tsx'
import { Text } from '@/components/text.tsx'
import { Trans } from '@lingui/macro'
import type { ReactNode } from 'react'

export function Header(props: { new: any }): ReactNode {
  return (
    <div className='lg:mt-10'>
      {props.new ? (
        <>
          <Heading>
            <Trans
              context='EditListingDetailsPage'
              comment='Header text that is displayed when the user is editing a newly created listing'
            >
              New listing
            </Trans>
          </Heading>
          <Text className='mt-4 lg:mt-2'>
            <Trans
              context='EditListingDetailsPage'
              comment='Subheading text that is displayed when the user is editing a newly created listing'
            >
              To create a compelling and informative listing description, please provide as many details as possible.
            </Trans>
          </Text>
        </>
      ) : (
        <Heading>
          <Trans
            context='EditListingDetailsPage'
            comment='Header text that is displayed when the user is editing an existing listing'
          >
            Edit details
          </Trans>
        </Heading>
      )}
      <Divider className='my-10 mt-6' />
    </div>
  )
}
