// @generated by protoc-gen-connect-query v1.4.1
// @generated from file service/admin/v1/admin_service.proto (package service.admin.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { ImpersonateRequest, ImpersonateResponse } from "./admin_service_pb.js";

/**
 * @generated from rpc service.admin.v1.AdminService.Impersonate
 */
export const impersonate = {
  localName: "impersonate",
  name: "Impersonate",
  kind: MethodKind.Unary,
  I: ImpersonateRequest,
  O: ImpersonateResponse,
  service: {
    typeName: "service.admin.v1.AdminService"
  }
};
