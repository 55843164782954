import { hasTokens } from '@/utils/token-util.ts'
import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/')({
  beforeLoad: async () => {
    if (await hasTokens()) {
      throw redirect({
        to: '/listings',
      })
    }
    throw redirect({
      to: '/login',
    })
  },
})
