import { Currency } from '@/pb/service/checkout/v1/checkout_service_pb'
import {
  ARS,
  AUD,
  BGN,
  BRL,
  CAD,
  CHF,
  CLP,
  COP,
  CRC,
  CZK,
  DKK,
  type Currency as DineroCurrency,
  EUR,
  GBP,
  HUF,
  ILS,
  ISK,
  JPY,
  KRW,
  MXN,
  NOK,
  NZD,
  PLN,
  RON,
  RUB,
  SEK,
  TRY,
  USD,
} from '@dinero.js/currencies'
import { type Dinero, toDecimal } from 'dinero.js'

export function mapToDinero(currency: Currency): DineroCurrency<number> {
  switch (currency) {
    case Currency.ARS:
      return ARS
    case Currency.AUD:
      return AUD
    case Currency.BGN:
      return BGN
    case Currency.BRL:
      return BRL
    case Currency.CAD:
      return CAD
    case Currency.CHF:
      return CHF
    case Currency.CLP:
      return CLP
    case Currency.COP:
      return COP
    case Currency.CRC:
      return CRC
    case Currency.CZK:
      return CZK
    case Currency.DKK:
      return DKK
    case Currency.EUR:
      return EUR
    case Currency.GBP:
      return GBP
    case Currency.HUF:
      return HUF
    case Currency.ILS:
      return ILS
    case Currency.ISK:
      return ISK
    case Currency.JPY:
      return JPY
    case Currency.KRW:
      return KRW
    case Currency.MXN:
      return MXN
    case Currency.NOK:
      return NOK
    case Currency.NZD:
      return NZD
    case Currency.PLN:
      return PLN
    case Currency.RON:
      return RON
    case Currency.RUB:
      return RUB
    case Currency.SEK:
      return SEK
    case Currency.TRY:
      return TRY
    case Currency.USD:
      return USD
    default:
      throw new Error(`Unsupported currency: ${Currency[currency]}`)
  }
}

interface TransformerParams {
  value: string
  currency: DineroCurrency<number>
}

export function localizedPrice(dineroObject: Dinero<number>, locale: string, fullCents = false) {
  function transformer({ value, currency }: TransformerParams) {
    return Number(value).toLocaleString(locale, {
      style: 'currency',
      currency: currency.code,
      currencyDisplay: 'symbol',
      minimumFractionDigits: fullCents ? 2 : 0,
      maximumFractionDigits: 2,
    })
  }

  return toDecimal(dineroObject, transformer)
}
