import { Subheading } from '@/components/heading.tsx'
import { Text } from '@/components/text.tsx'
import { Trans } from '@lingui/macro'
import type { ReactNode } from 'react'

export function BasicDetailsSection(props: {
  isAirbnb: boolean
  bedroomsField: ReactNode
  bathroomsField: ReactNode
  bedsField: ReactNode
  styleField: ReactNode
  yearBuiltField: ReactNode
}) {
  return (
    <section className='grid gap-x-8 gap-y-6 sm:grid-cols-2'>
      <div className='space-y-1'>
        <Subheading>
          <Trans context='EditListingDetailsPage' comment='Subheading text for the basic details section'>
            Basic Details
          </Trans>
        </Subheading>
        <Text>
          <Trans context='EditListingDetailsPage' comment='Detail text for the basic details section'>
            Provide essential information about your property, including the number of bedrooms, bathrooms, beds, style,
            and year built.
          </Trans>
        </Text>
      </div>
      <div className='grid grid-cols-2 gap-6'>
        {props.bedroomsField}
        {props.bathroomsField}
        {props.isAirbnb && props.bedsField}
        {props.isAirbnb || props.styleField}
        {props.isAirbnb || props.yearBuiltField}
      </div>
    </section>
  )
}
