import type { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 48 48' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M21.285 2.49a6.76 6.76 0 0 1 5.43 0c8.608 3.776 14.895 9.517 17.535 12.187 1.174 1.187 1.804 2.744 1.9 4.35.141 2.415.35 6.805.35 11.816 0 3.65-.11 6.97-.226 9.435a6.23 6.23 0 0 1-6.015 5.952c-3.46.128-8.93.27-16.259.27-7.328 0-12.8-.142-16.259-.27a6.23 6.23 0 0 1-6.015-5.952 205.402 205.402 0 0 1-.226-9.435c0-5.011.208-9.401.35-11.816.096-1.606.726-3.163 1.9-4.35 2.64-2.67 8.927-8.41 17.535-12.186ZM16 36a2 2 0 1 0 0 4h16a2 2 0 1 0 0-4H16Z'
      clipRule='evenodd'
    />
  </svg>
)
export default SvgComponent
