import { i18n } from '@lingui/core'

export async function dynamicActivate(locale: string) {
  try {
    const [language] = locale.split('-')
    const { messages } = await import(`./locales/${language}.po`)
    i18n.load(locale, messages)
    i18n.activate(locale)
  } catch (e) {
    const fallbackLocale = 'en'
    const { messages } = await import(`./locales/${fallbackLocale}.po`)
    i18n.load(fallbackLocale, messages)
    i18n.activate(fallbackLocale)
  }
}
