import {
  deleteListing,
  getListing,
  listListings,
} from '@/pb/service/listing/v1/listing_service-ListingsService_connectquery'
import { createConnectInfiniteQueryKey, createConnectQueryKey, useMutation } from '@connectrpc/connect-query'
import type { QueryClient } from '@tanstack/react-query'
import {
  listDescriptionsForAccount, listDescriptionsForListing
} from "@/pb/service/description/v1/description_service-DescriptionService_connectquery";

export function useDeleteListingMutation(queryClient: QueryClient) {
  return useMutation(deleteListing, {
    onSuccess: async (_data, variables) => {
      const { id } = variables
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getListing, { id }),
          refetchType: 'none',
          exact: true,
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectInfiniteQueryKey(listListings),
          refetchType: 'all',
          exact: true,
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectInfiniteQueryKey(listDescriptionsForAccount),
          refetchType: 'all',
          exact: true,
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectInfiniteQueryKey(listDescriptionsForListing, { listingId: id }),
          refetchType: 'all',
          exact: true,
        }),
      ])
    },
  })
}
