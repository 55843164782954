// @generated by protoc-gen-connect-query v1.4.1
// @generated from file service/feedback/v1/feedback_service.proto (package service.feedback.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { CreateFeedbackRequest, CreateFeedbackResponse } from "./feedback_service_pb.js";

/**
 * @generated from rpc service.feedback.v1.FeedbackService.CreateFeedback
 */
export const createFeedback = {
  localName: "createFeedback",
  name: "CreateFeedback",
  kind: MethodKind.Unary,
  I: CreateFeedbackRequest,
  O: CreateFeedbackResponse,
  service: {
    typeName: "service.feedback.v1.FeedbackService"
  }
};
