// @generated by protoc-gen-connect-query v1.4.1
// @generated from file service/media/v1/media_service.proto (package service.media.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { AddImagesToListingRequest, AddImagesToListingResponse, DeleteImageFromListingRequest, DeleteImageFromListingResponse, GetImageUploadUrlRequest, GetImageUploadUrlResponse } from "./media_service_pb.js";

/**
 * @generated from rpc service.media.v1.MediaService.GetImageUploadUrl
 */
export const getImageUploadUrl = {
  localName: "getImageUploadUrl",
  name: "GetImageUploadUrl",
  kind: MethodKind.Unary,
  I: GetImageUploadUrlRequest,
  O: GetImageUploadUrlResponse,
  service: {
    typeName: "service.media.v1.MediaService"
  }
};

/**
 * @generated from rpc service.media.v1.MediaService.AddImagesToListing
 */
export const addImagesToListing = {
  localName: "addImagesToListing",
  name: "AddImagesToListing",
  kind: MethodKind.Unary,
  I: AddImagesToListingRequest,
  O: AddImagesToListingResponse,
  service: {
    typeName: "service.media.v1.MediaService"
  }
};

/**
 * @generated from rpc service.media.v1.MediaService.DeleteImageFromListing
 */
export const deleteImageFromListing = {
  localName: "deleteImageFromListing",
  name: "DeleteImageFromListing",
  kind: MethodKind.Unary,
  I: DeleteImageFromListingRequest,
  O: DeleteImageFromListingResponse,
  service: {
    typeName: "service.media.v1.MediaService"
  }
};
