import { Button } from '@/components/button.tsx'
import { Divider } from '@/components/divider.tsx'
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '@/components/dropdown.tsx'
import { Heading } from '@/components/heading.tsx'
import { Link } from '@/components/link.tsx'
import { getLocalizedError } from '@/i18n/error-localization.ts'
import { getListing } from '@/pb/service/listing/v1/listing_service-ListingsService_connectquery'
import { getImageUploadUrl } from '@/pb/service/media/v1/media_service-MediaService_connectquery'
import {
  type GetImageUploadUrlResponse,
  ImageVariant,
  Image as ListingImage,
  UploadDetails,
  VariantType,
} from '@/pb/service/media/v1/media_service_pb'
import { ErrorPage } from '@/routes/-components/error-page.tsx'
import ResponsiveImage from '@/routes/-components/responsive-image.tsx'
import { Spinner } from '@/routes/-components/spinner.tsx'
import { useAddImagesToListingMutation } from '@/routes/_protected/_dashboard/-listings/mutations/useAddImagesMutation.ts'
import { useDeleteImageFromListingMutation } from '@/routes/_protected/_dashboard/-listings/mutations/useDeleteImageFromListingMutation.ts'
import { isDarkMode } from '@/utils/theme-util.ts'
import { toastOptions } from '@/utils/toast-util.ts'
import type { Transport } from '@connectrpc/connect'
import { callUnaryMethod, createQueryOptions, useSuspenseQuery } from '@connectrpc/connect-query'
import { EllipsisHorizontalIcon, PhotoIcon, PlusIcon } from '@heroicons/react/16/solid'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { Trans, msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import * as Sentry from '@sentry/react'
import { createFileRoute } from '@tanstack/react-router'
import { clsx } from 'clsx'
import heic2any from 'heic2any'
import { type ChangeEvent, type ReactNode, useRef } from 'react'
import { toast } from 'sonner'
import { useImmer } from 'use-immer'

export const Route = createFileRoute('/_protected/_dashboard/listings/$id/edit/photos')({
  component: EditPhotos,
  loader: async ({ context: { queryClient, connectTransport }, params }) => {
    await queryClient.prefetchQuery({
      ...createQueryOptions(getListing, { id: params.id }, { transport: connectTransport }),
    })
  },
})

function EditPhotos(): ReactNode {
  const params = Route.useParams()
  const { connectTransport, queryClient } = Route.useRouteContext()

  const listingId = params.id

  const { data } = useSuspenseQuery(getListing, { id: params.id })
  const deleteImageFromListingMutation = useDeleteImageFromListingMutation(queryClient)
  const addImagesToListingMutation = useAddImagesToListingMutation(queryClient)

  if (data.listing === undefined) {
    return <ErrorPage />
  }

  const listing = data.listing

  const [images, setImages] = useImmer(listing.images)
  const [uploadProgress, setUploadProgress] = useImmer(Array(6).fill(null))

  const fileInputRef = useRef<HTMLInputElement>(null)

  const { _ } = useLingui()

  function updateUploadProgress(index: number, progress: number) {
    setUploadProgress((draft) => {
      draft[index] = progress
    })
  }

  function clearUploadProgress() {
    setUploadProgress((draft) => draft.map(() => null))
  }

  function deleteImageWithID(id: string) {
    setImages((draft) => {
      const index = draft.findIndex((image) => image.id === id)
      if (index !== -1) draft.splice(index, 1)
    })
  }

  function setPreviewUrlForImage(
    idx: number,
    uploadDetails: Awaited<{
      uploadDetails: UploadDetails
      localPreviewUrl: string
    }>,
  ) {
    setImages((draft) => {
      draft[idx] = new ListingImage({
        id: extractImageId(uploadDetails.uploadDetails.objectName),
        variants: [new ImageVariant({ url: uploadDetails.localPreviewUrl, width: 800, type: VariantType.MEDIUM })],
      })
    })
  }

  async function handleFileInputChange(event: ChangeEvent<HTMLInputElement>): Promise<void> {
    if (!event.target.files) return
    const files = Array.from(event.target.files)

    const availableSlots = 6 - images.length
    const filesToUpload = files.slice(0, availableSlots)

    const uploadPromises = filesToUpload.map(async (file, index) => {
      let fileToUpload = file
      let localPreviewUrl = URL.createObjectURL(file)
      if (file.type === 'image/heic') {
        updateUploadProgress(images.length + index, 10)

        const blob = (await heic2any({
          blob: file,
          toType: 'image/jpeg',
          quality: 0.92,
        })) as Blob
        fileToUpload = new File([blob], file.name.replace(/\..+$/, '.jpeg'), { type: 'image/jpeg' })
        localPreviewUrl = URL.createObjectURL(blob)
      }

      const signedUrlResponse = await getSignedUrl(connectTransport, listingId, fileToUpload)
      await uploadImage(fileToUpload, signedUrlResponse.signedUrl, (progress) => {
        updateUploadProgress(images.length + index, progress)
      })
      const { width, height } = await getImageDimensions(fileToUpload)
      const uploadDetails = new UploadDetails({
        width,
        height,
        objectName: signedUrlResponse.objectName,
      })
      return {
        localPreviewUrl,
        uploadDetails,
      }
    })

    try {
      const uploadDetails = await Promise.all(uploadPromises)

      await addImagesToListingMutation.mutateAsync({
        listingId: listingId,
        uploadDetails: uploadDetails.map((value) => value.uploadDetails),
      })

      uploadDetails.forEach((value, index) => {
        setPreviewUrlForImage(images.length + index, value)
      })

      clearUploadProgress()
      toast.success(
        _(
          msg({
            context: 'ToastAlert',
            comment: `Toast alert text that's shown when a photo is successfully added uploaded for a listing`,
            message: 'Photos successfully uploaded',
          }),
        ),
        toastOptions,
      )
    } catch (e) {
      clearUploadProgress()
      const { isExpectedError, message } = getLocalizedError(e)
      if (!isExpectedError) {
        Sentry.captureException(e)
      }
      toast.error(_(message), toastOptions)
    }
  }

  async function handleDeleteImageClicked(imageId: string) {
    try {
      await deleteImageFromListingMutation.mutateAsync({
        imageId: imageId,
      })

      deleteImageWithID(imageId)
    } catch (e) {
      const { isExpectedError, message } = getLocalizedError(e)
      if (!isExpectedError) {
        Sentry.captureException(e)
      }
      toast.error(_(message), toastOptions)
    }
  }

  function handleAddPhotosClicked(): void {
    if (images.length < 6) {
      fileInputRef.current?.click()
    }
  }

  return (
    <>
      <div className='hidden lg:block'>
        <Link
          from={Route.fullPath}
          href='/listings/$id'
          params={{ id: params.id }}
          className='inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400'
        >
          <ChevronLeftIcon className='size-4 fill-zinc-400 dark:fill-zinc-500' />
          <Trans context='EditListingPhotosPage' comment='Link text for navigating back to the listing detail page'>
            Listing
          </Trans>
        </Link>
      </div>
      <div className='flex items-center justify-between gap-4 lg:mt-10'>
        <Heading>
          <Trans context='EditListingPhotosPage' comment='Heading text for the edit listings photos page'>
            Edit Photos
          </Trans>
        </Heading>
        <Button onClick={handleAddPhotosClicked} disabled={images.length === 6}>
          <PlusIcon className='text-white' />
          <Trans context='EditListingPhotosPage' comment='Button text for adding photos to a listing'>
            Add photos
          </Trans>
        </Button>
      </div>
      <Divider soft className='mt-6' />
      <div className='@container mt-8'>
        <div className='-mx-2 flex flex-wrap justify-between'>
          {images.map((img) => (
            <ImagePreview
              key={img.id}
              image={img}
              onEmptyImageClicked={handleAddPhotosClicked}
              onDeleteImage={handleDeleteImageClicked}
            />
          ))}
          {Array.from({ length: 6 - images.length }, (_, i) => (
            <ImagePreview
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              key={i + images.length}
              progress={uploadProgress[i + images.length]}
              onEmptyImageClicked={handleAddPhotosClicked}
            />
          ))}
        </div>
        <input
          ref={fileInputRef}
          type='file'
          accept='image/jpeg, image/png, image/heic'
          multiple
          onChange={handleFileInputChange}
          className='hidden'
        />
      </div>
    </>
  )
}

function ImagePreview({
  image,
  onEmptyImageClicked,
  progress,
  onDeleteImage,
}: {
  image?: ListingImage
  onEmptyImageClicked: () => void
  progress?: number
  onDeleteImage?: (imageId: string) => void
}): ReactNode {
  return (
    <div className='mb-4 aspect-[3/2] h-full w-full px-2 sm:w-1/2'>
      <div
        onClick={() => {
          if (!image && !progress) onEmptyImageClicked()
        }}
        className={clsx(
          'relative flex h-full w-full flex-col overflow-hidden rounded-lg',
          !image && 'items-center justify-center border-2 border-gray-300 border-dashed dark:border-zinc-600',
          !image && 'hover:bg-gray-50 active:bg-gray-100 dark:active:bg-zinc-700 dark:hover:bg-zinc-800',
        )}
      >
        {progress ? (
          <div className='flex size-full items-center justify-center bg-gray-100 dark:bg-zinc-800'>
            <Spinner {...(!isDarkMode() && { dark: true })} />
          </div>
        ) : null}
        {!image && !progress && <PhotoIcon className='size-8 fill-gray-300 dark:fill-zinc-600' />}
        {image && !progress && (
          <div className='relative h-full w-full'>
            <div className='absolute top-2 right-2'>
              <Dropdown>
                <DropdownButton color='light'>
                  <EllipsisHorizontalIcon className='fill-zinc-950 dark:fill-white' />
                </DropdownButton>
                <DropdownMenu>
                  <DropdownItem onClick={() => onDeleteImage?.(image.id)}>
                    <Trans context='EditListingPhotosPage' comment='Dropdown item text that deletes the photo'>
                      Delete
                    </Trans>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <ResponsiveImage
              sizes='(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 25vw'
              alt='Listing image'
              variants={image.variants}
              className='size-full object-cover object-center'
            />
          </div>
        )}
      </div>
    </div>
  )
}

function extractImageId(objectName: string): string {
  const parts = objectName.split('/')
  const index = parts.indexOf('images') + 1
  return index > 0 && index < parts.length ? parts[index] : ''
}

function uploadImage(file: File, signedUrl: string, onProgress: (progress: number) => void): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', signedUrl, true)
    xhr.setRequestHeader('Content-Type', file.type)
    xhr.setRequestHeader('Cache-Control', 'public, max-age=31536000')

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded / event.total) * 100)
        onProgress(progress)
      }
    }

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve()
      } else {
        reject(new Error(`error when uploading: ${xhr.status}`))
      }
    }

    xhr.onerror = () => {
      reject(new Error('error when uploading'))
    }

    xhr.send(file)
  })
}

function getSignedUrl(connectTransport: Transport, listingId: string, file: File): Promise<GetImageUploadUrlResponse> {
  return callUnaryMethod(
    getImageUploadUrl,
    {
      listingId: listingId,
      contentType: file.type,
    },
    { transport: connectTransport },
  )
}

interface ImageDimensions {
  width: number
  height: number
}

function getImageDimensions(file: File): Promise<ImageDimensions> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight })
    }
    img.onerror = () => reject(new Error())
    img.src = URL.createObjectURL(file)
  })
}
