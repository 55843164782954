// @generated by protoc-gen-es v1.10.0
// @generated from file service/auth/v1/auth_service.proto (package service.auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message service.auth.v1.LoginRequest
 */
export const LoginRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.LoginRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.auth.v1.LoginResponse
 */
export const LoginResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.LoginResponse",
  () => [
    { no: 1, name: "id_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "expires_in", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "email_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "pending_account_creation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message service.auth.v1.SignUpRequest
 */
export const SignUpRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.SignUpRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.auth.v1.SignUpResponse
 */
export const SignUpResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.SignUpResponse",
  () => [
    { no: 1, name: "id_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "expires_in", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message service.auth.v1.RefreshIdTokenRequest
 */
export const RefreshIdTokenRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.RefreshIdTokenRequest",
  () => [
    { no: 1, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.auth.v1.RefreshIdTokenResponse
 */
export const RefreshIdTokenResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.RefreshIdTokenResponse",
  () => [
    { no: 1, name: "id_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "expires_in", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message service.auth.v1.SendPasswordResetEmailRequest
 */
export const SendPasswordResetEmailRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.SendPasswordResetEmailRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.auth.v1.SendPasswordResetEmailResponse
 */
export const SendPasswordResetEmailResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.SendPasswordResetEmailResponse",
  [],
);

/**
 * @generated from message service.auth.v1.SendVerificationEmailRequest
 */
export const SendVerificationEmailRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.SendVerificationEmailRequest",
  [],
);

/**
 * @generated from message service.auth.v1.SendVerificationEmailResponse
 */
export const SendVerificationEmailResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.SendVerificationEmailResponse",
  [],
);

/**
 * @generated from message service.auth.v1.ConfirmEmailVerificationCodeRequest
 */
export const ConfirmEmailVerificationCodeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.ConfirmEmailVerificationCodeRequest",
  () => [
    { no: 1, name: "oob_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.auth.v1.ConfirmEmailVerificationCodeResponse
 */
export const ConfirmEmailVerificationCodeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.ConfirmEmailVerificationCodeResponse",
  [],
);

/**
 * @generated from message service.auth.v1.ConfirmEmailRecoveryCodeRequest
 */
export const ConfirmEmailRecoveryCodeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.ConfirmEmailRecoveryCodeRequest",
  () => [
    { no: 1, name: "oob_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.auth.v1.ConfirmEmailRecoveryCodeResponse
 */
export const ConfirmEmailRecoveryCodeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.ConfirmEmailRecoveryCodeResponse",
  () => [
    { no: 1, name: "recovered_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.auth.v1.ConfirmPasswordResetCodeRequest
 */
export const ConfirmPasswordResetCodeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.ConfirmPasswordResetCodeRequest",
  () => [
    { no: 1, name: "oob_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "new_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.auth.v1.ConfirmPasswordResetCodeResponse
 */
export const ConfirmPasswordResetCodeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.ConfirmPasswordResetCodeResponse",
  [],
);

/**
 * @generated from message service.auth.v1.ConfirmVerifyAndChangeEmailCodeRequest
 */
export const ConfirmVerifyAndChangeEmailCodeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.ConfirmVerifyAndChangeEmailCodeRequest",
  () => [
    { no: 1, name: "oob_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.auth.v1.ConfirmVerifyAndChangeEmailCodeResponse
 */
export const ConfirmVerifyAndChangeEmailCodeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.ConfirmVerifyAndChangeEmailCodeResponse",
  [],
);

/**
 * @generated from message service.auth.v1.SendVerifyAndChangeEmailRequest
 */
export const SendVerifyAndChangeEmailRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.SendVerifyAndChangeEmailRequest",
  () => [
    { no: 1, name: "new_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.auth.v1.SendVerifyAndChangeEmailResponse
 */
export const SendVerifyAndChangeEmailResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.SendVerifyAndChangeEmailResponse",
  [],
);

/**
 * @generated from message service.auth.v1.ChangePasswordRequest
 */
export const ChangePasswordRequest = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.ChangePasswordRequest",
  () => [
    { no: 1, name: "current_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "new_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message service.auth.v1.ChangePasswordResponse
 */
export const ChangePasswordResponse = /*@__PURE__*/ proto3.makeMessageType(
  "service.auth.v1.ChangePasswordResponse",
  () => [
    { no: 1, name: "id_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "expires_in", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

